import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';


import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';

import { Divider } from 'primereact/divider';
 

import { useForm, Controller } from 'react-hook-form';

import { api, URL_CALENDAR_CREATE, URL_CALENDAR_DELETE } from '@/services/api';
import { formatShortName } from '@/services/utils';
import { useNavigate } from 'react-router-dom';


export const UIFullcalendarDay = (_data) => {


    const navigate = useNavigate();

    const [showEvent, setShowEvent] = useState(false);
    const [showEventAdd, setShowEventAdd] = useState(false);
    const [showEventEdit, setShowEventEdit] = useState(false);
    const [contentEvent, setContentEvent] = useState();
    const [selectedTime, setSetSelectedTime] = useState();

    const events = _data.events?.filter(ev => {
        const dt_event = new Date(~~ev.date.split('-')[0], (~~ev.date.split('-')[1]) -1, ~~ev.date.split('-')[2]);
        return dt_event.toLocaleDateString() === _data.date.toLocaleDateString();
    })

    ////console.log(events);

    //const step = ~~_data?.step || 30;
    const step = 1;

    //console.log(_data);

    //const step = 30;
    
    const listTimes = [];

    let isComplete = false;

    let line = 0;
    let lineTime = 0;

    let columnsRef = 0

    console.log('Day', _data);

    const timer = new Date(_data.date.getTime());
    timer.setHours(8);
    timer.setMinutes(0);
    timer.setSeconds(0);
    timer.setMilliseconds(0);

    do{
        if( _data?.end){
            listTimes.push(timer.toLocaleTimeString());
            timer.setMinutes(timer.getMinutes() + step);
            const _hour = _data?.end ? ~~_data?.end?.split(':')[0] : null;
            const _minutes = _data?.end ?~~ _data?.end?.split(':')[1] : null;

            if(!_hour && !_minutes)
                isComplete = true;


            if((timer.getHours() >= _hour && timer.getMinutes() > _minutes))
                isComplete = true;
        }else{
            isComplete = true;
        }

    }while(!isComplete);

    const defaultEvent = useMemo(()=>(
        {note:''}
    ),[]);

    const form = useForm(defaultEvent);
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const pointer = useRef();
    const pointerUpdate = useRef();
    const hasEventID = useRef(0);


    const  isVisibilite = (_time = null) => {

        const _isEditable = _data?.all_hours_day;

        if(_isEditable)
            return _isEditable;

        const select_temp = new Date(_data.date.getTime());

        ////console.log(_time);

        let _today_time;

        if(_time){
            _today_time = _time.split(':');
            select_temp.setHours(~~_today_time[0]);
            select_temp.setMinutes(~~_today_time[1]);
            select_temp.setSeconds(~~_today_time[2]);
            select_temp.setMilliseconds(999);
        }

        const start_temp = new Date(_data.date.getTime());

        
        if(_data?.start){
            const  _start = _data?.start.split(':');
            start_temp.setHours(~~_start[0]);
            start_temp.setMinutes(~~_start[1]);
            start_temp.setSeconds(~~_start[2]);
            start_temp.setMilliseconds(0);
        }else{
            start_temp.setHours(8);
            start_temp.setMinutes(0);
            start_temp.setSeconds(0);
            start_temp.setMilliseconds(0);
        }
        

        const end_temp = new Date(_data.date.getTime());
        if(_data?.end){
            const  _end = _data?.end.split(':');
            end_temp.setHours(~~_end[0]);
            end_temp.setMinutes(~~_end[1]);
            end_temp.setSeconds(~~_end[2]);
            end_temp.setMilliseconds(0);
        }else{
            end_temp.setHours(18);
            end_temp.setMinutes(0);
            end_temp.setSeconds(0);
            end_temp.setMilliseconds(0);
        }

        return (start_temp.getTime() <= select_temp.getTime() && end_temp.getTime()  >= select_temp.getTime());
    }
    
    const isEditable = (_time = null, buttonCreate = false) => {

        let _isEditable = true;

        
        const today_temp = new Date();
        
        const select_temp = new Date(_data.date.getTime());

        if(today_temp.toLocaleDateString() === select_temp.toLocaleDateString()){
            const _today_time = _time.split(':');
            select_temp.setHours(_today_time[0]);
            select_temp.setMinutes(_today_time[1]);
            select_temp.setSeconds(_today_time[2]);
            select_temp.setMilliseconds(999);
        }else{
            select_temp.setHours(23);
            select_temp.setMinutes(59);
            select_temp.setSeconds(59);
            select_temp.setMilliseconds(999);
        }

        _isEditable = today_temp.getTime() <= select_temp.getTime();

        let _today_time;

        if(_time){
            _today_time = _time.split(':');
            select_temp.setHours(~~_today_time[0]);
            select_temp.setMinutes(~~_today_time[1]);
            select_temp.setSeconds(~~_today_time[2]);
            select_temp.setMilliseconds(999);
        }

        const start_temp = new Date(_data.date.getTime());
        if(_data?.start){
            const  _start = _data?.start.split(':');
            start_temp.setHours(~~_start[0]);
            start_temp.setMinutes(~~_start[1]);
            start_temp.setSeconds(~~_start[2]);
            start_temp.setMilliseconds(0);
        }else{
            start_temp.setHours(0);
            start_temp.setMinutes(0);
            start_temp.setSeconds(0);
            start_temp.setMilliseconds(0);
        }
        

        const end_temp = new Date(_data.date.getTime());
        if(_data?.end){
            const  _end = _data?.end.split(':');
            end_temp.setHours(~~_end[0]);
            end_temp.setMinutes(~~_end[1]);
            end_temp.setSeconds(~~_end[2]);
            end_temp.setMilliseconds(0);
        }else{
            start_temp.setHours(23);
            start_temp.setMinutes(59);
            start_temp.setSeconds(59);
            start_temp.setMilliseconds(999);
        }
        
        if(buttonCreate){
             return _isEditable && (start_temp.getTime() <= select_temp.getTime() && end_temp.getTime()  >= select_temp.getTime()) &&  (_data.timeBlock ? _data.timeBlock[_time]?.length > 0 : true );
        }
        
        return _isEditable && (start_temp.getTime() <= select_temp.getTime() && end_temp.getTime()  >= select_temp.getTime());


    }

    const openEvent = (_event) => {
        //console.log(_event);
        setContentEvent(_event);
        setShowEvent(true);
    }

    const addEvent = (_event) => {
        setSetSelectedTime(_event);
        setShowEventAdd(true);
        reset(defaultEvent);
    }

    const editEvent = (_event) => {
        setShowEventEdit(true);
    }

    const removeEvent = (_event) => {

        const acceptBox = async () =>{
            await api.delete(`${URL_CALENDAR_DELETE}/${_event.id}`, null, true);
            _data.forceUpdate();
        }

        confirmDialog({
            message: `Deseja desmarcar este horário? #${_event.id}`,
            header: 'Atenção',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:'Sim',
            rejectLabel:'Não',
            accept: acceptBox,
            reject: () => {}
        });
    }
    
    const mountBox = () => {
        
        if(listTimes?.length === 0){
            return <div style={{gridColumn:'1 / span 2'}}>
                Não há horários disponíveis
            </div>
        }

        console.log('Eventos', events);
        const min = Math.max(1000 - 150, 180 * events.length);

        return <>
                {
                        listTimes?.map((t, i) => {
                            if(isVisibilite(t))
                                lineTime++;
    
                            return <div data-line={lineTime} style={{'--widthSize': `${min}px`}} className={`timer ${isVisibilite(t) ? 'enable' : 'disable'} ${lineTime === 1 || (lineTime - 1) % 10 === 0 || i === listTimes.length - 1 ? 'max' : 'min' }`}>
                                    {t}
                                    <div onClick={()=>addEvent(t)} className="add-event" style={{display: (isEditable(t, true) && _data?.create ? 'flex' : 'none')}}>
                                        <i className='pi pi-plus'></i>
                                    </div>
                                </div>
                        })
                    }
                    {
                        listTimes?.map((t, i) => {
                            const _events = events?.filter(el => t === el.time);
                            
                            return _events?.map((ev, c) => {
                                
                                let dt = (ev?.end ? ev?.end : ev?.timepreview);

                                if(!dt){
                                    dt = new Date(`${ev.date} ${ev.time}`);
                                    dt.setMinutes(dt.getMinutes() + 90);
                                    dt = dt.toLocaleTimeString();
                                    //console.log('data', dt);

                                    //console.log(dt);
                                }

                                const [sh, sm] = dt?.split(':')
                                

                                const [eh, em] = t.split(':');

                                const time =  ((~~eh * 60) + (~~em) ) - ((~~sh * 60) + (~~sm ) );
                                
                                const totallines = Math.abs(time / step);
                                const lines = `${i + 1} / span ${totallines + 1}`;

                                //const colTemp = Math.max(~~ev.room_meeting_id + 2, 2);
                                const colTemp = Math.max(~~columnsRef + 2, 2);
                                columnsRef++;
                                    return <div style={{'--c':colTemp, '--l': lines, '--color':ev?.room_color || 'var(--yellow-200)'}} className={`events`}>
                                            {/* <div className='title'>{ev?.identification}</div>    */}
                                             {
                                                ~~ev?.block === 1 ?
                                                <div className='info'>

                                                    <div className='buttons'>
                                                        <div style={{display: (_data?.show ? 'flex' : 'none')}} onClick={()=>openEvent(ev)} className="icon-edit">
                                                            <i className='pi pi-book'></i>
                                                        </div>
                                                        <div style={{display: (_data?.show ? 'flex' : 'none'), color:'var(--red-500)' }} onClick={()=>removeEvent(ev)} className="icon-edit">
                                                            <i className='pi pi-times'></i>
                                                        </div>
                                                    </div>
                                                    <div className='location'>
                                                        <div><strong><small>Ausência</small></strong></div>
                                                    </div>
                                                    <div className='location'>
                                                        <div><strong><small>Responável</small></strong></div>
                                                        {ev?.prefixe} {formatShortName(ev?.name)}
                                                    </div>
                                                    <div className='location'>
                                                        <div><strong><small>Período previsto</small></strong></div>
                                                        {ev?.time} / {ev?.end}
                                                    </div>
                                                </div>
                                                :
                                                <div className='info'>
                                                    <div className='buttons'>
                                                        <div style={{display: (_data?.show ? 'flex' : 'none')}} onClick={()=>openEvent(ev)} className="icon-edit">
                                                            <i className='pi pi-book'></i>
                                                        </div>
                                                        <div style={{display: (_data?.show ? 'flex' : 'none'), color:'var(--blue-500)' }} onClick={()=>navigate('/user/attendance/create',{state:{calendar: ev}})} className="icon-edit">
                                                            <i className='pi pi-eye'></i>
                                                        </div>
                                                        <div style={{display: (_data?.show ? 'flex' : 'none'), color:'var(--red-500)' }} onClick={()=>removeEvent(ev)} className="icon-edit">
                                                            <i className='pi pi-times'></i>
                                                        </div>
                                                    </div>
                                                    <div className='location'>
                                                        <div><strong><small>Local</small></strong></div>
                                                        {ev?.room ? `${ev?.room}` : ev?.location}
                                                    </div>
                                                    <div className='location'>
                                                        <div><strong><small>Responável</small></strong></div>
                                                        {ev?.prefixe} {formatShortName(ev?.name)}
                                                    </div>
                                                    <div className='location'>
                                                        <div><strong><small>Cliente</small></strong></div>
                                                        {ev?.client_name}
                                                    </div>
                                                    <div className='location'>
                                                        <div><strong><small>Período previsto</small></strong></div>
                                                        {ev?.time} / {ev?.timepreview}
                                                    </div>
                                                </div>
                                             }
                                            
                                    </div>
                            })

                        })

                    }

                </>

        
    }

    const onSubmit = useCallback(async (_event) => {

        const eventdata = {
            date: `${_data.date.getFullYear()}-${_data.date.getMonth() + 1}-${_data.date.getDate()}`,
            time:selectedTime,
            ..._event
        }

        await api.post(URL_CALENDAR_CREATE, eventdata, true);

        reset(defaultEvent);

        setShowEventAdd(false);
        
        _data.forceUpdate();

    },[_data, defaultEvent, reset, selectedTime]);

    useEffect(()=>{

        if(pointer.current){
            if(!pointerUpdate.current){
                setInterval(()=>{
                    const total = (3600 * 23) + (60 * 60) + 60 ;
                    const currentDate = new Date();
                    const current  =  (currentDate.getHours() * 3600) + (currentDate.getMinutes() * 60) + currentDate.getSeconds();
                    const percent = ~~(current / total * 100);
                    try{
                        if(pointer.current['style'])
                            pointer.current['style']['top'] = `calc(${percent}% + 45px)`;
                    }catch(ex){}
                }, 1000);
            }
        }

    }, [pointer])

    return (
        <div className="ui-fullcalendar-day" >
            <div className="days-day-calendar last">
                {/* <div ref={pointer} className="timer-pointer"></div> */}
                {mountBox()}
            </div>
            <Dialog header="Detalhes" 
                visible={showEvent} 
                style={{ width: '98%', maxWidth:'600px' }} 
                onHide={() => setShowEvent(false)}>
                    {
                        ~~contentEvent?.block === 1 ?
                        <>
                            <Divider type='dashed' align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-briefcase mr-2"></i>
                                    <b>Responsável</b>
                                </div>
                            </Divider>
                            <div style={{paddingLeft:'25px'}}>
                                {contentEvent?.prefixe} {contentEvent?.name}
                            </div>
                            <Divider type='dashed' align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-clock mr-2"></i>
                                    <b>Período previsto</b>
                                </div>
                            </Divider>
                            <div style={{paddingLeft:'25px'}}>
                                {contentEvent?.time} / {contentEvent?.timepreview} {contentEvent?.end}
                            </div>
                            <Divider type='dashed' align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-book mr-2"></i>
                                    <b>Motivo</b>
                                </div>
                            </Divider>
                            <div style={{paddingLeft:'25px'}} dangerouslySetInnerHTML={{__html: contentEvent?.note}}></div>
                            
                        </>
                        :
                        <>
                            <Divider type='dashed' align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-hashtag mr-2"></i>
                                    <b>ID</b>
                                </div>
                            </Divider>
                            <div style={{paddingLeft:'25px'}}>
                                { contentEvent?.identification}
                            </div>

                            <Divider type='dashed' align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-clock mr-2"></i>
                                    <b>Período previsto</b>
                                </div>
                            </Divider>
                            <div style={{paddingLeft:'25px'}}>
                                {contentEvent?.time} / {contentEvent?.timepreview} {contentEvent?.end ? ` - (Finalizado em : ${contentEvent?.end})` : ''}
                            </div>

                            <Divider type='dashed' align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-briefcase mr-2"></i>
                                    <b>Responsável</b>
                                </div>
                            </Divider>
                            <div style={{paddingLeft:'25px'}}>
                                {contentEvent?.prefixe} {contentEvent?.name}
                            </div>
                            <Divider type='dashed' align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>
                                    <b>Cliente</b>
                                </div>
                            </Divider>
                            <div style={{paddingLeft:'25px'}}>
                                {contentEvent?.client_name}
                            </div>
                            <Divider type='dashed' align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-map-marker mr-2"></i>
                                    <b>Local</b>
                                </div>
                            </Divider>
                            <div style={{paddingLeft:'25px'}}>
                            {contentEvent?.room ? `${contentEvent?.room}` : contentEvent?.location}
                            </div>
                            <Divider type='dashed' align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-book mr-2"></i>
                                    <b>Notas</b>
                                </div>
                            </Divider>
                            <div style={{paddingLeft:'25px'}} dangerouslySetInnerHTML={{__html: contentEvent?.note}}>
                            </div>
                        </>
                    }
            </Dialog>
            <Dialog header={`Marcar horário : ${selectedTime}`}
                visible={showEventAdd} 
                style={{ width: '98%', maxWidth:'600px' }} 
                onHide={() => setShowEventAdd(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid">
                        <div className="col-12">
                            <Controller name={`note`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputTextarea placeholder='Informe o assunto' autoComplete='off' className='p-inputtext-sm' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-12 mt-3" style={{textAlign:'right'}} >
                            <Button className='p-button-sm' label='Marcar' />
                        </div>
                    </div>
                </form>
            </Dialog>
            <Dialog header="Editar" 
                visible={showEventEdit} 
                style={{ width: '98%', maxWidth:'600px' }} 
                onHide={() => setShowEventEdit(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid">
                        <div className="col-12">
                            <Controller name={`note`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputTextarea placeholder='Informe o assunto' autoComplete='off' className='p-inputtext-sm' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-12 mt-3" style={{textAlign:'right'}} >
                            <Button className='p-button-sm' label='Atualizar' />
                        </div>
                    </div>
                </form>
            </Dialog>
            {/* <ConfirmDialog /> */}
        </div>
    )
}