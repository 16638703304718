import React, { useCallback, useState, useRef, useEffect } from 'react';

import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

import { Toast } from 'primereact/toast';
 

import { MultiSelect } from 'primereact/multiselect';
 

import { useForm, Controller } from 'react-hook-form';


import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { useNavigate } from 'react-router-dom';
import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { api, URL_COLLABORATORS, URL_USER_AREAS, URL_PROCESSCASES_ALL, URL_TICKET_TAG, URL_TICKET_CREATE, URL_TICKET_OFFICE, URL_TICKET_ACTING } from '@/services/api';
import { useAPP } from '@/services/provider/app';

export const PageUserTicketCreate = () => {

    const { user } = useAPP();

    const navigate = useNavigate();

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const created = useRef(null);
    created.current = created.current ? created.current : new Date();

    const toast = useRef(null);

    const form = useForm({});
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    

    const [collaboratorsList, setCollaboratorsList] = useState();
    const [areasList, setAreasList] = useState();
    const [casesList, setCasesList] = useState();
    const [tagList, setTagList] = useState();
    const [actingList, setActingList] = useState();
    const [officeList, setOfficeList] = useState();


    const getCollaborators = useCallback(async () => {
        const data = await api.get(URL_COLLABORATORS, null, true);
        setCollaboratorsList(data.data);
        
    }, [])
    
    const getAreas = useCallback(async () => {
        const data = await api.get(URL_USER_AREAS, null, true);
        setAreasList(data.data);
        
    }, [])
    
    const getCases = useCallback(async () => {
        let data = await api.get(URL_PROCESSCASES_ALL, null, true);
        data.data = data.data.map(item => {
            item.label = `${item.name} ${item.process_number ? `- ${item.process_number} -${item?.name_client} - ${item?.cpf_cnpj}` : ''}`;
            return item;
        })
        
        setCasesList(data.data);

    }, [])

    const getTags = useCallback(async ()=> {

        const data = await api.get(URL_TICKET_TAG, null, true);

        setTagList(data.data);


    }, [])

    const getActing = useCallback(async ()=> {

        const data = await api.get(URL_TICKET_ACTING, null, true);

        setActingList(data.data);


    }, [])

    const getOffice = useCallback(async ()=> {

        const data = await api.get(URL_TICKET_OFFICE, null, true);

        setOfficeList(data.data);


    }, [])

    const onSubmit = useCallback(async (_data) => {
        const sendData = {
            ticket: {
                datetime_start: `${new Date(created.current).toISOString().substr(0, 10)} ${new Date(created.current).toLocaleTimeString()}`,
                user_id: _data.adm,
                process_cases_id: _data.case,
                user_area_id: _data.acting,
                user_office_id: _data.office,
                user_open_id: user.id,
                datetime_end: `${new Date(_data.timeend).toISOString().substr(0, 10)} ${new Date(_data.timeend).toLocaleTimeString()}`,
                priority: ~~_data.priority === 2 ? true : false,
                description: _data.description
            },
            ticket_ticket_tag:_data?.tag?.map(item => ({ticket_tag_id: item}))
        };

        await api.post(URL_TICKET_CREATE,  sendData, true);

        navigate('/user/tickets');

    }, [created, user])

    const onSubmitErros = useCallback((_data)=>{
        //console.log(_data);


        const erros = Object.keys(_data)?.map(item => {
            return ({severity:'error', summary:`Campo obrigatório`, detail:_data[item].message, life: 3000})
        })

        toast.current.show(erros);
    }, [])

    useEffect(()=>{
        getCollaborators();
        getAreas();
        getCases();
        getTags();
        getActing();
        getOffice();
    }, [getCollaborators, getAreas, getCases, getTags, getActing, getOffice])

    return (
        <div className="page-user-wiki-create">
            <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Ticket', url: '/user/tickets'},
                        {label:'Criar ticket'}
                    ]}
                />
            <div>
                <form onSubmit={handleSubmit(onSubmit, onSubmitErros)} >
                    <div className="grid mt-4">
                        <div className="col-4" style={{display:'flex', alignItems:'center'}}>
                          <strong>Criado em</strong>:  { created.current.toLocaleDateString() } - {created.current.toLocaleTimeString()}
                        </div>
                        <div className="col-4">
                            <Controller name={`timeend`} defaultValue=""  rules={{ required: 'Prazo limite' }} control={control}  render={({ field, fieldState }) => (
                                <Calendar showTime inputStyle={{width:'100%'}} style={{width:'100%'}} placeholder='Prazo limite' dateFormat="dd/mm/yy" showIcon locale='pt-BR' onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value} />
                            )} />
                        </div>
                        <div className="col-4">
                            <Controller name={`priority`} defaultValue="" rules={{ required: 'Prioridade' }} control={control}  render={({ field, fieldState }) => (
                                <Dropdown 
                                    options={[
                                        {label:'Não', value: 1},
                                        {label:'Sim', value: 2}
                                    ]}
                                    inputStyle={{width:'100%'}}  placeholder='Tem prioridade?' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        
                        </div>
                        <div className="col-6">
                            <Controller name={`acting`} defaultValue="" rules={{ required: 'Área de atuação' }} control={control}  render={({ field, fieldState }) => (
                                <Dropdown 
                                    filter
                                    options={actingList}
                                    optionLabel="name"
                                    optionValue='id'
                                    inputStyle={{width:'100%'}}  placeholder='Selecione área de atuação' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-6">
                            <Controller name={`office`} defaultValue="" rules={{ required: 'Área do escritório' }} control={control}  render={({ field, fieldState }) => (
                                <Dropdown 
                                    filter
                                    options={officeList}
                                    optionLabel="name"
                                    optionValue='id'
                                    inputStyle={{width:'100%'}}  placeholder='Selecione área do escritório' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-6">
                            <Controller name={`adm`} defaultValue=""  control={control}  render={({ field, fieldState }) => (
                                <Dropdown 
                                    filter
                                    options={collaboratorsList}
                                    optionLabel="name"
                                    optionValue='id'
                                    inputStyle={{width:'100%'}}  placeholder='Selecione o Responsável' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-6">
                            <Controller name={`case`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Dropdown 
                                    filter
                                    options={casesList}
                                    optionLabel="label"
                                    optionValue="id"
                                    inputStyle={{width:'100%'}}  placeholder='Selecione o caso' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`tag`} defaultValue=""  control={control}  render={({ field, fieldState }) => (
                                <MultiSelect 
                                    display="chip"
                                    options={tagList}
                                    optionLabel="name"
                                    optionValue="id"
                                    inputStyle={{width:'100%'}}  placeholder='Tag' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`description`} defaultValue="" rules={{ required: 'Descrição' }} control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <InputTextarea autoResize inputStyle={{width:'100%'}} placeholder='Descrição' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 mt-4 mb-4" style={{textAlign:'right'}}>
                            <Button onClick={()=> navigate('/user/tickets')} label="Cancelar" className='p-button-danger mr-2 p-button-sm' />
                            <Button label="Cadastrar" className='p-button-sm' />
                        </div>
                    </div>
                </form>
            </div>
            <Toast ref={toast} position="bottom-right" />
        </div>
    )
}