import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Menu } from 'primereact/menu';
import { Avatar } from 'primereact/avatar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Badge } from 'primereact/badge';
 

import { AutoComplete } from 'primereact/autocomplete';
 
 
 
import { api, URL_LOGOUT, URL_VALIDATESESSION, URL_FILES_FILE, URL_SYSTEM_SEARCH, URL_USER_MY_NOTIFICATION } from "@/services/api";

import logo from '@/assets/images/logo.png';

import '@/assets/styles/pages/user/index.scss';
import { setLogout } from "@/services/auth";
import { useAPP } from "@/services/provider/app";

import { SITE_MAP } from "@/services/provider/site";

export const NavigationFrameUser = () => {
    const navigate = useNavigate();

    const { user } = useAPP();

    const [search, setSearch] = useState();
    const [searchSugestion, setSearchSugestion] = useState([]);
    const [activeItem, setActiveItem] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [notificationList, setNotificationList] = useState([]);

    const [updatePage, setUpdatePage] = useState(0);

    const timerUpdate =  useRef();

    const executeLogout = useCallback(async()=>{
        await api.get(URL_LOGOUT, null, true);
        setLogout();
        navigate('');
    }, [navigate])

    let pre_id = 0;

    let menu_pre = user?.access.map((item, id) =>{
        let base = {};
        if(item?.menu_name){
            base = ({label:item?.menu_name, menu_mark: SITE_MAP[item?.page]?.menu_mark,  urlbase:item?.url, icon:item?.icon, command:()=>{ navigate(item?.url);}, className: (activeItem === pre_id ? 'active' : '')})
            pre_id++;
        }
        return base;
    });

    menu_pre = menu_pre.filter(item => item?.label)

    const menu = useMemo(()=>[
        ...menu_pre,
        {label:'Sair', icon:'pi pi-power-off', command:()=> executeLogout()}
    ], [executeLogout, menu_pre])

    const validadeSession = useCallback(async()=>{
        const data = await api.get(URL_VALIDATESESSION, false, true);
        if(!data?.data){
            executeLogout();
        }
    }, [executeLogout])


    const goTo = useCallback((_data) => {
        if(_data){
            setSearch(null);
            navigate(_data);
        }
    },[])

    const getSearchData = useCallback(async ()=> {
        const _data =  await api.get(URL_SYSTEM_SEARCH, {word: search}, true);
        let result = _data.data;

        //console.log(result);

        const _result = result?.map(item => {
            return ({label:`${item.type} - ${item.result}`, code:item.link});
        })

       setSearchSugestion(_result);

    }, [search])

    const openAlert = useCallback(async () =>{
        setShowAlert(true);
    }, [])

    const getNotification = useCallback(async () => {
        const dataNotification = await api.get(`${URL_USER_MY_NOTIFICATION}/10`, null, true);
        setNotificationList(dataNotification.data);

        timerUpdate.current = setTimeout(()=>{
            clearTimeout(timerUpdate.current);
            setUpdatePage(new Date().getTime());
        }, 60000);

    }, [])

    useEffect(()=> {

        if(search)
            goTo(search?.code);

    }, [search, goTo])

    useEffect(()=>{

        validadeSession();

        let id = -1;

        menu.map((c, n) =>{
            if(new RegExp(`${c?.menu_mark}`,'gi').test(window.location.pathname))
                id = n;

            return c;
        });

        if(id !== activeItem)
            setActiveItem(id);

    }, [navigate, menu, activeItem, validadeSession])

    useEffect(()=>{
        getNotification();

        return(()=>{
            clearTimeout(timerUpdate.current);
        })
    }, [updatePage, getNotification])

    return (
        <div className="navigation-frame-user">
            <header>
                <div className="logo"><img src={logo} alt="AFF Advocacia" /></div>
                <div className="search">
                    <span className="p-input-icon-right">
                        <i className="pi pi-search" />
                        <AutoComplete onSelect={(e)=> console.log(e.value)} field="label"  inputStyle={{width:'100%', maxWidth:'600px'}} style={{width:'100%', maxWidth:'600px'}} suggestions={searchSugestion} completeMethod={getSearchData} value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Pesquisa de contatos, processos ou tarefa" />
                    </span>
                </div>
                <div className="buttons">
                    <Button onClick={()=>{}} className="mr-1 p-button-text p-button-sm" icon="pi pi-question" />
                    <Button onClick={openAlert} className="mr-4 p-button-text p-button-sm" icon="pi pi-bell" >
                        {
                            notificationList?.filter(item => ~~item.viewed === 0)?.length > 0 ?
                                <Badge style={{position:'absolute', transform:'scale(0.6) translate(50%, -50%)'}} severity="danger" />
                            :
                                <></>
                        }
                    </Button>
                    <span onClick={()=>navigate('/user/profile')} >
                        {/* <Avatar className="mr-2" shape="circle" icon="pi pi-user" /> */}
                        {
                            user?.user_data?.avatar_url ? <Avatar  className="mr-2" image={`${URL_FILES_FILE}/?file=${user?.user_data.avatar_url}`} shape='circle' /> :  <Avatar  className="mr-2" icon="pi pi-user"  shape='circle' />
                        }
                        <span className="name">{user?.name?.split(' ')[0]}</span>
                    </span>
                </div>
            </header>
            <section>
                <div className="area-menu">
                    <Menu model={menu} />
                </div>
                <div className="area-content">
                    <Outlet></Outlet>
                </div>
            </section>
            <Sidebar icons={()=>{
                return <>
                        <Button onClick={()=>{ navigate('/user/notification'); setShowAlert(false); setUpdatePage(new Date().getTime())}} className="ml-4 p-button-text">Vizualizar todas</Button>
                        <Button onClick={()=> setUpdatePage(new Date().getTime())} icon="pi pi-sync" className="ml-2 p-button-text" />
                    </>
            }} position="right" visible={showAlert} onHide={() => setShowAlert(false)}>
                {
                    notificationList?.map(item => {
                        return <div onClick={()=>{navigate(`/user/notification/view/${item.id}`); setShowAlert(false); setUpdatePage(new Date().getTime()) }} className={`card-notification ${~~item.viewed === 1 ? 'is-read' : ''}`}>
                            <div className="title-notification">
                                <div className="text">
                                    {item?.title}
                                </div>
                            </div>
                            <div className="text-notification">
                                <div className="text">
                                    {item?.description}
                                </div>
                            </div>
                        </div>
                    })
                }
            </Sidebar>
        </div>
    )
}