import React, {useEffect, useCallback, useState} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar'; 

import { formatShortName } from '@/services/utils';

import { useNavigate } from 'react-router-dom';
import { api, URL_TICKET_BYCASE } from '@/services/api';

export const PageUserProcessCasesTickets = (props) => {

    const [data, setData] = useState();

    const navigate = useNavigate();

    const getData = useCallback(async () => {
        const result = await api.get(`${URL_TICKET_BYCASE}/${props?.data?.id}`, null, true);
        setData(result?.data);

    }, [props])

    useEffect(()=>{
        getData();
    },[getData])

    if(!data)
        return (
            <div className="page-user-processcases-tickets">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-user-processcasess-tickets">
            <DataTable 
                value={data} 
                responsiveLayout="scroll"
                emptyMessage="Não há casos cadastrados"
                size='small'
                stripedRows
            >
                <Column style={{width:'220px'}} body={(row)=> {
                    return row?.datetime_end ? `${new Date(row?.datetime_end).toLocaleDateString()} - ${new Date(row?.datetime_end).toLocaleTimeString()}` : '-'
                }}
                header="Termino" />
                <Column style={{width:'100px', textAlign:'center'}} body={(row)=> {
                    return ~~row?.priority === 1 ? <div className='ticket-priority'></div> : '-';
                } } header="Prioridade" />
                <Column body={(row)=> {
                    return row?.ticket_identification;
                } } header="Identificação" />
                <Column body={(row)=> {
                    return row?.user ? formatShortName(row?.user?.name) : '-'
                } } header="Responsável" />
                <Column body={(row)=> {
                    return row?.status_name
                } } header="Status" />
                <Column style={{width:'50px'}} body={(row)=> {
                    return <>
                        <Button onClick={()=>navigate(`/user/tickets/${row?.id}`)} icon="pi pi-eye" className='p-button-text' />
                    </>
                } } header="" />
            </DataTable>
        </div>
    )
}