import React, { useState, useCallback, useEffect, useRef } from "react";

import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Timeline } from 'primereact/timeline';
import { Message } from 'primereact/message';
import { AutoComplete } from 'primereact/autocomplete';
import { InputText } from 'primereact/inputtext';

import { useForm, Controller } from 'react-hook-form';

import { useParams } from "react-router-dom";
import { api, URL_PROCESSCASES_PROCESS, URL_PROCESSCASES_STATUSPROCESS, URL_PROCESSCASES_PROCESS_LAST_STATUS, URL_PROCESSCASES_UPDATE_STATUS_PROCESS, URL_PROCESSCASES_PROCESS_SUBJECT, URL_PROCESSCASES_PROCESS_JUR_CREATE } from "@/services/api";

export const PageUserProcessCasesProcess = (props) => {

    const params = useParams();

    const [data, setData] = useState();
    const [listStatus, setListStatus] = useState();
    const [status, setStatus] = useState();
    const [lastStatus, setLastStatus] = useState();
    const [subjecList, setSubjectList] = useState();
    const [subject, setSubject] = useState();

    const statusTemp = useRef();

    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    
    const getData = useCallback(async () => {
        //console.log('Process', props);
        const type =  props?.type;
        const result = await api.get(`${URL_PROCESSCASES_PROCESS}/${params?.id}/${type}`, false, true);
        //console.log(result.data);
        setData(result.data || []); 
    }, [params, props])
    
    const alterStatus = useCallback(async() =>{
        const processStatus = {
            process_id: data.id,
            description: statusTemp.current
        }

        await api.post(URL_PROCESSCASES_UPDATE_STATUS_PROCESS, processStatus, true);

        setStatus('');
        getData();

    },[setStatus, data, getData])

    const getStatus = useCallback(async ()=> {
        const result = await api.get(URL_PROCESSCASES_STATUSPROCESS, false, true);
        //console.log(result.data);
        setListStatus(result.data);
    }, [])

    const getLastStatus = useCallback(async () => {
        const result = await api.get(`${URL_PROCESSCASES_PROCESS_LAST_STATUS}/${params?.id}`, false, true);
        //console.log('Last', result.data);
        setLastStatus(result.data);
    }, [setLastStatus, params])

    const onSubmit = useCallback(async (_data) => {

        const sendData = {
            process_cases_id: params.id,
            ..._data
        }

        const rs = await api.post(URL_PROCESSCASES_PROCESS_JUR_CREATE, sendData, true);
        getData();

        //console.log('Submit ', sendData);        
    },[params, props]);

    const searchSubject = useCallback(async () => {
        //if(_data?.query.length >= 3){
            // data.data = data?.data?.map(item => {
                //     item.label = `${item?.cod_subject} - ${item?.name}`;
                //     return item;
                // })
                
            const data = await api.get(`${URL_PROCESSCASES_PROCESS_SUBJECT}`, null, true);
            console.log(data.data);
            setSubjectList(data.data);
        //}
    }, []);

    useEffect(()=>{

        statusTemp.current = status;

    },[status])

    useEffect(()=> {
        const getAllData = async () => {
            await searchSubject();
            await getData();
            await getStatus();
            await getLastStatus();
        }
        
        getAllData();

    }, [getData, getStatus, getLastStatus, searchSubject]);


    if(!data)
        return (
            <div className="page-user-processcases-process">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    if(data.length === 0){
        return(
            <div className="page-user-processcases-process">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid">
                        <div className="col-12">
                            <h3>
                                Cadastrar processo
                            </h3>
                        </div>
                        <div className="col-6">
                            <Controller name={`number`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}} className="p-input-icon-left">
                                    <i className="pi pi-hashtag" />
                                    <InputText required inputStyle={{width:'100%'}} placeholder='Número' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-6">
                            <Controller name={`judgment`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}} className="p-input-icon-left">
                                    <i className="pi pi-file" />
                                    <InputText required inputStyle={{width:'100%'}} placeholder='Juízo' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`competence`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}} className="p-input-icon-left">
                                    <i className="pi pi-tag" />
                                    <InputText required inputStyle={{width:'100%'}} placeholder='Competência' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`process_subject_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}} className="p-input-icon-left">
                                    <i className="pi pi-tags" />
                                    <Dropdown 
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        optionLabel="name"
                                        optionValue="id"
                                        filter
                                        options={subjecList} 
                                        required 
                                        inputStyle={{width:'100%'}} 
                                        placeholder='Assunto' 
                                        className='' 
                                        style={{width:'100%'}} 
                                        onChange={(e) => {field.onChange(e.value);}}  
                                        id='searchSubject' 
                                        value={field.value}  
                                    />
                                </span>
                            )} />
                        </div>
                        <div style={{textAlign:'right'}} className="col-12 mt-2">
                            <Button label="Registrar" />
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div className="page-user-processcases-process">
            <div className="grid mt-4">
                <div className="col-6">
                    <strong>Número</strong>: {data?.number}
                </div>
                <div className="col-6">
                    <strong>Juizo</strong>: {data?.judgment}
                </div>
                <div className="col-6">
                    <strong>Competência</strong>: {data?.competence}
                </div>
                <div className="col-6">
                    <strong>Assunto</strong>: {data?.subject_name}
                </div>
                <div className="col-6">
                    <strong>Criado em</strong>: {data?.timecreate ? `${new Date(data?.timecreate).toLocaleDateString()} - ${new Date(data?.timecreate).toLocaleTimeString()}` : '-'}
                </div>
                <div className="col-6">
                    <strong>Distribuido em</strong>: {data?.timedistributed ? `${new Date(data?.timedistributed).toLocaleDateString()} - ${new Date(data?.timedistributed).toLocaleTimeString()}` : '-'}
                </div>
            </div>
            <div className="grid">
                {/* <div className="col-12">
                    <h3>Atual status</h3>
                </div>
                <div className="col-12">
                    <Message sticky={true} style={{width:'100%'}} text={lastStatus?.description} />

                </div>
                <div className="col-12 mt-4">
                    <div class="grid">
                        <div className="col-9">
                            <Dropdown optionLabel="name" optionValue="name" style={{width:'100%'}} value={status} options={listStatus} onChange={(e) => setStatus(e.value)} placeholder="Selecione o status do processo"/>
                        </div>
                        <div className="col-3">
                            <Button style={{width:'100%'}} onClick={()=>alterStatus()} label="Alterar status"  />
                        </div>
                    </div>
                </div> */}
                <div className="col-12">
                    <h3>Histórico</h3>
                </div>
                <div className="col-12 mt-2">
                    <Timeline value={data?.history} 
                    opposite={(item)=> {
                       return <strong>{item.timemodified ? `${new Date(item.timemodified).toLocaleDateString()} - ${new Date(item.timemodified).toLocaleTimeString()}` : '-'}</strong>
                    }}
                    content={(item) => {
                        return <div>
                                {item?.description}
                            </div>
                    }} />
                </div>
            </div>
        </div>
    )
}