import React, {useEffect, useState, useCallback, useMemo, useRef} from 'react';

import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';

import { UIClientCalendar } from '@/components/ui/clientcalendar';

import logo from '@/assets/images/logo.png';

import { useForm, Controller } from 'react-hook-form';
import { api, URL_CALENDAR_CREATE, URL_QUESTION_ACTUATION, URL_USER_JURIDIC_BY_ACTUATION } from '@/services/api';
import { useAPP } from '@/services/provider/app';


import '@/assets/styles/pages/calendar/index.scss';
import { InputText } from 'primereact/inputtext';

export const PageCalendar = () =>{

    const  { user } = useAPP();

    const toast = useRef(null);
 
    const [questions, setQuestions] =  useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const [selectedQuestion, setSeletedQuestion] = useState(null);
    const [selectedDatetime, setSeletedDateTime] = useState(null);
    const [selectedJuridcs, setSelectedJuridics] = useState(null);
    const [juridcs, setJuridics] = useState(null);
    const [location, setLocation] = useState('local');
    const [note, setNote] = useState();
    const [clientName, setClientName] = useState();
    const [clientContact, setClientContact] = useState();

    const stepMenu = useMemo(()=>[
        {label: 'Assunto'},
        {label: 'Horário'},
        {label: 'Confirmação'}
    ], [])

    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const { setIsLoading } = useAPP();
    
    const navigationStep = useCallback((_bl)=>{

        setActiveIndex(_bl ? activeIndex + 1 : activeIndex - 1);
    }, [activeIndex, setActiveIndex])

    const resetAll = useCallback(() =>{
        setSeletedQuestion([]);
        setSeletedDateTime(null);
        setSelectedJuridics(null);
        setNote(null);
        setJuridics(null)
        setActiveIndex(0);
        reset(null);

    }, [setSeletedQuestion, setSeletedDateTime, setNote, setActiveIndex, setJuridics, reset])

    const onSubmit = useCallback(async (_data) => {
        
        if(!_data.questions){
            toast.current.show({severity:'error', summary: 'Erro', detail:'Selecione um assunto', life: 3000});
        }else{
            const temp = questions.filter(item => ~~item.id === ~~_data.questions);
            setSeletedQuestion(temp[0]);

            const juridics_list = await api.get(`${URL_USER_JURIDIC_BY_ACTUATION}/${temp[0]?.actuation_id}`, null, false);
            
            const jurids_id =  juridics_list?.data?.map(item => item.id);
            setJuridics(jurids_id);

            navigationStep(true);
        }
    },[questions, navigationStep]);

    const saveData = useCallback(async () => {
        if(!selectedDatetime || !selectedQuestion){
            toast.current.show({severity:'error', summary: 'Erro', detail:'Selecione todas as informações', life: 3000});
        }else{
            const data = {
                identification: new Date().getTime(),
                user_id: selectedJuridcs,
                noclieet_name: clientName,
                noclient_contact: clientContact,
                date: selectedDatetime?.toISOString().substr(0, 10),
                time: selectedDatetime?.toLocaleTimeString(),
                location: location,
                note: `${selectedQuestion?.question}\n${note}`
            }

            const result = await api.post(`${URL_CALENDAR_CREATE}`, data, false);
            if(result?.data){
                toast.current.show({severity:'success', summary: 'Sucesso', detail:'Agendamento registrado com sucesso', life: 3000});
                resetAll();
            }else{
                toast.current.show({severity:'error', summary: 'Erro', detail:'Houve um erro ao agendar, tente novamente', life: 3000});
            }
        }

    }, [selectedDatetime, note, selectedQuestion, resetAll, selectedJuridcs, location, clientName, clientContact]);

    const getContent = () => {
        let content = '';
        switch(activeIndex){
            case 0:
                content = <>
                    <div className='box-subject'>
                        <i className='pi pi-exclamation-triangle mr-2' />
                        <span>Selecione o assunto para seu atendimento</span>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller name={`questions[]`} roles={{required: true}} control={control}  render={({ field }) => (
                            <>
                                {
                                    questions.map(item => {
                                        return <div className='mt-2 mb-3' key={`question${item.id}`}>
                                            <RadioButton {...field} id={`question-item-${item.id}`} value={item.id} onChange={(e) => field.onChange(e.value)} checked={field.value === item.id} />
                                            <label className='ml-2' htmlFor={`question-item-${item.id}`}>{item.question}</label>
                                        </div>
                                    })  
                                }
                            </>
                        )} />
                        <div className="grid mt-4">
                            <div className="col-12" style={{textAlign:'right'}}>
                                <Button className='navigate' icon="pi pi-arrow-right" iconPos='right' label="Continuar" />
                            </div>
                        </div>
                    </form>
                </>
            break;
            case 1:
                content = <>
                    <div className="grid">
                        <div className="col-12">
                            <UIClientCalendar 
                                selectedData={selectedDatetime}
                                updateSelectedData={setSeletedDateTime}
                                juridics={juridcs}
                                updateSelectJuridics={setSelectedJuridics}

                            />
                        </div>
                    </div>
                    <div className="grid mt-4">
                        <div className="col-12" style={{textAlign:'right'}}>
                            <Button className='mr-3 navigate' icon="pi pi-arrow-left" iconPos='left' label="Voltar" onClick={()=>navigationStep(false)} /> <Button className='navigate' icon="pi pi-arrow-right" iconPos='right' label="Continuar" onClick={()=>navigationStep(true)} />
                        </div>
                    </div>
                </>
            break;
            case 2:
                content = <>
                    <div className='box-subject'>
                        <i className='pi pi-exclamation-triangle mr-2' />
                        <span>Revise e confirme os detalhes do seu agendamento</span>
                    </div>
                    <div className="grid mt-4">
                        <div className="col-6">
                            <InputText style={{width:'100%'}} placeholder='Informe seu nome' onChange={(e)=>setClientName(e.targetValue)} value={clientName} />
                        </div>
                        <div className="col-6">
                            <InputText style={{width:'100%'}} placeholder='Informe um contato (Email/ Telefone/ Celular)' onChange={(e)=>setClientContact(e.targetValue)} value={clientContact} />
                        </div>
                        <div className="col-6"></div>
                        <div className="col-12">
                            <div className='mb-2 mt-1'>
                                <strong>Data e Horário</strong>: { selectedDatetime?.toLocaleDateString() } <span className='box-time'>{ selectedDatetime?.toLocaleTimeString() }</span>
                            </div>
                            <div className="col-12">
                                <SelectButton 
                                    onChange={(e)=> setLocation(e.value)}
                                    value={location}
                                    options={[
                                    {label:'Presencial', value:'local'},
                                    {label:'On-line', value:'online'}
                                ]} />
                            </div>
                            <div className='mt-4'>
                                <div className='mb-4'>
                                    <strong>Assunto</strong>: { selectedQuestion?.question } 
                                </div>
                                <h3>Caso necessário adicionar alguma observação use o campo abaixo:</h3>
                                <InputTextarea value={note} onInput={(e)=>setNote(e.target.value)} style={{width:'100%'}} autoResize={true} placeholder='Observação' />
                            </div>
                        </div>
                    </div>
                    <div className="grid mt-4">
                        <div className="col-12" style={{textAlign:'right'}}>
                        <Button className='mr-3 navigate' icon="pi pi-arrow-left" iconPos='left' label="Voltar" onClick={()=>navigationStep(false)} /> <Button className='navigate' icon="pi pi-calendar" label="Agendar" onClick={()=>saveData()} />
                        </div>
                    </div>
                </>
            break;
            default:
                content = <></>
        }

        return content;
    }

    const getQuestions = useCallback(async ()=>{
        setIsLoading(true);
        const data = await api.get(URL_QUESTION_ACTUATION, null, false);
        setQuestions(data.data);
        setIsLoading(false);
    }, [setIsLoading])

    useEffect(()=>{
        getQuestions()
    }, [getQuestions]);

    return (
        <div className="page-calendar">
            <div className="header">
                <div className="logo"><img src={logo} alt="AFF Advocacia" /></div>
                <div>
                    <h2>
                        Agendamento online
                    </h2>
                </div>
            </div>
            <div className="main-frame">
                <div className="grid">
                    <div className="col-12">
                    <Steps model={stepMenu} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
                    </div>
                </div>
                <div className="content">
                    {
                        getContent()
                    }
                </div>
            </div>
            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}