import React, {useCallback, useState, useEffect, useRef} from 'react';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { useNavigate} from 'react-router-dom';

import { URL_CLIENT_SEARCH, api, URL_COLLABORATORS, URL_PROCESSCASES_PROCESS_CREATE, URL_PROCESSCASES_BASE_TITLE } from '@/services/api';

export const PageProcessCasesCreate = () => {

    const navigate = useNavigate();

    const [othersPeople, setOthersPeoples] = useState([]);
    const [typeOtherPeople, setTypeOtherPeople] = useState();
    const [useClientOtherPeople, setUseClientOtherPeople] = useState();
    const [nameOtherPeople, setNameOtherPeople] = useState();
    const [extraOtherPeople, setExtraOtherPeople] = useState();
    const [titleData, setTitleData] = useState();
    const [currentArea, setCurrentArea] = useState([]);
    const [currentTypeCase, setCurrentTypeCase] = useState([]);

    const [client, setClient] = useState();
    const [clientSearch, setClientSearch] = useState();
    const [clientList, setClientList] = useState();

    const [ collaborators, setCollaborators] = useState();

    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;

    const toast = useRef(null);

    const onSubmit = useCallback(async (_data) => {
        let sendData = {};
        let casedata = {..._data};

        const _title =  titleData?.process_cases_type_case.filter(item => ~~item.id === ~~_data.title_type_case)[0]?.name;

        casedata.name = _title;

        delete casedata.title_area;
        delete casedata.title_type_case;

        casedata.client_id = client?.id;
        casedata.user_id = casedata?.user_id?.length > 0 ? casedata.user_id : null;

        sendData.case = casedata;
        if(othersPeople.length > 0){
            sendData.parts = [...othersPeople];
        }

        
        const result = await api.post(URL_PROCESSCASES_PROCESS_CREATE, sendData, true);

        if(result){
            toast.current.show({severity:'success', summary: 'Sucesso', detail:'Dados Salvos com sucesso', life: 3000});
            navigate(`/user/processcases/${result?.data?.case}`);
        }else{
            toast.current.show({severity:'error', summary: 'Erro', detail:'Houve problemas ao salvar os dados. Tente novamente.', life: 3000});
        }
        ////console.log(sendData);
    }, [client, othersPeople, navigate])

    const addOtherPeople = (e) =>{
        e.preventDefault();

        let name = nameOtherPeople;
        name = name?.replace(/^[\s]|[\s]$/gi,'')

        if(name?.length === 0){
            return;
        }

        const temp  = [...othersPeople];
        temp.push({id:(new Date()).getTime(), name:name, type: typeOtherPeople, description: extraOtherPeople, user_id: useClientOtherPeople ? client.id : null});
        //console.log(typeOtherPeople);
        //console.log(temp);
        setOthersPeoples(temp);
        setNameOtherPeople('');
        setTypeOtherPeople('');
        setExtraOtherPeople('');
        setUseClientOtherPeople(false);
        
    }

    const removeOtherPeople = (_id) => {
        let temp  = [...othersPeople];

        temp = temp.filter(item => item.id !== _id);

        setOthersPeoples(temp);
    }

    const onRowEditComplete = (e) =>{
        let temp  = [...othersPeople];
        temp[e.index] = e.newData;

        setOthersPeoples(temp);
    }

    const searchClient = useCallback(async (_data) => {
        if(_data?.query.length >= 3){
            const data = await api.get(`${URL_CLIENT_SEARCH}?data=${_data?.query}`, null, true);
            data.data = data?.data?.map(item => {
                item.label = `${item?.name} ${item?.user_ref ? ` - ${item?.user_ref}` : ''} ${item?.cpf_cnpj ? ` - ${item?.cpf_cnpj}` : ''}`;
                return item;
            })
            
            setClientList(data.data);
        }
    }, []);

    const getCollaborators  = useCallback(async()=>{
        const result = await api.get(`${URL_COLLABORATORS}`, null, true);
        setCollaborators(result?.data);
    }, [])

    const getTitleData = useCallback(async () => {
        const result = await api.get(`${URL_PROCESSCASES_BASE_TITLE}`, null, true);
        setTitleData(result.data);
    },[])

    useEffect(()=>{
       const temp = titleData?.process_cases_type_case.filter(item => ~~item.process_cases_area_id === ~~currentArea);
       setCurrentTypeCase(temp);

    },[currentArea])

    useEffect(()=>{
        if(useClientOtherPeople){
            setNameOtherPeople(client.name);
        }else{
            setNameOtherPeople('');
        }
    },[useClientOtherPeople, client])

    useEffect(()=>{
        const init = async () => {
            await getCollaborators();
            await getTitleData();
        }

        init();

    },[getCollaborators]);

    return (
        <div className="page-processcases-create">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Processos e casos', url: '/user/processcases'},
                        {label:'Criar Caso'}
                    ]}
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid mt-2 mb-2'>
                    <div className="col-6">
                        <Controller name={`title_area`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Dropdown filter {...field} placeholder="Selecione a área" options={titleData?.process_cases_area} optionLabel="name" optionValue="id" style={{width:'100%'}} onChange={(e) => {
                                setCurrentArea(e.target.value);
                                field.onChange(e.target.value);
                            }}  id={field.name}  value={field.value} />
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`title_type_case`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Dropdown filter {...field} placeholder="Selecione o tipo do caso" options={currentTypeCase} optionLabel="name" optionValue="id" style={{width:'100%'}} onChange={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                    </div>
                    {/* <div className="col-12">
                        <Controller name={`name`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-folder" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='Titulo do caso' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value.toString().replace(/\D/gi,''));}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div> */}
                    <div className="col-12">
                        <span style={{width:'100%'}} className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <AutoComplete 
                                field='label' 
                                suggestions={clientList} 
                                completeMethod={searchClient} 
                                required 
                                inputStyle={{width:'100%'}} 
                                placeholder='Buscar cliente' 
                                className='' style={{width:'100%'}} 
                                onChange={(e) => {setClientSearch(e.value);}}  
                                id='searchClient' 
                                value={clientSearch}  
                                onSelect={(e)=> setClient(e.value)}
                                minLength={3}
                            />
                        </span>
                    </div>
                    <div className="col-12">
                        <Controller name={`user_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Dropdown filter {...field} placeholder="Selecione o responsável" options={collaborators} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                    </div>
                    <div className="col-12">
                        <h4>
                            Adicionar partes envolvidas 
                        </h4>
                        <div className="grid">
                            <div className="col-2">
                                <Checkbox disabled={!client} className='mr-2' inputId="useClient" value={useClientOtherPeople} onChange={(e)=> setUseClientOtherPeople(e.checked)} checked={useClientOtherPeople}></Checkbox>
                                <label htmlFor="useClient" className="p-checkbox-label">Usar cliente?</label>
                            </div>
                            <div className="col-6">
                                <span style={{width:'100%'}} className="p-input-icon-left">
                                    <i className="pi pi-user" />
                                    <InputText inputStyle={{width:'100%'}} value={nameOtherPeople} onInput={(e)=> setNameOtherPeople(e.target.value)} style={{width:'100%'}} placeholder='Nome envolvido' />
                                </span>
                            </div>
                            <div className="col-4">
                                <span style={{width:'100%'}} className="p-input-icon-left">
                                    <i className="pi pi-user" />
                                    <Dropdown options={[
                                        {label:'Autor', value: 'Autor'},
                                        {label:'Réu', value: 'Réu'}
                                    ]} inputStyle={{width:'100%'}} value={typeOtherPeople} onChange={(e)=> setTypeOtherPeople(e.target.value)} style={{width:'100%'}} placeholder='Tipo envolvido' />
                                </span>
                            </div>
                            <div className="col-12">
                                <InputTextarea inputStyle={{width:'100%'}} value={extraOtherPeople} onInput={(e)=> setExtraOtherPeople(e.target.value)} style={{width:'100%'}} autoResize placeholder='Outras informações' />
                            </div>
                            <div style={{textAlign:'right'}} className="col-12">
                                <Button onClick={(e)=>addOtherPeople(e)} icon="pi pi-plus" label="Adicionar" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        {
                            othersPeople?.length > 0 ?
                                <DataTable 
                                    style={{width:'100%'}}
                                    size="small"
                                    value={othersPeople} 
                                    emptyMessage="Não há outros envolvidos"
                                    editMode="row" 
                                    dataKey="id" 
                                    onRowEditComplete={onRowEditComplete}
                                    >
                                    <Column style={{width:'250px'}} field='name' header="Nome" editor={
                                        (options) => {
                                            return <InputText inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                                        }
                                    } />
                                    <Column style={{width:'250px'}} field='type' header="Tipo" editor={
                                        (options) => {
                                            return <Dropdown options={[
                                                {label:'Autor', value: 'Autor'},
                                                {label:'Réu', value: 'Réu'}]} 
                                                inputStyle={{width:'100%'}} style={{width:'100%'}}  value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
                                        }
                                    } />
                                    <Column style={{width:'auto'}} field='extra' header="Extra" editor={
                                        (options) => {
                                            return <InputTextarea autoResize inputStyle={{width:'100%'}} style={{width:'100%'}} value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                                        }
                                    } />
                                    <Column rowEditor headerStyle={{ width: '100px'}} bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column style={{width:'40px', textAlign:'center'}} body={(row)=>{
                                        return <Button onClick={(e)=>{e.preventDefault(); removeOtherPeople(row.id)}} icon="pi pi-trash" className="p-button-danger p-button-text" />
                                    }} header=""/>
                                </DataTable>
                            :
                                ''
                        }
                    </div>
                    <div className="col-12">
                        <Controller name={`description`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <InputTextarea {...field} autoResize required inputStyle={{width:'100%', minHeight:'300px'}} placeholder='Descreava o caso' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name} value={field.value}  />
                        )} />
                    </div>
            </div>
            <div className="grid mt-4">
                <div className="col-12" style={{textAlign:'right'}}>
                    <Button onClick={()=>navigate('/user/processcases')} className='p-button-danger p-button-text mr-2' label="Cancelar cadastro"/>
                    <Button className='p-button-warning' label="Criar caso"/>
                </div>
            </div>
        </form>
        <Toast position="bottom-center" ref={toast} />
    </div>
    )
}