import React, { useCallback, useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useNavigate, useParams } from 'react-router-dom';
import { api, URL_ATTENDANCE_BYUSERID } from '@/services/api';
import { formatShortName } from '@/services/utils';

export const PageProfileUserAttendance = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState();
    const [showinfo, setShowinfo] = useState(false);
    const [infotext, setInfotext] = useState();

    const openInfo = useCallback((data)=>{
        setInfotext(data);
        setShowinfo(true);
    }, [])

    const getData = useCallback(async () => {
        const _data = await api.get(`${URL_ATTENDANCE_BYUSERID}/${params?.id}`, null, true);
        setData(_data.data);
        //console.log(_data.data);

    }, [params]);

    useEffect(() => {
        getData();
    }, [getData])

    return (
        <div className="page-profile-attendance">
            <DataTable 
            style={{width:'100%'}}
            size="small"
            value={data} 
            emptyMessage="Não há atendimentos"
            >
                <Column body={(row)=> {
                        return new Date(row.start).toLocaleDateString();
                    } } header="Data" />
                    <Column body={(row)=>{
                        return new Date(row.start).toLocaleTimeString();
                    }} sortable field="start" header="Início" />
                    <Column body={(row)=>{
                        return row.end ? new Date(row.end).toLocaleTimeString() : '-';
                    }} sortable field="end" header="Fim" />
                    <Column field="type" header="Tipo" />
                    <Column field="location" header="Local" />
                    <Column body={(row)=>{
                        return formatShortName(row?.attendance_user?.name);
                    }} header="Responsável" />
                    <Column body={(row)=>{
                        return row?.process_cases_id ? <span style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/processcases/${row?.process_cases_id}`, {state:{tabActive:3}})}>{row?.process_cases?.name}</span> : '-';
                    }} header="Caso" />
                    <Column body={(row)=>{
                        return <>
                            <Button onClick={()=>openInfo(row)} className='p-button-sm p-button-text' icon="pi pi-book" />
                        </>
                    }} header="" />
                    
            </DataTable>
            <Dialog header={`Detalhes atendimento - ${infotext?.id}`}
                visible={showinfo} 
                style={{ width: '98%', maxWidth:'600px' }} 
                onHide={() =>{setShowinfo(false);}}>
                    {infotext?.description}
            </Dialog>
        </div>
    )
}