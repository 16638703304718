import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { TabMenu } from 'primereact/tabmenu';
import { Tag } from 'primereact/tag';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Avatar } from 'primereact/avatar';
import { FileUpload } from 'primereact/fileupload';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';

import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { api, URL_PROFILE_COLLABORATOR, URL_USER_UPDATE, URL_USER_TYPE, URL_FILES_FILE, URL_FILES_UPLOAD, URL_FILES_DELETE } from '@/services/api';
import { calculateOlder, formatCPJCNPJ, formatRG } from '@/services/utils';

import { useForm, Controller } from 'react-hook-form';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { formatPhoneNumber } from '@/services/utils';


import { PageProfileUserAttendance } from './attendance';
import { PageProfileUserDocument } from './document';

import '@/assets/styles/pages/profile/index.scss';
import { useAPP } from '@/services/provider/app';

export const PageProfileCollaborator = () => {

    const { user, setUser } = useAPP();

    const [userData, setUserData] = useState(null);
     
    const [active, setActive] = useState(0);
    const [isEdit, setIsEdit] = useState(false);

    const [alterPassword, setAlterPassword] = useState(false);

    const [listWorks, setListWorks] = useState([]);
    const [work, setWork] = useState();

    const [listContacts, setListContacts] = useState([]);
    const [contact, setContact] = useState();

    const [listAddress, setListAddress] = useState([]);
    const [address, setAddress] = useState();
    const addressData = useRef();
    const contactData = useRef();
    
    
    const [listResponsable, setListResponsable] = useState([]);
    const [responsable, setResponsable] = useState();

    const [listBank, setListBank] = useState([]);
    const [bank, setBank] = useState();

    const [listClientTypeData, setListClinetTypeData] = useState();
    const [listClientType, setListClientType] = useState([]);

    const workData = useRef();
    const responsablepointer = useRef();
    const bankpointer = useRef();

    const photoupload = useRef(null);

    const toast = useRef(null);

    const forceUpdate = useRef(false);
    
    const params = useParams();
    

    const form = useForm({}); 
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const tabMenuList = useMemo(()=>[
        {label: 'Atendimentos', component: <PageProfileUserAttendance user={params.id} />},
        {label: 'Documentos', component: <PageProfileUserDocument user={params.id} />},
    ], [params]);

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje', 
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);
    

    const getData = useCallback(async () => {
        const data = await api.get(`${URL_PROFILE_COLLABORATOR}/${params.id}`, false, true);
        
        setUserData(data?.data);

        if(forceUpdate.current && ~~user.id === ~~params?.id){
            setUser(data.data);
            forceUpdate.current = false;
        }
        // setListContacts(data?.data?.user_contact);
        // setListAddress(data?.data?.user_address);
        // setListBank(data?.data?.user_bank);

    }, [setUserData, params])

    const getContent = () => {

        if(tabMenuList[active]?.component){
            return tabMenuList[active]?.component;
        }

        return 'Página não encontrada';
    }

    const copyContent = (data) => {
        navigator.clipboard.writeText(data);
        toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Conteúdo copiado com sucesso'});
    }

    

    const addBank = useCallback((e)=>{
        e.preventDefault();
        const temp = listBank ? [...listBank] : [];
        const insert = {...bank};
        insert.id = bankpointer.current;
        bankpointer.current++;
        temp.push(insert);
        setBank('');
        setListBank(temp);
    }, [bank, listBank])

    const removeBank = useCallback((_data)=>{

        const newlist = listBank?.filter(item => ~~_data !== ~~item?.id);

        setListBank(newlist);

    }, [listBank]);

    const onRowEditCompleteBank = (e) =>{
        let temp  = [...listBank];
        temp[e.index] = e.newData;

        setListBank(temp);
    }

    const addResponsable = useCallback((e)=>{
        e.preventDefault();
        const temp = listResponsable ? [...listResponsable] : [];
        const insert = {...responsable};
        insert.id = responsablepointer.current;
        responsablepointer.current++;
        temp.push(insert);
        setResponsable('');
        setListResponsable(temp);
    }, [responsable, listResponsable])

    const removeResponsable = useCallback((_data)=>{

        const newlist = listResponsable?.filter(item => ~~_data !== ~~item?.id);

        setListResponsable(newlist);

    }, [listResponsable]);

    const onRowEditCompleteResponsable = (e) =>{
        let temp  = [...listResponsable];
        temp[e.index] = e.newData;

        setListResponsable(temp);
    }

    const addWork = useCallback(async ()=> {
        
        const temp = listWorks ? [...listWorks] : [];

        if(!workData.current?.id || workData.current?.id === 0){
            workData.current.temp_id = new Date().getTime();
            delete workData.current.id;
        }

        let sendData = {...workData.current}
        if(sendData?.actual){
            delete sendData?.end;
            delete sendData?.actual;
        }
        
        temp.push(sendData);
        setListWorks(temp);

        let tempreset = {...workData.current};
        Object.keys(tempreset)?.map(item => {
            tempreset[item] = '';
            return item;
        })
        
        setWork(tempreset);

    }, [listWorks, setListWorks, setWork, workData]);

    const removeWork = useCallback(async (_data) => {
        const newlist = listWorks?.filter(item => ~~_data !== ( item?.temp_id ? ~~item?.temp_id : ~~item?.id));
        setListWorks(newlist);
    }, [])

    const onRowEditCompleteWork = (e) =>{
        let temp  = [...listWorks];
        temp[e.index] = e.newData;

        setListWorks(temp);
    }


    const addContact = useCallback(async ()=> {
        const temp = listContacts ? [...listContacts] : [];

        if(!contactData.current?.id || contactData.current?.id === 0){
            contactData.current.temp_id = new Date().getTime();
            delete contactData.current.id;
        }

        let sendData = {...contactData.current}
        
        temp.push(sendData);
        setListContacts(temp);

        let tempreset = {...contactData.current};
        Object.keys(tempreset)?.map(item => {
            tempreset[item] = '';
            return item;
        })
        
        setContact(tempreset);
    }, [contactData, setContact, listContacts]);

    const removeContact = useCallback(async (_data) => {
        const newlist = listContacts?.filter(item => ~~_data !== ( item?.temp_id ? ~~item?.temp_id : ~~item?.id));
        setListContacts(newlist);
    }, [listContacts, setListContacts])

    const onRowEditCompleteContact = (e) =>{
        let temp  = [...listContacts];
        temp[e.index] = e.newData;

        setListContacts(temp);
    }


    const addAddress = useCallback(async (_data)=> {
        const temp = listAddress ?  [...listAddress] : [];

        if(!addressData.current?.id || addressData.current?.id === 0){
            addressData.current.temp_id = new Date().getTime();
            delete addressData.current.id;
        }

        let sendData = {...addressData.current}
        
        temp.push(sendData);
        setListAddress(temp);

        let tempreset = {...addressData.current};
        Object.keys(tempreset)?.map(item => {
            tempreset[item] = '';
            return item;
        })
        
        setAddress(tempreset);
    }, [listAddress, setListAddress, setAddress, addressData]);

    const removeAddress = useCallback(async (_data) => {
        const newlist = listAddress?.filter(item => ~~_data !== ( item?.temp_id ? ~~item?.temp_id : ~~item?.id));
        setListAddress(newlist);
    }, [listAddress, setListAddress])

    const onRowEditCompleteAddress = (e) =>{
        let temp  = [...listAddress];
        temp[e.index] = e.newData;

        setListAddress(temp);
    }


    const getCepData = useCallback(async (_data) => {
        const cep = _data?.replace(/\D/gi, '');
        if(cep?.length < 8)
            return;
        const data = await api.get(`https://viacep.com.br/ws/${cep}/json/`, null, false);

        const temp = {...address};
        temp.zipcode = data?.cep;
        temp.address = data?.logradouro;
        temp.district = data?.bairro;
        temp.city = data?.localidade;
        temp.state = data?.uf;

        setAddress(temp);

    }, [setAddress, address])

    const getAddressData = useCallback(async (_state, _city, _address) => {
        
        const state = _state?.replace(/^\s|\s$/gi,'');
        const city = _city?.replace(/^\s|\s$/gi,'');
        const addressData = _address?.replace(/^\s|\s$/gi,'').replace(/\s/gi,'+');

        const data = await api.get(`https://viacep.com.br/ws/${state}/${city}/${addressData}/json/`, null, false);
        const temp = {...address};
        temp.zipcode = data[0]?.cep;
        temp.address = data[0]?.logradouro;
        temp.district = data[0]?.bairro;
        temp.city = data[0]?.localidade;
        temp.state = data[0]?.uf;

        setAddress(temp);



    }, [setAddress, address])

    const searchAddress = useCallback(async (event) => {
        
        let addressFind = null;
        let stateFind = null;
        let cityFind = null;

        const accepctInfo = () => {
           getAddressData(stateFind, cityFind, addressFind);
        }


        confirmDialog({
            target: event.currentTarget,
            message: <>
                <p>Busque seu CEP</p>
                <div className="grid">
                    <div className="col-4">
                        <InputText onInput={(e)=>{
                            stateFind = e.target.value;
                        }} style={{width:'100%'}} placeholder='Estado' />
                    </div>
                    <div className="col-8">
                        <InputText onInput={(e)=>{
                            cityFind = e.target.value;
                        }} style={{width:'100%'}} placeholder='Cidade' />
                    </div>
                </div>
                <div>
                    <InputText onInput={(e)=>{
                        addressFind = e.target.value;
                    }} style={{width:'100%'}} placeholder='Rua' />
                </div>
            </>,
            acceptLabel: 'Buscar',
            rejectLabel:'Cancelar',
            accept: () => accepctInfo(),
            reject: () => null
        });
    }, [getAddressData])


    const onRowEditCompleteClientType = (e) =>{
        let temp  = [...listClientType];
        temp[e.index] = e.newData;

        setListClientType(temp);
    }

    const onSubmit = useCallback(async (_data) => {
        
        //console.log(_data);

        let temp = {
            user: {},
            user_data: {}
        };

        temp['user'].password = _data['password'];

        Object.keys(userData)?.map(item => {
            if(!(/user_data|user_work|user_address|user_contact|user_bank|access|type|timemodified|user_id/gi).test(item))
                temp['user'][item] = _data[item];
            return item;
        })

        Object.keys(userData?.user_data)?.map(item => {
            if(!(/id/gi).test(item))
                temp.user_data[item] = _data[item];
            else
                temp.user_data[item] = _data['user_data'][item];
            return item;
        })

        temp.user_address = listAddress ? [...listAddress] : null;
        temp.user_contact = listContacts ? [...listContacts] : null;
        temp.user_bank = listBank ? [...listBank] : null;
        
        //console.log('##',_clientType);

        const accepctData = async () => {
            
            if(!alterPassword){
                delete temp['user'].password;
            }
            //console.log(temp);

            const result = await api.put(URL_USER_UPDATE, temp, true);

            forceUpdate.current = true;

            setIsEdit(false);
            setAlterPassword(false);
            setUserData('reload');
         }
 
 
         confirmDialog({
            header:'Atenção',
            message:'Deseja salvar as alterações',
            acceptLabel: 'Sim',
            rejectLabel:'Não',
            accept: () => accepctData(),
            reject: () => null
         });


    }, [listWorks, listAddress, listContacts, userData, alterPassword, getData])

    const getDataClientType = useCallback(async ()=>{
        const data = await api.get(URL_USER_TYPE, null, true);
        
        setListClinetTypeData(data.data);

    }, [setListClinetTypeData])

    const removePhoto = useCallback(async (_data)=> {

        await api.delete(`${URL_FILES_DELETE}`, {file: userData?.user_data?.avatar_url}, true);
        await api.put(URL_USER_UPDATE, { id:  params?.id, user_data:{id:userData?.user_data?.id, avatar: null}}, true);
        forceUpdate.current = true;
        getData();
    }, [])

    const uploadImage = useCallback(async (photo) =>{

        if(userData?.user_data?.avatar_url){
            await api.delete(`${URL_FILES_DELETE}`, {file: userData?.user_data?.avatar_url}, true);
        }

        const sendData = new FormData();
        
        sendData.append('file', photo);
        sendData.append('name', 'avatar');
        sendData.append('area_location', 'profile');
        sendData.append('user_id', user.id);
        sendData.append('instance_id', params?.id);
        
        
        const dataphoto = await api.upload(URL_FILES_UPLOAD, sendData, true);

        const temp = {
            id:  params?.id,
            user_data:{
                avatar: dataphoto.data, 
                id: userData?.user_data?.id ?? null
            }
        }
        
        

        await api.put(URL_USER_UPDATE, temp, true);
        forceUpdate.current = true;
        photoupload.current.clear();
        getData();

    }, [])


    useEffect(()=> {

        if(isEdit){
            reset(userData);
        }

    }, [isEdit, reset, userData])

    useEffect(()=>{
        workData.current = {...work};
    }, [work, workData])

    useEffect(()=>{
        contactData.current = {...contact};
    }, [contact, contactData])

    useEffect(()=>{
        addressData.current = {...address};
    }, [address, addressData])

    useEffect(()=>{
        if((/reload/gi).test(userData))
            getData();
    },[userData, getData])

    useEffect(()=>{
        getData();
        getDataClientType();
    }, [getData, getDataClientType])

    if(!userData || (/reload/gi).test(userData))
        return (
            <div className="page-clients">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-clients">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Colaboradores', url:'/user/collaborators'},
                        {label:'Perfil'}
                    ]}
                />
            </div>
            <div className="header mt-4 mb-4">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><>
                        {
                            userData?.user_data?.avatar_url ? <Avatar image={`${URL_FILES_FILE}/?file=${userData?.user_data.avatar_url}`} shape='circle' /> :  <Avatar icon="pi pi-user"  shape='circle' />
                        }

                        {userData?.user_data?.avatar_url ? <Button className='p-button-sm p-button-danger p-button-text' icon="pi pi-times" label='Remover foto' onClick={removePhoto} /> : '' }

                        <FileUpload 
                            ref={photoupload}
                            chooseOptions={{
                                className:'p-button-small p-button-text',
                                label:'Trocar foto',
                                icon:'pi pi-image'
                            }}
                            onSelect={(e)=> uploadImage(e.files[0])}
                            className='ml-2' 
                            mode="basic"
                            accept='image/*' 
                            customUpload
                            uploadHandler={(e)=>{
                                if(e.files[0]){
                                    photoupload.current.clear();
                                }
                            }}
                        />
                            
                    </>} 
                    right={()=><>
                        { !isEdit ? <Button onClick={()=>{getData()}} icon="pi pi-sync" className="p-button-warning p-button-text" /> : ''}
                        {
                            isEdit ?
                                <>
                                    <Button onClick={()=>{setIsEdit(false); getData();}} icon="pi pi-times" className="p-button-danger p-button-text" />
                                    <Button onClick={handleSubmit(onSubmit)} icon="pi pi-save" className="p-button-success ml-2 p-button-text" />
                                </>
                            :
                                <>
                                    <Button onClick={()=>{setIsEdit(true)}} icon="pi pi-pencil" className="p-button-success ml-2 p-button-text" />
                                </>
                        }
                    </>} 
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} >
            <div className="grid">
            <div className="col-1" style={{display:'flex', alignItems:'center'}}>
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Prefixo</strong>: {userData?.prefixe || '-'}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`prefixe`} defaultValue={userData?.prefixe} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Prefixo' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-5">
                    <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <h3>{userData?.name}</h3>
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`name`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Nome' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                
                <div className="col-4" style={{display:'flex', alignItems:'center'}}>
                    <Inplace style={{width:'100%'}} active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay style={{width:'100%'}}>
                            <strong>Tipo acesso</strong>: {userData?.type || '-'}
                        </InplaceDisplay>
                        <InplaceContent style={{width:'100%'}}>
                            <Controller name={`user_type_id`} defaultValue={~~userData?.user_type_id} control={control}  render={({ field, fieldState }) => (
                                <Dropdown placeholder='Tipo de acesso' {...field} options={listClientTypeData} optionLabel="name" optionValue="id"  style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>

                <div className="col-2" style={{display:'flex', alignItems:'center'}}>
                    <Inplace style={{width:'100%'}} active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay style={{width:'100%'}}>
                            <Tag style={{width:'100%',backgroundColor: (~~userData?.active === 1 ? 'var(--green-200)' : 'var(--red-200)'), color: (~~user.active === 1 ? 'var(--green-800)' : 'var(--red-800)')}} value={~~user.active === 1 ? 'Ativo' : 'Inátivo' } />
                        </InplaceDisplay>
                        <InplaceContent style={{width:'100%'}}>
                            <Controller name={`active`} defaultValue={~~userData?.active === 1} control={control}  render={({ field, fieldState }) => (
                                <Dropdown placeholder='Ativo' {...field} options={[{name:'Sim', id: true},{name: 'Não', id: false}
                                ]} optionLabel="name" optionValue="id"  style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-12">
                    <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                <strong>CPF/CNPJ</strong>: {formatCPJCNPJ(userData?.user_data?.cpf_cnpj)} 
                            </div>
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`cpf_cnpj`} defaultValue={userData?.user_data?.cpf_cnpj} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='CPF / CNPJ' {...field} inputStyle={{width:'100%'}} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace> 
                </div>
            </div>
            <div className="grid mt-2 mb-4">
                <div className="col-6">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Username</strong>: {userData?.username}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`username`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputText {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                {
                    isEdit ?
                        <div className="col-3" style={{display:'flex', alignItems:'center'}}>
                            
                            <label htmlFor="cb2" className="p-checkbox-label mr-4">
                                <strong>Deseja alterar senha?</strong>
                            </label>
                            <Checkbox inputId="cb2" value="alterpassword" onChange={()=> setAlterPassword(!alterPassword) } checked={alterPassword} />
                        </div>
                    :
                    ''
                }
                <div className={isEdit ? "col-3" : "col-6"}>
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Senha</strong>: **********
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`password`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Senha' type='password' disabled={!alterPassword} {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                    <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Data nascimento</strong>: {userData?.user_data?.birthday ? new Date(userData?.user_data?.birthday?.split('-')[0], ~~userData?.user_data?.birthday?.split('-')[1] - 1, userData?.user_data?.birthday?.split('-')[2]).toLocaleDateString() : '' } {userData?.user_data?.birthday ? ' - ' : ''} {calculateOlder(userData?.user_data?.birthday)} {userData?.user_data?.birthday ? 'anos' : ''}
                        </InplaceDisplay>
                        <InplaceContent>
                        <Controller name={`birthday`} defaultValue={ new Date(userData?.user_data?.birthday?.split('-')[0], ~~userData?.user_data?.birthday?.split('-')[1] - 1, userData?.user_data?.birthday?.split('-')[2])} control={control}  render={({ field, fieldState }) => (
                            <Calendar {...field} locale='pt-BR' dateFormat='dd/mm/yy' showIcon style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3">
                    <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <span style={{textTransform:'capitalize'}}><strong>Estado civíl</strong>: {userData?.user_data?.marital_status}</span>
                        </InplaceDisplay>
                        <InplaceContent>
                        <Controller name={`marital_status`} defaultValue={userData?.user_data?.marital_status } control={control}  render={({ field, fieldState }) => (
                            <Dropdown 
                            required
                            options={[
                                {label:'Solteiro(a)', value:'solteiro(a)'},
                                {label:'Casado(a)', value:'casado(a)'},
                                {label:'Divorciado(a)', value:'divorciado(a)'},
                                {label:'Viuvo(a)', value:'viuvo(a)'},
                                {label:'União Estável', value:'União Estável'}
                            ]}
                            inputStyle={{width:'100%'}}  placeholder='Estado civil' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                        )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3">
                    <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Gênero</strong>: {(/m/gi).test(userData?.user_data?.genere) ? 'Masculino' : 'Feminino'}
                        </InplaceDisplay>
                        <InplaceContent>
                        <Controller name={`genere`} defaultValue={userData?.user_data?.genere } control={control}  render={({ field, fieldState }) => (
                            <Dropdown 
                            required
                            options={[
                                {label:'Masculino', value:'M'},
                                {label:'Feminino', value:'F'}
                            ]}
                            inputStyle={{width:'100%'}}  placeholder='Gênero' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                        )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                
                <div className="col-6" style={{display:'flex', alignItems:'center'}}>
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>RG</strong>: {formatRG(userData?.user_data?.rg)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`rg`} defaultValue={userData?.user_data?.rg} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='RG' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6" style={{display:'flex', alignItems:'center'}}>
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>CNH</strong>: {userData?.user_data?.cnh}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`cnh`} defaultValue={userData?.user_data?.cnh} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='CNH' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6" style={{display:'flex', alignItems:'center'}}>
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Título de eleitor</strong>: {userData?.user_data?.eleitoral_number}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`cnh`} defaultValue={userData?.user_data?.eleitoral_number} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Título de eleitor' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6" style={{display:'flex', alignItems:'center'}}>
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Certificado de reservista</strong>: {userData?.user_data?.military}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`military`} defaultValue={userData?.user_data?.military} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Certificado de reservista' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>CTPS</strong>: {(userData?.user_data?.ctps)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`ctps`} defaultValue={userData?.user_data?.ctps} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='CTPS' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>CTPS série</strong>: {(userData?.user_data?.ctps_serie)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`ctps_serie`} defaultValue={userData?.user_data?.ctps_serie} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='CTPS' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay> 
                            <strong>CTPS criação</strong>: {userData?.user_data?.ctps_create ? new Date(userData?.user_data?.ctps_create?.split('-')[0], ~~userData?.user_data?.ctps_create?.split('-')[1] - 1, userData?.user_data?.ctps_create?.split('-')[2]).toLocaleDateString() : '' } 
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`ctps_create`} defaultValue={new Date(userData?.user_data?.ctps_create?.split('-')[0], ~~userData?.user_data?.ctps_create?.split('-')[1] - 1, userData?.user_data?.ctps_create?.split('-')[2])} control={control}  render={({ field, fieldState }) => (
                                <Calendar {...field} locale='pt-BR' dateFormat='dd/mm/yy' showIcon  placeholder='CTPS criação' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-12">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>PIS</strong>: {(userData?.user_data?.pis)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`pis`} defaultValue={userData?.user_data?.pis} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='PIS' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Nome pai</strong>: {(userData?.user_data?.father_name)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`father_name`} defaultValue={userData?.user_data?.father_name} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Nome pai' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Nome mãe</strong>: {(userData?.user_data?.mother_name)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`mother_name`} defaultValue={userData?.user_data?.mother_name} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Nome mãe' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                
                <div className="col-12 'mt-2 mb-2">
                   
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <>
                            <strong>Outras informações</strong>: 
                            <div className='mt-2 mb-2'>{(userData?.user_data?.others)}</div>
                            </>
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`others`} defaultValue={userData?.user_data?.others} control={control}  render={({ field, fieldState }) => (
                                <InputTextarea autoResize placeholder='Outros' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-12">
                        <strong>
                            Dados bancários
                        </strong>
                    </div>
                    <div className="col-12">
                        <DataTable 
                            header={()=><>
                            {
                                isEdit ?
                                <div className="grid">
                                    <div className="col-6">
                                        <InputText placeholder='Banco' style={{width:'100%'}} value={bank?.name} onChange={(e)=> {
                                            const t = {...bank};
                                            t.name = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-6">
                                        <InputText placeholder='Tipo (corrente, poupança)' style={{width:'100%'}} value={bank?.category} onChange={(e)=> {
                                            const t = {...bank};
                                            t.type = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-2">
                                        <InputText placeholder='Agência' style={{width:'100%'}} value={bank?.agency} onChange={(e)=> {
                                            const t = {...bank};
                                            t.agency = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-4">
                                        <InputText placeholder='Conta' style={{width:'100%'}} value={bank?.account} onChange={(e)=> {
                                            const t = {...bank};
                                            t.account = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-5">
                                        <InputText placeholder='PIX' style={{width:'100%'}} value={bank?.pix} onChange={(e)=> {
                                            const t = {...bank};
                                            t.pix = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                 
                                    <div className="col-1" style={{textAlign:'right'}}>
                                        <Button className='p-button-secundary' onClick={addBank} tooltip="Adicionar dados bancários" tooltipOptions={{position:'bottom'}} icon="pi pi-plus" />
                                    </div>
                                </div>
                                :''}
                            </>}
                            value={listBank || []} 
                            responsiveLayout="scroll"
                            emptyMessage="Não há informações cadastradas"
                            size='small'
                            stripedRows
                            rows={10}
                            paginator={listBank?.length > 10}
                            editMode="row"
                            onRowEditComplete={onRowEditCompleteBank}
                        >
                            <Column field="name" header="Nome" />
                            <Column field="type" header="Tipo" />
                            <Column field="agency" header="Agência" />
                            <Column field="account" header="Conta" />
                            <Column field="pix" header="PIX" />
                            <Column style={{width:'50px'}} body={(row)=> {
                                return <Button onClick={()=>removeBank(row.id)} icon="pi pi-times" className='p-button-danger p-button-sm p-button-text' />
                            }} header="" />
                        </DataTable>
                    </div>
                <div className="col-12">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Profissão</strong>: {userData?.user_data?.work}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`work`} defaultValue={userData?.user_data?.work} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Profissão' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                
                <div className="col-12">
                   <div className='mb-2'>
                        <strong>Contatos</strong>
                   </div>
                   <DataTable 
                        header={()=>
                            (
                                isEdit ?
                                <>
                                    <div className="grid">
                                        <div className="col-3">
                                            <Dropdown value={contact?.type} options={[{label:'Email', value: 'email'},{label: 'Celular', value: 'mobile'}, {label: 'Telefone', value: 'phone'}]} placeholder='Tipo' onChange={(e)=> {
                                                const t = {...contact};
                                                t.type = e.target.value;
                                                setContact(t);
                                            }} />
                                        </div>
                                        <div className="col-4">
                                            <InputText value={contact?.value} type={(/email/gi).test(contact?.type) ? 'email' : 'tel' } placeholder='Valor' style={{width:'100%'}} onChange={(e)=> {
                                                const t = {...contact};
                                                t.value = e.target.value;
                                                setContact(t);
                                            }} />
                                        </div>
                                        <div className="col-4">
                                            <InputText value={contact?.note} placeholder='Observação' style={{width:'100%'}} onChange={(e)=> {
                                                const t = {...contact};
                                                t.note = e.target.value;
                                                setContact(t);
                                            }} />
                                        </div>
                                        <div className="col-1" style={{textAlign:'right'}}>
                                            <Button className='p-button-secundary' onClick={(e)=>{e.preventDefault(); addContact();}} tooltip="Adicionar contato" tooltipOptions={{position:'bottom'}} icon="pi pi-plus" />
                                        </div>
                                    </div>
                                </>
                            :
                            ''
                            )
                        }
                        value={listContacts} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há dados cadastradas"
                        size='small'
                        stripedRows
                        rows={10}
                        paginator={listContacts?.length > 10}
                        editMode="row"
                        onRowEditComplete={onRowEditCompleteContact}
                    >
                        <Column style={{width:'200px'}} field="type" header="Tipo" 
                        editor={
                            (options) => {
                                return <Dropdown options={[{label:'Email', value: 'email'},{label: 'Celular', value: 'mobile'}, {label: 'Telefone', value: 'phone'}]} placeholder='Tipo' inputStyle={{width:'100%'}} style={{width:'100%'}}  value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }
                        
                        />
                        
                        <Column body={(row)=>{
                            return !(/email/gi).test(row?.type) ? <>{formatPhoneNumber(row?.value)}</> : <>{row?.value}</>
                        }}  
                        field="value" header="Contato" 
                        editor={
                            (options) => {
                                return <InputText placeholder='Valor' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                    }
                    />
                        <Column  field="note" header="Observação" 
                        editor={
                            (options) => {
                                return <InputText placeholder='Observação' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }
                        />
                        <Column rowEditor={isEdit} headerStyle={{ minWidth: '50px', textAlign:'right'}} bodyStyle={{ textAlign: 'right' }}></Column>
                        <Column style={{width:'50px'}} body={(row)=> {
                            return (isEdit ? <Button onClick={(e)=>{e.preventDefault(); removeContact(row.temp_id || row.id);}} icon="pi pi-trash" className='p-button-danger p-button-sm p-button-text' /> : '')
                        }} header="" />
                    </DataTable>
                </div>
                <div className="col-12">
                   <div className='mb-2'>
                        <strong>Endereços</strong>
                   </div>
                   <DataTable
                        header={()=>
                            (
                                isEdit ?
                                <>
                                    <div className="grid">
                                        <div className="col-6">
                                            <InputText placeholder='Nome (Casa, empresa, loja , outros)' value={address?.name} style={{width:'100%'}} onChange={(e)=> {
                                                const t = {...address};
                                                t.name = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-6">
                                              <div className='grid'>
                                                <div className="col-10">
                                                    <span style={{width:'100%'}} className="p-input-icon-left">
                                                        <i className="pi pi-map-marker" />
                                                        <InputText required inputStyle={{width:'100%'}} value={address?.zipcode} placeholder='CEP' autoComplete='off' className='' style={{width:'100%'}} nChange={(e)=> {
                                                            const t = {...address};
                                                            t.zipcode = e.target.value;
                                                            setAddress(t); 
                                                            getCepData(e.target.value);
                                                            
                                                        }}/>
                                                    </span>
                                                </div>
                                                <div className="col-2" style={{textAlign:'right'}}>
                                                    <Button style={{width:'100%'}} onClick={searchAddress} icon="pi pi-search" className="p-button-secondary"/>
                                                    <ConfirmDialog />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <InputText placeholder='Endereço' style={{width:'100%'}} value={address?.address} onChange={(e)=> {
                                                const t = {...address};
                                                t.address = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-3">
                                            <InputText placeholder='Número' style={{width:'100%'}} value={address?.number} onChange={(e)=> {
                                                const t = {...address};
                                                t.number = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-3">
                                            <InputText placeholder='Complemento' style={{width:'100%'}} value={address?.extra} onChange={(e)=> {
                                                const t = {...address};
                                                t.extra = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-6">
                                            <InputText placeholder='Bairro' style={{width:'100%'}} value={address?.district} onChange={(e)=> {
                                                const t = {...address};
                                                t.district = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-3">
                                            <InputText placeholder='Cidade' style={{width:'100%'}} value={address?.city} onChange={(e)=> {
                                                const t = {...address};
                                                t.city = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-3">
                                            <InputText placeholder='Estado' style={{width:'100%'}} value={address?.state} onChange={(e)=> {
                                                const t = {...address};
                                                t.state = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-11">
                                            <InputTextarea autoResize placeholder='Referência' value={address?.reference} style={{width:'100%'}} onChange={(e)=> {
                                                const t = {...address};
                                                t.reference = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-1" style={{textAlign:'right'}}>
                                            <Button style={{width:'100%'}} className='p-button-secundary' onClick={(e)=>{e.preventDefault(); addAddress();}} tooltip="Adicionar endereço" tooltipOptions={{position:'bottom'}} icon="pi pi-plus" />
                                        </div>
                                    </div>
                                </>
                            :
                            ''
                            )
                        } 
                        value={listAddress} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há dados cadastrados"
                        size='small'
                        stripedRows
                        rows={10}
                        paginator={listAddress?.length > 10}
                        editMode="row"
                        onRowEditComplete={onRowEditCompleteAddress}
                    >
                        <Column field="name" header="Nome" 
                        editor={
                            (options) => {
                                return <InputText placeholder='Nome' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }
                        />
                        <Column field="zipcode" header="CEP" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='CEP' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="address" header="Endereço" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Endereço' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="number" header="Número" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Número' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="extra" header="Complemento" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Complemento' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="district" header="Bairro" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Bairro' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="city" header="Cidade" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Cidade' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="state" header="Estado" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Estado' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="reference" header="Referência" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Referência' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column rowEditor={isEdit} headerStyle={{ minWidth: '50px', textAlign:'right'}} bodyStyle={{ textAlign: 'right' }}></Column>
                        <Column style={{width:'50px'}} body={(row)=> {
                            return (isEdit ? <Button onClick={()=>removeAddress(row.temp_id || row.id)} icon="pi pi-trash" className='p-button-danger p-button-sm p-button-text' /> : '')
                        }} header="" />
                    </DataTable>
                </div>
            </div>
            </form>
            <div className="grid">
                <div className="col-12">
                <TabMenu 
                    activeIndex={active}
                    onTabChange={(e)=>setActive(e.index)}
                    model={tabMenuList} />
                </div>
            </div>
            <div key={`active-${active}`} className="grid mt-2 mb-2">
                <div class="col-12">
                    {getContent()}
                </div>
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}