import React, { useState, useEffect, useCallback, useRef } from "react";

import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import { SplitButton } from 'primereact/splitbutton';
import { Calendar } from 'primereact/calendar';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { api, URL_FEEDBACK_DASHBOARD } from "@/services/api";
import { CSAT, CSAT_FEEDBACK, NPS_FEEDBACK } from "@/services/provider/feedback";

import '@/assets/styles/pages/user/feedback/index.scss';

export const PageUserFeedback = () => {

    const [data, setData] = useState();
    const [showFeedback, setShowFeedback] = useState(false);
    const [cpf, setCPF] = useState();
    const [link, setLink] = useState();
    const [calendar, setCalendar] = useState(new Date());
    const [calendarEnd, setCalendarEnd] = useState(new Date());

    const toast = useRef(null);

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const getData = useCallback(async ()=>{

        const dashdata = await api.get(URL_FEEDBACK_DASHBOARD, {start:calendar.toISOString().substring(0, 10), end: calendarEnd.toISOString().substring(0, 10)}, true);
        const dash = {};
        dash.csat = [];
        Object.keys(dashdata?.data?.csat).map(item => {
            const quest_id = ~~item.replace(/\D/gi, '');
            dash.csat[quest_id - 1] = { total: dashdata?.data?.csat[item]};
            const csatData = CSAT.filter(item => item.id === quest_id)[0];
            dash.csat[quest_id - 1].title = csatData.analytics;
            const feedback =  CSAT_FEEDBACK.filter(el => {
                return  ~~dashdata?.data?.csat[item] >= el.min && ~~dashdata?.data?.csat[item] <= el.max;
            })[0];
            dash.csat[quest_id - 1].feedback = feedback?.name;
            return item;
        })

        const feedbacknps =  NPS_FEEDBACK.filter(el => {
            return  ~~dashdata?.data?.nps >= el.min && ~~dashdata?.data?.nps <= el.max;
        })[0];

        dash.nps = {
            total: dashdata?.data?.nps,
            feedback: feedbacknps?.name
        };

        dash.total = dashdata?.data?.total;
        
        setData(dash);
    }, [calendar, calendarEnd])

    const reset = useCallback(()=>{
        setLink('');
        setCPF('');
    }, [])

    const generateLink = useCallback((e)=>{
        e.preventDefault();

        const linkobj = {
            user_ref: cpf,
            user_ref_key: new Date().getTime()
        }

        const token = btoa(JSON.stringify(linkobj));

        

        const url = `${window.location.protocol}//${window.location.host}/feedback/${token}`;

        setLink(url);

    }, [cpf])

    const copyContent = (data) => {
        navigator.clipboard.writeText(data);
        toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Conteúdo copiado com sucesso'});
    }

    const selectPeriod = (e) =>{
        const start = new Date();
        start.setDate(start.getDate() - e);
        const end = new Date();

        setCalendar(start);
        setCalendarEnd(end);
    }

    useEffect(()=>{
        getData();
    }, [getData])

    return (
        <div className="page-user-feedback">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Feedback'}
                    ]}
                />
            </div>
            <div className="header">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><>
                        <Calendar dateFormat="dd/mm/yy" showIcon locale='pt-BR' value={calendar} onChange={(e)=>setCalendar(e.value)} />
                        <Calendar className='ml-3 mr-3' dateFormat="dd/mm/yy" showIcon locale='pt-BR' minDate={calendar} value={calendarEnd} onChange={(e)=>setCalendarEnd(e.value)} />
                        <SplitButton onClick={()=>selectPeriod(0)} label="Hoje" className="p-button-raised"
                            model={[
                                {label:'15 Dias', command:()=>selectPeriod(15)},
                                {label:'30 Dias', command:()=>selectPeriod(30)},
                                {label:'60 Dias', command:()=>selectPeriod(60)}
                            ]} 
                         />
 
                    </>} 
                    right={()=><>
                        <Button onClick={()=>{getData()}} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>{setShowFeedback(true)}} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} 
                />
            </div>
            <div className="resume">
                <div className="grid">
                    <div className="col-12">
                        <strong>Total de feedbacks</strong>: {data?.total}
                    </div>
                    <div className="col-6">
                        <div className="card" style={{'--background':'var(--blue-50)', '--text':'var(--blue-400'}}>
                            <div className="title">
                                {data?.csat[0]?.title}
                            </div>
                            <div className="icon">
                                <i className="pi pi-star" />
                            </div>
                            <div className="content">
                            { data?.csat[0]?.total ? `${data?.csat[0]?.feedback} - ${data?.csat[0]?.total}%` : '-' }
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card" style={{'--background':'var(--green-50)', '--text':'var(--green-400'}}>
                            <div className="title">
                                {data?.csat[1]?.title}
                            </div>
                            <div className="icon">
                                <i className="pi pi-book" />
                            </div>
                            <div className="content">
                            { data?.csat[1]?.total ? `${data?.csat[1]?.feedback} - ${data?.csat[1]?.total}%` : '-'}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card" style={{'--background':'var(--yellow-50)', '--text':'var(--yellow-400'}}>
                            <div className="title">
                                {data?.csat[2]?.title}
                            </div>
                            <div className="icon">
                                <i className="pi pi-shield" />
                            </div>
                            <div className="content">
                            { data?.csat[2]?.total ? `${data?.csat[2]?.feedback} - ${data?.csat[2]?.total}%` : '-'}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card" style={{'--background':'var(--red-50)', '--text':'var(--red-400'}}>
                            <div className="title">
                                {data?.csat[3]?.title}
                            </div>
                            <div className="icon">
                                <i className="pi pi-sitemap" />
                            </div>
                            <div className="content">
                                { data?.csat[3]?.total ? `${data?.csat[3]?.feedback} - ${data?.csat[3]?.total}%` : '-' }
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card" style={{'--background':'var(--purple-50)', '--text':'var(--purple-400'}}>
                            <div className="title">
                                {data?.csat[4]?.title}
                            </div>
                            <div className="icon">
                                <i className="pi pi-history" />
                            </div>
                            <div className="content">
                            {data?.csat[4]?.total ? `${data?.csat[4]?.feedback} - ${data?.csat[4]?.total}%` : '-'}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card" style={{'--background':'var(--orange-50)', '--text':'var(--orange-400'}}>
                            <div className="title">
                                NPS
                            </div>
                            <div className="icon">
                                <i className="pi pi-thumbs-up" />
                            </div>
                            <div className="content">
                                {data?.nps?.total ? `${data?.nps?.feedback} - ${data?.nps?.total}%` : '-'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid">
                <div class="col-6"></div>
                <div class="col-6"></div>
            </div>

            <Toast ref={toast} position="bottom-center" />

            <Dialog header="Gerar link feedback"
                visible={showFeedback} 
                style={{ width: '98%', maxWidth:'400px' }} 
                onHide={() =>{setShowFeedback(false); reset(); }}>
                    <form method="post" onSubmit={generateLink}>
                        <div className="grid mt-2">
                            <div className="col-9">
                                <InputText style={{width:'100%'}} maxLength={11} required pattern="[0-9]{11}" size="small" type="tel" placeholder="Informe o CPF" value={cpf} onInput={(e)=>setCPF((e.target.value || '').replace(/\D/gi, ''))} />
                            </div>
                            <div className="col-3">
                                <Button label="Gerar" />
                            </div>
                            <div className="col-12" style={{textAlign:'center'}}>
                                {
                                    link ?
                                        <Button className="p-button-sm p-button-text" label="Copiar Link" icon="pi pi-copy" onClick={()=>copyContent(link)} />
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </form>
                </Dialog>
        </div>
    )
}