import React, { useState, useEffect, useCallback } from 'react';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { UIFullcalendarDay } from './day';
import { UIFullcalendarWeek } from './week';
import { UIFullcalendarMonth } from './month';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import '@/assets/styles/components/ui/fullcalendar/index.scss';

export const UIFullcalendar = (props) => {
    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const [tabview, setTabview] = useState('month');
    const [contentTab, setContentTab] = useState();
    const now = new Date();
    const [currentDate, setCurrentDate] = useState(new Date(`${now.toISOString().substr(0, 10)}`));
    const [update, setUpdate] = useState(new Date().getTime());

    const setToday = () => {
        setCurrentDate(new Date());
    }

    const openDay = (_time) =>{
        setCurrentDate(_time);
        setTabview('day');
    }

    const forceUpdate = useCallback(() =>{
        setUpdate(new Date().getTime());
        console.log('Force update', currentDate);
        props?.updateCurrentDate(new Date(`${currentDate.toISOString().substr(0, 10)}`));
    },[setUpdate, props]);

    const getContent = useCallback(() =>{
        const data = {
            day: <UIFullcalendarDay 
                    forceUpdate={forceUpdate} 
                    start={props?.week_available ? props?.week_available[currentDate?.getDay()]?.start : null} 
                    end={props?.week_available ? props?.week_available[currentDate?.getDay()]?.end : null} 
                    step={props?.step} 
                    events={props?.events} 
                    timeBlock={props?.timeBlock}
                    date={currentDate} 
                    editable={props?.editable} 
                    create={props?.create} 
                    remove={props?.remove} 
                    show={props?.show} 
                    />,
            week: <UIFullcalendarWeek events={props.events} openDay={openDay} date={currentDate}/>,
            month: <UIFullcalendarMonth events={props.events} openDay={openDay} date={currentDate} />
        }

        setContentTab(data[tabview]);
    },[setContentTab, tabview, currentDate, forceUpdate, props]);


    useEffect(()=>{
        getContent();
    }, [currentDate, getContent]);

    useEffect(()=>{

        getContent();

    }, [tabview, getContent])

    useEffect(()=> {
        props?.updateCurrentDate(currentDate);
    }, [currentDate, props])

    useEffect(()=>{
        getContent();
    }, [getContent])

    return (
        <div className="ui-fullcalendar" key={`version-calendar-${update}`}>
            <div className="header-fullcalendar">
                <div className="date-reference">
                    <div className="p-inputgroup">
                        {
                            tabview === 'day' ?
                            <Calendar
                            locale='pt-BR'
                            value={currentDate} 
                            onChange={(e) => setCurrentDate(e.value)} 
                            dateFormat="dd/mm/yy"/>
                            :
                            ''
                        }
                        {
                            tabview === 'week' ?
                            <Calendar
                            locale='pt-BR'
                            value={currentDate} 
                            onChange={(e) => setCurrentDate(e.value)} 
                            dateFormat="dd/mm/yy"/>
                            :
                            ''
                        }
                        {
                            tabview === 'month' ?
                            <Calendar
                            locale='pt-BR'
                            value={currentDate} 
                            onChange={(e) => setCurrentDate(e.value)} 
                            view="month" 
                            dateFormat="MM - yy"/>
                            :
                            ''
                        }
                        <Button className='today' onClick={setToday} label="Hoje" />
                    </div>
                </div>
                <div></div>
                <div className="view-buttons">
                    <div className={`${tabview === 'day' ? 'current' : ''}`} onClick={()=>setTabview('day')} >Dia</div>
                    <div className={`${tabview === 'week' ? 'current' : ''}`} onClick={()=>setTabview('week')}>Semana</div>
                    <div className={`${tabview === 'month' ? 'current' : ''}`} onClick={()=>setTabview('month')}>Mês</div>
                </div>
            </div>
            <div className="main-frame">
                {contentTab}
            </div>
        </div>
    )
}