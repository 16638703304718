import React, {useState, useEffect, useCallback, useRef} from 'react';

import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AutoComplete } from 'primereact/autocomplete';
import { Timeline } from 'primereact/timeline';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { LoadFile } from "@/components/ui/loadfile";

import { Dialog } from 'primereact/dialog';

import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';

import { useForm, Controller } from 'react-hook-form';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';
 

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { api, URL_DEADLINE_BYID, URL_DEADLINE_COURT,URL_DEADLINE_STATUS, URL_DEADLINE_SPECIES, URL_COLLABORATORS, URL_DEADLINE_UPDATE, URL_DEADLINE_HISTORY_CREATE, URL_FILES_UPLOAD, URL_FILES_FILE, URL_FILES_DELETE, URL_DEADLINE_DELETE_FILE } from '@/services/api';
import { useAPP } from '@/services/provider/app';

export const PageUserDeadlineView = () => {

    const params = useParams();
    const navigate = useNavigate();
    const { user } = useAPP();

    const [data, setData] = useState();

    const [active, setActive] = useState(false);
    const [ court, setCourt] = useState();
    const [ species, setSpecies] = useState();
    const [ status, setStatus] = useState();
    const [ collaborators, setCollaborators] = useState();

    const [showContent, setShowContent] = useState(false);
    const [ content, setContent] = useState();

    const uploadData = useRef(null);
    const [uploadName, setUploadName] = useState('');
    const [uploadDate, setUploadDate] = useState();

    const [historyText, setHistoryText] = useState();

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const form = useForm({});
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const tagForm = useRef();

    const sendData = useRef({});

    const filesUploadList = useRef();

    const toast = useRef(null);
    

    const getData = useCallback(async ()=>{
        let result = await api.get(`${URL_DEADLINE_BYID}/${params?.id}`, null, true);

        Object.keys(result?.data).map(el => {
            if((/[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}/gi).test(result?.data[el])){
                result.data[el] = new Date(result?.data[el])
            }

            return el
        })
        
        sendData.current = result?.data;
        setData(result?.data);
    }, [params]);


    const uploadFiles = useCallback(async () => {
        //console.log('Upload', uploadData.current);

        let messages = []

        let fileid = null;

        if(uploadData.current){
            const sendData = new FormData();
            
            sendData.append('file', uploadData.current);
            sendData.append('name', uploadName);
            sendData.append('area_location', 'deadline');
            sendData.append('user_id', user.id);
            sendData.append('instance_id', params?.id);
            
            const data = await api.upload(URL_FILES_UPLOAD, sendData, true);
            if(data?.data){
                fileid = data.data;
                uploadData.current = null;
                filesUploadList.current.clear();
                messages.push({severity:'success', summary:`Sucesso`, detail:'Dados atualizados', life: 3000});
            }else{
                messages.push({severity:'error', summary:`Revise`, detail:'Dados inválidos', life: 3000});
            }
            
        }

        let deadline_files = {
            name: uploadName,
            deadline_control_id: params?.id,
            datetime: uploadDate,
        }
        
        if(fileid){
            deadline_files.file_id = fileid;
        }
        

        const deadline_data = await api.put(URL_DEADLINE_UPDATE, {deadline_files: [deadline_files], id: params.id}, true);

        if(deadline_data?.data){
            messages.push({severity:'success', summary:`Sucesso`, detail:'Dados atualizados', life: 3000});
        }else{
            messages.push({severity:'error', summary:`Revise`, detail:'Houve um problema ao salvar os dados', life: 3000});
        }
        
        setUploadName('');
        setUploadDate('');
        getData();

        toast.current.show(messages);


    }, [uploadData, getData, uploadName, uploadDate])


    const getStatus  = useCallback(async()=>{
        const result = await api.get(`${URL_DEADLINE_STATUS}`, null, false);
        setStatus(result?.data);
    }, [])

    const getSpecies  = useCallback(async()=>{
        const result = await api.get(`${URL_DEADLINE_SPECIES}`, null, false);
        setSpecies(result?.data);
    }, [])

    const getCourt  = useCallback(async()=>{
        const result = await api.get(`${URL_DEADLINE_COURT}`, null, false);
        
        setCourt(result?.data);
    }, [])

    const getCollaborators  = useCallback(async()=>{
        const result = await api.get(`${URL_COLLABORATORS}`, null, true);
        setCollaborators(result?.data);
    }, [])

    const addHistory = useCallback(async () => {
        const data = {
            deadline_control_id: params.id,
            user_id: user.id,
            description: historyText
        }

        await api.post(URL_DEADLINE_HISTORY_CREATE, data, true);
        setHistoryText('');
        getData();

    }, [params, user, historyText, getData])

    const saveData = useCallback(async(_data)=>{
        
        let data = {...sendData.current};

        const remove = ["status", "status_color" , "court", "specie", "specie_color", "process_number", "process_cases_id", "user_id", "name_user", "process_number", "user_adm_name",'user_attorney_name'];

        remove.map(item => {
            delete data[item];
            return item;
        })

        data.id = params.id;

        Object.keys(data).map(item => {
            // if(_data[item] instanceof Date){
            //     data[item] = _data[item];
            // }else{
            //     data[item] = _data[item];
            // }

            data[item] = _data[item];

            if('duration_time' === item){
                if(_data[item]){
                    data[item] = _data[item].value;
                }
            }
            return item;
        })

        if(data?.user_adm_id && !data?.time_set_user_adm){
            data.time_set_user_adm = new Date();
        }

        delete data?.history;

        const sendData = {
            id: params.id, 
            data: data
        }

        await api.put(URL_DEADLINE_UPDATE, sendData, true);
        ////console.log(result);
        
        setActive(false);
        getData();
    }, [params, getData])

    const openContent = (_data) => {
        setContent(_data);
        setShowContent(true);
    }


    const donwloadContent = useCallback( async (_data) => {
        const url_base = `${URL_FILES_FILE}/?file=${_data.url}`;

        const contentData = await fetch(url_base);
        const content = await contentData.blob();

        const href = URL.createObjectURL(content);

        const forcedownload = document.createElement('a');
        forcedownload.setAttribute('download', `${_data.name}.${_data.ext}`);
        forcedownload.setAttribute('href', href);
        forcedownload.click();
        URL.revokeObjectURL(href);

    }, []);

    
    const deleteFile = useCallback(async (_data) => {
        const acceptFunc = async () =>{
            await api.delete(`${URL_DEADLINE_DELETE_FILE}`, {file_id: _data.file_id}, true);
            await api.delete(`${URL_FILES_DELETE}`, {file: _data.url}, true);
            getData();
            toast.current.show({severity:'success', summary:`Sucesso`, detail:'Dados atualizado com sucesso', life: 3000});
        }


        confirmDialog({
            message: `Deseja apagar definitivamente o arquivo ${_data.name}?` ,
            header: 'Confirmar',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Sim",
            acceptClassName:'p-button-danger',
            rejectLabel:"Não",
            accept: () => acceptFunc(),
            reject: () =>{}
        });
    }, [])

    const updateUploadFile = useCallback(async(_data) => {
        //console.log(_data);


        const sendData = new FormData();
        sendData.append('area_location', 'deadline');
        sendData.append('user_id', user.id);
        sendData.append('instance_id', params?.id);
        sendData.append('name', _data.name);
        sendData.append('file', _data.file);

        const data = await api.upload(URL_FILES_UPLOAD, sendData, true);
        if(data?.data){
            const filedata = {
                id: params?.id,
                deadline_files :[
                    {file_id: data.data.id, id: _data.id}
                ]
            }
            await api.put(URL_DEADLINE_UPDATE, filedata, true);
            getData();
        }else{
            toast.current.show({severity:'error', summary:`Revise`, detail:'Dados inválidos', life: 3000});
        }

    }, []);

    useEffect(()=>{
        if(active){
            reset(sendData.current)
        }
    }, [active, sendData, reset])

    useEffect(()=>{
        const init = async () => {
            await getStatus();
            await getSpecies();
            await getCourt();
            await getCollaborators();
            getData();
        }

        init();

    },[getData, getStatus, getSpecies, getCourt, getCollaborators]);


    if(!data)
        return (
            <div className="page-user-deadline-view">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-user-deadline-view">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Controle de prazos', url:'/user/deadline' },
                        {label:'Detalhes controle de prazos' }
                    ]}
                />
            </div>
            <div className="header mt-4 mb-2">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><></>} 
                    right={()=><>
                        {
                            active ?
                            <>
                                <Button onClick={()=>{setActive(false)}} icon="pi pi-times" className="p-button-danger mr-2 p-button-text" />
                                <Button onClick={handleSubmit(saveData)} icon="pi pi-save" className="p-button-success mr-2 p-button-text" />
                            </>
                            :
                            <Button onClick={()=>{setActive(true)}} icon="pi pi-pencil" className="p-button-success mr-2 p-button-text" />
                        }
                        <Button onClick={()=>{getData()}} icon="pi pi-sync" className="p-button-warning p-button-text" />
                    </>} 
                />
            </div>
            <form ref={tagForm} onSubmit={(e)=> e.preventDefault()} >
            <div className="grid mt-4">
                <div className="col-12">
                    <strong>Processo</strong>: { data?.process_id ? <span style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/processcases/${data?.process_cases_id}`,{state:{tabActive:1}})}>{data?.process_number}</span> : '-'}
                </div>
                <div className="col-12">
                    <strong>Cliente</strong>: { data?.process_id ? <span style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/profile/${data?.user_id}`)}>{data?.name_user}</span> : '-'}
                </div>
                <div className="col-4">
                    <strong>Responsável</strong>:
                    <div className='mt-2'>
                        <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                            <InplaceDisplay>
                                {data?.user_adm_name}
                            </InplaceDisplay>
                            <InplaceContent>
                                <Controller name={`user_adm_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                    <Dropdown filter {...field} options={collaborators} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                            </InplaceContent>
                        </Inplace>
                    </div>
                </div>
                <div className="col-4">
                    <strong>Advogado(a)</strong>:
                    <div className='mt-2'>
                        <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                            <InplaceDisplay>
                                {data?.user_attorney_name}
                            </InplaceDisplay>
                            <InplaceContent>
                                <Controller name={`attorney_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                    <Dropdown filter {...field} options={collaborators} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                            </InplaceContent>
                        </Inplace>
                    </div>
                </div>
                <div className="col-4">
                    <strong>Delegado em</strong>: 
                    <div className='mt-2'>
                        {data?.time_set_user_adm ? `${new Date(data.time_set_user_adm).toLocaleDateString()} - ${new Date(data.time_set_user_adm).toLocaleTimeString()}` : '-'}
                    </div>
                </div>
                <div className="col-4">
                    <div><strong>Tribunal</strong></div>
                    <div className='mt-2'>
                        <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                            <InplaceDisplay>
                                {data?.court}
                            </InplaceDisplay>
                            <InplaceContent>
                            <Controller name={`deadline_control_court_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Dropdown filter {...field} options={court} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                            </InplaceContent>
                        </Inplace>
                    </div>
                </div>
                <div className="col-4">
                    <div><strong>Espécie</strong></div>
                    <div className='mt-2'>
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            <Tag style={{backgroundColor:data?.specie_color, width:'100%'}} value={data?.specie} />
                        </InplaceDisplay>
                        <InplaceContent>
                        <Controller name={`deadline_control_species_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Dropdown filter {...field} options={species} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                        </InplaceContent>
                    </Inplace>
                    </div>
                </div>
                <div className="col-4">
                    <div><strong>Status</strong></div>
                    <div className='mt-2'>
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            <Tag style={{backgroundColor:data?.status_color, width:'100%'}} value={data?.status} />
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`deadline_control_status_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Dropdown filter {...field} options={status} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                    </div>
                </div>
                <div className="col-12">
                    <div><strong>Motivo intimação</strong></div>
                    <div className='mt-2'>
                        <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            {data?.reason}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`reason`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputTextarea autoResize style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                    </div>
                </div>
                <div className="col-12">
                    <div className='mb-2'><strong>Criado em</strong></div>
                    {data?.timecreated ? `${new Date(data.timecreated).toLocaleDateString()} - ${new Date(data.timecreated).toLocaleTimeString()}` : '-'}
                </div>
                <div className="col-12">

                </div>
                <div className="col-4">
                    <div className='mb-2'><strong>Data recomendada</strong></div>
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            {data?.time_recommended ? new Date(data.time_recommended).toLocaleDateString() : '-'}
                        </InplaceDisplay>
                        <InplaceContent>
                        <Controller name={`time_recommended`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Calendar {...field} locale='pt-BR' showIcon style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-4">
                    <div className='mb-2'><strong>Data fatal</strong></div>
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            {data?.time_fatal ? new Date(data.time_fatal).toLocaleDateString() : '-'}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`time_fatal`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Calendar {...field} locale='pt-BR' showIcon style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-4">
                    <div className='mb-2'><strong>Expedição</strong></div>
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            {data?.expedition ? new Date(data.expedition).toLocaleDateString() : '-'}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`expedition`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Calendar {...field} locale='pt-BR' showIcon style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-4">
                    <div className='mb-2'><strong>Ciência</strong></div>
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            {data?.science ? new Date(data.science).toLocaleDateString() : '-'}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`science`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Calendar {...field} locale='pt-BR' showIcon style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-4">
                    <div className='mb-2'><strong>Duração do prazo</strong></div>
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            {data?.duration_time ? `${data?.duration_time} dia(s)` : '-'}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`duration_time`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <AutoComplete 
                                    {...field}
                                    field='name' 
                                    suggestions={[
                                        {name:'1 dia', value:1},
                                        {name:'5 dias', value:5},
                                        {name:'10 dias', value:10},
                                        {name:'15 dias', value:15},
                                        {name:'30 dias', value:30}
                                    ]}  
                                    inputStyle={{width:'100%'}} 
                                    placeholder="Duração do prazo"
                                    className='' 
                                    style={{width:'100%'}} 
                                    onChange={(e) => {field.onChange(e.value)}}  
                                    value={field.value}
                                    min={1}
                                />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-4">
                    <div className='mb-2'><strong>Cumprido em</strong></div>
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            {data?.time_completed ? new Date(data.time_completed).toLocaleDateString() : '-'}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`time_completed`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Calendar showButtonBar {...field} locale='pt-BR' showIcon style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-12">
                    <div className='mb-2'><strong>Arquivos</strong></div>
                    <div className='mb-4'>
                        <div className="grid mt-2 mb-2">
                            <div class="col-3">
                                <FileUpload 
                                    mode="basic"
                                    chooseLabel="Selecionar arquivo"
                                    customUpload
                                    uploadHandler={(e)=> {
                                        if(e.files.length > 0){
                                            uploadData.current = null
                                            filesUploadList.current.clear();
                                        }else{
                                            uploadData.current = e.files[0];
                                        }
                                    }}
                                    onSelect={(e)=>{uploadData.current = e.files[0]; }}
                                    ref={filesUploadList} 
                                    name="files" 
                                    chooseOptions={{icon: 'pi pi-fw pi-file', iconOnly: true, style:{width:'100%'}}}
                                    accept="image/*,application/pdf,.txt,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                                />
                            </div>
                            <div className="col-4">
                                <InputText onChange={(e)=>{ setUploadName(e.target.value) }} value={uploadName} style={{width:'100%'}} placeholder='Nome do Arquivo' />
                            </div>
                            <div className="col-3">
                                <Calendar locale='pt-BR' dateFormat='dd/mm/yy' showIcon onChange={(e)=>{ setUploadDate(e.target.value) }} value={uploadDate} style={{width:'100%'}} placeholder='Data' />
                            </div>
                            <div className="col-2">
                                <Button onClick={uploadFiles} label='Enviar do arquivo' />
                            </div>
                        </div>
                    </div>

                    <DataTable 
                        style={{width:'100%'}}
                        size="small"
                        value={data?.files} 
                        emptyMessage="Não há arquivos"
                        editMode="row" 
                        dataKey="id" 
                        onRowEditComplete={(_file)=>{
                            let temp = {...data};

                            temp?.files?.map(el =>{
                                
                                if(~~el.id === ~~_file.newData.id){
                                    //console.log(_file.newData);
                                    el = _file.newData;
                                }
                                
                                return el;
                            })


                            //console.log(temp);

                            setData(temp);
                        }}
                        >
                        
                        <Column  field='name' header="Nome" editor={
                            (options) => {
                                return <InputText  
                                    inputStyle={{width:'100%'}} style={{width:'100%'}}  value={options.value} onInput={(e) => options.editorCallback(e.target.value)} />;
                            }
                        } />
                        <Column body={(row) => {
                            return row.user_name || '-'
                        }} field="user_name" header="Usuário" />
                        <Column body={(row)=>{
                            return row?.datetime ? `${new Date(row.datetime).toLocaleDateString()} ${new Date(row.datetime).toLocaleTimeString()}` : '-'
                        }} style={{width:'150px'}} field='datetime' header="Data" editor={
                            (options) => {
                                return <Calendar dateFormat='dd/mm/yy' locale='pt-BR' showIcon style={{width:'100%'}} onInput={(e) => {options.editorCallback(e.target.value)}} value={new Date(options.value)} />
                            }
                        } />
                        <Column style={{width:'150px'}} body={(row) => {
                            return row?.lastupdate ? `${new Date(row.lastupdate).toLocaleDateString()} ${new Date(row.lastupdate).toLocaleTimeString()}` : '-'
                        }} field="lastupdate" header="Última atualização" />
                        <Column rowEditor={active} headerStyle={{ width: `${ active ? '100px' : '10px'}`}} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column style={{width:'250px', textAlign:'right'}} body={row => {
                            return <>
                                {
                                    row.url ?
                                        <>
                                            <Button onClick={()=> openContent(row)} icon="pi pi-eye" className="p-button-sm p-button-text" />
                                            <Button onClick={()=> donwloadContent(row)} icon="pi pi-download" className="p-button-sm p-button-text mr-1 ml-1" />
                                        </>
                                    :
                                        <span style={{display:'inline-block'}}>
                                            <FileUpload 
                                                mode="basic"
                                                chooseLabel="Selecionar arquivo"
                                                customUpload
                                                onSelect={(e)=>{
                                                    updateUploadFile({
                                                        file:e.files[0],
                                                        data:row
                                                    })
                                                }}
                                                name="files" 
                                                chooseOptions={{icon: 'pi pi-fw pi-upload', iconOnly: false}}
                                                accept="image/*,application/pdf,.txt,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                                            />
                                        </span>
                                }
                                <Button onClick={()=> deleteFile(row)} icon="pi pi-times" className="p-button-sm p-button-text p-button-danger" />
                            </>
                        }} header="" />
                    </DataTable>
                </div>
                <div className="col-12 mt-3">
                    <div className='mb-2'><strong>Observações</strong></div>
                    <div className="grid mt-2 mb-2">
                        <div className="col-12">
                            <InputTextarea value={historyText} onInput={(e)=>{ setHistoryText(e.target.value);}} autoResize style={{width:'100%'}} placeholder='Adicione uma observação' />
                        </div>
                        <div className="col-12" style={{textAlign:'right'}}>
                            <Button onClick={addHistory} label='Enviar do observação' />
                        </div>
                    </div>
                    <Timeline value={data?.history} 
                    opposite={(item)=> item.timecreated ? `${new Date(item.timecreated).toLocaleDateString()} - ${new Date(item.timecreated).toLocaleTimeString()}` : '-'} 
                    content={(item) => {
                        return <div>
                            <div><strong>{item?.user_name}</strong></div>
                            <small>{item?.description}</small>
                        </div>
                    }} />
                </div>
            </div>
            </form>
            <Dialog maximizable header="Visualizador" visible={showContent}  footer={()=>{}} onHide={() => setShowContent(false)}>
                <LoadFile data={content} />
            </Dialog>
            <ConfirmDialog />
            <Toast ref={toast} position="bottom-right" />
        </div>
    )
}