import React, { useState, useMemo, Suspense, lazy, useEffect} from 'react';

import { ProgressBar } from 'primereact/progressbar';
import { TabMenu } from 'primereact/tabmenu';
 
import { ConfigurationActuationArea } from './area';
import { ConfigurationActuationQuestion } from './question';


export const ConfigurationPageActuation = () => {

    const menu = useMemo(()=> [
        {label:'Área', page:'area'},
        {label:'Questionário', page:'question'}
    ])

    const pages = {
        area:  <ConfigurationActuationArea />,
        question: <ConfigurationActuationQuestion />
    }

    const [tabIndex, setTabIndex] = useState(0);

    useEffect(()=> {

       // console.log(tabIndex);

    },[tabIndex])

    return (
        <div className="configuration-page-actuation">
            <div>
                <h3>Área de atuação</h3>
            </div>
            <div className='mb-4'>
                <TabMenu model={menu} activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}/>
            </div>
            <div>
                <Suspense fallback={()=> <ProgressBar style={{ height: '6px' }} mode="indeterminate" />}>
                    {
                        pages[menu[tabIndex].page]
                    }
                </Suspense>
            </div>
        </div>
    )
}