import React, { useState, useCallback, useEffect, useRef} from 'react';

import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { InputSwitch } from 'primereact/inputswitch';
import { Message } from 'primereact/message';
 
import { URL_USER_TYPE_ALL, URL_SYSTEM_LIST_PAGES, api, URL_SYSTEM_LIST_PAGES_BY_AREA, URL_SYSTEM_UPDATE_ACCESS_PAGES } from '@/services/api';

import '@/assets/styles/pages/configuration/controlleraccesspage/index.scss';

export const ConfigurationPageAccess = () => {

    const [listRoles, setListRoles] = useState();
    const [selectedRole, setSelectedRole] = useState(1);
    const [listPages, setListPages] = useState();
    const [isLoad, setIsLoad] = useState(true);

    const tabRole = useRef(1);

    const getRoles = useCallback(async () => {
        const data = await api.get(URL_USER_TYPE_ALL, null , true);
        setListRoles(data.data);
        if(data.data){
            setSelectedRole(data.data[0].id);
        }

    }, [setListRoles]);

    const getSystemPages = useCallback(async ()=> {

        const role = tabRole.current;

        setIsLoad(true);
        let data = await api.get(URL_SYSTEM_LIST_PAGES, null, true);
        
        if(role){
            const dataarea = await api.get(`${URL_SYSTEM_LIST_PAGES_BY_AREA}/${role}`, null, true);
            
            data.data = data.data?.map(item => {
                item.active = false;
                const exist = dataarea?.data?.filter(el => ~~el.system_pages_id === ~~item.id);
                console.log(role, item.id, exist);
                if(exist?.length > 0){
                    item.active = true;
                }

                return item;
            })

        }
        setListPages(data.data);
        setIsLoad(false);

    },[setListPages])

    const updatePageActive = useCallback(async(_data)=> {
        setIsLoad(true);

        await api.post(`${URL_SYSTEM_UPDATE_ACCESS_PAGES}/${_data.id}/${ tabRole.current}`, null, true);

        getSystemPages();
    }, [getSystemPages])

    useEffect(()=>{
        tabRole.current = selectedRole;
        getSystemPages();
    }, [getSystemPages, selectedRole, tabRole])

    useEffect(()=> {
        getRoles();
    }, [getRoles])

    return (
        <div className="configuration-page-access">
            
            <div>
                <h3>Controle de acessos</h3>
            </div>
            <div className="grid mt-4">
                <div className="col-2">
                    <ListBox optionLabel='name' optionValue='id' value={selectedRole} options={listRoles} onChange={(e) => setSelectedRole(e.value)}  />
                </div>
                <div className="col-10">
                    {
                        selectedRole ?
                            <DataTable value={listPages} size='small' loading={isLoad} stripedRows >
                                <Column style={{width:'250px'}} field='name' header="Nome" />
                                <Column field='description' header="Descrição" />
                                <Column style={{width:'40px'}} body={(row)=> {
                                   return <InputSwitch  checked={row.active} onChange={()=> updatePageActive(row)} />
                                }} header="" />
                            </DataTable>
                        :
                            <>
                                <Message style={{width:'100%', textAlign:'left'}} severity='warn' text='Selecione uma área'/>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}