import React, { useState, useEffect, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';

import { Dialog } from 'primereact/dialog';

import { useNavigate } from 'react-router-dom';
import { formatMoney } from '@/services/utils';

import { api, URL_FINANCE_BYUSER } from '@/services/api';

export const PageProfileUserFinance = (props) => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [detailsData, setDetailsData] = useState();

    const getData = useCallback(async () => {
        if(!props?.user)
            return;
    
        const data = await api.get(`${URL_FINANCE_BYUSER}/${props?.user}`, false, true);
        //console.log(data.data);
        setData(data?.data || []);

    }, [setData, props])

    const openContent = (_data) => {
        const html = <>
        <div className="grid">
            <div className="col-12">
                <h2>
                    {_data.title}
                </h2>
            </div>
            <div className="col-12">
                <p><strong>Descrição</strong></p>
                <div>
                    {_data.description}
                </div>
            </div>
            <div className="col-4">
                <strong>Criado</strong>: {_data?.created ? `${new Date(_data?.created).toLocaleDateString()} - ${new Date(_data?.created).toLocaleTimeString()}` : '-'}
            </div>
            <div className="col-4">
                <strong>Vencimento</strong>: {_data?.date ? `${new Date(`${_data.date} 00:00:00`).toLocaleDateString()}` : '-'}
            </div>
            <div className="col-4">
                <strong>Última atualização</strong>: {_data?.timemodified ? `${new Date(_data?.timemodified).toLocaleDateString()} - ${new Date(_data?.timemodified).toLocaleTimeString()}` : '-'}
            </div>
            <div className="col-4">
                <strong>Fluxo</strong>: {(/in/gi).test(_data?.direction) ? 'Entrada' : 'Saída'}
            </div>
            <div className="col-4">
                <strong>C/D</strong>: {formatMoney(_data?.paid_list?.reduce((a, n) => {return parseFloat(a) + parseFloat(n.paid);}, 0))}
            </div>
            <div className="col-4">
                <strong>Total</strong>: {formatMoney(_data?.value)}
            </div>
            <div className="col-12">
                <p><strong>Detalhes fluxo</strong></p>
                <div>
                    {_data?.paid_list?.map(item => {
                        return 
                    })}
                    <DataTable     
                    value={_data?.paid_list} 
                    responsiveLayout="scroll"
                    emptyMessage="Não há lançamentos"
                    size='small'
                    stripedRows
                >
                    <Column body={(row)=> {
                        return row?.timepaid ? `${new Date(row?.timepaid).toLocaleDateString()} - ${new Date(row?.timepaid).toLocaleTimeString()}` : '-';
                    } } header="Data" />
                    <Column body={(row)=> {
                        return formatMoney(row?.paid);
                    } } header="C/D" />
                    <Column body={(row)=> {
                        return row.description;
                    } } header="Comentário" />
                </DataTable>
                </div>
            </div>
        </div>
        </>

        setDetailsData(html);

        setShowDetail(true);
    }

    useEffect(()=>{
        getData();
    }, [getData])

    if(!data)
        return (
            <div className="page-profile-user-finance">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-profile-user-address">
            <DataTable     
                    value={data} 
                    responsiveLayout="scroll"
                    emptyMessage="Não há finanças cadastrados"
                    size='small'
                    stripedRows
                    rows={10}
                    paginator={data?.length > 10}
                >
                    {/* <Column body={(row)=> {
                        return <div>{row?.type_name}</div>;
                    } } header="Área" /> */}
                    <Column body={(row)=> {
                        return <div>{row?.title}</div>;
                    } } header="Título" />
                    <Column body={(row)=> {
                        const sum = row?.paid_list?.reduce((a, n) => {return parseFloat(a) + parseFloat(n.paid);}, 0);
                        return formatMoney(sum);
                    } } header="C/D" />
                    <Column body={(row)=> {
                        return formatMoney(row?.value);
                    } } header="Total" />
                    <Column body={(row)=> {
                        return row?.date ? `${new Date(`${row.date} 00:00:00`).toLocaleDateString()}` : '-';
                    } } header="Vencimento" />
                    <Column body={(row)=> {
                        return row?.timemodified ? `${new Date(row?.timemodified).toLocaleDateString()} - ${new Date(row?.timemodified).toLocaleTimeString()}` : '-';
                    } } header="última atualização" />
                    
                    <Column style={{width:'40px'}} body={(row)=> {
                        return <Button onClick={()=> openContent(row) } className='p-button-text p-button-info' icon='pi pi-book' />;
                    } } header="" />
                </DataTable>
                <Dialog header="Detalhes" visible={showDetail} style={{ maxWidth: '90%', minWidth: '300px' }} footer={()=>{}} onHide={() =>{ setShowDetail(false); } } >
                    {detailsData}
                </Dialog>
        </div>
    )
}