import React, {useState, useCallback, useEffect} from "react";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';

import { api, URL_ATTENDANCE_BYCASE } from "@/services/api";
import { calculateDurationProcessCases } from "@/services/utils";

export const PageUserProcessCasesAttendance = (props) => {

    const [content, setContent] = useState();
    const [showDialogFeedback, setShowDialogFeedback] = useState(false);
    const [contentFeedback, setContentFeedback] = useState();
    const [showDialogDescription, setShowDialogDescription] = useState(false);
    const [contentDescription, setContentDescription] = useState();


    const openFeedback = (_data) => {
        setContentFeedback(_data);
        setShowDialogFeedback(true);
    }

    const openDescription = (_data) => {
        setContentDescription(_data);
        setShowDialogDescription(true);
    }

    const getData = useCallback(async ()=> {
        const data = await api.get(`${URL_ATTENDANCE_BYCASE}/${props?.data?.id}`, null, true);
        setContent(data.data || []);
        //console.log(data.data);
    }, [props, setContent]);

    useEffect(()=>{
        getData();
    }, [getData])

    if(!content)
        return (
            <div className="page-user-processcases-attendance">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-user-processcases-attendance">
            <DataTable 
                value={content} 
                responsiveLayout="scroll"
                emptyMessage="Não há atendimentos cadastrados"
                size='small'
                stripedRows
            >
                <Column style={{width:'120px'}} body={(row)=> {
                    return row?.start ? new Date(row?.start).toLocaleDateString() : '-';
                } } header="Data" />
                <Column style={{width:'120px'}} body={(row)=> {
                    return row?.start ? new Date(row?.start).toLocaleTimeString() : '-';
                } } header="Início" />
                <Column style={{width:'120px'}} body={(row)=> {
                    return row?.end ? new Date(row?.end).toLocaleTimeString() : '-';
                } } header="Fim" />
                <Column body={(row)=> {
                    return calculateDurationProcessCases(row?.start, row?.end).time;
                } } header="Duração" />
                <Column body={(row)=> {
                    return row?.type;
                } } header="Tipo" />
                <Column body={(row)=> {
                    return row?.location;
                } } header="Local" />
                <Column body={(row)=> {
                    return row?.attendance_user?.name;
                } } header="Responsável" />
                <Column style={{width:'120px'}} body={(row)=> {
                    return <>
                        <Button onClick={()=>openDescription(row)} icon="pi pi-book" className="p-button-primary p-button-text" />
                        <Button onClick={()=>openFeedback(row)} icon="pi pi-star-fill" className="p-button-warning p-button-text mr-2" />
                    </>;
                } } header="" />
            </DataTable>
            <Dialog header="Feedback" visible={showDialogFeedback} style={{ width: '90vw' }} footer={null} onHide={() => setShowDialogFeedback(false)}>
                {contentFeedback?.feedback ? 'Resultado' : 'Solicitar'}
            </Dialog>
            <Dialog header="Descrição" visible={showDialogDescription} style={{ width: '90vw' }} footer={null} onHide={() => setShowDialogDescription(false)}>
                {contentDescription?.description}
            </Dialog>
        </div>
    )
}