import React, {useState, useEffect, useCallback, useMemo} from 'react';

import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
 

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { useNavigate } from 'react-router-dom';

import { PageFinanceCase } from './case';
import { PageFinanceFlow } from './flow';

export const PageFinance = () => {

    const navigate = useNavigate();

    const tabmodel = useMemo(()=> [
            {label: 'Fluxo', content: <PageFinanceFlow />, params: null}
        ], [])

    const [activeIndex, setActiveIndex] = useState(0);
    const [contentData, setContentData] = useState();
    const [pageRender, setPageRender] = useState();

    const getContentData = useCallback(async (_data)=>{
        setContentData(tabmodel[_data].content);
        setPageRender(new Date().getTime());
    }, [setContentData, tabmodel, setPageRender])
    
    useEffect(()=>{
        setContentData(null);
        getContentData(activeIndex);
    },[activeIndex, getContentData])

    return (
        <div className="page-finance">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Financeiro'}
                    ]}
                />
            </div>
            <div className="header mt-4 mb-2">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><></>} 
                    right={()=><>
                        <Button onClick={()=>{getContentData(activeIndex)}} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>{navigate('/user/finance/create')}} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} 
                />
            </div>
            <div className="menu">
                <TabMenu 
                    activeIndex={activeIndex}
                    onTabChange={(e)=>setActiveIndex(e.index)}
                    model={tabmodel} />
            </div>
            <div key={`render-${pageRender}`} className="grid mt-4">
                <div className="col-12">
                    {
                        contentData
                    }
                </div>
            </div>
        </div>
    )
}