import React, { useState, useCallback, useRef, useEffect} from "react";

import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';

import { Dialog } from 'primereact/dialog';
 
 

import { useForm, Controller } from 'react-hook-form';
import { Button } from "primereact/button";
import { api, URL_FILES, URL_FILES_UPLOAD, URL_FILES_FILE, URL_FILES_DELETE } from "@/services/api";
import { useAPP } from "@/services/provider/app";
import { LoadFile } from "@/components/ui/loadfile";

export const PageProfileUserDocument = (props) => {

    const { user } = useAPP();

    

    const [data, setData] = useState();
    const [showContent, setShowContent] = useState(false);
    const [ content, setContent] = useState();
    const [namesData, setNamesData] = useState({});

    const form = useForm({});
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const toast = useRef(null);
    const upfile = useRef(null);

    const listNames = useRef({})

    const onSubmit = useCallback(async (_data) => {
        

        console.dir(namesData);

        const requestList = [];

        console.log('@ props', props);

        Object.keys(namesData).map(item => {
            const sendData = new FormData();
            sendData.append('file', namesData[item].document);
            sendData.append('name', namesData[item].name);
            sendData.append('area_location', 'profile');
            sendData.append('user_id', user.id);
            sendData.append('instance_id', props?.user);
            const data = api.upload(URL_FILES_UPLOAD, sendData, true);
            requestList.push(data);
            return item;
        })

        const result = await Promise.all(requestList);

        if(result){
            upfile.current.clear();
            listNames.current = {};
            setNamesData({});
            reset({});
            getData();
        }else{
            toast.current.show({severity:'error', summary:`Revise`, detail:'Dados inválidos', life: 3000});
        }
        
        // const sendData = new FormData();
        // Object.keys(_data).map(item => {
        //     sendData.append(item, _data[item]);
        //     return item;
        // })

        // sendData.append('area_location', 'case');
        // sendData.append('user_id', user.id);
        // sendData.append('instance_id', props?.data?.id);
        
        // console.log('#', _data);

        
        // const data = await api.upload(URL_FILES_UPLOAD, sendData, true);
        

    }, [namesData])

    const onSubmitErros = useCallback((_data)=>{

        console.log('Erros', _data);

        const erros = Object.keys(_data)?.map(item => {
            return ({severity:'error', summary:`Campo obrigatório`, detail:_data[item].message, life: 3000})
        })

        toast.current.show(erros);
    }, [])


    const openContent = (_data) => {
        setContent(_data);
        setShowContent(true);
    }


    const donwloadContent = useCallback( async (_data) => {
        const url_base = `${URL_FILES_FILE}/?file=${_data.url}`;

        const contentData = await fetch(url_base);
        const content = await contentData.blob();

        const href = URL.createObjectURL(content);

        const forcedownload = document.createElement('a');
        forcedownload.setAttribute('download', `${_data.name}.${_data.ext}`);
        forcedownload.setAttribute('href', href);
        forcedownload.click();
        URL.revokeObjectURL(href);

    }, []);

    const getData = useCallback(async () => {
        const _data = await api.get(URL_FILES, {instance_id: props?.user, area_location:'profile'}, true);
        setData(_data.data)
    }, [])

    const deleteFile = useCallback(async (_data) => {
        const acceptFunc = async () =>{
            const result = await api.delete(`${URL_FILES_DELETE}`, {file: _data.url}, true);
            getData();
            toast.current.show({severity:'success', summary:`Sucesso`, detail:'Dados atualizado com sucesso', life: 3000});
        }


        confirmDialog({
            message: `Deseja apagar definitivamente o arquivo ${_data.name}?` ,
            header: 'Confirmar',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Sim",
            acceptClassName:'p-button-danger',
            rejectLabel:"Não",
            accept: () => acceptFunc(),
            reject: () =>{}
        });
    }, [])

    const onChangeName = (_data) => {
        console.log(_data);
        const list = upfile.current;
        list.current.props.value.newname = _data.name;
        console.log(list);
    }

    useEffect(()=>{
       console.log(namesData);
    }, [namesData])

    useEffect(()=>{
        getData();
    }, [getData])


    if(!data)
        return (<div className="page-profile-user-document"><ProgressBar style={{ height: '6px' }} mode="indeterminate" /></div>)


    return (
        <div className="page-profile-user-document">
            <div className="grid">
                <div className="col-8">
                    <div className="grid">
                        <div className="col-12">
                            <h3>Lista de documentos</h3>
                            <DataTable 
                                    style={{width:'100%'}}
                                    size="small"
                                    value={data} 
                                    emptyMessage="Não há arquivos"
                                    editMode="row" 
                                    dataKey="id" 
                                    stripedRows
                            >
                                <Column  style={{width:'160px'}} body={(row)=>{
                                    return <>{row?.timecreated ? `${new Date(row?.timecreated).toLocaleDateString()} - ${new Date(row?.timecreated).toLocaleTimeString()}` : '-'}</>
                                }} field='timecreated' header="Criado" />
                                <Column  style={{width:'160px'}} body={(row)=>{
                                    return <>{row?.lastupdate ? `${new Date(row?.lastupdate).toLocaleDateString()} - ${new Date(row?.lastupdate).toLocaleTimeString()}` : '-'}</>
                                }} field='lastupdate' header="Atualizado" />
                                <Column field='name' header="Nome" />
                                <Column style={{width:'180px'}} body={row => {
                                    return <>
                                        <Button onClick={()=> openContent(row)} icon="pi pi-eye" className="p-button-sm p-button-text" />
                                        <Button onClick={()=> donwloadContent(row)} icon="pi pi-download" className="p-button-sm p-button-text mr-1 ml-1" />
                                        <Button onClick={()=> deleteFile(row)} icon="pi pi-times" className="p-button-sm p-button-text p-button-danger" />
                                    </>
                                }} header="" />
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <form onSubmit={handleSubmit(onSubmit, onSubmitErros)} >
                        <div className="grid">
                            <div className="col-12">
                            <h3>Adicionar documento</h3>
                            <Controller name={`documents[]`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                    <FileUpload 
                                        {...field}
                                        ref={upfile}
                                        style={{width:'100%'}} 
                                        onSelect={(e)=>{
                                            // console.log(e.files[0]);
                                            // console.log(field.value);
                                            // //field.value = e.files[0];

                                            const temp = {...namesData};

                                            Object.keys(e.files)?.map((id) => {
                                                console.log(id);
                                                e.files[id].idref = `${new Date().getTime()}_${Math.round(Math.random() * 10000)}`; 
                                                temp[e.files[id].idref] = {name: e.files[id].name.substr(0, e.files[id].name.lastIndexOf('.')), document: e.files[id]};
                                            })

                                             setNamesData(temp); 
                                            // const filesList = [];
                                            // if(field.value)
                                            //     filesList = [...field.value];
                                            // else
                                            //     filesList.push(e.files[0])
                                            // field.onChange(filesList); 
                                            field.onChange(e.files); 
                                        }}
                                        onClear={()=>{field.value = null; field.onChange( null)}}
                                        onRemove={()=>{field.value = null; field.onChange( null)}}
                                        onChange={()=> field.onChange( field.value)}
                                        name={field.name}  
                                        value={field.value}
                                        uploadOptions={{icon: 'pi pi-fw pi-file', iconOnly: true, style:{display:'none'}}}
                                        chooseOptions={{label:'Selecione o arquivo', iconOnly: true}}
                                        cancelOptions={{label:'Cancelar', iconOnly: true, className:"p-button-danger"}}
                                        customUpload 
                                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                        multiple
                                        emptyTemplate={()=> {
                                            return <div className="flex align-items-center flex-column">
                                                        <i className="pi pi-image" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                                                        <span style={{'fontSize': '1em', color: 'var(--text-color-secondary)'}} className="my-5">Arraste e solte seu arquivo aqui</span>
                                                    </div>
                                        }}
                                        itemTemplate={(item, _props) => {

                                            //console.log(item, listNames);
                                            
                                            return <div className="grid">
                                                <div className="col-10">
                                                    <InputText 
                                                        onChange={(e)=> {
                                                            const temp = {...namesData};
                                                            temp[item.idref].name = e.target.value;
                                                            setNamesData(temp);
                                                        }} 
                                                        style={{width:'100%'}} 
                                                        value={namesData[item.idref]?.name} />
                                                </div>
                                                <div className="col-2">
                                                    <Button onClick={(e) => { 
                                                        e.preventDefault();
                                                        console.log(item);
                                                        const temp = {...namesData };
                                                        if(temp[item.idref]){
                                                            delete temp[item.idref];
                                                        }
                                                        _props.onRemove();
                                                        
                                                        setNamesData(temp);

                                                    }} icon="pi pi-times" />
                                                </div>
                                            </div>
                                        }}
                                        
                                    />
                                )} />
                            </div>
                            <div className="col-7">
                            {/* <Controller name={`name`} defaultValue="" rules={{ required: 'Informe o nome do arquivo' }} control={control}  render={({ field, fieldState }) => (
                                <InputText 
                                    placeholder="Nome do arquivo"
                                    style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}
                                />
                            )} /> */}
                            </div>
                            <div className="col-5">
                                <Button style={{width:'100%'}} label="Cadastrar" className='p-button-sm' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Dialog maximizable header="Visualizador" style={{maxWidth: '90vw', width:'auto'}} visible={showContent}  footer={()=>{}} onHide={() => setShowContent(false)}>
                <LoadFile data={content} />
            </Dialog>
            <ConfirmDialog />
            <Toast ref={toast} position="bottom-right" />
        </div>
    )
}