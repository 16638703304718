import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';

import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AutoComplete } from 'primereact/autocomplete';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { useForm, Controller } from 'react-hook-form';

import { useNavigate} from 'react-router-dom';
import { api, URL_PROCESSCASES_PROCESS_LIST, URL_EXPERTISEDEPLOYMENT_STATUS, URL_EXPERTISEDEPLOYMENT_TYPE, URL_EXPERTISEDEPLOYMENT_CREATE, URL_COLLABORATORS, URL_EXPERTISEDEPLOYMENT_EXECUTION } from '@/services/api';
import { InputText } from 'primereact/inputtext';

export const PageUserExpertiseDeploymentCreate = () => {

    const navigate = useNavigate();

    const [ client, setClient] = useState();
    const [ type, setType] = useState();
    const [ execution, setExecution] = useState();
    const [ status, setStatus] = useState();
    const [ collaborators, setCollaborators] = useState();
    const [process, setProcess] = useState();
    const [currentProcess, setCurrentProcess] = useState();

    let createDate = useRef(new Date());
    createDate.current = createDate.current ? createDate.current : new Date()

    const defaultValues = useMemo(()=>({}),[])

    const form = useForm(defaultValues);
    const control = form.control;
    const handleSubmit = form.handleSubmit;

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);


    const getDataClient = useCallback(async (_data)=>{

        let data = process?.filter(item => ~~item?.id === ~~_data);

        if(data?.length > 0){
            data =  data[0].client;
        }
        //const result = await api.get(`${URL_USER_CLIENT}/${data?.id}`, null, true);
        ////console.log(result.data);
        setClient(data);
    }, [process]);

    const getStatus  = useCallback(async()=>{
        const result = await api.get(`${URL_EXPERTISEDEPLOYMENT_STATUS}`, null, false);
        setStatus(result?.data);
    }, [])

    const getExecution  = useCallback(async()=>{
        const result = await api.get(`${URL_EXPERTISEDEPLOYMENT_EXECUTION}`, null, false);
        setExecution(result?.data);
   }, [])

   const getType  = useCallback(async()=>{
       const result = await api.get(`${URL_EXPERTISEDEPLOYMENT_TYPE}`, null, false);
       
       setType(result?.data);
   }, [])

    const getCollaborators  = useCallback(async()=>{
        const result = await api.get(`${URL_COLLABORATORS}`, null, true);
        setCollaborators(result?.data);
    }, [])

    const getProcess  = useCallback(async()=>{
        const result = await api.get(`${URL_PROCESSCASES_PROCESS_LIST}`, null, true);
        //console.log(result.data);
        setProcess(result?.data);
    }, [])


    const onSubmit = useCallback(async (_data) => {
        
        const result = await api.post(URL_EXPERTISEDEPLOYMENT_CREATE, _data, true);

        navigate(`/user/expertisedeployment/view/${result?.data}`);

    }, [navigate]);

    useEffect(()=>{
        getDataClient(currentProcess);
    }, [currentProcess, getDataClient])

    useEffect(()=>{
        const init = async () => {
            await getStatus();
            await getExecution();
            await getType();
            await getCollaborators();
            await getProcess();
        }

        init();

    },[getStatus, getExecution, getType, getCollaborators, getProcess]);



    return (
        <div className="page-user-deadline-create">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Perícia e implantação', url:'/user/expertisedeployment' },
                        {label:'Criar perícia e implantação' }
                    ]}
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid mt-4">
                    <div className="col-12">
                        <Controller name={`process_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Dropdown placeholder='Processo' filter {...field} options={process} optionLabel="number" optionValue="id" style={{width:'100%'}} onChange={(e) => {setCurrentProcess(e.target.value); field.onChange(e.target.value); }}  id={field.name}  value={field.value} />
                        )} /> 
                    </div>
                    <div className="col-12">
                        {client?.name || '-'}
                    </div>
                    <div className="col-4">
                        <Controller name={`type_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Dropdown placeholder='Tipo' filter {...field} options={type} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                    </div>
                    <div className="col-4">
                        <Controller name={`execution_type_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Dropdown placeholder='Execução' {...field} options={execution} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                    </div>
                    <div className="col-4">
                        <Controller name={`status_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Dropdown placeholder='Status' filter {...field} options={status} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                    </div>
                    
                    <div className="col-4">
                        <Controller name={`priority`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Dropdown placeholder='Prioridade' {...field} options={[
                                {name:'Sim', id: 1},
                                {name: 'Não', id: 0}
                            ]} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                    </div>

                    <div className="col-4">
                        <Controller name={`timefuture`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <Calendar required locale='pt-BR' dateFormat='dd/mm/yy' showIcon inputStyle={{width:'100%'}} placeholder='Agendamento / Estipulado' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                        
                    </div>
                    
                    <div className="col-4" style={{alignItems:'center', display:'flex'}}>
                        <strong>Criado em</strong>: <span className='ml-2'> {`${createDate.current.toLocaleDateString()}`}</span>
                    </div>
                </div>
                <div className="grid mt-4">
                    <div className="col-12" style={{textAlign:'right'}}>
                        <Button onClick={()=>navigate('/user/expertisedeployment')} className='p-button-danger p-button-text mr-2' label="Cancelar"/>
                        <Button className='p-button-warning' label="Criar"/>
                    </div>
                </div>
            </form>
        </div>
    )
}