import React, { useState, useEffect, useMemo, useCallback, useRef} from 'react';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { UIFullcalendar } from '@/components/ui/fullcalendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useForm, Controller } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';


import { api, URL_COLLABORATORS, URL_CALENDAR_CREATE} from '@/services/api';
import { useAPP } from '@/services/provider/app';

export const PageCollaboratorCalendarAusence = () =>{

    const {user } = useAPP();

    const [collaboratorsList, setCollaboratorsList] = useState();

    const defaultData = useMemo(()=> ({
        datestart: new Date(),
        dateend: new Date(),
        timestart: new Date(`${new Date().toISOString().substr(0, 10)} 08:00`),
        timeend:new Date(`${new Date().toISOString().substr(0, 10)} 18:00`)
    }), [])

    const form = useForm(defaultData);
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const toast = useRef(null);

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);


    const onSubmit = useCallback(async (data) => {

        const current_temp = new Date(`${data.datestart.toISOString().substr(0, 10)} 08:00:00:0`);
        const end_temp = new Date(`${data.dateend.toISOString().substr(0, 10)} 08:00:00:0`);

        const current = current_temp.getTime() < end_temp.getTime() ? current_temp : end_temp;
        const end = current_temp.getTime() > end_temp.getTime() ? current_temp : end_temp;

        const listSend = [];

        do{

            const sendData = {
                create_by: user.id,
                user_id: data.user_id,
                block: 1,
                date: current.toISOString().substr(0, 10),
                time: data.useday ? '08:00' : data.timestart,
                end: data.useday ? '18:00' : data.timeend,
                note: data.description
            }

            listSend.push(api.post(URL_CALENDAR_CREATE, sendData, true));
            current.setDate(current.getDate() + 1);
        }while(current.getTime() <= end.getTime())

        await Promise.all(listSend);
        reset(defaultData);
        toast.current.show({severity:'success', summary:`Confirmação`, detail:'Dados salvos com sucesso.', life: 3000});
        
    },[]);

    const onSubmitErros = useCallback((_data)=>{
        
        const erros = Object.keys(_data)?.map(item => {
            return ({severity:'error', summary:`Campo obrigatório`, detail:_data[item].message, life: 3000})
        })

        toast.current.show(erros);
    }, [])

    const getCollaborators = useCallback(async () => {
        const data = await api.get(URL_COLLABORATORS, null, true);
        setCollaboratorsList(data.data);
        
    }, [])


    useEffect(()=>{
        reset(defaultData);
    }, [reset, defaultData])

    useEffect(()=>{
        getCollaborators();
    }, [getCollaborators])

    return (
        <div className="page-collaborator-ausence">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Agenda', url:'/user/calendar'},
                        {label:'Agendar ausência'}
                    ]}
                />
                <h3>
                    Agendamento online - ausência
                </h3>
            </div>
            <form onSubmit={handleSubmit(onSubmit, onSubmitErros)}>
                <div className="grid">
                    <div className="col-3">
                        <div><small>Data inícial</small></div>
                        <Controller name={`datestart`}  rules={{ required: 'Data inícial' }} control={control}  render={({ field, fieldState }) => (
                            <Calendar {...field} minDate={new Date()} inputStyle={{width:'100%'}} style={{width:'100%'}} placeholder='Início' dateFormat="dd/mm/yy" showIcon locale='pt-BR' onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} value={field.value} />
                        )} />
                    </div>
                    <div className="col-3">
                        <div><small>Data Final</small></div>
                        <Controller name={`dateend`}  rules={{ required: 'Data final' }} control={control}  render={({ field, fieldState }) => (
                            <Calendar {...field} minDate={new Date()} inputStyle={{width:'100%'}} style={{width:'100%'}} placeholder='Fim' dateFormat="dd/mm/yy" showIcon locale='pt-BR' onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} value={field.value} />
                        )} />
                    </div>
                    <div className="col-2">
                        <div><small>Horário inícial</small></div>
                        <Controller name={`timestart`}  rules={{ required: 'Horario inícial' }} control={control}  render={({ field, fieldState }) => (
                            <Calendar {...field} timeOnly inputStyle={{width:'100%'}} style={{width:'100%'}} placeholder='Início' dateFormat="dd/mm/yy" showIcon locale='pt-BR' onInput={(e) => {field.onChange(new Date(`${new Date().toISOString().substr(0, 10)} ${e.target.value}`));}}  id={field.name} value={field.value} />
                        )} />
                    </div>
                    <div className="col-2">
                        <div><small>Horário Final</small></div>
                        <Controller name={`timeend`}  rules={{ required: 'Horário final' }} control={control}  render={({ field, fieldState }) => (
                            <Calendar {...field} timeOnly inputStyle={{width:'100%'}} style={{width:'100%'}} placeholder='Fim' dateFormat="dd/mm/yy" showIcon  locale='pt-BR' onInput={(e) => {field.onChange(new Date(`${new Date().toISOString().substr(0, 10)} ${e.target.value}`));}}  id={field.name} value={field.value} />
                        )} />
                    </div>
                    <div className="col-2">
                        <div><small>Preencher o dia?</small></div>
                        <Controller name={`useday`} control={control} defaultValue={false}  render={({ field, fieldState }) => (
                            <Dropdown {...field} placeholder='Preencher o dia'  
                            options={[
                                {label: 'Sim', value: true},
                                {label: 'Não', value: false}
                            ]} style={{width:'100%'}} onChange={(e) => {field.onChange(e.target.value); }}  id={field.name}  value={field.value}  />
                        )} />
                    </div>
                    <div className="col-12">
                    <div><small>Colaborador</small></div>
                        <Controller name={`user_id`} defaultValue="" rules={{ required: 'Colaborador' }} control={control}  render={({ field, fieldState }) => (
                            <Dropdown {...field} placeholder='Colaborador' filter  options={collaboratorsList} optionLabel="name" optionValue="id" style={{width:'100%'}} onChange={(e) => {field.onChange(e.target.value); }}  id={field.name}  value={field.value}  />
                        )} /> 
                    </div>
                    <div className="col-12">
                        <Controller name={`description`} defaultValue="" rules={{ required: 'Motivo' }} control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}}>
                                <InputTextarea autoResize inputStyle={{width:'100%'}} placeholder='Motivo' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-12 mt-3" style={{textAlign:'right'}} >
                        <Button className='p-button-sm' label='Marcar' />
                    </div>
                </div>
            </form>
            <Toast ref={toast} position="bottom-right" />
        </div>
    )
}