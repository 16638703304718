import React, {useState, createContext, useContext} from 'react';


export const  AppContext = createContext();

export const AppProvider = (props) => {

    
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showMenu, setShowMenu] = useState(false);

    return (
        <AppContext.Provider value={{
            user,
            setUser,
            isLoading,
            setIsLoading,
            showMenu, 
            setShowMenu
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export const useAPP = () => {
    const context = useContext(AppContext);
    const {user, setUser, isLoading, setIsLoading, showMenu, setShowMenu} = context;
    return {
        user,
        setUser,
        isLoading,
        setIsLoading,
        showMenu,
        setShowMenu
    }
}