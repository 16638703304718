import React, { useState, useEffect, useMemo, useCallback, useRef} from 'react';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
 
import { UIFullcalendar } from '@/components/ui/fullcalendar';

import { InputTextarea } from 'primereact/inputtextarea';

import { ConfirmDialog } from 'primereact/confirmdialog';
// import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { useForm, Controller } from 'react-hook-form';

import { api, URL_SYSTEM_CONFIG, URL_USER_CLIENT, URL_USER_JURIDIC, URL_CALENDAR_ALL, URL_CALENDAR_AVAILABLE_TIMES } from '@/services/api';
import { useNavigate } from 'react-router-dom';

export const PageUserCalendar = () => {


    const navigate = useNavigate();

    const [config, setConfig] = useState();

    const [currentDate, setCurrentDate] = useState();
    const [showEventAdd, setShowEventAdd] = useState(false);
    const [clientsList, setClientsList] = useState();
    const [juridicsList, setJuridicsList] = useState();
    const [events, setEvents] = useState();
    const [availableTimes, setAvailableTimes] = useState();
    const [selectTimes, setSelectTimes] = useState();
    const [selectRooms, setSelectRooms] = useState();

    const selectedsJuricts = useRef();
    const selectedsTime = useRef();

    const defaultEvent = useMemo(()=>(
        {
            client_id:null,
            user_id:null,
            note:''
        }
    ),[]);

    const form = useForm(defaultEvent);
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    //const calendar_ref = useRef();

    const updateDate = (_date) => {
        setCurrentDate(_date);
    }

    const generetaAvailableTimesSelect = () =>{
        
        if(!selectedsJuricts.current){
            setSelectTimes(null);
            setSelectRooms(null);
            selectedsTime.current = null;
        }else{

            let _temp = availableTimes?.reduce((_c, item) =>{ 
                const obj = [..._c];

                if(item[Object.keys(item)[0]].length === 0)
                    return _c;

                if(selectedsJuricts.current){
                    const total = item[Object.keys(item)[0]]?.reduce((_total, jur) => {
                        return (_total + (~~jur.id === ~~selectedsJuricts.current ? 1 : 0)); 
                    }, 0)

                    if(total === 0)
                        return _c;

                }

                obj.push({
                    id: Object.keys(item)[0],
                    name: Object.keys(item)[0]
                })
                return obj;
            }, [] )
            
            setSelectTimes(_temp);
        }

    }

    const generetaAvailableRoomsSelect = () =>{
        
        let date = availableTimes.filter(item => {
            return item[selectedsTime.current];
        })

        if(date[0]){
            date = date[0][selectedsTime.current];
        }

        let locals = date?.filter(item => {
            return ~~item?.id === ~~selectedsJuricts.current
        })

        if(locals[0]){
            locals = locals[0]?.local;
        }

        setSelectRooms(locals);
    }
    
    const getConfig = useCallback(async () =>{
        const _data = await api.get(URL_SYSTEM_CONFIG, null, false);
        setConfig(_data?.data);
    }, []);
    
    const getEvents = useCallback(async () =>{
        let startday = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        let lastday = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        const data = await api.get(`${URL_CALENDAR_ALL}/${startday.getFullYear()}-${startday.getMonth()}-${startday.getDate()}/${lastday.getFullYear()}-${lastday.getMonth() + 1}-${lastday.getDate()}`, null, true);
        //console.log('@@', data.data);
        setEvents(data.data);
        
    }, [setEvents, currentDate]);
    
    const getAvailableTimes = useCallback(async()=>{
        const _date = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
        const _data = await api.get(`${URL_CALENDAR_AVAILABLE_TIMES}/${_date}`, null, true);
        
        setAvailableTimes(_data?.data);
    }, [currentDate]);

    const getClients = useCallback(async () =>{
        const _data = await api.get(URL_USER_CLIENT, null, true);

        setClientsList(_data?.data);
    }, []);

    const getJuridics = useCallback(async () =>{
        const _data = await api.get(URL_USER_JURIDIC, null, true);
        setJuridicsList(_data?.data);
    }, []);

    const onSubmit = useCallback(async (_event) => {

        const eventdata = {
            date: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`,
            ..._event
        }

        ////console.log(eventdata);

        //wait api.post(URL_CALENDAR_CREATE, eventdata, true);

        reset(defaultEvent);

        // setShowEventAdd(false);
        
        // _data.forceUpdate();

    },[defaultEvent, currentDate, reset]);

    const iCanCreate = () => {
        let bl = true;
        const _now = new Date();
        const _temp =  new Date(currentDate);
        _temp.setHours(23);
        _temp.setMinutes(59);
        _temp.setSeconds(59);
        _temp.setMilliseconds(999);

        bl = _now < _temp;

        return bl;
    }

    useEffect(()=>{
        getConfig();
    }, [getConfig])

    useEffect(()=>{
        getClients();
        getJuridics();
    }, [getClients, getJuridics])

    useEffect(()=>{
        
    }, [config]);

    useEffect(()=>{
        if(currentDate){
            getEvents();
            getAvailableTimes();
            ////console.log(currentDate);
        }
    }, [currentDate, getEvents, getAvailableTimes])

    return (
        <div className="page-user-calendar">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Agenda'}
                    ]}
                />
                <h3>
                    Agendamento online
                </h3>
            </div>
            <div className="header">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: 0}}
                    left={()=><>
                    </>} 
                    right={()=><>
                        {/* <Button tooltip='Marcar horário' tooltipOptions={{position:'bottom'}} onClick={()=>{setShowEventAdd(true); generetaAvailableTimesSelect()}} icon="pi pi-calendar" className="p-button-success mr-2 p-button-text" /> */}
                        <Button tooltip='Sincronizar' tooltipOptions={{position:'bottom'}} onClick={()=>{getConfig(); getEvents(); getAvailableTimes();}} icon="pi pi-sync" className="p-button-warning p-button-text" />
                        <Button tooltip='Marcar ausência colaborador' tooltipOptions={{position:'bottom'}} onClick={()=>{navigate('/user/calendar/ausence')}} icon="pi pi-user-plus" className="p-button-success p-button-text mr-2 ml-2" />
                        <Button tooltip='Marcar horário' tooltipOptions={{position:'bottom'}} onClick={()=>{navigate('/user/calendar/create')}} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} />
            </div>
            <UIFullcalendar 
                events={events}
                week_available={config?.day_times_available?.map(item => item[Object.keys(item)[0]])}
                step={10}
                timeBlock={availableTimes?.reduce((_c, item) =>{ 
                    const obj = {..._c};
                    obj[Object.keys(item)[0]] = item[Object.keys(item)[0]];
                    return obj;
                }, {})}
                updateCurrentDate = {updateDate}
                editable = {false}
                create = {false}
                remove = {true}
                show = {true}
            />

            <Dialog header={`Marcar horário: ${new Date(currentDate).toLocaleDateString()}`}
                visible={showEventAdd} 
                style={{ width: '98%', maxWidth:'600px' }} 
                onHide={() =>{setShowEventAdd(false); reset(defaultEvent); selectedsJuricts.current = null; }}>
                {
                    iCanCreate() ?
                
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid">
                        <div className="col-12">
                            <Controller name={`client_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={
                                    {
                                        display:'grid',
                                        gridTemplateColumns: 'minmax(64px, 200px) auto ',
                                        alignItems:'center'
                                    }
                                }>
                                    <label className='mr-4' htmlFor={field.name}>Selecione o cliente</label>
                                    <Dropdown {...field} filter style={{minWidth:'300px'}} optionLabel="name" optionValue='id' value={field.value} options={clientsList} onChange={(e) => field.onChange(e.value)}  id={field.name} />
                                </span>
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`user_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={
                                    {
                                        display:'grid',
                                        gridTemplateColumns: 'minmax(64px, 200px) auto ',
                                        alignItems:'center'
                                    }
                                }>
                                    <label className='mr-4' htmlFor={field.name}>Selecione o advogado</label>
                                    <Dropdown style={{minWidth:'300px'}} 
                                        {...field}
                                        filter
                                        showClear={field.value} 
                                        optionLabel="name" 
                                        optionValue='id' 
                                        value={field.value} 
                                        options={juridicsList}  
                                        onChange={(e) => { 
                                            selectedsJuricts.current = e.value;
                                            field.onChange(e.value);
                                            generetaAvailableTimesSelect();
                                        }}  
                                        id={field.name}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`time`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={
                                    {
                                        display:'grid',
                                        gridTemplateColumns: 'minmax(64px, 200px) auto ',
                                        alignItems:'center'
                                    }
                                }>
                                    <label className='mr-4' htmlFor={field.name}>Selecione o horário</label>
                                    <Dropdown {...field} filter style={{minWidth:'300px'}} optionLabel="name" optionValue='id' value={field.value} options={selectTimes} 
                                    onChange={(e) => {
                                        selectedsTime.current = e.value;
                                        field.onChange(e.value);
                                        generetaAvailableRoomsSelect();
                                    }}  
                                    id={field.name}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`room`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={
                                    {
                                        display:'grid',
                                        gridTemplateColumns: 'minmax(64px, 200px) auto ',
                                        alignItems:'center'
                                    }
                                }>
                                    <label className='mr-4' htmlFor={field.name}>Selecione o local</label>
                                    <Dropdown {...field} filter style={{minWidth:'300px'}} optionLabel="name" optionValue='id' value={field.value} options={selectRooms} 
                                    onChange={(e) => {field.onChange(e.value);}}  
                                    id={field.name}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`note`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputTextarea placeholder='Informe o assunto' autoComplete='off' className='p-inputtext-sm' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-12 mt-3" style={{textAlign:'right'}} >
                            <Button className='p-button-sm' label='Marcar' />
                        </div>
                    </div>
                </form>
                :
                'Não há como marcar para essa data'
                }
            </Dialog>
            <ConfirmDialog />
        </div>
    )
     
}