import React, { useMemo, useCallback, useEffect, useState } from 'react';

import { useAPP } from '@/services/provider/app';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { Route, Routes } from 'react-router-dom';
import { api, URL_PROFILE, URL_VALIDATESESSION } from '@/services/api';

import { NavigationFrameUser } from '../user';
import { PageUserHome } from '@/pages/user/home';
import { PageUserCalendar } from '@/pages/user/calendar';
import { PageUserProcessCases } from '@/pages/user/processcases';
import { PageUserProcessCasesView } from '@/pages/user/processcases/view';
import { PageClientCalendar } from '@/pages/client/calendar';
import { PageCollaboratorCalendarAusence } from '@/pages/user/calendar/ausence';
import { PageClients } from '@/pages/clients';
import { PageProfile } from '@/pages/profile';
import { PageProfileUser } from '@/pages/profile/user';
import { PageClientCreate } from '@/pages/client/create';
import { PageUserFeedback } from '@/pages/user/feedback';
import { PageUserAttendance } from '@/pages/user/attendance';
import { PageUserAttendanceCreate } from '@/pages/user/attendance/create';
import { PageUserTicket } from '@/pages/user/tickets';
import { PageUserTicketCreate } from '@/pages/user/tickets/create';
import { PageUserTicketView } from '@/pages/user/tickets/view';
import { PageFinance } from '@/pages/finance';
import { PageFinanceCreate } from '@/pages/finance/create';
import { PageCollaborators } from '@/pages/collaborators';
import { PageCollaboratorsCreate } from '@/pages/collaborators/create';
import { PageUserWiki } from '@/pages/user/wiki';
import { PageUserWikiCreate } from '@/pages/user/wiki/create';
import { PageUserWikiRead } from '@/pages/user/wiki/read';
import { PageProfileCollaborator } from '@/pages/collaborators/profile';
import { PageUserDeadline } from '@/pages/user/deadline';
import { PageUserDeadlineCreate } from '@/pages/user/deadline/create';
import { PageUserDeadlineView } from '@/pages/user/deadline/view';
import { PageUserCalendarCreate } from '@/pages/user/calendar/create';
import { PageProcessCasesCreate } from '@/pages/user/processcases/create';
import { PageUserExpertiseDeployment } from '@/pages/user/expertisedeployment';
import { PageUserExpertiseDeploymentCreate } from '@/pages/user/expertisedeployment/create';
import { PageUserExpertiseDeploymentView } from '@/pages/user/expertisedeployment/view';
import { PageConfiguration } from '@/pages/configuration';
import { PageCalendarNotification } from '@/pages/CalendarNotificastion';



import { SITE_MAP } from '@/services/provider/site';
import { setLogout } from '@/services/auth';
import { PageUserAttendanceView } from '@/pages/user/attendance/view';
import { PageDRE } from '@/pages/dre';
import { PageNotification } from '@/pages/notification';
import { PageNotificationView } from '@/pages/notification/view';
import { PagePartner } from '@/pages/partner';
import { PagePartnerCreate } from '@/pages/partner/create';
import { PagePartnerEdit } from '@/pages/partner/edit';

export const NavigationBase = () =>{

    const objectPages = useMemo(()=> ({
        PageUserHome: <PageUserHome />,
        PageClientHome: <PageUserHome />,
        PageUserCalendar: <PageUserCalendar />,
        PageUserProcessCases: <PageUserProcessCases />,
        PageUserProcessCasesView: <PageUserProcessCasesView />,
        PageClientProcessCases: <PageUserProcessCases />,
        PageProcessCasesCreate: <PageProcessCasesCreate />,
        PageClientCalendar: <PageClientCalendar />,
        PageClients: <PageClients />,
        PageProfileUser: <PageProfileUser />,
        PageProfileCollaborator: <PageProfileCollaborator />,
        PageClientCreate: <PageClientCreate />,
        PageUserFeedback: <PageUserFeedback />,
        PageUserAttendance: <PageUserAttendance />,
        PageUserAttendanceCreate: <PageUserAttendanceCreate />,
        PageUserTicket: <PageUserTicket />,
        PageUserTicketView: <PageUserTicketView />,
        PageUserTicketCreate: <PageUserTicketCreate />,
        PageFinance: <PageFinance />,
        PageCollaborators: <PageCollaborators />,
        PageCollaboratorsCreate: <PageCollaboratorsCreate />,
        PageUserWiki: <PageUserWiki />,
        PageUserWikiCreate: <PageUserWikiCreate />,
        PageUserWikiRead: <PageUserWikiRead />,
        PageUserDeadline: <PageUserDeadline />,
        PageUserDeadlineCreate: <PageUserDeadlineCreate />,
        PageUserDeadlineView: <PageUserDeadlineView />,
        PageUserCalendarCreate: <PageUserCalendarCreate />,
        PageUserExpertiseDeployment: <PageUserExpertiseDeployment />,
        PageUserExpertiseDeploymentCreate: <PageUserExpertiseDeploymentCreate />,
        PageUserExpertiseDeploymentView: <PageUserExpertiseDeploymentView />,
        PageUserAttendanceView: <PageUserAttendanceView />,
        PageFinanceCreate: <PageFinanceCreate />,
        PageCollaboratorCalendarAusence: <PageCollaboratorCalendarAusence />,
        PageConfiguration: <PageConfiguration />,
        PageDRE: <PageDRE />,
        PageCalendarNotification: <PageCalendarNotification />,
        PageNotification: <PageNotification />,
        PageNotificationView: <PageNotificationView />,
        PagePartner: <PagePartner />,
        PagePartnerCreate: <PagePartnerCreate />,
        PagePartnerEdit: <PagePartnerEdit />
    }),[])

    const { user, setUser } = useAPP();

    const location = useLocation();

    const [routerData, setRouterData] = useState(null);

    const navigate = useNavigate();

    const getRouters = useCallback(() => {

            let data;

            const map = {
                private: [],
                public: []
            } 

            user?.access.map(item => {

                const map_temp = SITE_MAP[item.page];

                if(map_temp){
                    item.component = map_temp?.component;
                    item.path = map_temp?.path;
                    item.url = map_temp?.url;

                    if(map_temp?.private){
                        map.private.push(item);
                    }else{
                        map.public.push(item);
                    }
                }else{
                    map.private.push({component: null});
                }

                return item;
            });

            
            data = (
                <Routes>
                    <Route element={<NavigationFrameUser />} >
                        {
                            map.private?.map(item => {
                                if(objectPages[item?.component] )
                                    return (<Route key={new Date().getTime()} path={item?.path} element={objectPages[item?.component]} />);
                                else
                                    return (<Route key={new Date().getTime()} path={item?.path} element={<>Página em construção</>} />);
                            })
                        }
                        <Route path="/profile" element={<PageProfile />} />
                        <Route path="/notification" element={<PageNotification />} />
                        <Route path="/notification/view/:id" element={<PageNotificationView />} />
                        <Route path="*" element={<>Página não encontrada</>} />
                    </Route>
                </Routes>)

            if(!user?.type)
                data = <Navigate to="../" state={{ from: location }} replace />

            setRouterData(data);
        }, [location, user, objectPages])

        const getData = useCallback(async ()=>{
            
            if(!user){
                const userdata = await api.get(URL_PROFILE, null, true);
                if(userdata.data)
                    setUser(userdata.data, ()=>{
                        getRouters();    
                    });
                else
                    getRouters();
                
            }else{
                getRouters();
            }
        },[user, setUser, getRouters]);

        const validateToken = useCallback(async () => {
            const data = await api.get(URL_VALIDATESESSION, null, true);
            
            if(!data.data){
                setLogout();
                navigate('/');
            }
        }, [navigate])

        useEffect(()=>{
            validateToken();
        }, [location, validateToken])

        useEffect(()=>{
            getData();
        }, [getData]);

    return(<>{ routerData }</>)
}