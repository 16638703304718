import React, { useCallback, useEffect, useState} from 'react';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { useParams } from 'react-router-dom';

import "@/assets/styles/pages/wiki/index.scss";
import { api, URL_WIKI_BYID } from '@/services/api';

export const PageUserWikiRead = () => {

    const params  = useParams();

    const [data, setData] = useState();

    const getData = useCallback(async()=>{
        const result = await api.get(`${URL_WIKI_BYID}/${params?.id}`, null, true);
        setData(result.data);
    }, [params])


    useEffect(()=>{
        getData();
    }, [getData])

    return (
        <div className="page-user-wiki-read">
            <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Wiki', url: '/user/wiki'},
                        {label:'Ler wiki'}
                    ]}
                />
                <div className="grid mt-4">
                    <div className="col-12">
                        <div class="cover">
                            <div className="image"></div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="title">
                            {data?.name}
                        </div>
                    </div>
                    <div className="col-12 description mt-2 mb-4">
                        {
                            data?.description
                        }
                    </div>
                    <div className="col-12">
                        <div className='content' dangerouslySetInnerHTML={{__html:data?.content}}></div>
                    </div>
                    <div className="grid-12">
                        <h3>Material compmentar</h3>
                    </div>
                </div>
        </div>
    )
}