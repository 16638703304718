import React, { useState, useEffect, useCallback, useRef } from "react";

import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useForm, Controller } from 'react-hook-form';

import { URL_ACTUACTION_AREA, URL_ACTUACTION_AREA_CREATE, api } from "@/services/api";

export const ConfigurationActuationArea = () => {

    const [data, setData] = useState();
    const [isLoad, setIsLoad] = useState(false);
    
    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;

    const toast = useRef(null);

    const getData = useCallback(async () => {
        const result =  await api.get(URL_ACTUACTION_AREA, null, true);
        setData(result?.data);

    }, [])

    const saveData = useCallback(async (_data) => {
        getData();
    }, [])


    const onSubmit = useCallback(async (_data) => {
        const sendData = {};

        await api.post(URL_ACTUACTION_AREA_CREATE,  sendData, true);

        getData();

    }, [])

    const onSubmitErros = useCallback((_data)=>{

        const erros = Object.keys(_data)?.map(item => {
            return ({severity:'error', summary:`Campo obrigatório`, detail:_data[item].message, life: 3000})
        })

        toast.current.show(erros);
    }, [])

    const onRowEditComplete = (e) =>{
        let temp  = [...data];
        temp[e.index] = e.newData;

        setData(temp);
    }

    const removeItem = (_data) => {

    }

    useEffect(()=>{
        getData();
    },[getData])


    return (
        <div className="configuration-actuation-area">
            <div className="register mt-2 mb-2">
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="grid">
                        <div className="col-10">
                            <Controller name={`name`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputText required inputStyle={{width:'100%'}} placeholder='Nome' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-2">
                            <Button label="Cadastrar" className="p-button-primary" />
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <DataTable 
                    style={{width:'100%'}}
                    size="small"
                    value={data} 
                    emptyMessage="Não há informações cadastradas"
                    editMode="row" 
                    dataKey="id" 
                    onRowEditComplete={onRowEditComplete}
                    paginator={data?.length > 10}
                    rows={10}
                >
                    <Column style={{width:'250px'}} field='name' header="Nome" editor={
                        (options) => {
                            return <InputText inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                        }
                    } />
                    <Column rowEditor headerStyle={{ width: '100px'}} bodyStyle={{ textAlign: 'right' }}></Column>
                    <Column style={{width:'50px',maxWidth:'50px', textAlign:'center'}} body={(row)=>{
                        return <Button onClick={(e)=>{e.preventDefault(); removeItem(row.id)}} icon="pi pi-trash" className="p-button-danger p-button-text" />
                    }} header=""/>
                </DataTable>
            </div>
            <Toast ref={toast} position="bottom-right" />
        </div>
    )
}