import React, { useState, useCallback, useEffect } from "react";

import { ProgressBar } from 'primereact/progressbar';
import { Timeline } from 'primereact/timeline';
 

import { useParams } from "react-router-dom";
import { api, URL_PROCESSCASES_HISTORY } from "@/services/api";

export const PageUserProcessCasesHistory = (props) => {

    const params = useParams();

    const [data, setData] = useState();

    const getData = useCallback(async () => {
        const result = await api.get(`${URL_PROCESSCASES_HISTORY}/${params?.id}`, false, true);
        //console.log('#', result.data);
        setData(result.data || []); 
    }, [params])

    useEffect(()=> {
        getData();
    }, [getData]);


    if(!data)
        return (
            <div className="page-user-processcases-history">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    if(data.length === 0)
        return (
            <div className="page-user-processcases-history">
                <p>
                    Não há informações cadastradas
                </p>
            </div>    
        )

    return (
        <div className="page-user-processcases-history">
            <div className="grid mt-4">
                <div className="col-12">
                <Timeline value={data} 
                opposite={(item)=> item.date_time ? `${new Date(item.date_time).toLocaleDateString()} - ${new Date(item.date_time).toLocaleTimeString()}` : '-'} 
                content={(item) => {
                    return <div>
                        <div><strong>{item?.action}</strong></div>
                        <small>{item?.description}</small>
                    </div>
                }} />
                </div>
            </div>
        </div>
    )
}