import React, { useState, useEffect, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';

import { ProgressBar } from 'primereact/progressbar';
 

import { api, URL_CALENDAR_BYCLIENT } from '@/services/api';

export const PageProfileUserCalendar = (props) => {

    const [dates, setDates] = useState(null);
    const [showNote, setShowNote] = useState(false);
    const [contentNote, setContentNote] = useState();

    const getData = useCallback(async () => {
        if(!props?.user)
            return;
            const data = await api.get(`${URL_CALENDAR_BYCLIENT}/${props?.user}`, false, true);
            setDates(data?.data || []);
    }, [setDates, props])

    const openNote = (data) => {
        setContentNote(data);
        setShowNote(true);
    }

    useEffect(()=>{
        getData();
    }, [getData])

    if(!dates)
        return (
            <div className="page-profile-user-address">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        ) 

    return (
        <div className="page-profile-user-address">
            <DataTable     
                    value={dates} 
                    responsiveLayout="scroll"
                    emptyMessage="Não há endereços cadastrados"
                    size='small'
                    stripedRows
                    rows={10}
                    paginator={dates?.length > 10}
                >
                    <Column style={{width:'200px'}} sortable field="identification" header="ID" />
                    <Column body={(row)=>{
                        
                        return <>
                            {new Date(row?.date?.split('-')[0], ~~row?.date?.split('-')[1] - 1 , row?.date?.split('-')[2]).toLocaleDateString()} 
                        </>
                    }} sortable field="date" header="Dia" />
                    <Column sortable field="time" header="Horário" />
                    <Column body={(row)=>{
                        return <>
                            {row.prefixe}. {row.name} 
                        </>
                    }} sortable field="name" header="Responsável" />
                    <Column body={(row)=>{
                        return <Button onClick={()=>openNote(row)} icon="pi pi-book" className='p-button-sm p-button-text' />
                    }} field="note" header="Notas" />
                </DataTable>

                <Dialog header={`Notas ID: ${contentNote?.identification}`}
                visible={showNote} 
                style={{ width: '98%', maxWidth:'600px' }} 
                onHide={() =>{setShowNote(false); setContentNote('');}}>
                    <div dangerouslySetInnerHTML={{__html: contentNote?.note}}></div>
                </Dialog>
        </div>
    )
}