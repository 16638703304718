import React from 'react';

import '@/assets/styles/components/ui/breadcrumb/index.scss';
import { useNavigate } from 'react-router-dom';

export const UIBreadcrumb = (props) => {

    const navigate = useNavigate();

    const openURL = (url = null) => {
        if(url){
            navigate(url);
        }
    }

    return (
        <div className="ui-breadcrumb">
            {
                props?.model?.map((item, id) => {
                    return (
                        <div key={`item-${id}`} className="item">
                            <div onClick={()=> openURL(item?.url)} className={`text ${item?.url ? 'click' : ''}`} >
                                {item?.label}
                            </div>
                            <div className="icon">
                                <i className='pi pi-chevron-right' />
                            </div>
                        </div>

                    )
                })
            }
        </div>
    )
}