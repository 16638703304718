import React, {useState, useEffect, useMemo, useCallback, useRef} from "react";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { URL_CALENDAR_NOTIFICATION_LISTALL_BY_DATE, URL_CALENDAR_NOTIFICATION_MODE_DATA, api } from "@/services/api";
import { Button } from "primereact/button";



export const PageCalendarNotificationManager = ({currentDate}) =>{
    
    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const [listData, setListData] =  useState();

    const getData = useCallback(async () => {

        const _date = currentDate?.toLocaleDateString().split('/').reverse().join('-');

        const data = await api.get(`${URL_CALENDAR_NOTIFICATION_LISTALL_BY_DATE}/${_date}`);
        setListData(data.data);

    }, [currentDate]);

    useEffect(()=> {
        getData();
    }, [getData])

    return (
        <div className="calender-notification-manager">
            <div className="grid">
                <div className="col-12">
                    <DataTable 
                        emptyMessage="Não há dados cadastrados"
                        value={listData} 
                        responsiveLayout="scroll">
                        <Column style={{width:'120px'}} body={(row)=> new Date(row.datetimer).toLocaleTimeString() } field="datetimer" header="Horário" />
                        <Column field="title" header="Titulo" />
                        <Column field="description" header="Descrição" />
                        <Column style={{width:'150px'}} body={(row)=> {
                            if((new Date()).getTime() <= (new Date(row.datetimer)).getTime()){
                                return <>
                                    <Button icon="pi pi-eye" className="mr-2 p-button-sm p-button-text" /> 
                                    <Button icon="pi pi-times" className="p-button-sm p-button-text p-button-danger" /> 
                                </>
                            }
                            return ''
                        }} field="" header="" />
                    </DataTable>
                </div>
            </div>
        </div>
    )
}