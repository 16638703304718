import React, { useCallback, useState, useRef, useEffect } from 'react';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';


import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { useNavigate, useParams } from 'react-router-dom';
import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { api, URL_PARTNER_UPDATE, URL_PARTNER_BY_ID} from '@/services/api';
import { formatMoney } from '@/services/utils';
import { Checkbox } from 'primereact/checkbox';

export const PagePartnerEdit = () => {

    const param = useParams();

    const navigate = useNavigate();

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const tikectID = useRef(null);
    tikectID.current = tikectID.current ? tikectID.current : new Date().getTime();

    const created = useRef(null);
    created.current = created.current ? created.current : new Date();

    const toast = useRef(null);

    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset =  form.reset;

    const [partnerData, setPartnerData] = useState();
    const [parcelamentData, setParcelamentData] = useState();


    const [isLoad, setIsLoad] = useState(false);

    const alterParcelamentData = ((id, _data) => {

        const temp  = parcelamentData.map(item => {
            if(~~id === ~~item.number){
                const temp = {...item, ..._data};
                return temp;
            }
            return item;
        })

        setParcelamentData(temp);

    })

    const onSubmit = useCallback(async (_data) => {

        setIsLoad(true);
        
        const sendData = { ...partnerData, ..._data};

        sendData.date_paid = _data.date_paid.toISOString().substr(0, 10);

        const rs = await api.put(URL_PARTNER_UPDATE, sendData, true);
        
        if(rs?.data){
            toast.current.show({severity:'success', summary: 'Sucesso', detail:'Dados atualizados com sucesso.', life: 3000});
        }else{
            toast.current.show({severity:'error', summary: 'Erro', detail:'Existe problemas nos valores.', life: 3000});
        }

        setIsLoad(false);

    }, 
    [partnerData])

    const getData = useCallback(async () => {
        const data = await api.get(`${URL_PARTNER_BY_ID}/${param.id}`, null, true);
        
        Object.keys(data.data).map(item => {
            if(/date_paid/gi.test(item))
                form.setValue(item, new Date(`${data.data[item]} 00:00:00` ));
            else
                form.setValue(item, data.data[item]);
            return item;
        })
        
        setPartnerData(data.data);

    }, [param, form]);


    useEffect(()=> {
        getData();
    }, [getData])


    return (
        <div className="page-user-wiki-create">
            <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Parceiros', url: '/user/partner'},
                        {label:'Editar parceiro'}
                    ]}
                />
            <div>
                <form onSubmit={handleSubmit(onSubmit)}  style={{pointerEvents: isLoad ? 'none' : 'auto'}} >
                    <div className="grid mt-4">
                        
                        <div className="col-4">
                            <Controller name={`cpf_cnpj`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <InputText inputStyle={{width:'100%'}} placeholder='CPF/CNPJ' className='' style={{width:'100%'}} onChange={(e) => { field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-4">
                            <Controller name={`date_paid`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}} className="">
                                    <Calendar required locale='pt-BR' dateFormat='dd/mm' showIcon inputStyle={{width:'100%'}} placeholder='Data de pagamento' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-4">
                            <Controller name={`value`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <InputText inputStyle={{width:'100%'}} placeholder='Valor' type='number' min={'0.00'} step={'0.01'} className='' style={{width:'100%'}} onChange={(e) => { field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-4">
                            <Controller name={`type`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <InputText inputStyle={{width:'100%'}} placeholder='Tipo' className='' style={{width:'100%'}} onChange={(e) => { field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-4">
                            <Controller name={`segment`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <InputText inputStyle={{width:'100%'}} placeholder='Segmento' className='' style={{width:'100%'}} onChange={(e) => { field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-4" style={{display:'flex', alignItems:'center'}}>
                            <Controller name={`active`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <Checkbox inputStyle={{width:'100%'}} placeholder='Ativo' className='' onChange={(e) => { field.onChange(e.target.checked);}}  id={field.name} {...field} checked={~~field.value === 1} value={field.value}  />
                                    <span className='ml-2'>Parceiro ativo</span>
                                </span>
                            )} />
                        </div>
                        <div className="col-12" >
                            <Controller name={`description`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <InputTextarea autoResize inputStyle={{width:'100%'}} placeholder='Descrição' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 mt-4 mb-4" style={{textAlign:'right'}}>
                            <Button onClick={()=> navigate('/user/partner')} label="Cancelar" className='p-button-danger mr-2 p-button-sm' />
                            <Button loading={isLoad} label="Atualizar" className='p-button-sm' />
                        </div>
                    </div>
                </form>
            </div>
            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}