import { Navigate, useLocation } from 'react-router-dom';

export const TOKEN_KEY = '@AFFADVOCACIA_TOKEN';

export const isAuthenticated = () => window.sessionStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => window.sessionStorage.getItem(TOKEN_KEY);

export const setLogin = token => { window.sessionStorage.setItem(TOKEN_KEY, token)};

export const setLogout = () => { window.sessionStorage.removeItem(TOKEN_KEY)};

export const RequireAuth = ({children}) => {

    const location = useLocation();
    
    if(!isAuthenticated()){
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
}