import React, { useState, useEffect, useCallback, useRef } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { api, URL_CLIENTS } from '@/services/api';
import { useNavigate } from 'react-router-dom';
import { formatCPJCNPJ, formatShortName } from '@/services/utils';

export const PageClients = () => {

    const navigate = useNavigate();

    const toast = useRef(null);

    const [users, setUsers] = useState([]);
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const getData = useCallback(async () => {
        const data = await api.get(URL_CLIENTS, false, true);
       // //console.log(data.data);

        setUsers(data?.data);
    }, [setUsers])

    const openProfile = (user) => {
        navigate(`/user/profile/${user}`);
    }

    const copyContent = (data) => {
        navigator.clipboard.writeText(data);
        toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Conteúdo copiado com sucesso'});
    }

    const clearFilter = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'user_ref': { value: null, matchMode: FilterMatchMode.STARTS_WITH }
        })
        setGlobalFilterValue('');
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters };
        _filters1['global'].value = value;

        setFilters(_filters1);
        setGlobalFilterValue(value);
    }


    useEffect(()=>{
        getData();
    }, [getData])

    return (
        <div className="page-clients">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Clientes'}
                    ]}
                />
            </div>
            <h3>
                Clientes cadastrados
            </h3>
            <div className="header">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: 0}}
                    left={()=><>
                    </>} 
                    right={()=><>
                        <Button onClick={()=>getData()} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>navigate('/user/client')} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} />
            </div>
            <div className="grid mt-4">
                <div className="col-12">
                    <DataTable 
                        value={users} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há clientes cadastrados"
                        size='small'
                        stripedRows
                        rows={10}
                        paginator={users?.length > 10}
                        filters={filters}
                        globalFilterFields={['user_ref', 'name', 'cpf_cnpj']}
                        header={()=>{
                            return <div className="flex justify-content-end">
                                        <span className="p-input-icon-left">
                                            <i className="pi pi-search" />
                                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Busca" />
                                        </span>
                                        <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-sm ml-2" onClick={clearFilter} />
                                    </div>
                        }}
                    >
                        <Column 
                        sortable style={{width:'100px'}}  
                        body={(row)=> {
                            return <div style={{display:'flex', alignItems:'center'}}>
                             {
                                (row.user_ref ? row.user_ref : row.id) ? 
                                    <>
                                        <span style={{width:'calc(100% - 48px)'}}>{(row.user_ref ? row.user_ref : row.id)} </span>
                                        <Button onClick={()=> copyContent((row.user_ref ? row.user_ref : row.id))} className='p-button-sm p-button-text' icon='pi pi-copy' />
                                    </>
                                :
                                    '-'
                             }
                             </div>
                        }}
                        field="user_ref" header="ID" />
                        <Column sortable field="name" header="Nome" body={(row)=> formatShortName(row.name)} />
                        <Column style={{width:'200px'}} body={(row)=> {
                            return <div style={{display:'flex', alignItems:'center'}}>
                             {
                                row?.user_data?.cpf_cnpj ? 
                                    <>
                                        <span>{formatCPJCNPJ(row?.user_data?.cpf_cnpj)} </span>
                                        <Button onClick={()=> copyContent(row?.user_data?.cpf_cnpj)} className='p-button-sm p-button-text' icon='pi pi-copy' />
                                    </>
                                :
                                    '-'
                             }
                            </div>
                        }}
                        field="user_data.cpf_cnpj" header="CPF/CNPJ"
                        />
                        <Column style={{width:'200px'}} body={(row)=> {
                            return <div style={{display:'flex', alignItems:'center'}}>
                            {
                               row?.user_data?.access_inss ? 
                                   <>
                                       <span>{row?.user_data?.access_inss} </span>
                                       <Button onClick={()=> copyContent(row?.user_data?.access_inss)} className='p-button-sm p-button-text' icon='pi pi-copy' />
                                   </>
                               :
                                   '-'
                            }
                           </div>
                        }} field="user_data.access_inss" header="Acesso INSS" />
                        <Column style={{width:'100px'}} body={(row)=>{
                            return <Tag style={{width:'100%',backgroundColor: (~~row.active === 1 ? 'var(--green-200)' : 'var(--red-200)'), color: (~~row.active === 1 ? 'var(--green-800)' : 'var(--red-800)')}} value={~~row.active === 1 ? 'Ativo' : 'Inátivo' } />
                        }}  header="Status" />
                        <Column style={{width:'100px', textAlign:'right'}} body={(row)=>{
                            return <Button className='p-button-sm p-button-text' onClick={()=>openProfile(row.id)} icon="pi pi-eye" />
                        }}  header="" />
                    </DataTable>
                </div>
            </div>

            <Toast ref={toast} position="bottom-center" />
 
        </div>
    )
}