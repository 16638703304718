import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
 
 

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import '@/assets/styles/components/ui/clientcalendar/index.scss';
import { api, URL_CALENDAR_AVAILABLE_JURIDIC, URL_CALENDAR_TIMES_ATTENDANCE } from '@/services/api';
import { useAPP } from '@/services/provider/app';
import { useNavigate } from 'react-router-dom';

export const UICollaboratorCalendar = (props) =>{

    const navigate = useNavigate();

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const { user } = useAPP();

    const roomActive = useRef();

    const [calendarData, setCalendarData] = useState();
    const [eventsData, setEventsData] = useState();

    const [currentDate, setCurrentDate] = useState(props?.selectedData || new Date());
    const [listtimes, setListtimes] = useState([]);
    const [selectedDay, setSelectedDay] = useState(props?.selectedData || null);
    const [registersData, setRegistersData] = useState([]);
    const [selectedTime, setSelectedTime] = useState( null);
    const [rooms, setRooms] = useState();
    const [roomsList, setRoomsList] = useState();
    const [timesAttendance, setTimesAttendance] = useState();
    const [selectedAttendance, setSelectedAttendance] = useState();
    const [selectedAttendanceCustom, setSelectedAttendanceCustom] = useState();
    const [selectLocation, setSelectionLocation] = useState('local');

    const [selectedUsing, setSelectedUsing] = useState(1);

    //const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1,  currentDate.getDay());
    const date = new Date(currentDate);
    let init = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`).getDay() - 1;
    let lastday = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    
    let countweek = 1;
    for(let i=0; i < lastday; i++){
        const temp = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${i}`);
        temp.setDate(i);
        if(temp.getDay() === 6)
            countweek++;
    }
    
    const boxTotal = countweek * 7;

    const createList = useCallback(() => {
        //const data = calendarData[date.toISOString().substr(0, 10)];
        const data = calendarData;
    
        let _list = [];
        
        
        if(data){
            Object.keys(data).map(item => {
                if(data[item]?.available){
                    _list.push({label: item, value: item, enable: true})
                }
                return item;
            })
        }

        setRooms();
        setRoomsList();
        setListtimes(_list);
    }, [calendarData, setRooms, setRoomsList, setListtimes])

    const openDay = useCallback(async (_day = null) =>{
        setListtimes([]);
        setRoomsList();
        setRooms();
        setSelectedAttendance();
        
        if(_day)
            setSelectedDay(_day);
        //const date = new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${_day.getDate()}`);
        
        // const date = new Date(currentDate);
        
        // if(!calendarData){
        //     setListtimes([]);
        //     return;
        // }

        //createList();
        
    }, [setSelectedDay, setListtimes, currentDate])

    const saveData = useCallback((_data)=>{
        const sendData = {..._data}
        console.log('Send Data', sendData);
        props?.confirmData(sendData);

    }, [props])

    const openTime = useCallback((_time) => {

        const list = calendarData[_time].available;
        
        setRoomsList(list);
        
        

    },[calendarData, currentDate]);

    const mountBox = () => {
        
        let listbox = [];
        let dayTemp = 1;

        for(let i =0; i < boxTotal; i++ ){
            const temp = new Date(date);
            temp.setDate(dayTemp);
            
            const today = new Date();

            if(date.getMonth() === temp.getMonth() && i > init){ 
                let availableDay = '';
                if(!eventsData){
                    availableDay = 'no-time';
                }else{
                    if(eventsData[temp?.toISOString()?.substr(0, 10)] ){
                        availableDay = 'no-time';    
                    }else{
                    }
                }
                
                listbox.push(<div key={`day-bt-${temp.getDate()}-${i}`} onClick={()=>openDay(temp)} className={`day-calendar ${selectedDay?.toLocaleDateString() === temp?.toLocaleDateString()  ? 'selected' : ''} ${availableDay}`}>
                        <div className={`${temp.toLocaleDateString() === today.toLocaleDateString() ? 'today' : '' } number `}>{temp.getDate()}</div></div>);
                dayTemp++;
            }else{
                listbox.push(<div key={`clear-${i}`}></div>);
            }
        }

        return listbox;
    }

    const alterMonth = useCallback((isNext)=>{
        //const _date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        const _date = new Date(currentDate);
        _date.setMonth(_date.getMonth() + (isNext ? 1 : -1));
        
        setCurrentDate(_date);
        setSelectedDay();

    }, [currentDate, setSelectedDay]);

    const getDataCalendar = useCallback(async()=> {

        let time = 10;
        
        if(~~selectedAttendance === 0 && selectedAttendanceCustom){
           time =  (~~selectedAttendanceCustom.getHours() * 60) + selectedAttendanceCustom.getMinutes();   
        }else if(selectedAttendance){
            time = selectedAttendance;
        }
        
       
        const data = await api.get(`${URL_CALENDAR_AVAILABLE_JURIDIC}/${props?.juridics}/${currentDate?.toISOString()?.substr(0, 10)}/${time}`, null, true);
        setCalendarData(data?.data?.list);
        setEventsData(data?.data?.events);

    }, [props, setCalendarData, currentDate, selectedAttendance, selectedAttendanceCustom])

    const getTimesAttendance = useCallback(async () => {
        const data = await api.get(URL_CALENDAR_TIMES_ATTENDANCE, null, true);
        
        const list = [];
        Object.keys(data.data)?.map(item =>{
            list.push({label: `${item} - ${~~data.data[item] === 0 ? '' : `${data.data[item]} minutos`}`, value: data.data[item]});
            return item;
        })

        setTimesAttendance(list);
    }, [])

    useEffect(()=>{
        getDataCalendar();
    }, [currentDate])

    useEffect(()=>{
        setSelectedTime(null);
    }, [selectedDay, setSelectedTime])

    useEffect(()=>{
        setRooms();
        setRoomsList([]);
        if(selectedTime){
            openTime(selectedTime)
        }
    }, [selectedTime])

    useEffect(()=>{
        // if(!calendarData)
        //     getDataCalendar();

        if(calendarData)
            createList();

    }, [calendarData])

    useEffect(()=>{
        
        if((selectedAttendance || ~~selectedAttendance === 0) && selectedDay){
            const temp = new Date();
            temp.setHours(0);
            temp.setMinutes(10);
            setSelectedAttendanceCustom(temp);
        }else{
            setSelectedAttendanceCustom(null);
        }
    }, [selectedAttendance, selectedDay])

    useEffect(()=>{
        if(selectedAttendance === 0 && selectedAttendanceCustom){
            getDataCalendar();
            //createList();
        }

    },[selectedAttendanceCustom, selectedAttendance])

    useEffect(()=>{
        setListtimes();
        if(~~selectedAttendance !== 0){
            getDataCalendar();
            //createList();
        }

    },[selectedAttendance])

    useEffect(()=>{
        console.log('Salas', rooms);
        saveData({room_meeting_id: rooms, date:selectedDay, time: selectedTime, attendance: selectedAttendance, location: selectLocation});
    }, [rooms, selectedDay, selectedTime, selectedAttendance, selectLocation])

    useEffect(()=>{
        if(!timesAttendance){
            getTimesAttendance();
        }
    }, [getTimesAttendance, timesAttendance])

   
    return (
        <div className="ui-client-calendar">
            <div className="grid mt-4">
                <div className="col-6">
                    <div className='area-calendar'>
                        <div>
                            <Button onClick={()=>alterMonth(false)} style={{color:'#666'}} icon="pi pi-chevron-left" className='p-button-text' />
                        </div>
                        <div>
                            <Calendar
                            locale='pt-BR'
                            value={currentDate} 
                            onChange={(e) => {
                                setSelectedDay();
                                setCurrentDate(e.value);
                            }} 
                            view="month" 
                            dateFormat="MM - yy"
                            />
                        </div>
                        <div>
                            <Button onClick={()=>alterMonth(true)} style={{color:'#666'}} icon="pi pi-chevron-right" className='p-button-text' />
                        </div>
                    </div>
                    <div className="component-calendar mt-4">
                        <div className="header-month-calendar">
                            {
                                moment.localeData().weekdaysShort().map((item, id) =>{
                                    return <div key={`week-${id}`}>{item}</div>
                                })
                            }
                        </div>
                        <div className="days-month-calendar">
                            {mountBox()}
                        </div>
                        <div className="footer-calendar">
                            <div className="current-day">Dia atual</div>
                            <div className="selected-day">Dia selecionado</div>
                            <div className="pre-day">Marcações</div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="resume-selection">
                       
                        {
                            selectedDay ?
                                <>
                                    <span>{moment.localeData().weekdays()[(new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate()).getDay())]}</span>, {selectedDay.getDate()} de  <span>{moment.localeData().months()[selectedDay.getMonth()]}</span> de { selectedDay.getFullYear()}
                                </>
                            : 
                            ''
                        }
                    </div>
                    <div >
                    {
                            timesAttendance?.length > 0 && selectedDay ?
                                <>
                                <div className='title-resume'>
                                    Temp de atendimento
                                </div>
                                <Dropdown filter style={{backgroundColor:'#CFEAC0', width:'80%', color:'#666'}} value={selectedAttendance} options={timesAttendance} onChange={(e) => setSelectedAttendance(e.value)} placeholder="Selecione o tipo de atendimento"/>
                                </>
                            :
                                ''
                        }
                        {
                            selectedDay ?
                                <>
                                <div className='title-resume'>
                                    Utilização
                                </div>
                                <SelectButton unselectable={false} value={selectedUsing} 
                                options={[
                                    {label: 'Atendimento', value: 1},
                                    {label: 'Audiência', value: 2}
                                ]} onChange={(e) =>{setRooms(); setSelectedUsing(e.value)}}></SelectButton>
                                </>
                            :
                                ''
                        }
                       <div className='mt-2 mb-2'>
                        {
                            selectedAttendance === 0 ?
                                <>
                                    <div className='title-resume'>
                                        Tempo de atendimento
                                    </div>
                                    <Calendar style={{backgroundColor:'#CFEAC0', width:'80%', color:'#666'}} timeOnly showTime hourFormat="24" value={selectedAttendanceCustom} onChange={(e) =>setSelectedAttendanceCustom(e.value)} onBlur={()=>{ getDataCalendar(); createList(); }} />
                                </>
                            :
                                <></>
                        }
                       </div>
                        {
                            listtimes?.length > 0  && selectedAttendance?
                                <>
                                <div className='title-resume'>
                                    Horários
                                </div>
                                <Dropdown filter style={{backgroundColor:'#CFEAC0', width:'80%', color:'#666'}} optionLabel='label' optionValue='label' key={`list-${selectedDay}`} value={selectedTime} options={listtimes} onChange={(e) => setSelectedTime(e.value)} placeholder="Selecione o horário"/>
                                </>
                            :
                                ''
                        }
                        {
                            roomsList?.length > 0 ?
                                <>
                                <div className='title-resume'>
                                    Sala
                                </div>
                                 { 
                                 selectedUsing === 1 ? 
                                    <Dropdown filter style={{backgroundColor:'#CFEAC0', width:'80%', color:'#666'}} optionLabel='name' optionValue='id' key={`list-room-${selectedDay}`} value={rooms} options={roomsList} onChange={(e) => setRooms(e.value)} placeholder="Selecione a sala"/>
                                    :
                                    <MultiSelect filter style={{backgroundColor:'#CFEAC0', width:'80%', color:'#666'}} optionLabel='name' optionValue='id' key={`list-room-${selectedDay}`} value={rooms} options={roomsList} onChange={(e) => setRooms(e.value)} placeholder="Selecione a sala"/>
                                 }
                                <div className='title-resume'>
                                    Local
                                </div>
                                <Dropdown style={{backgroundColor:'#CFEAC0', width:'80%', color:'#666'}} key={`local-${selectedDay}`} value={selectLocation} options={[
                                    {label: 'Local', value: 'local'},
                                    {label: 'On-line', value: 'online'}
                                ]} onChange={(e) => setSelectionLocation(e.value)} placeholder="Selecione o local"/>
                                </>
                            :
                                ''
                        }
                    </div>
                </div>
                
                <div className="grid mt-4">
                    </div>
            </div>
        </div>
    )
}