import React, { useState, useCallback, useEffect} from 'react';

import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressBar } from 'primereact/progressbar';
 

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { useNavigate } from 'react-router-dom';
import { api, URL_ATTENDANCE_ALL } from '@/services/api';

import '@/assets/styles/pages/user/attendance/index.scss';
import { formatShortName } from '@/services/utils';

export const PageUserAttendance = () =>{

    const navigate = useNavigate();

    const [calendar, setCalendar] = useState(new Date());
    const [calendarEnd, setCalendarEnd] = useState(new Date());
    const [list, setList] = useState();
    const [showinfo, setShowinfo] = useState(false);
    const [infotext, setInfotext] = useState();
    const [isLoading, setIsLoading] = useState(false);

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const selectPeriod = (e) =>{
        const start = new Date();
        start.setDate(start.getDate() - e);
        const end = new Date();

        setCalendar(start);
        setCalendarEnd(end);
    }

    const openInfo = useCallback((data)=>{
        setInfotext(data);
        setShowinfo(true);
    }, [])

    const getData = useCallback(async()=>{
        setIsLoading(true);
        const data = await api.get(`${URL_ATTENDANCE_ALL}/${calendar.toISOString().substring(0, 10)}/${calendarEnd.toISOString().substring(0, 10)}`, null , true);
        setList(data.data || []);
        setIsLoading(false);
    }, [calendar, calendarEnd])

    useEffect(()=>{
        getData();
    }, [getData])

    if(!list){
        return (
            <div className="page-user-processcases-view">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )
    }

    return (
        <div className="page-user-attendance">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Atendimento'}
                    ]}
                />
            </div>
            <div className="header">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><>
                        <Calendar dateFormat="dd/mm/yy" showIcon locale='pt-BR' value={calendar} onChange={(e)=>setCalendar(e.value)} />
                        <Calendar className='ml-3 mr-3' dateFormat="dd/mm/yy" showIcon locale='pt-BR' minDate={calendar} value={calendarEnd} onChange={(e)=>setCalendarEnd(e.value)} />
                        <SplitButton onClick={()=>selectPeriod(0)} label="Hoje" className="p-button-raised"
                            model={[
                                {label:'15 Dias', command:()=>selectPeriod(15)},
                                {label:'30 Dias', command:()=>selectPeriod(30)},
                                {label:'60 Dias', command:()=>selectPeriod(60)}
                            ]} 
                         />
 
                    </>} 
                    right={()=><>
                        <Button onClick={()=>{getData()}} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>{navigate('/user/attendance/create')}} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} 
                />
            </div>
            <div>
                <DataTable 
                    value={list} 
                    responsiveLayout="scroll"
                    emptyMessage="Não há atendimentos cadastrados"
                    size='small'
                    stripedRows
                    loading={isLoading}
                >
                    <Column body={(row)=> {
                        return new Date(row.start).toLocaleDateString();
                    } } header="Data" />
                    <Column body={(row)=>{
                        return new Date(row.start).toLocaleTimeString();
                    }} sortable field="start" header="Início" />
                    <Column body={(row)=>{
                        return row.end ? new Date(row.end).toLocaleTimeString() : '-';
                    }} sortable field="end" header="Fim" />
                    {/* <Column field="type" header="Tipo" />
                    <Column field="location" header="Local" /> */}
                    <Column body={(row)=>{
                        return <span style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/profile/${row?.user_id}`)}>{row?.user?.name}</span>
                    }} header="Cliente" />
                    <Column body={(row)=>{
                        return formatShortName(row?.attendance_user?.name);
                    }} header="Responsável" />
                    <Column body={(row)=>{
                        return row?.area?.name || '-';
                    }} header="Área" />
                    <Column body={(row)=>{
                        return row?.process_cases_id ? <span style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/processcases/${row?.process_cases_id}`, {state:{tabActive:3}})}>{row?.process_cases?.name}</span> : '-';
                    }} header="Caso" />
                    <Column body={(row)=>{
                        return <>
                            <Button onClick={()=>openInfo(row)} className='p-button-sm p-button-text mr-2' icon="pi pi-book" />
                            <Button onClick={()=>navigate(`/user/attendance/${row.id}`)} className='p-button-sm p-button-text' icon="pi pi-eye" />
                        </>
                    }} header="" />
                </DataTable>
            </div>

            <Dialog header={`Detalhes atendimento - ${infotext?.id}`}
                visible={showinfo} 
                style={{ width: '98%', maxWidth:'600px' }} 
                onHide={() =>{setShowinfo(false);}}>
                    {infotext?.description}
            </Dialog>
        </div>
    )
}