import React, { useState, useEffect, useMemo, Suspense } from 'react';

import { ProgressBar } from 'primereact/progressbar';
import { Menubar } from 'primereact/menubar';
import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { ConfigurationPageActuation } from './actuation';
import { ConfigurationPageAccess } from './pagesaccess';

export const PageConfiguration = () => {

    const menu = useMemo(()=> (
        [
            
            {
                label: 'Início', command:()=>{ openPage('home')}
            },
            {
                label:'Cadastros',
                items: [
                    {label:'Área de atuação', command:()=>{ openPage('actuation')}},
                    {label:'Área de atendimento', command:()=>{ openPage('attendance')}},
                    {label:'Tipo de cliente', command:()=>{ openPage('client_type_extra')}},
                    {label:'Controle de prazo', command:()=>{ openPage('deadline')}},
                    {label:'Perícia e implatação', command:()=>{ openPage('expertise_deployment')}},
                    {label:'Casos', command:()=>{ openPage('cases')}},
                    {label:'Processos', command:()=>{ openPage('process')}},
                    {label:'Salas', command:()=>{ openPage('room_meeting')}},
                    {label:'Ticket', command:()=>{ openPage('ticket')}},
                    {label:'Usuários', command:()=>{ openPage('users')}},
                    {label:'Sistema', command:()=>{ openPage('system_config')}},
                ]
            },
            {
                label: 'Páginas',
                items:[
                    {label:'Cadastrar página', command:()=>{ openPage('controller_pages')}},
                    {label:'Cadastrar acesso', command:()=>{ openPage('controller_pages_access')}}
                ]
            },
            {
                label: 'Logs', command:()=>{ openPage('logs')}
            },
            {
                label: 'Repositório', command:()=>{ openPage('repository')}
            }
        ]
            
    ), [])

    const pages = {
        home:<div>Home</div>,
        actuation: <ConfigurationPageActuation />,
        attendance:<div>Área de atendimento</div>, 
        teste:<div>TESTE</div>,
        controller_pages_access: <ConfigurationPageAccess />
    }

    const openPage = (_data) => {
        let selectPage =  <div>
            Página indisponível
        </div>;

        if(pages[_data]){
            selectPage = pages[_data];
        }
        
        setData(selectPage);
    }

    const [data, setData] = useState();

    useEffect(()=> {

        setData(pages['home']);

    },[])

    if(!data){
        return (
            <div className="page-clients">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )
    }

    return( 
        <div className="page-config">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Configuração', command:()=>{openPage('teste')}}
                    ]}
                />
            </div>
            <div className='mt-4 mb-4'>
                <Menubar model={menu}/>
            </div>
            <div>
                { data }
            </div>

        </div>
    )
}