import React, {useState, useEffect, useCallback, useRef} from 'react';

import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AutoComplete } from 'primereact/autocomplete';
import { Timeline } from 'primereact/timeline';
import { Toast } from 'primereact/toast';

import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';

import { useForm, Controller } from 'react-hook-form';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { formatCPJCNPJ } from '@/services/utils';

import { useAPP } from '@/services/provider/app';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { api, URL_ATTENDANCE_BYID } from '@/services/api';

export const PageUserAttendanceView = () => {

    const { user } = useAPP();

    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState();

    const getData = useCallback(async ()=>{
        
        let result = await api.get(`${URL_ATTENDANCE_BYID}/${params?.id}`, null, true);
        //console.log('#', result.data);
        setData(result?.data);
    }, [params]);

    useEffect(()=>{
        getData();
    },[getData]);


    if(!data)
        return (
            <div className="page-user-attendance-view">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-user-attendance-view">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Atendimento', url:'/user/attendance' },
                        {label:'Detalhes atendimento' }
                    ]}
                />
            </div>
            <div className="grid mt-4">
                <div className="col-12">
                    <strong>ID</strong>: {data?.user_ref}
                </div>
                <div className="col-12">
                    <strong>Cliente</strong>: {data?.name_user}
                </div>
                <div className="col-4">
                    <strong>Tipo</strong>: {data?.type}
                </div>
                <div className="col-4">
                    <strong>Início</strong>: { data?.start ? ` ${new Date(data?.start).toLocaleDateString()} ${new Date(data?.start).toLocaleTimeString()}` : '-'}
                </div>
                <div className="col-4">
                    <strong>Fim</strong>: { data?.end ? ` ${new Date(data?.end).toLocaleDateString()} ${new Date(data?.end).toLocaleTimeString()}` : '-'}
                </div>
                <div className="col-4">
                    <strong>Gerou caso</strong>: { ~~data?.generate_case === 1 ? 'Sim' : 'Não'}
                </div>
                <div className="col-8">
                    <strong>Casos</strong>: {data?.process_cases?.name || '-'}
                </div>
                <div className="col-4">
                    <strong>Área</strong>: {data?.area?.name || '-'}
                </div>
                <div className="col-8">
                    <strong>Responsável</strong>: {data?.attendance_user?.name || '-'}
                </div>
                <div className="col-12">
                    <div className='mb-2'><strong>Descrição</strong>: </div>
                    {data?.description || '-'}
                </div>
            </div>
            
        </div>
    )
}