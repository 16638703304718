export const ATTENDANCE_TYPE = [
    {name:'Primeiro atendimento', value:'Primeiro atendimento'},
    {name:'Retorno', value:'Retorno'},
    {name:'Entrada e saída de documentos', value:'Entrada e saída de documentos'}
];

export const ATTENDANCE_LOCATION = [
    {name:'Presencial', value:'Presencial'},
    {name:'On-line', value:'On-line'},
    {name:'Telefone', value:'Telefone'},
    {name:'Whatsapp', value:'Whatsapp'},
    {name:'E-mail', value:'E-mail'}
]