import React, { useState, useCallback, useEffect} from 'react';

import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { useNavigate } from 'react-router-dom';

export const PageDRE = () => {

    const navigate = useNavigate();

    const start = new Date();
    start.setDate(1);
    start.setMonth(0);

    const end = new Date();
    end.setDate(0);
    end.setMonth(11);


    const [calendar, setCalendar] = useState(start);
    const [calendarEnd, setCalendarEnd] = useState(end);
    const [data, setData] = useState(null);

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const selectPeriod = (e) =>{

        const start = new Date();
        start.setDate(1);
        start.setMonth(0);
        start.setFullYear(start.getFullYear()  - e);

        const end = new Date();
        end.setDate(0);
        end.setMonth(11);
        end.setFullYear(end.getFullYear()  - e);

        setCalendar(start);
        setCalendarEnd(end);
    }

    const getData = useCallback(async()=>{

        setData([]);
    }, [])

    useEffect(()=>{
        getData();
    }, [getData])


    if(!data){
        return (
            <div className="page-user-processcases-view">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )
    }

    return (
        <div className="page-dre">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'DRE'}
                    ]}
                />
            </div>
            <div className="header">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><>
                        <Calendar maxDate={new Date()} dateFormat="mm/yy"  showIcon locale='pt-BR' value={calendar} onChange={(e)=>setCalendar(e.value)} />
                        <Calendar minDate={calendar} className='ml-3 mr-3' dateFormat="mm/yy"  showIcon locale='pt-BR' value={calendarEnd} onChange={(e)=>setCalendarEnd(e.value)} />
                        <SplitButton onClick={()=>selectPeriod(0)} label="Este ano" className="p-button-raised"
                            model={[
                                {label:'Ano passado', command:()=>selectPeriod(1)},
                                {label:'Ano repassado', command:()=>selectPeriod(2)}
                            ]} 
                         />
 
                    </>} 
                    right={()=><>
                        <Button onClick={()=>{getData()}} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>{navigate('/user/attendance/create')}} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} 
                />
            </div>
            <div className="content">
                <DataTable 
                    value={data} 
                    responsiveLayout="scroll"
                    emptyMessage="Não há dados cadastrados"
                    size='small'
                    stripedRows
                >
                    <Column body={(row)=> {
                        return new Date(row.start).toLocaleDateString();
                    } } header="Data" />
                    
                    <Column body={(row)=>{
                        return <>
                            <Button onClick={()=>navigate(`/user/attendance/${row.id}`)} className='p-button-sm p-button-text' icon="pi pi-eye" />
                        </>
                    }} header="" />
                </DataTable>
            </div>
        </div>
    )
}