import React, { useState, useEffect, useCallback, useRef } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { api, URL_PARTNER_ALL } from '@/services/api';
import { useNavigate } from 'react-router-dom';
import { formatCPJCNPJ, formatMoney, formatShortName } from '@/services/utils';

export const PagePartner = () => {

    const navigate = useNavigate();

    const toast = useRef(null);

    const [users, setUsers] = useState([]);
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [showDetails, setShowDetails] = useState(false);
    const [detailsContent, setDetailsContent] = useState();

    const getData = useCallback(async () => {
        const data = await api.get(URL_PARTNER_ALL, false, true);
       // //console.log(data.data);

        setUsers(data?.data);
    }, [setUsers])

    const copyContent = (data) => {
        navigator.clipboard.writeText(data);
        toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Conteúdo copiado com sucesso'});
    }

    const clearFilter = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'cpf_cnpj': { value: null, matchMode: FilterMatchMode.STARTS_WITH }
        })
        setGlobalFilterValue('');
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters };
        _filters1['global'].value = value;

        setFilters(_filters1);
        setGlobalFilterValue(value);
    }

    const openDetail = (_data) => {
        const _html = <div>
            <div className="grid">
                <div className="col-12">
                    {_data.description}
                </div>
            </div>
        </div>
        setDetailsContent(_html);
        setShowDetails(true);
    }


    useEffect(()=>{
        getData();
    }, [getData])

    return (
        <div className="page-clients">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Parceiros'}
                    ]}
                />
            </div>
            <div className="header">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: 0}}
                    left={()=><>
                    </>} 
                    right={()=><>
                        <Button onClick={()=>getData()} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>navigate('/user/partner/create')} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} />
            </div>
            <div className="grid mt-4">
                <div className="col-12">
                    <DataTable 
                        value={users} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há clientes cadastrados"
                        size='small'
                        stripedRows
                        rows={10}
                        paginator={users?.length > 10}
                        filters={filters}
                        globalFilterFields={['user_ref', 'name', 'cpf_cnpj']}
                        header={()=>{
                            return <div className="flex justify-content-end">
                                        <span className="p-input-icon-left">
                                            <i className="pi pi-search" />
                                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Busca" />
                                        </span>
                                        <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-sm ml-2" onClick={clearFilter} />
                                    </div>
                        }}
                    >
                        <Column style={{width:'200px'}} body={(row)=> {
                            return <div style={{display:'flex', alignItems:'center'}}>
                             {
                                row?.cpf_cnpj ? 
                                    <>
                                        <span>{formatCPJCNPJ(row?.cpf_cnpj)} </span>
                                        <Button onClick={()=> copyContent(row?.cpf_cnpj)} className='p-button-sm p-button-text' icon='pi pi-copy' />
                                    </>
                                :
                                    '-'
                             }
                            </div>
                        }}
                        field="cpf_cnpj" header="CPF/CNPJ"
                        />
                        <Column field='type' header="Tipo" />
                        <Column field='segment' header="Segmento" />
                        <Column body={(row) => formatMoney(row.value)} field='value' header="Valor" />
                        <Column body={(row) => {
                            const datetime = new Date(`${row.date_paid} 00:00:00`);

                            return `${datetime.toLocaleDateString().substring(0, 5)}`;

                        }} field='date_paid' header="Data pagamento" />
                        <Column body={(row) => {
                            const datetime = new Date(row.created);

                            return `${datetime.toLocaleDateString()}`;

                        }} field='created' header="Cadastro" />

                        <Column body={(row) => {
                            return <>{~~row.active === 1 ? 'Sim' : 'Não'}</>

                        }} field='active' header="Ativo" />

                        <Column style={{width:'120px', textAlign:'right'}} body={(row)=>{
                            return <>
                                <Button className='p-button-sm p-button-text ml-2' onClick={()=>{ navigate(`/user/partner/edit/${row.id}`) }} icon="pi pi-pencil" />
                                <Button className='p-button-sm p-button-text' onClick={()=>openDetail(row)} icon="pi pi-book" />
                            </>
                        }}  header="" />
                    </DataTable>
                </div>
            </div>

            <Toast ref={toast} position="bottom-center" />

            <Dialog header="Observação" visible={showDetails} style={{ maxWidth: '90%', minWidth: '300px' }} footer={()=>{}} onHide={() => setShowDetails(false)}>
                {detailsContent}
            </Dialog>
        </div>
    )
}