import React, { useCallback, useState, useRef, useEffect } from 'react';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

 

import { Icon } from 'react-icons-kit';
import {male} from 'react-icons-kit/ionicons/male';
import {map} from 'react-icons-kit/ionicons/map';
import {female} from 'react-icons-kit/ionicons/female';
import {asterisk} from 'react-icons-kit/ionicons/asterisk'

import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { api, URL_USER_CREATE, URL_USER_TYPE } from '@/services/api';

import '@/assets/styles/pages/client/create/index.scss';

export const PageCollaboratorsCreate = (props) =>{

    const navigate = useNavigate();

    const bankpointer = useRef(1);

    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;



    const [listWorks, setListWorks] = useState([]);
    const [work, setWork] = useState();

    const [listBank, setListBank] = useState([]);
    const [bank, setBank] = useState();

    const [listUsersTypes, setListUsersTypes] = useState();

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);


    const onSubmit = useCallback(async (_data) => {
        
        const user_data = {
            user:{
                prefixe: _data?.prefixe,
                user_type_id: _data?.user_type,
                name: _data?.name,
                username: _data?.username,
                password: _data?.password
            },
            user_data:{
                birthday: _data?.birthday.toISOString().substr(0,10),
                cpf_cnpj: _data?.cpf_cnpj?.toString()?.replace(/[^0-9]/gi, ''),
                rg: _data?.rg?.toString()?.replace(/[^0-9a-z]/gi, ''),
                cnh: _data?.cnh?.toString(),
                military: _data?.military?.toString(),
                eleitoral_number: _data?.eleitoralNumber?.toString()?.replace(/[^0-9a-z]/gi, ''),
                marital_status: _data?.marital_status,
                genere: _data?.genere,
                pis: _data?.pis?.toString()?.replace(/[^0-9]/gi, ''),
                ctps: _data?.ctps?.toString()?.replace(/[^0-9]/gi, ''),
                ctps_serie: _data?.ctps_serie?.toString()?.replace(/[^0-9]/gi, ''),
                ctps_create: _data?.ctps_create,
                nationality: _data?.nationality,
                naturalness: _data?.naturalness,
                others: _data?.others,
                mother_name: _data?.mother_name,
                father_name: _data?.father_name,
                access_inss: _data?.access_inss,
                path_location: _data?.path_location,
                work: _data?.work
            },
            user_address:{
                name: _data?.address_name,
                address: _data?.address_address,
                number: _data?.address_number?.toString()?.replace(/[^0-9]/gi, ''),
                extra: _data?.address_extra,
                district: _data?.address_district,
                city: _data?.address_city,
                state: _data?.address_state,
                zipcode: _data?.address_zipcode?.toString()?.replace(/[^0-9]/gi, ''),
                reference: _data?.address_reference
            },
            user_contact:[
                {type:'phone', value: _data?.phone?.toString()?.replace(/[^0-9]/gi, ''), note: _data?.phone_note},
                {type:'mobile', value: _data?.mobile?.toString()?.replace(/[^0-9]/gi, ''), note: _data?.mobile_note},
                {type:'email', value: _data?.email, note: _data?.email_note}
            ],
            user_bank: listBank?.map(item =>{
                return (
                    {
                        name: item?.name,
                        type: item?.type,
                        agency: item?.agency?.replace(/\D/gi, ''),
                        count: item?.count?.replace(/\D/gi, ''),
                        pix: item?.pix

                    }
                )
            })
        }

        //console.log(user_data);

        const result = await api.post(URL_USER_CREATE, user_data, true);
        //console.log(result?.data);

        navigate('/user/collaborators');

    },[listWorks, props, navigate]);


    const addBank = useCallback((e)=>{
        e.preventDefault();
        const temp = [...listBank];
        const insert = {...bank};
        insert.id = bankpointer.current;
        bankpointer.current++;
        temp.push(insert);
        setBank('');
        setListBank(temp);
    }, [bank, listBank])

    const removeBank = useCallback((_data)=>{

        const newlist = listBank?.filter(item => ~~_data !== ~~item?.id);

        setListBank(newlist);

    }, [listBank]);


    const getCepData = useCallback(async (_data) => {
        const cep = _data?.replace(/\D/gi, '');
        if(cep?.length < 8)
            return;
        const data = await api.get(`https://viacep.com.br/ws/${cep}/json/`, null, false);

        //console.log(form);
        
        form.setValue('address_address', data?.logradouro);
        form.setValue('address_district', data?.bairro);
        form.setValue('address_city', data?.localidade);
        form.setValue('address_state', data?.uf);

    }, [form])

    const getAddressData = useCallback(async (_state, _city, _address) => {
        
        const state = _state?.replace(/^\s|\s$/gi,'');
        const city = _city?.replace(/^\s|\s$/gi,'');
        const address = _address?.replace(/^\s|\s$/gi,'').replace(/\s/gi,'+');

        const data = await api.get(`https://viacep.com.br/ws/${state}/${city}/${address}/json/`, null, false);

        form.setValue('address_zipcode', data[0]?.cep);
        form.setValue('address_address', data[0]?.logradouro);
        form.setValue('address_district', data[0]?.bairro);
        form.setValue('address_city', data[0]?.localidade);
        form.setValue('address_state', data[0]?.uf);

    }, [form])

    const searchAddress = useCallback(async (event) => {
        
        let addressFind = null;
        let stateFind = null;
        let cityFind = null;

        const accepctInfo = () => {
           getAddressData(stateFind, cityFind, addressFind);
        }


        confirmDialog({
            target: event.currentTarget,
            message: <>
                <p>Busque seu CEP</p>
                <div className="grid">
                    <div className="col-4">
                        <InputText onInput={(e)=>{
                            stateFind = e.target.value;
                        }} style={{width:'100%'}} placeholder='Estado' />
                    </div>
                    <div className="col-8">
                        <InputText onInput={(e)=>{
                            cityFind = e.target.value;
                        }} style={{width:'100%'}} placeholder='Cidade' />
                    </div>
                </div>
                <div>
                    <InputText onInput={(e)=>{
                        addressFind = e.target.value;
                    }} style={{width:'100%'}} placeholder='Rua' />
                </div>
            </>,
            acceptLabel: 'Buscar',
            rejectLabel:'Cancelar',
            accept: () => accepctInfo(),
            reject: () => null
        });
    }, [getAddressData])


    const getData = useCallback(async ()=>{
        const data = await api.get(URL_USER_TYPE, null, true);
        
        setListUsersTypes(data.data);

    }, [])

    useEffect(()=>{
        getData();
    }, [getData]);

    return (
        <div className="page-client-create">
            {
                !props?.removeBreadcrumb ?
                    <div className="breadcrump">
                        <UIBreadcrumb 
                            model={[
                                {label:'Início', url:'/user'},
                                {label:'Colaboradores', url:'/user/collaborators'},
                                {label:'Novo colaborador'}
                            ]}
                        />
                    </div>
                :
                    ''
            }
            <h3>
                Novo Colaborador
            </h3>
            <form onSubmit={handleSubmit(onSubmit)} >
                <p>
                    Dados pessoais
                </p>
                <div className="grid">
                <div className="col-2">
                        <Controller name={`prefixe`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-user" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='Prefixo' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-10">
                        <Controller name={`name`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-user" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='Nome completo' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`cpf_cnpj`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-credit-card" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='CPF/CNPJ' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`rg`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-id-card" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='RG' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`cnh`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-id-card" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='CNH' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`military`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-id-card" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='Certificado de reservista' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`pis`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-book" />
                                <InputText inputStyle={{width:'100%'}} placeholder='PIS' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`eleitoral_number`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-book" />
                                <InputText inputStyle={{width:'100%'}} placeholder='Título de eleitor' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`ctps`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-briefcase" />
                                <InputText inputStyle={{width:'100%'}} placeholder='CTPS' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-3">
                        <Controller name={`ctps_serie`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-briefcase" />
                                <InputText inputStyle={{width:'100%'}} placeholder='CTPS série' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-3">
                        <Controller name={`ctps_create`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-briefcase" />
                                <Calendar {...field} locale='pt-BR' dateFormat='dd/mm/yy' showIcon  inputStyle={{width:'100%'}} placeholder='CTPS criação' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`birthday`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                {/* <i className="pi pi-calendar" /> */}
                                <Calendar required locale='pt-BR' dateFormat='dd/mm/yy' showIcon inputStyle={{width:'100%'}} placeholder='Data nascimento' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-3">
                        <Controller name={`marital_status`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <Dropdown 
                                required
                                options={[
                                    {label:'Solteiro(a)', value:'solteiro(a)'},
                                    {label:'Casado(a)', value:'casado(a)'},
                                    {label:'Divorciado(a)', value:'divorciado(a)'},
                                    {label:'Viuvo(a)', value:'viuvo(a)'},
                                    {label:'União Estável', value:'União Estável'}
                                ]}
                                inputStyle={{width:'100%'}}  placeholder='Estado civil' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-3">
                        <Controller name={`genere`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <Dropdown 
                                    required
                                    options={[
                                        {label:'Masculino', value:'M'},
                                        {label:'Feminino', value:'F'}
                                    ]}
                                    inputStyle={{width:'100%'}}  placeholder='Gênero' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`nationality`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i><Icon icon={map}/></i>
                                <InputText inputStyle={{width:'100%'}} placeholder='Nacionalidade' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`naturalness`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i><Icon icon={map}/></i>
                                <InputText inputStyle={{width:'100%'}} placeholder='Naturalidade' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`father_name`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i><Icon icon={male}/></i>
                                <InputText inputStyle={{width:'100%'}} placeholder='Nome do pai' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`mother_name`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i><Icon icon={female}/></i>
                                <InputText inputStyle={{width:'100%'}} placeholder='Nome da mãe' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-12">
                        <Controller name={`others`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}}>
                                <InputTextarea autoResize inputStyle={{width:'100%'}} placeholder='Outros' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                </div>
                
                <div className="gird">
                    <div className="col-12">
                        <strong>
                            Dados bancários
                        </strong>
                    </div>
                    <div className="col-12">
                        <DataTable 
                            header={()=><>
                                <div className="grid">
                                    <div className="col-6">
                                        <InputText placeholder='Banco' style={{width:'100%'}} value={bank?.name} onChange={(e)=> {
                                            const t = {...bank};
                                            t.name = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-6">
                                        <InputText placeholder='Tipo (corrente, poupança)' style={{width:'100%'}} value={bank?.category} onChange={(e)=> {
                                            const t = {...bank};
                                            t.type = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-2">
                                        <InputText placeholder='Agência' style={{width:'100%'}} value={bank?.agency} onChange={(e)=> {
                                            const t = {...bank};
                                            t.agency = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-4">
                                        <InputText placeholder='Conta' style={{width:'100%'}} value={bank?.account} onChange={(e)=> {
                                            const t = {...bank};
                                            t.account = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-5">
                                        <InputText placeholder='PIX' style={{width:'100%'}} value={bank?.pix} onChange={(e)=> {
                                            const t = {...bank};
                                            t.pix = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                 
                                    <div className="col-1" style={{textAlign:'right'}}>
                                        <Button className='p-button-secundary' onClick={addBank} tooltip="Adicionar dados bancários" tooltipOptions={{position:'bottom'}} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </>}
                            value={listBank || []} 
                            responsiveLayout="scroll"
                            emptyMessage="Não há informações cadastradas"
                            size='small'
                            stripedRows
                            rows={10}
                            paginator={listBank?.length > 10}
                        >
                            <Column field="name" header="Nome" />
                            <Column field="type" header="Tipo" />
                            <Column field="agency" header="Agência" />
                            <Column field="account" header="Conta" />
                            <Column field="pix" header="PIX" />
                            <Column style={{width:'50px'}} body={(row)=> {
                                return <Button onClick={()=>removeBank(row.id)} icon="pi pi-times" className='p-button-danger p-button-sm p-button-text' />
                            }} header="" />
                        </DataTable>
                    </div>
                </div>
                <p>
                    Profissional
                </p>
                <div className="grid">
                    <div className="col-12">
                        <Controller name={`work`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-wallet" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='Profissão' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                </div>
                <p>
                    Contato
                </p>
                <div className="grid">
                    <div className="col-6">
                        <Controller name={`phone`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-phone" />
                                <InputText inputStyle={{width:'100%'}} placeholder='Telefone' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`phone_note`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <InputText inputStyle={{width:'100%'}} placeholder='Observação para telefone' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`mobile`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-mobile" />
                                <InputText inputStyle={{width:'100%'}} placeholder='Celular' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`mobile_note`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <InputText inputStyle={{width:'100%'}} placeholder='Observação para celular' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`email`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-envelope" />
                                <InputText inputStyle={{width:'100%'}} placeholder='E-mail' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value); }}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`email_note`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <InputText inputStyle={{width:'100%'}} placeholder='Observação para email' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                </div>
                <p>
                    Endereço
                </p>
                <div className="grid">
                    <div className="col-6">
                        <Controller name={`address_name`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-home" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='Nome (Casa, empresa, loja , outros)' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`address_zipcode`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <div className='grid'>
                                <div className="col-10">
                                    <span style={{width:'100%'}} className="p-input-icon-left">
                                        <i className="pi pi-map-marker" />
                                        <InputText required inputStyle={{width:'100%'}} placeholder='CEP' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value); getCepData(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                    </span>
                                </div>
                                <div className="col-2" style={{textAlign:'right'}}>
                                    <Button onClick={searchAddress} icon="pi pi-search" className="p-button-secondary"/>
                                    <ConfirmDialog />
                                </div>
                            </div>
                        )} />
                    </div>
                    <div className="col-12">
                        <Controller name={`address_address`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-globe" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='Endereço' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-3">
                        <Controller name={`address_number`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-globe" />
                                <InputText required style={{width:'100%'}} inputStyle={{width:'100%'}} placeholder='Número' autoComplete='off' className=''onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-3">
                        <Controller name={`address_extra`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <InputText inputStyle={{width:'100%'}} style={{width:'100%'}} placeholder='Complemento' autoComplete='off' className='' onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`address_district`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-globe" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='Bairro' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`address_city`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-globe" />
                                <InputText inputStyle={{width:'100%'}} placeholder='Cidade' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`address_state`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-globe" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='Estado' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-12">
                        <Controller name={`address_reference`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}}>
                                <InputTextarea autoResize inputStyle={{width:'100%'}} placeholder='Referência' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                </div>
                <p>
                    Acesso
                </p>
                <div className="grid">
                    <div className="col-4">
                        <Controller name={`username`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-lock" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='Usuário' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-4">
                        <Controller name={`password`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i><Icon icon={asterisk}/></i>
                                <InputText required inputStyle={{width:'100%'}} placeholder='Senha do sistema' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-4">
                        <Controller name={`user_type`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <Dropdown 
                                    required
                                    options={listUsersTypes}
                                    optionLabel="name"
                                    optionValue="id"
                                    inputStyle={{width:'100%'}}  placeholder='Tipo de acesso' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12 mt-4 mb-4" style={{textAlign:'right'}}>
                        <Button onClick={()=> navigate('/user/clients')} label="Cancelar" className='p-button-danger mr-2 p-button-sm' />
                        <Button label="Cadastrar" className='p-button-sm' />
                    </div>
                </div>
            </form>
            
        </div>
    )
}