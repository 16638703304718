import React, {useState, useEffect, useCallback} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Knob } from 'primereact/knob';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';

import { formatMoney } from '@/services/utils';

import { useNavigate } from 'react-router-dom';

import { api, URL_FINANCE_ALL } from '@/services/api';

export const PageFinanceCase = () => {

    const navigate = useNavigate();

    const [contentData, setContentData] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [selectedContent, setSelectedContant] = useState();

    const openContent = (_data) => {
        setSelectedContant(_data);
        setShowDialog(true);
    }

    const getContentData = useCallback(async ()=>{
        const data = await api.get(URL_FINANCE_ALL,null, true);
        setContentData(data?.data);
    }, [setContentData])
    
    useEffect(()=>{
        setContentData(null);
        getContentData();
    },[getContentData])

    if(!contentData)
        return (
            <div className="page-finance-flow">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-finance-case">
            <div className="grid">
                <div className="col-12">
                    <DataTable 
                        value={contentData} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há lançamentos cadastrados"
                        size='small'
                        stripedRows
                    >
                        <Column body={(row)=> {
                            return <div style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/processcases/${row?.process_cases?.id}`)}>{row?.process_cases?.name}</div>;
                        } } header="Caso" />
                        <Column body={(row)=> {
                            return row.process_cases.user_name || '-';
                        } } header="Cliente" />
                        <Column style={{width:'60px'}} body={(row)=> {
                                return `${~~row?.paids?.filter(item => ~~item?.ispayment === 1).length}/${~~row?.paids?.length}`;
                            } } header="Parcela(s)" />
                        <Column body={(row)=> {
                            return formatMoney(row?.paid);
                        } } header="Pago" />
                        <Column body={(row)=> {
                            return formatMoney(row?.total);
                        } } header="Total" />
                        <Column body={(row)=> {
                            return row?.timemodified ? `${new Date(row?.timemodified).toLocaleDateString()} - ${new Date(row?.timemodified).toLocaleTimeString()}` : '-';
                        } } header="última atualização" />
                        <Column style={{width:'50px', textAlign:'center'}} body={(row)=> {
                            const percent = Math.round(parseFloat(row?.paid) * 100 / parseFloat(row?.total));
                            return <Knob size={40} strokeWidth={10} valueTemplate={"{value}%"} value={percent} />
                             ;
                        } } header="" />
                        <Column style={{width:'120px'}} body={(row)=> {
                            return <>
                                <Button onClick={()=>{openContent(row)}} className='p-button-text p-button-info mr-1' icon='pi pi-book' />
                                <Button onClick={()=>navigate(`/user/processcases/${row.process_cases_id}`, {state:{tabActive: 4}})} className='p-button-text p-button-info' icon='pi pi-eye' />
                            </>;
                        } } header="" />
                    </DataTable>
                </div>
            </div>
            <Dialog header="Detalhes" visible={showDialog} style={{ width: '90vw' }} footer={null} onHide={() => setShowDialog(false)}>
                <div className="grid">
                    <div className="col-12">
                        <DataTable 
                            value={selectedContent?.paids} 
                            responsiveLayout="scroll"
                            emptyMessage="Não há lançamentos cadastrados"
                            size='small'
                            stripedRows
                            footer={()=>{
                                return <div className='grid mt-2'>
                                    <div className="col-12" style={{textAlign:'right'}}>
                                        <strong className='mr-2'>
                                            A receber:
                                        </strong>
                                        {formatMoney(selectedContent?.paids?.reduce((c, n) =>{
                                            return c+= parseFloat(n.value)
                                        }, 0))}
                                    </div>
                                    <div className="col-12" style={{textAlign:'right'}}>
                                        <strong className='mr-2'>Total recebido:</strong>
                                        {formatMoney(selectedContent?.paids?.reduce((c, n) =>{
                                            if(~~n.ispayment === 1){
                                                return c+= parseFloat(n.value)
                                            }else{
                                                return c;
                                            }
                                        }, 0))}
                                    </div>
                                </div>
                            }}
                        >
                            <Column style={{width:'120px'}} body={(row)=> {
                                return new Date(row.date).toLocaleDateString();
                            } } header="Vencimento" />
                            <Column style={{width:'120px'}} body={(row)=> {
                                return row.timepaid ? new Date(row.timepaid).toLocaleDateString() : '-';
                            } } header="Pagamento" />
                            <Column style={{width:'60px'}} body={(row)=> {
                                return ~~row.ispayment === 1 ? 'Sim' : 'Não';
                            } } header="Pago" />
                            <Column body={(row)=> {
                                return row.value ? formatMoney(row.value) : '-';
                            } } header="Valor" />
                            <Column style={{width:'150px'}} body={(row)=> {
                                const now = new Date();
                                const limit = new Date(row.date);
                                const isValid =  limit > now;
                                return <Tag style={{width:'100%'}} severity={~~row?.ispayment === 1 ? 'success' : (isValid ? 'Warning' : 'danger')} value={~~row?.ispayment === 1 ? 'Pago' : (isValid ? 'Em aberto' : 'Atrasado')} />
                            } } header="Status" />
                            <Column body={(row)=> {
                                return row.attendance_area || '-';
                            } } header="Área" />
                            <Column style={{width:'60px', textAlign:'center'}} body={(row)=> {
                                return ~~row.ispayment === 1 ? <Button onClick={()=>{}} icon="pi pi-print" className="p-button-secondary p-button-text" /> : <Button onClick={()=>{}} icon="pi pi-dollar" className="p-button-help p-button-text" />; 
                                
                            } } header="" />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}