import React, {useState, useEffect, useMemo, useCallback, useRef} from "react";

import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { Divider } from 'primereact/divider';
 
 

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { PageCalendarNotificationResume } from "./resume";
import { PageCalendarNotificationManager } from "./manager";

import { useForm, Controller } from 'react-hook-form';


import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import '@/assets/styles/pages/calendarnotification/index.scss';
import { URL_CALENDAR_NOTIFICATION_CREATE, URL_CALENDAR_NOTIFICATION_MODE, URL_CALENDAR_NOTIFICATION_MODE_DATA, api } from "@/services/api";

export const PageCalendarNotification = () => {

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);


    const [activeIndex, setActiveIndex] = useState(0);
    const [contentData, setContentData] = useState();
    const [isAllTime, setIsAllTime] = useState(true);

    const [listMode, setListMode] = useState();
    const [currentMode, setCurrentMode] = useState();

    const [listModeData, setListModeData] = useState();

    const [requesLoad, setRequestLoad] = useState(false);

    const [updatePage, setUpdatePage] = useState(0);

    const [currentDate, setCurrentDate] = useState(new Date());

    const toast = useRef(null);

    const form = useForm({time: new Date().toLocaleTimeString()});
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const tabmodel = useMemo(()=> [
        {label: 'Resumo', componet: <PageCalendarNotificationResume currentDate={currentDate} key={`content-data-${updatePage}`} />, params: {status: 0}},
        {label: 'Gerenciar', componet: <PageCalendarNotificationManager currentDate={currentDate} key={`content-data-${updatePage}`} />,  params: {status: 1}}
    ], [currentDate])

    const getContentData = useCallback(async (_data)=>{
        setContentData(tabmodel[_data].componet)
    }, [tabmodel])

    const onSubmit = useCallback(async (_data) => {
        setRequestLoad(true);

        const datetimer = _data.date;

        if(isAllTime){
            datetimer.setHours(0);
            datetimer.setMinutes(0);
            datetimer.setSeconds(0);
        }else{
            datetimer.setHours(_data.time.getHours());
            datetimer.setMinutes(_data.time.getMinutes());
            datetimer.setSeconds(_data.time.getSeconds());
        }

        const senddata = {
            datetimer: `${datetimer.toISOString().substr(0, 10)} ${datetimer.toLocaleTimeString()}`,
            title: _data.title,
            description: _data.description,
            mode: currentMode,
            modevalue: JSON.stringify({data:_data.mode_data})
        }

        const rs = await api.post(URL_CALENDAR_NOTIFICATION_CREATE, senddata, true);

        reset({});
        setIsAllTime(true);
        setUpdatePage(new Date().getTime());
        setRequestLoad(false);

        toast.current.show([{severity:'success', summary:`Sucesso`, detail:'Lembrete cadastrado com sucesso.', life: 3000}]);

    }, [reset, currentMode, isAllTime])

    const onSubmitErros = useCallback((_data)=>{
        //console.log(_data);

        

        const erros = Object.keys(_data)?.map(item => {
            return ({severity:'error', summary:`Campo obrigatório`, detail:_data[item].message, life: 3000})
        })

        toast.current.show(erros);
    }, [])

    const getDataMode = useCallback(async () => {
        const data = await api.get(URL_CALENDAR_NOTIFICATION_MODE, null, true);
        setListMode(data.data);
    }, [setListMode])

    const getDataModeData = useCallback(async (_data) => {
        const data = await api.get(`${URL_CALENDAR_NOTIFICATION_MODE_DATA}/${_data}`, null, true);
        setListModeData(data.data);
    }, [setListModeData])

    useEffect(()=>{
        
        setListModeData([]);

        form.resetField('mode_data');

        if(currentMode){
            const _mode = listMode.filter(item => ~~item.id === ~~currentMode)[0];
            getDataModeData(_mode?.action);
        }

    }, [currentMode, getDataModeData])

    useEffect(()=>{
        getDataMode();
    }, [getDataMode])

    useEffect(()=>{
        getContentData(activeIndex);
    },[activeIndex, getContentData])

    useState(()=>{
        if(updatePage !== 0){
            getContentData();
        }
}, [updatePage])

    return (
        <div className="page-calendar-notification">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'lembrete'}
                    ]}
                />
            </div>
            <div className="header mt-2 mb-2">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><></>} 
                    right={()=><>
                        <Button onClick={()=>{
                            setUpdatePage(new Date().getTime())
                        }} icon="pi pi-sync" className="p-button-warning p-button-text" />
                    </>} 
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit, onSubmitErros)} >
                <div className="grid mt-2 mb-2">
                    <div className="col-12">
                        <h3>
                            Adicionar novo lembrete
                        </h3>
                    </div>
                    <div className="col-6">
                    <Dropdown 
                        filter
                        options={listMode}
                        optionLabel="name"
                        optionValue="id"
                        inputStyle={{width:'100%'}}  placeholder='Selecione o modo' style={{width:'100%'}} onChange={(e) => {setCurrentMode(e.value);}} value={currentMode}  />
                    </div>
                    <div className="col-6">
                        <Controller name={`mode_data`} defaultValue="" rules={{ required: 'Lista de envio' }} control={control}  render={({ field, fieldState }) => (
                            <MultiSelect 
                                filter
                                optionLabel="name"
                                optionValue="id"
                                multiple
                                options={listModeData}
                                inputStyle={{width:'100%'}}  placeholder='Selecione a lista de envio' className='' style={{width:'100%'}} onChange={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`date`} defaultValue=""  rules={{ required: 'Data' }} control={control}  render={({ field, fieldState }) => (
                                <Calendar minDate={new Date()} inputStyle={{width:'100%'}} style={{width:'100%'}} placeholder='Data' dateFormat="dd/mm/yy" showIcon locale='pt-BR' onChange={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value} />
                        )} />
                    </div>
                    <div className="col-3" style={{display:'flex', alignItems:'center'}}>
                        <Checkbox  
                            value={'allday'}
                            inputId={'alltime'} 
                            onChange={(e) => {
                               setIsAllTime(e.target.checked);
                            }}  
                            checked={isAllTime} 
                        />
                        <label htmlFor={'alltime'} className="p-checkbox-label ml-2">Marcar para início do dia?</label>
 
                    </div>
                    <div className="col-3">
                        <Controller name={`time`} defaultValue={new Date()}  rules={ !isAllTime ? { required: 'Horário' } : {}} control={control}  render={({ field, fieldState }) => (
                                <Calendar showIcon disabled={isAllTime} showTime timeOnly inputStyle={{width:'100%'}} style={{width:'100%'}} placeholder='Horário' locale='pt-BR' onChange={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value} />
                        )} />
                    </div>
                    <div className="col-12">
                        <Controller name={`title`} defaultValue="" rules={{ required: 'Título' }} control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}}>
                                <InputText autoResize inputStyle={{width:'100%'}} placeholder='Título' autoComplete='off' className='' style={{width:'100%'}} onChange={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-12">
                        <Controller name={`description`} defaultValue="" rules={{ required: 'Descrição' }} control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}}>
                                <InputTextarea autoResize inputStyle={{width:'100%'}} placeholder='Descrição' autoComplete='off' className='' style={{width:'100%'}} onChange={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                </div>
                <div className="grid">
                        <div className="col-12 mt-1 mb-4" style={{textAlign:'right'}}>
                            <Button loading={requesLoad} label="Cadastrar" className='p-button-sm' />
                        </div>
                    </div>
            </form>
            <Divider />
            <div className="grid">
                <div className="col-8" style={{ display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <strong>Data de referência:</strong>
                </div>
                <div className="col-4">
                    <Calendar style={{width:'100%'}} placeholder='Data' dateFormat="dd/mm/yy" showIcon locale='pt-BR' onChange={(e) => {setCurrentDate(e.target.value);}}  value={currentDate} />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <TabMenu 
                        activeIndex={activeIndex}
                        onTabChange={(e)=>setActiveIndex(e.index)}
                        model={tabmodel} />
                    </div>
            </div>
            <div className="gird mt-4" key={`render-update-${updatePage}`}>
                <div className="col-12" >
                    {contentData}
                </div>
            </div>
            <Toast ref={toast} position="bottom-right" />
        </div>
    )
}