import React, { useState, useCallback, useEffect, useRef } from 'react';

import { SelectButton } from 'primereact/selectbutton';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm, Controller } from 'react-hook-form';
import { Toast } from 'primereact/toast';

import { UICollaboratorCalendar } from '@/components/ui/collaboratorcalendar';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { useNavigate } from 'react-router-dom';
import { URL_CLIENT_SEARCH, api, URL_USER_JURIDIC , URL_PROCESSCASES_SEARCH, URL_CALENDAR_CREATE } from '@/services/api';
import { useAPP } from '@/services/provider/app';

export const PageUserCalendarCreate = () => {

    const navigate = useNavigate();

    const {user } = useAPP();

    const [isClient, setIsClient] = useState(true);
    const [client, setClient] = useState();
    const [clientSearch, setClientSearch] = useState();
    const [clientList, setClientList] = useState();
    const [isCase, setIsCase] = useState(true);
    const [processCasesList, setProcessCasesList] = useState();
    const [isAdm, setIsAdm] = useState();
    

    const [caseData, setCaseData] = useState();

    const [selectedQuestion, setSeletedQuestion] = useState(null);
    const [selectedDatetime, setSeletedDateTime] = useState(null);
    const [selectedJuridcs, setSelectedJuridics] = useState(null);
    
    const [juridcs, setJuridics] = useState(null);
    const [juridcsList, setJuridicsList] = useState(null);

    const [isQUestions, setIsQuestion] = useState(true);

    const [calendarSelected, setCalendarSelected] = useState();

    const toast = useRef(null);

    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;

    const confirmData = (_data) => {
        setCalendarSelected(_data);
    }

    const searchClient = useCallback(async (_data) => {
        if(_data?.query.length >= 3){
            const data = await api.get(`${URL_CLIENT_SEARCH}?data=${_data?.query}`, null, true);
            data.data = data?.data?.map(item => {
                item.label = `${item?.name} ${item?.user_ref ? ` - ${item?.user_ref}` : ''} ${item?.cpf_cnpj ? ` - ${item?.cpf_cnpj}` : ''}`;
                return item;
            })
            
            setClientList(data.data);
        }
    }, []);

    const searchProcessCases = useCallback(async (_data) => {
        const data = await api.get(`${URL_PROCESSCASES_SEARCH}`, {..._data}, true);
            //console.log('$', data.data);
            data.data = data?.data?.map(item => {
                item.label = `${item?.name} - ${item?.number ? item?.number : ' - '}`;
                return item;
            })
            
            setProcessCasesList(data.data);
    }, []);

    const getData = useCallback(async () => {
        const data = await api.get(URL_USER_JURIDIC, null, true);
        setJuridicsList(data.data);
    }, [])


    useEffect(()=>{
        if(caseData){
            const dataTemp = processCasesList?.filter(item => ~~item.id === ~~caseData);
            const userid = dataTemp[0]?.user_id;
            if(userid){
                form.setValue('adm_id', userid);
                setIsAdm(userid);
            }
        }

    }, [caseData, processCasesList,form])

    const onSubmit = useCallback(async(data)=>{
       console.log(calendarSelected?.room_meeting_id, calendarSelected?.room_meeting_id instanceof Array);


       if(calendarSelected?.room_meeting_id instanceof Array){

        const requests = [];

        calendarSelected?.room_meeting_id.map(item => {
            let datasubmit = {}
        
            if(isClient){
                datasubmit.client_id = client?.id
            }else{
                datasubmit.noclient_name = data?.name_user
                datasubmit.noclient_contact = data?.tel_user
            }
    
            if(isCase){
                datasubmit.process_cases_id = caseData
            }
    
            datasubmit.user_id = data?.adm_id;
            datasubmit.location = 'local';
            datasubmit.create_by = user.id;
            datasubmit.note = data.description;
    
            const preview = new Date(`${calendarSelected?.date?.toISOString()?.substr(0, 10)} ${calendarSelected?.time}`);
            preview.setMinutes(preview.getMinutes() + ~~calendarSelected?.attendance);
    
            datasubmit.date = calendarSelected?.date;
            datasubmit.time = calendarSelected?.time;
            datasubmit.timepreview = preview.toLocaleTimeString();
            datasubmit.room_meeting_id = ~~item;
            
            
            const result = api.post(URL_CALENDAR_CREATE, datasubmit, true);

            requests.push(result);

        })

        await Promise.all(requests);

       }else{

           let datasubmit = {}
        
            if(isClient){
                datasubmit.client_id = client?.id
            }else{
                datasubmit.noclient_name = data?.name_user
                datasubmit.noclient_contact = data?.tel_user
            }
    
            if(isCase){
                datasubmit.process_cases_id = caseData
            }
    
            datasubmit.user_id = data?.adm_id;
            datasubmit.location = 'local';
            datasubmit.create_by = user.id;
            datasubmit.note = data.description;
    
            const preview = new Date(`${calendarSelected?.date?.toISOString()?.substr(0, 10)} ${calendarSelected?.time}`);
            preview.setMinutes(preview.getMinutes() + ~~calendarSelected?.attendance);
    
            datasubmit.date = calendarSelected?.date;
            datasubmit.time = calendarSelected?.time;
            datasubmit.timepreview = preview.toLocaleTimeString();
            datasubmit.room_meeting_id = calendarSelected.room_meeting_id;
            
            //console.log(datasubmit);
            
            const result = await api.post(URL_CALENDAR_CREATE, datasubmit, true);
    
            //if(result?.data){
                //}
            }
            
            toast.current.show({severity:'success', summary: 'Sucesso', detail:'Dados Salvos com sucesso', life: 3000});
            navigate('/user/calendar');
    

    }, [client, isCase, isClient, calendarSelected, caseData, user, navigate]);

    useEffect(()=>{
        //console.log(isAdm);
    }, [isAdm])

    useEffect(()=>{
        if(isCase && client){
            searchProcessCases({client_id: client?.id});
        }
    }, [isCase, searchProcessCases, client])

    useEffect(()=>{
        getData();
    }, [getData])

    return (
        <div className="page-user-calendar-create">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Agenda', url: '/user/calendar'},
                        {label:'Agendamento online'}
                    ]}
                />
                <h3>
                    Agendamento online
                </h3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid">
                <div className="col-12">
                    <strong>É um cliente?</strong>
                </div>
                <div className="col-12">
                    <SelectButton
                        unselectable={false} 
                        value={isClient} 
                        options={[
                            {label:'Não', value: false},
                            {label:'Sim', value: true}
                        ]} 
                        onChange={(e) => setIsClient(e.value)} />
                </div>
                <>
                    {
                        isClient ? 
                            <>
                                <div className="col-12">
                                    <span style={{width:'100%'}} className="p-input-icon-left">
                                        <i className="pi pi-search" />
                                        <AutoComplete 
                                            field='label' 
                                            suggestions={clientList} 
                                            completeMethod={searchClient} 
                                            required 
                                            inputStyle={{width:'100%'}} 
                                            placeholder='Buscar cliente' 
                                            className='' style={{width:'100%'}} 
                                            onChange={(e) => {setClientSearch(e.value);}}  
                                            id='searchClient' 
                                            value={clientSearch}  
                                            onSelect={(e)=> setClient(e.value)}
                                            minLength={3}
                                        />
                                    </span>
                                </div>
                            </>
                        :
                            <>
                                <div className="col-6">
                                    <Controller name={`name_user`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                        <span style={{width:'100%'}} className="p-input-icon-left">
                                            <i className="pi pi-user" />
                                            <InputText required inputStyle={{width:'100%'}} placeholder='Nome' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name} {...field} value={field.value}  />
                                        </span>
                                    )} />
                                </div>
                                <div className="col-6">
                                    <Controller name={`tel_user`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                        <span style={{width:'100%'}} className="p-input-icon-left">
                                            <i className="pi pi-phone" />
                                            <InputText type="tel" maxLength={11} required inputStyle={{width:'100%'}} placeholder='Telefone' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value.toString().replace(/\D/gi,''));}}  id={field.name} {...field} value={field.value}  />
                                        </span>
                                    )} />
                                </div>
                            </>
                    }

                </>

                {
                    isClient ?
                        <>
                            <div className="col-12">
                                <strong>Existe caso?</strong>
                            </div>
                            <div className="col-12">
                                <SelectButton
                                    unselectable={false} 
                                    value={isCase} 
                                    options={[
                                        {label:'Não', value: false},
                                        {label:'Sim', value: true}
                                    ]} 
                                    onChange={(e) => setIsCase(e.value)} />
                            </div>
                            {
                                isCase ?
                                    <>
                                        <div className="col-12">
                                            <Controller name={`process_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                                <Dropdown placeholder='Processo e Casos' filter {...field} options={processCasesList} optionLabel="name" optionValue="id" style={{width:'100%'}} onChange={(e) => {setCaseData(e.target.value); field.onChange(e.target.value); }}  id={field.name}  value={field.value} />
                                            )} /> 
                                        </div>
                                    </>
                                :
                                    <>
                                        
                                    </>
                            }
                        </>
                    :
                        <>
                            
                        </>
                }
                <div className="col-12">
                    <Controller name={`adm_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                        <Dropdown {...field} placeholder='Responsável' filter  options={juridcsList} optionLabel="name" optionValue="id" style={{width:'100%'}} onChange={(e) => {setIsAdm(e.target.value); field.onChange(e.target.value); }}  id={field.name}  value={field.value}  />
                    )} /> 
                </div>
                
                {isAdm ?
                    <div className="col-12">
                        <UICollaboratorCalendar 
                            selectedData={selectedDatetime}
                            updateSelectedData={setSeletedDateTime}
                            juridics={isAdm}
                            updateSelectJuridics={setSelectedJuridics}
                            confirmData={confirmData}

                        />
                    </div>
                    :
                    <></>
                }
                <div className="col-12">
                    <Controller name={`description`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                        <InputTextarea {...field} autoResize required inputStyle={{width:'100%', minHeight:'300px'}} placeholder='Descreava o assunto' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name} value={field.value}  />
                    )} />
                </div>
                <div className="col-12 mt-4" style={{textAlign:'right'}}>
                    <Button onClick={()=>{navigate('/user/calendar')}} className='p-button-danger p-button-text mr-2' label="Cancelar atendimento"/>
                    <Button  className='p-button-warning' label="Agendar"/>
                </div>
            </div>
            </form>
            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}