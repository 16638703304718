import React, {useState, useEffect, useCallback, useRef} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';
import { Avatar } from 'primereact/avatar';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { api, URL_COLLABORATORS, URL_FILES_FILE } from '@/services/api';
import { useNavigate } from 'react-router-dom';
import { formatCPJCNPJ } from '@/services/utils';

export const PageCollaborators = () => {

    const navigate = useNavigate();

    const toast = useRef(null);

    const [users, setUsers] = useState([]);
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');


    const clearFilter = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'user_ref': { value: null, matchMode: FilterMatchMode.STARTS_WITH }
        })
        setGlobalFilterValue('');
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters };
        _filters1['global'].value = value;

        setFilters(_filters1);
        setGlobalFilterValue(value);
    }

    const openProfile = (user) => {
        navigate(`/user/collaborator/${user}`);
    }

    const getData = useCallback(async () => {
        const data = await api.get(URL_COLLABORATORS, false, true);
        //console.log(data.data);

        setUsers(data?.data);
    }, [setUsers])

    useEffect(()=>{
        getData();
    }, [getData])

    return (
        <div class="page-collaborators">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Colaboradores'}
                    ]}
                />
            </div>
            <div className="header">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: 0}}
                    left={()=><>
                    </>} 
                    right={()=><>
                        <Button onClick={()=>getData()} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>navigate('/user/collaborators/create')} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} />
            </div>
            <div className="grid mt-4">
                <div className="col-12">
                    <DataTable 
                        value={users} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há clientes cadastrados"
                        size='small'
                        stripedRows
                        rows={10}
                        paginator={users?.length > 10}
                        filters={filters}
                        globalFilterFields={['name', 'cpf_cnpj']}
                        header={()=>{
                            return <div className="flex justify-content-end">
                                        <span className="p-input-icon-left">
                                            <i className="pi pi-search" />
                                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Busca" />
                                        </span>
                                        <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-sm ml-2" onClick={clearFilter} />
                                    </div>
                        }}
                    >
                        <Column style={{width: '50px'}} body={(row)=>{
                            return row?.user_data?.avatar_url ? <Avatar image={`${URL_FILES_FILE}/?file=${row?.user_data.avatar_url}`} shape='circle' /> :  <Avatar icon="pi pi-user"  shape='circle' />
                        }} header="" />
                        <Column body={(row)=>{
                            return <>{row?.prefixe ? `${row?.prefixe}. ` : ''}{row?.name}</>
                        }} sortable field="name" header="Nome" />
                        <Column style={{width: '150px'}} body={(row)=>{
                            return <>{ row?.user_data?.cpf_cnpj ? formatCPJCNPJ(row?.user_data?.cpf_cnpj) : '-'}</>
                        }} header="CPF" />
                        <Column body={(row)=>{
                            
                            return <>{ row?.user_area?.map(a => a.name).join(',') || '-'}</>
                        }} header="Setor" />
                        <Column style={{width: '100px'}} body={(row)=>{
                            return <>
                                {~~row?.active === 1 ? 'Ativo' : 'Inativo'}
                            </>
                        }} header="Status" />
                        {/* <Column body={(row)=>{
                            return <>{row?.user_data?.function || '-'}</>
                        }} header="Função" /> */}
                        <Column style={{width:'100px', textAlign:'right'}} body={(row)=>{
                            return <Button className='p-button-sm p-button-text' onClick={()=>openProfile(row.id)} icon="pi pi-eye" />
                        }}  header="" />
                    </DataTable>
                </div>
            </div>

            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}