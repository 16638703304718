import React, {useCallback, useState, useEffect, useRef} from 'react';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { TabMenu } from 'primereact/tabmenu';
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
 
 

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { useForm, Controller } from 'react-hook-form';

import { ATTENDANCE_LOCATION, ATTENDANCE_TYPE } from '@/services/provider/attendance';
import { useAPP } from '@/services/provider/app';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageClientCreate } from '@/pages/client/create';
import { URL_CLIENT_SEARCH, api, URL_ATTENDANCE_CREATE, URL_ATTENDANCE_AREAS, URL_PROCESSCASES_SEARCH, URL_PROCESSCASES_PROCESS_CREATE, URL_PROCESSCASES_BASE_TITLE } from '@/services/api';

export const PageUserAttendanceCreate = (props) => {


    const navigate = useNavigate();
    const params = useLocation();

    let clientType = 0;
    if(params?.state?.calendar?.client_name){
        clientType = 2;
    }

    const { user } = useAPP();
    const toast = useRef(null);
    const start = useRef();
    start.current = start.current || new Date();

    const [isCase, setIsCase] = useState(false);
    const [isCaseExist, setIsCaseExist] = useState(false);
    const [othersPeople, setOthersPeoples] = useState([]);
    const [typeOtherPeople, setTypeOtherPeople] = useState();
    const [useClientOtherPeople, setUseClientOtherPeople] = useState();
    const [nameOtherPeople, setNameOtherPeople] = useState();
    const [extraOtherPeople, setExtraOtherPeople] = useState();
    const [userType, setUserType] = useState(clientType);
    const [printContentType, setPrintContentType] = useState();
    const [showFormClient, setShowFormClient] = useState(false);

    const [client, setClient] = useState();
    const [clientSearch, setClientSearch] = useState();
    const [clientList, setClientList] = useState();
    const [areaSearch, setAreaSearch] = useState();
    const [areasList, setAreasList] = useState();
    const [processCasesList, setProcessCasesList] = useState();
    const [casesList, setCasesList] = useState();

    const [titleData, setTitleData] = useState();
    const [currentArea, setCurrentArea] = useState([]);
    const [currentTypeCase, setCurrentTypeCase] = useState([]);

    const defaultData = {
        start: `${start.current.toISOString().substring(0, 10)} ${start.current.toLocaleTimeString()}`,
        attendance_user_id: user?.id
    };

    if(props?.user_id){
        defaultData.user_id = props?.user_id;
    }

    if(props?.process_cases_id){
        defaultData.process_cases_id = props?.process_cases_id;
    }

    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    //const handleErros = form.formState.errors;

    // const onErros = useCallback(() => {
    //     //console.log(form.formState.errors);
    // }, [])

    const onSubmit = useCallback(async (_data) => {

        //console.log('Submit ');

        const erros = [];

        if(!client){
            let isErro = true;

            if(userType === 0){
                if(_data?.name_user && _data?.user_ref)
                    isErro = false;
            }
            
            if(isErro)
                erros.push('Cliente não selecionado');
        }

        if(!_data.type){
            erros.push('Tipo de atendimento não selecionado');
        }

        if(!_data.location){
            erros.push('Local do atendimento não selecionado');
        }

        if(!_data.description){
            erros.push('Descreva o atendimento');
        }


        const userTemp = client ? {...client} : {id: _data?.id, name: _data?.name_user, cpf_cnpj: _data?.user_ref, tel_user: _data?.tel_user, email_user: _data?.email_user};

        //console.log('Client', userTemp);

        if(erros.length > 0){
            //console.log(erros);
            const _html = <ul>
                {
                    erros?.map((item, id) => {
                        return  <li key={`erros_${id}`}>{item}</li>
                    })
                }
            </ul>

            toast.current.show({severity:'error', summary: 'Erro', detail:_html, life: 3000});
            
            return;
        }

        const end = new Date();


        let attendancedata = {
            attendance:{
                location:_data.location,
                description: _data.description,
                type: _data.type,
                user_id: userTemp?.id,
                name_user: userTemp?.name,
                user_ref: userTemp?.cpf_cnpj,
                tel_user: userTemp?.tel_user,
                email_user: userTemp?.email_user,
                start: `${start.current.toISOString().substr(0, 10)} ${start.current.toLocaleTimeString()}`,
                end: `${end.toISOString().substr(0, 10)} ${end.toLocaleTimeString()}`,
                generate_case: isCase,
                attendance_user_id: user?.id,
                area: _data.area

            },
            case: null,
            parts: null
        }

        if(isCaseExist){
            attendancedata.attendance.process_cases_id = _data.case.id;
        }

        if(!isCase){
            delete attendancedata.case;
        }else{
            const _title =  titleData?.process_cases_type_case.filter(item => ~~item.id === ~~_data.title_type_case)[0]?.name;

            attendancedata.parts = [...othersPeople]
            attendancedata.case ={
                name: _title,
                description: _data.case_description,
                client_id: userTemp?.id,
                user_id: user?.id
            }
        }

        //console.log('Finaliza atendimento', attendancedata);

        const result = await api.post(URL_ATTENDANCE_CREATE, attendancedata, true);
        //console.log(result.data);

        if(result){
            toast.current.show({severity:'success', summary: 'Sucesso', detail:'Dados Salvos com sucesso', life: 3000});
            if(isCase){
                navigate('/user/attendance');
            }else{
                navigate('/user/attendance');
            }
        }else{
            toast.current.show({severity:'error', summary: 'Erro', detail:'Houve problemas ao salvar os dados. Tente novamente.', life: 3000});
        }
        
    },[client, userType, user, isCase, navigate, isCaseExist, othersPeople]);

    const updateCreateClient = useCallback(async(_data = null)=>{
        //console.log('Usuario criado');
    }, []);

    const addOtherPeople = (e) =>{
        e.preventDefault();

        let name = nameOtherPeople;
        name = name?.replace(/^[\s]|[\s]$/gi,'')

        if(name?.length === 0){
            return;
        }

        const temp  = [...othersPeople];
        temp.push({id:(new Date()).getTime(), name:name, type: typeOtherPeople, description: extraOtherPeople});
        //console.log(typeOtherPeople);
        //console.log(temp);
        setOthersPeoples(temp);
        setNameOtherPeople('');
        setTypeOtherPeople('');
        setExtraOtherPeople('');
        setUseClientOtherPeople(false);
    }

    const removeOtherPeople = (_id) => {
        let temp  = [...othersPeople];

        temp = temp.filter(item => item.id !== _id);

        setOthersPeoples(temp);
    }

    const searchClient = useCallback(async (_data) => {
        if(_data?.query.length >= 3){
            const data = await api.get(`${URL_CLIENT_SEARCH}?data=${_data?.query}`, null, true);
            data.data = data?.data?.map(item => {
                item.label = `${item?.name} ${item?.user_ref ? ` - ${item?.user_ref}` : ''} ${item?.cpf_cnpj ? ` - ${item?.cpf_cnpj}` : ''}`;
                return item;
            })
            
            setClientList(data.data);
        }
    }, []);

    const searchAreas = useCallback(async (_data) => {
        if(_data?.query.length >= 3){
            const data = await api.get(`${URL_ATTENDANCE_AREAS}?filter=${_data?.query}`, null, true);
            data.data = data?.data?.map(item => {
                item.label = `${item?.name} ${item?.user_ref ? ` - ${item?.user_ref}` : ''} ${item?.cpf_cnpj ? ` - ${item?.cpf_cnpj}` : ''}`;
                return item;
            })
            
            setAreasList(data.data);
        }
    }, []);

    const searchProcessCases = useCallback(async (_data) => {
        if(_data?.query.length >= 3){
            const data = await api.get(`${URL_PROCESSCASES_SEARCH}`, {filter:_data?.query}, true);
            //console.log('$', data.data);
            data.data = data?.data?.map(item => {
                item.label = `${item?.name} - ${item?.number ? item?.number : ' - '}`;
                return item;
            })
            
            setProcessCasesList(data.data);
        }
    }, []);

    const getContentUserType = useCallback((_data)=>{

        let content = '';

        switch(_data){
            case 0:
                content = <div className='grid'>
                    <div className="col-6">
                        <Controller name={`user_ref`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-id-card" />
                                <InputText pattern='[0-9]{11}' maxLength={11} required inputStyle={{width:'100%'}} placeholder='CPF' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value.toString().replace(/\D/gi,''));}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`name_user`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-user" />
                                <InputText required inputStyle={{width:'100%'}} placeholder='Nome' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`tel_user`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-phone" />
                                <InputText type="tel" maxLength={11} required inputStyle={{width:'100%'}} placeholder='Telefone' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value.toString().replace(/\D/gi,''));}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-6">
                        <Controller name={`email_user`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="p-input-icon-left">
                                <i className="pi pi-at" />
                                <InputText type="email" required inputStyle={{width:'100%'}} placeholder='E-mail' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                </div>          
            break;
            case 1:
                content = <>
                    <Button className='p-button-success' icon="pi pi-pencil" label="Adicionar cliente" onClick={()=> setShowFormClient(true)} />
                </>          
            break;
            case 2:
                content = <>
                    <span style={{width:'100%'}} className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <AutoComplete 
                            field='label' 
                            suggestions={clientList} 
                            completeMethod={searchClient} 
                            required 
                            inputStyle={{width:'100%'}} 
                            placeholder='Buscar cliente' 
                            className='' style={{width:'100%'}} 
                            onChange={(e) => {setClientSearch(e.value);}}  
                            id='searchClient' 
                            value={clientSearch}  
                            onSelect={(e)=> setClient(e.value)}
                            minLength={3}
                        />
                    </span>
                </>          
            break;
            default:
                content = '';
        }

        setPrintContentType(content);

    }, [setPrintContentType, control, clientList, clientSearch, searchClient]);

    const onRowEditComplete = (e) =>{
        let temp  = [...othersPeople];
        temp[e.index] = e.newData;

        setOthersPeoples(temp);
    }

    const getTitleData = useCallback(async () => {
        const result = await api.get(`${URL_PROCESSCASES_BASE_TITLE}`, null, true);
        setTitleData(result.data);
    },[])

    useEffect(()=>{
        const temp = titleData?.process_cases_type_case.filter(item => ~~item.process_cases_area_id === ~~currentArea);
        setCurrentTypeCase(temp);
 
     },[currentArea])

    useEffect(()=>{
        const init = async () => {
            await getTitleData();
        }

        init();

    },[getTitleData]);

    useEffect(()=>{
        setClient('');
        setClientSearch('');
        setIsCaseExist(false);
    }, [userType])

    useEffect(()=>{

        if(isCaseExist){
            setIsCase(false);
        }

    }, [isCaseExist, setIsCase])

    useEffect(()=>{
        if(useClientOtherPeople){
            setNameOtherPeople(client.name);
        }else{
            setNameOtherPeople('');
        }
    },[useClientOtherPeople, client])

    useEffect(()=>{
        getContentUserType(userType)
    }, [userType, getContentUserType]);

    useEffect(()=>{
        //console.log(params);
        setClient(params?.state?.calendar?.client_id);
        setClientSearch(params?.state?.calendar?.client_name);
        
    },[params])

    return (
        <div className="page-user-attendance-create">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Atendimento', url:'/user/attendance'},
                        {label:'Criar atendimento'}
                    ]}
                />
            </div>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid mt-4">
                        <div className="col-12">
                            <strong>Inicio do atendimento</strong>: {start.current.toLocaleDateString()} - {start.current.toLocaleTimeString()}
                        </div>
                        <div className="col-12">
                            <TabMenu 
                                activeIndex={userType} 
                                model={[
                                    {label:'Não cliente', value: 0},
                                    {label:'Cadastrar cliente', value: 1},
                                    {label:'Cliente', value: 2}
                                ]} 
                                onTabChange={(e) => setUserType(e.index)} />
                        </div>
                        <div className="col-12">
                            {printContentType}
                        </div>
                        <div className="col-6">
                            <Controller name={`type`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Dropdown {...field} optionLabel='name' options={ATTENDANCE_TYPE} placeholder='Tipo de atendimento' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-6">
                            {
                                props?.location ?
                                    ''
                                :
                                <>
                                <Controller name={`location`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                    <span style={{width:'100%'}} className="p-input-icon-left">
                                        <Dropdown {...field} optionLabel='name' options={ATTENDANCE_LOCATION}  placeholder='Local do atendimento' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} value={field.value}  />
                                    </span>
                                )} />
                                </>
                            }
                        </div>
                        <div className="col-12">
                            <Controller name={`area`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                    <AutoComplete 
                                        {...field}
                                        field='name' 
                                        suggestions={areasList} 
                                        completeMethod={searchAreas} 
                                        required 
                                        inputStyle={{width:'100%'}} 
                                        placeholder="Área do atendimento"
                                        className='' 
                                        style={{width:'100%'}} 
                                        onChange={(e) => {field.onChange(e.value); setAreaSearch(e.value);}}  
                                        id='searchAreas' 
                                        value={areaSearch}  
                                        onSelect={(e)=> field.onChange(e.value)}
                                        minLength={3}
                                    />
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`description`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputTextarea {...field} autoResize required inputStyle={{width:'100%', minHeight:'300px'}} placeholder='Descreva o atendimento' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value}  />
                            )} />
                        </div>
                    </div>
                    {
                        userType === 2 && client ?
                            <div className="grid">
                                <div className="col-12">
                                    <h4>Existe caso aberto?</h4>
                                </div>
                                <div className="col-12">
                                    <SelectButton
                                        unselectable={false} 
                                        value={isCaseExist} 
                                        options={[
                                            {label:'Não', value: false},
                                            {label:'Sim', value: true}
                                        ]} 
                                        onChange={(e) => setIsCaseExist(e.value)} />
                                </div>
                            </div>
                        :
                        ''
                    }
                    {
                        isCaseExist && userType > 0 && client ?
                            <div className="col-12">
                                <h4>Utilize o campo abaixo para selecioner o Caso/ processo</h4>
                                <Controller name={`case`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                        <AutoComplete 
                                            {...field}
                                            field='name' 
                                            suggestions={processCasesList} 
                                            completeMethod={searchProcessCases} 
                                            required 
                                            inputStyle={{width:'100%'}} 
                                            placeholder="Informe aqui"
                                            className='' style={{width:'100%'}} 
                                            onChange={(e) => {field.onChange(e.value); setCasesList(e.value);}}  
                                            id='searchAreas' 
                                            value={casesList}  
                                            onSelect={(e)=> field.onChange(e.value)}
                                            minLength={3}
                                        />
                                )} />
                            </div>
                        :
                        ''
                    }
                    {
                        !isCaseExist && userType > 0 && client ?
                            <div className="grid">
                                <div className="col-12">
                                    <h4>Este atendimento gera um caso?</h4>
                                </div>
                                <div className="col-12">
                                    <SelectButton
                                        unselectable={false} 
                                        value={isCase} 
                                        options={[
                                            {label:'Não', value: false},
                                            {label:'Sim', value: true}
                                        ]} 
                                        onChange={(e) => setIsCase(e.value)} />
                                </div>
                            </div>
                    :
                    ''
                    }
                    {
                        isCase && userType > 0 && client ?
                            <div className='grid mt-2 mb-2'>
                                <div className="col-6">
                                    <Controller name={`title_area`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                        <Dropdown filter {...field} placeholder="Selecione a área" options={titleData?.process_cases_area} optionLabel="name" optionValue="id" style={{width:'100%'}} onChange={(e) => {
                                            setCurrentArea(e.target.value);
                                            field.onChange(e.target.value);
                                        }}  id={field.name}  value={field.value} />
                                    )} />
                                </div>
                                <div className="col-6">
                                    <Controller name={`title_type_case`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                        <Dropdown filter {...field} placeholder="Selecione o tipo do caso" options={currentTypeCase} optionLabel="name" optionValue="id" style={{width:'100%'}} onChange={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                    )} />
                                </div>
                                {/* <div className="col-12">
                                    <Controller name={`case_title`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                        <span style={{width:'100%'}} className="p-input-icon-left">
                                            <i className="pi pi-folder" />
                                            <InputText required inputStyle={{width:'100%'}} placeholder='Titulo do caso' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value.toString().replace(/\D/gi,''));}}  id={field.name} {...field} value={field.value}  />
                                        </span>
                                    )} />
                                </div> */}
                                <div className="col-12">
                                    <h4>
                                        Adicionar partes envolvidas 
                                    </h4>
                                    <div className="grid">
                                        <div className="col-2">
                                        <Checkbox className='mr-2' inputId="useClient" value={useClientOtherPeople} onChange={(e)=> setUseClientOtherPeople(e.checked)} checked={useClientOtherPeople}></Checkbox>
                                        <label htmlFor="useClient" className="p-checkbox-label">Usar cliente?</label>
                                        </div>
                                        <div className="col-6">
                                            <span style={{width:'100%'}} className="p-input-icon-left">
                                                <i className="pi pi-user" />
                                                <InputText inputStyle={{width:'100%'}} value={nameOtherPeople} onInput={(e)=> setNameOtherPeople(e.target.value)} style={{width:'100%'}} placeholder='Nome envolvido' />
                                            </span>
                                        </div>
                                        <div className="col-4">
                                            <span style={{width:'100%'}} className="p-input-icon-left">
                                                <i className="pi pi-user" />
                                                <Dropdown options={[
                                                    {label:'Autor', value: 'Autor'},
                                                    {label:'Réu', value: 'Réu'}
                                                ]} inputStyle={{width:'100%'}} value={typeOtherPeople} onChange={(e)=> setTypeOtherPeople(e.target.value)} style={{width:'100%'}} placeholder='Tipo envolvido' />
                                            </span>
                                        </div>
                                        <div className="col-12">
                                            <InputTextarea inputStyle={{width:'100%'}} value={extraOtherPeople} onInput={(e)=> setExtraOtherPeople(e.target.value)} style={{width:'100%'}} autoResize placeholder='Outras informações' />
                                        </div>
                                        <div style={{textAlign:'right'}} className="col-12">
                                            <Button onClick={(e)=>addOtherPeople(e)} icon="pi pi-plus" label="Adicionar" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {
                                        othersPeople?.length > 0 ?
                                            <DataTable 
                                                style={{width:'100%'}}
                                                size="small"
                                                value={othersPeople} 
                                                emptyMessage="Não há outros envolvidos"
                                                editMode="row" 
                                                dataKey="id" 
                                                onRowEditComplete={onRowEditComplete}
                                                >
                                                <Column style={{width:'250px'}} field='name' header="Nome" editor={
                                                    (options) => {
                                                        return <InputText inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                                                    }
                                                } />
                                                <Column style={{width:'250px'}} field='type' header="Tipo" editor={
                                                    (options) => {
                                                        return <Dropdown options={[
                                                            {label:'Autor', value: 'Autor'},
                                                            {label:'Réu', value: 'Réu'}]} 
                                                            inputStyle={{width:'100%'}} style={{width:'100%'}}  value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
                                                    }
                                                } />
                                                <Column style={{width:'auto'}} field='description' header="Extra" editor={
                                                    (options) => {
                                                        return <InputTextarea autoResize inputStyle={{width:'100%'}} style={{width:'100%'}} value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                                                    }
                                                } />
                                                <Column rowEditor headerStyle={{ width: '100px'}} bodyStyle={{ textAlign: 'center' }}></Column>
                                                <Column style={{width:'40px', textAlign:'center'}} body={(row)=>{
                                                    return <Button onClick={(e)=>{e.preventDefault(); removeOtherPeople(row.id)}} icon="pi pi-trash" className="p-button-danger p-button-text" />
                                                }} header=""/>
                                            </DataTable>
                                        :
                                            ''
                                    }
                                </div>
                                <div className="col-12">
                                    <Controller name={`case_description`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                        <InputTextarea {...field} autoResize required inputStyle={{width:'100%', minHeight:'300px'}} placeholder='Descreava o caso' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name} value={field.value}  />
                                    )} />
                                </div>
                            </div>
                        :
                            ''
                    }
                    <div className="grid mt-4">
                        <div className="col-12" style={{textAlign:'right'}}>
                            <Button onClick={()=>navigate('/user/attendance')} className='p-button-danger p-button-text mr-2' label="Cancelar atendimento"/>
                            <Button className='p-button-warning' label="Finalizar atendimento"/>
                        </div>
                    </div>
                </form>
            </div>
            <Dialog header="Cadastro" maximizable visible={showFormClient} style={{ width: '90vw' }} footer={null} onHide={() => setShowFormClient(false)}>
                <PageClientCreate updateCreateClient={updateCreateClient} removeBreadcrumb={true} cep="top"  />
            </Dialog>

            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}