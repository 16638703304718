export const CSAT = [
    {id: 1, question: 'A pessoa que lhe atendeu na recepção do escritório foi educada, gentil, prestativa e lhe encantou?', analytics:'Excelência Atendimento'},
    {id: 2, question: 'A pessoa que fez o seu atendimento demonstrou muito conhecimento do assunto tratado?', analytics:'Conhecimento Técnico'},
    {id: 3, question: 'Você sentiu segurança nas informações tratadas com a pessoa que lhe atendeu?', analytics:'Confiança'},
    {id: 4, question: 'A pessoa que lhe atendeu propôs alternativas para a resolução da sua necessidade?', analytics:'Alternativas de Êxito'},
    {id: 5, question: 'O tempo de duração do seu atendimento atendeu as suas expectativas?', analytics:'Tempo de Atendimento'}
];

export const CSAT_FEEDBACK = [
    {name: 'Ruim', min: 0, max: 70},
    {name: 'Bom', min: 71, max: 90},
    {name: 'Excelência', min: 91, max: 100}
]

export const NPS = [
    {id: 1, question: 'Em uma escala de 0 a 10, o quanto você indicaria o nosso escritório para um amigo ou familiar?', analytics:'Fidelidade do Cliente'}
];

export const NPS_FEEDBACK = [
    {name: 'Crítico', min: -100, max: 0},
    {name: 'Aperfeiçoamento', min: 1, max: 50},
    {name: 'Qualidade', min: 51, max: 75},
    {name: 'Excelência', min: 76, max: 100}
]