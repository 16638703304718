import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

export const UIFullcalendarMonth = (_data) =>{

    moment.locale('pt-br');

    //const date = new Date(_data.date.getFullYear(), _data.date.getMonth(),  _data.date.getDay());
    const date = _data.date;
    let init = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`).getDay() -1;
    let lastday = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    
    let countweek = 1;
    for(let i=0; i < lastday; i++){
        const temp = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${i}`);
        if(temp.getDay() === 6)
            countweek++;
    }
    
    const boxTotal = countweek * 7;

    const openDay = (_day) =>{
        _data.openDay(_day);
    }

    const mountBox = () => {
        
        let listbox = [];
        let dayTemp = 1;

        for(let i =0; i < boxTotal; i++ ){
            const temp = new Date(date.getFullYear(), date.getMonth(), dayTemp);
            const today = new Date();

            
            if(date.getMonth() === temp.getMonth() && i > init){
                const eventsList = _data.events?.filter(_calendar => {
                    return _calendar.date === temp.toISOString().substr(0, 10);
                })
                listbox.push(<div key={`day-bt-${temp.getDate()}-${i}`} onClick={()=>openDay(temp)} className="day-calendar">
                        <div className={`${temp.toLocaleDateString() === today.toLocaleDateString() ? 'today' : '' } number ${eventsList?.length > 0 ? 'hasevent' : ''}`}>{dayTemp}</div>
                        {
                            eventsList?.length > 0 ? 
                                <div className='events'>
                                    {/* Agendados: { eventsList.length } */}
                                </div>
                            :
                                ''
                        }
                    </div>);

                dayTemp++;
            }else{
                listbox.push(<div key={`clear-${i}`}></div>);
            }
        }

        return listbox;
    }


    return (
        <div className="ui-fullcalendar-month" >
                <div className="header-month-calendar">
                    {
                        moment.localeData().weekdays().map((item, id) =>{
                            return <div key={`week-${id}`}>{item}</div>
                        })
                    }
                </div>
                <div className="days-month-calendar">
                    {mountBox()}
                </div>
                <div className="footer-calendar">
                    <div className="current-day">Dia atual</div>
                    <div className="pre-day">Marcações</div>
                </div>
        </div>
    )
}