import { getToken, setLogout } from "../auth";

export const URL_BASE = (/development/gi).test(process.env.NODE_ENV) ?  'http://localhost/affadvocacia/api/' : 'https://api.affadvocacia.adv.br/';

//export const URL_BASE = 'https://api.affadvocacia.adv.br/';



const validadeSession = async ( ) =>{


    const url = `${URL_BASE}user/validatesession`;

    const headerdata = new Headers();
    headerdata.append('Accept', 'application/json');
    headerdata.append('Content-Type', 'application/json');

    const tokendata = getToken();
    headerdata.append('Authorization', `Bearer ${tokendata}`);

    const params = {
        method: 'GET',
        mode:'cors',
        headers: headerdata
    }

    const result = await fetch(`${url}`, params);
    const json = await result.json();

    if(!json?.data){
        setLogout();
        window.location.reload();
    }

}

const requestPost = async (url, data = null, token = null) => {

    const headerdata = new Headers();

    if(token){
        await validadeSession();
        const tokendata = getToken();
        headerdata.append('Authorization', `Bearer ${tokendata}`);
    }

    const params = {
        method: 'POST',
        mode:'cors',
        headers: headerdata
    }

    if(data)
        params.body = JSON.stringify(data);

    const result = await fetch(url, params);

    let json = null;

    try{
        json = await result.json();
    }catch(ex){
        json = null;
    }


    return json;

}

const requestGet = async (url, data = null, token = null) => {

    const headerdata = new Headers();
    headerdata.append('Accept', 'application/json');
    headerdata.append('Content-Type', 'application/json');

    if(token){
        await validadeSession();
        const tokendata = getToken();
        headerdata.append('Authorization', `Bearer ${tokendata}`);
    }

    const params = {
        method: 'GET',
        mode:'cors',
        headers: headerdata
    }

    let querystring = '';

    if(data){
        const list = Object.keys(data)?.map((item) => {
            return `${item}=${data[item]}`;
        });

        querystring = `?${list.join('&')}`;
    }

    const result = await fetch(`${url}${querystring}`, params);

    let json = null;

    try{
        json = await result.json();
    }catch(ex){
        json = null;
    }


    return json;
}

const requestPut =  async (url, data = null, token = null) => {

    const headerdata = new Headers();

    if(token){
        await validadeSession();
        const tokendata = getToken();
        headerdata.append('Authorization', `Bearer ${tokendata}`);
    }

    const params = {
        method: 'PUT',
        mode:'cors',
        headers: headerdata
    }

    if(data)
        params.body = JSON.stringify(data);

    const result = await fetch(url, params);

    let json = null;

    try{
        json = await result.json();
    }catch(ex){
        json = null;
    }


    return json;
}

const requestDelete =  async (url, data = null, token = null) => {

    const headerdata = new Headers();

    if(token){
        await validadeSession();
        const tokendata = getToken();
        headerdata.append('Authorization', `Bearer ${tokendata}`);
    }

    const params = {
        method: 'DELETE',
        mode:'cors',
        headers: headerdata
    }

    if(data)
        params.body = JSON.stringify(data);

    const result = await fetch(url, params);

    let json = null;

    try{
        json = await result.json();
    }catch(ex){
        json = null;
    }


    return json;
}

const requestUpload =  async (url, data = null, token = null) => {

    const headerdata = new Headers();
    //headerdata.append("Content-Type", "multipart/form-data");

    if(token){
        await validadeSession();
        const tokendata = getToken();
        headerdata.append('Authorization', `Bearer ${tokendata}`);
    }

    const params = {
        method: 'POST',
        headers: headerdata
    }

    if(data){
        params.body = data;
    }

    const result = await fetch(url, params);

    let json = null;

    try{
        json = await result.json();
    }catch(ex){
        json = null;
    }


    return json;
}

export const api = {
    post : requestPost,
    get : requestGet,
    put : requestPut,
    delete : requestDelete,
    upload: requestUpload
}


export const URL_VALIDATESESSION = `${URL_BASE}User/validatesession`;
export const URL_LOGIN = `${URL_BASE}User/login`;
export const URL_LOGOUT = `${URL_BASE}User/logout`;
export const URL_PROFILE = `${URL_BASE}User/profile`;
export const URL_PROFILE_CLIENT = `${URL_BASE}User/profileclient`;
export const URL_CONTACT_CLIENT = `${URL_BASE}User/getContactListClient`;
export const URL_ADDRESS_CLIENT = `${URL_BASE}User/getAddressListClient`;
export const URL_USER_CREATE = `${URL_BASE}User/create`;
export const URL_USER_DELETE = `${URL_BASE}User/delete`;
export const URL_USER_UPDATE = `${URL_BASE}User/update`;
export const URL_USER_JURIDIC = `${URL_BASE}User/getjuridic`;
export const URL_USER_CLIENT = `${URL_BASE}User/getclient`;
export const URL_CLIENTS = `${URL_BASE}User/getallclients`;
export const URL_USER_JURIDIC_BY_ACTUATION = `${URL_BASE}User/getjuridicbyactuation`;
export const URL_CLIENT_SEARCH = `${URL_BASE}User/searchClient`;
export const URL_COLLABORATORS = `${URL_BASE}User/getallcollaborators`;
export const URL_PROFILE_COLLABORATOR = `${URL_BASE}User/profilecollaborator`;
export const URL_USER_AREAS = `${URL_BASE}User/getareas`;
export const URL_USER_CLIENT_TYPE = `${URL_BASE}User/getClientTypes`;
export const URL_USER_TYPE = `${URL_BASE}User/getTypes`;
export const URL_USER_TYPE_ALL = `${URL_BASE}User/getTypesAll`;
export const URL_USER_MY_NOTIFICATION = `${URL_BASE}User/mynotification`;
export const URL_USER_MY_NOTIFICATION_READ = `${URL_BASE}User/readnotification`;

export const URL_CALENDAR_ALL = `${URL_BASE}Calendar/all`;
export const URL_CALENDAR_MY = `${URL_BASE}Calendar/my`;
export const URL_CALENDAR_BYID = `${URL_BASE}Calendar/byid`;
export const URL_CALENDAR_BYCLIENT = `${URL_BASE}Calendar/byclient`;
export const URL_CALENDAR_CREATE = `${URL_BASE}Calendar/create`;
export const URL_CALENDAR_DELETE = `${URL_BASE}Calendar/delete`;
export const URL_CALENDAR_UPDATE = `${URL_BASE}Calendar/update`;
export const URL_CALENDAR_AVAILABLE_TIMES = `${URL_BASE}Calendar/availabletimes`;
export const URL_CALENDAR_AVAILABLE_JURIDIC = `${URL_BASE}Calendar/availableCalendarJuridic`;
export const URL_CALENDAR_TIMES_ATTENDANCE = `${URL_BASE}Calendar/getTimesAttendance`;

export const URL_QUESTION_ACTUATION = `${URL_BASE}Question/actuation`;

export const URL_FEEDBACK_ISCOMPLETE = `${URL_BASE}Feedback/iscomplete`;
export const URL_FEEDBACK_CREATE = `${URL_BASE}Feedback/create`;
export const URL_FEEDBACK_GET_TOKEN = `${URL_BASE}Feedback/gettoken`;
export const URL_FEEDBACK_DASHBOARD = `${URL_BASE}Feedback/dashboard`;

export const URL_ATTENDANCE_ALL = `${URL_BASE}Attendance/all`;
export const URL_ATTENDANCE_BYCASE = `${URL_BASE}Attendance/bycase`;
export const URL_ATTENDANCE_BYID = `${URL_BASE}Attendance/byid`;
export const URL_ATTENDANCE_BYUSERID = `${URL_BASE}Attendance/byuserid`;
export const URL_ATTENDANCE_CREATE = `${URL_BASE}Attendance/create`;
export const URL_ATTENDANCE_AREAS = `${URL_BASE}Attendance/areas`;

export const URL_PROCESSCASES_ALL = `${URL_BASE}ProcessCases/all`;
export const URL_PROCESSCASES_BYID = `${URL_BASE}ProcessCases/byid`;
export const URL_PROCESSCASES_BYUSER = `${URL_BASE}ProcessCases/byuser`;
export const URL_PROCESSCASES_PROCESS = `${URL_BASE}ProcessCases/process`;
export const URL_PROCESSCASES_PROCESS_LIST = `${URL_BASE}ProcessCases/processlist`;
export const URL_PROCESSCASES_PROCESS_LAST_STATUS = `${URL_BASE}ProcessCases/processlaststatus`;
export const URL_PROCESSCASES_HISTORY = `${URL_BASE}ProcessCases/history`;
export const URL_PROCESSCASES_RESUME = `${URL_BASE}ProcessCases/resume`;
export const URL_PROCESSCASES_SEARCH = `${URL_BASE}ProcessCases/search`;
export const URL_PROCESSCASES_STATUSPROCESS = `${URL_BASE}ProcessCases/stasusprocess`;
export const URL_PROCESSCASES_UPDATE_STATUS_PROCESS = `${URL_BASE}ProcessCases/updateStatusProcess`;
export const URL_PROCESSCASES_PROCESS_SUBJECT = `${URL_BASE}ProcessCases/subjectprocess`;
export const URL_PROCESSCASES_PROCESS_CREATE = `${URL_BASE}ProcessCases/create`;
export const URL_PROCESSCASES_PROCESS_CREATE_COMMENTS = `${URL_BASE}ProcessCases/createComments`;
export const URL_PROCESSCASES_TAGS = `${URL_BASE}ProcessCases/getTags`;
export const URL_PROCESSCASES_BASE_TITLE = `${URL_BASE}ProcessCases/basetitle`;

export const URL_PROCESSCASES_PROCESS_JUR_CREATE = `${URL_BASE}ProcessCases/createProcessJur`;
export const URL_PROCESSCASES_PROCESS_ADN_CREATE = `${URL_BASE}ProcessCases/createProcessAdm`;

export const URL_FINANCE_ALL = `${URL_BASE}Finance/all`;
export const URL_FINANCE_BYCASE = `${URL_BASE}Finance/bycase`;
export const URL_FINANCE_BYUSER = `${URL_BASE}Finance/byuser`;
export const URL_FINANCE_CREATE = `${URL_BASE}Finance/create`;
export const URL_FINANCE_PAID = `${URL_BASE}Finance/paid`;
export const URL_FINANCE_PAID_UPDATE = `${URL_BASE}Finance/updatepaid`;
export const URL_FINANCE_FLOW_BY_PERIOD = `${URL_BASE}Finance/flowByPeriod`;
export const URL_FINANCE_TYPE = `${URL_BASE}Finance/getFinanceType`;
export const URL_FINANCE_TYPE_VALUE_DATA = `${URL_BASE}Finance/getFinanceTypeValuesData`;
export const URL_FINANCE_PAYMENT_MODE = `${URL_BASE}Finance/getFinancePaymentMode`;



export const URL_TICKET_ALL = `${URL_BASE}Ticket/all`;
export const URL_TICKET_BYID = `${URL_BASE}Ticket/byid`;
export const URL_TICKET_BYCASE = `${URL_BASE}Ticket/bycase`;
export const URL_TICKET_ADDHISTORY = `${URL_BASE}Ticket/addTicketHistory`;
export const URL_TICKET_CREATE = `${URL_BASE}Ticket/create`;
export const URL_TICKET_UPDATE = `${URL_BASE}Ticket/updateTicket`;
export const URL_TICKET_TAG = `${URL_BASE}Ticket/getTags`;
export const URL_TICKET_STATUS = `${URL_BASE}Ticket/getStatus`;
export const URL_TICKET_ACTING = `${URL_BASE}Ticket/getActing`;
export const URL_TICKET_OFFICE = `${URL_BASE}Ticket/getOffice`;

export const URL_WIKI_ALL = `${URL_BASE}Wiki/all`;
export const URL_WIKI_BYID = `${URL_BASE}Wiki/byid`;
export const URL_WIKI_CREATE = `${URL_BASE}Wiki/create`;

export const URL_DEADLINE_ALL = `${URL_BASE}ControlDeadline/all`;
export const URL_DEADLINE_BYID = `${URL_BASE}ControlDeadline/byid`;
export const URL_DEADLINE_COURT = `${URL_BASE}ControlDeadline/deadlinecourt`;
export const URL_DEADLINE_STATUS = `${URL_BASE}ControlDeadline/deadlinestatus`;
export const URL_DEADLINE_SPECIES = `${URL_BASE}ControlDeadline/deadlineSpecies`;
export const URL_DEADLINE_UPDATE = `${URL_BASE}ControlDeadline/deadlineupdate`;
export const URL_DEADLINE_CREATE = `${URL_BASE}ControlDeadline/create`;
export const URL_DEADLINE_HISTORY_CREATE = `${URL_BASE}ControlDeadline/createhistory`;
export const URL_DEADLINE_DELETE_FILE = `${URL_BASE}ControlDeadline/deletefile`;

export const URL_EXPERTISEDEPLOYMENT_ALL = `${URL_BASE}ControlExpertiseDeployment/all`;
export const URL_EXPERTISEDEPLOYMENT_BYID = `${URL_BASE}ControlExpertiseDeployment/byid`;
export const URL_EXPERTISEDEPLOYMENT_TYPE = `${URL_BASE}ControlExpertiseDeployment/expertisedeploymentType`;
export const URL_EXPERTISEDEPLOYMENT_STATUS = `${URL_BASE}ControlExpertiseDeployment/expertisedeploymentStatus`;
export const URL_EXPERTISEDEPLOYMENT_EXECUTION = `${URL_BASE}ControlExpertiseDeployment/expertisedeploymentExecution`;
export const URL_EXPERTISEDEPLOYMENT_UPDATE = `${URL_BASE}ControlExpertiseDeployment/expertisedeploymentupdate`;
export const URL_EXPERTISEDEPLOYMENT_CREATE = `${URL_BASE}ControlExpertiseDeployment/create`;
export const URL_EXPERTISEDEPLOYMENT_HISTORY_CREATE = `${URL_BASE}ControlExpertiseDeployment/createhistory`;

export const URL_FILES = `${URL_BASE}File/files`;
export const URL_FILES_UPLOAD = `${URL_BASE}File/upload`;
export const URL_FILES_DELETE = `${URL_BASE}File/deletefile`;
export const URL_FILES_UPDATE = `${URL_BASE}File/update`;
export const URL_FILES_FILE = `${URL_BASE}File/getfile`;


export const URL_ACTUACTION_AREA = `${URL_BASE}actuation/area`;
export const URL_ACTUACTION_AREA_CREATE = `${URL_BASE}actuation/area/create`;
export const URL_ACTUACTION_QUESTION = `${URL_BASE}actuation/question`;
export const URL_ACTUACTION_QUESTION_CREATE = `${URL_BASE}actuation/question/create`;

export const URL_SYSTEM_SEARCH = `${URL_BASE}SystemData/search`;
export const URL_SYSTEM_LIST_PAGES = `${URL_BASE}SystemData/getListPages`;
export const URL_SYSTEM_LIST_PAGES_BY_AREA = `${URL_BASE}SystemData/getListPagesByArea`;
export const URL_SYSTEM_UPDATE_ACCESS_PAGES = `${URL_BASE}SystemData/updateAccessPage`;

export const URL_SYSTEM_CONFIG = `${URL_BASE}Config/system`;

export const URL_CALENDAR_NOTIFICATION_MODE = `${URL_BASE}CalendarNotification/mode`;
export const URL_CALENDAR_NOTIFICATION_MODE_DATA = `${URL_BASE}CalendarNotification/modedata`;
export const URL_CALENDAR_NOTIFICATION_CREATE = `${URL_BASE}CalendarNotification/create`;
export const URL_CALENDAR_NOTIFICATION_LISTALL_BY_DATE = `${URL_BASE}CalendarNotification/listAllByDate`;


export const URL_PARTNER_ALL = `${URL_BASE}Partner/all`;
export const URL_PARTNER_CREATE = `${URL_BASE}Partner/create`;
export const URL_PARTNER_BY_ID = `${URL_BASE}Partner/getbyid`;
export const URL_PARTNER_UPDATE = `${URL_BASE}Partner/update`;