import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';

import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { TabMenu } from 'primereact/tabmenu';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { formatCPJCNPJ } from '@/services/utils';

import { useNavigate } from 'react-router-dom';
import { api, URL_EXPERTISEDEPLOYMENT_ALL, URL_EXPERTISEDEPLOYMENT_STATUS, URL_EXPERTISEDEPLOYMENT_UPDATE, URL_COLLABORATORS, URL_EXPERTISEDEPLOYMENT_EXECUTION } from '@/services/api';



export const PageUserExpertiseDeployment = () => {

    const tabmodel = useMemo(()=> [
        {label: 'Pericias presenciais', params: {filter: true, propfilter:'execution_type_id', value: '1'}},
        {label: 'Pericias on-line',  params: {filter: true, propfilter:'execution_type_id', value: '2'}},
        {label: 'Implantação de beneficio',  params: {filter: true, propfilter:'execution_type_id', value: '3'}},
        {label: 'Todos', params: null}
    ], [])

    const navigate = useNavigate();

    const [data, setData] = useState();
    const [dataStatus, setDataStatus] = useState();
    const [activeIndex, setActiveIndex] = useState();
    const [contentData, setContentData] = useState();
    const [showDialogData, setShowDialogData] = useState(false);
    const [dataResume, setDataResume] = useState();
    const [executionData, setExecutionData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const toast = useRef(null);

    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const clearFilter = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'user_ref': { value: null, matchMode: FilterMatchMode.STARTS_WITH }
        })
        setGlobalFilterValue('');
    }
    
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters };
        _filters1['global'].value = value;
    
        setFilters(_filters1);
        setGlobalFilterValue(value);
    }

    const getContentData = useCallback(async (_data)=>{

        let list = [...data];
        if(tabmodel[_data].params){
            if(tabmodel[_data].params.filter){
                list = list.filter(item => {
                    if(tabmodel[_data].params?.value)
                        return item[tabmodel[_data].params.propfilter] === tabmodel[_data].params?.value
                    else
                        return item[tabmodel[_data].params.propfilter]
                });
            }else{
                list = list.filter(item => !/cumprido|decorrido/gi.test(item.status));
            }
        }

        setContentData(list);
    }, [setContentData, tabmodel, data])

    const openResume = (_data) => {
        setDataResume(_data);
        setShowDialogData(true);
    }

    const copyContent = (data) => {
        navigator.clipboard.writeText(data);
        toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Conteúdo copiado com sucesso'});
    }
    
    useEffect(()=>{
        if(data){
            setContentData(null);
            getContentData(activeIndex);
        }
    },[activeIndex, getContentData, data])

    const getData = useCallback(async ()=> {
        setIsLoading(true);
        const result = await api.get(URL_EXPERTISEDEPLOYMENT_ALL, null ,true);
        result.data = (result.data || [])?.map(item=>{
            item.id_name = `Prazo ${item.id}`;
            return item;
        })
        
        setData(result.data);
        setActiveIndex(0);
        setIsLoading(false);
    }, [])

    const getDataStatus = useCallback(async ()=> {
        const result = await api.get(URL_EXPERTISEDEPLOYMENT_STATUS, null ,false);
        setDataStatus(result.data);
    }, [])

    const editCell = useCallback(async (_data = null) => {  

         await api.put(URL_EXPERTISEDEPLOYMENT_UPDATE, _data, true);
        getData();
    }, [getData]);

    const getExecution = useCallback(async()=>{
        const cl = await api.get(URL_EXPERTISEDEPLOYMENT_EXECUTION, null, true);
        setExecutionData(cl.data);
    }, []);

    useEffect(()=>{
        getDataStatus();
        getData();
        getExecution();
    },[getData, getDataStatus, getExecution])

    if(!contentData)
        return (
            <div className="page-user-deadline">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-user-deadline">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Perícia e implantação'}
                    ]}
                />
            </div>
            <div className="header mt-4 mb-4">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: 0}}
                    left={()=><>
                    </>} 
                    right={()=><>
                        <Button onClick={()=>{getData()}} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>{navigate('/user/expertisedeployment/create')}} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} />
            </div>
            <div className="menu">
                <TabMenu 
                    activeIndex={activeIndex}
                    onTabChange={(e)=>setActiveIndex(e.index)}
                    model={tabmodel} />
            </div>
            <div className="grid">
                <div className="col-12">
                <DataTable 
                        value={contentData} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há prazos cadastrados"
                        size='small'
                        stripedRows
                        rows={10}
                        paginator={contentData?.length > 10}
                        filters={filters}
                        globalFilterFields={['execution', 'status', 'id', 'process_number']}
                        editMode="cell"
                        loading={isLoading}
                        header={()=>{
                            return <div className="flex justify-content-end">
                                        <span className="p-input-icon-left">
                                            <i className="pi pi-search" />
                                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Busca" />
                                        </span>
                                        <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-sm ml-2" onClick={clearFilter} />
                                    </div>
                        }}
                    >
                        <Column style={{width:'100px'}} body={(row)=>{
                            return row.id
                        }} header="ID" />
                        <Column style={{width:'100px'}} body={(row)=>{
                            return row?.priority ? <Tag style={{width:'100%'}} value={~~row?.priority === 1 ? 'Sim' : 'Não'} severity={~~row?.priority === 1 ? 'danger' : 'success'} /> : '-'
                        }} header="Prioridade" />
                        <Column style={{width:'120px'}} body={(row)=>{
                            return `${new Date(row.timecreated).toLocaleDateString()}`
                        }} header="Criado" />
                        <Column style={{width:'120px'}} body={(row)=>{
                            return `${new Date(row.timefuture).toLocaleDateString()}`
                        }} header="estipulado/ agendado" />
                        <Column style={{width:'200px'}} body={(row)=> {
                            return <div style={{display:'flex', alignItems:'center'}}>
                             {
                                row?.cpf_cnpj ? 
                                    <>
                                        <span>{formatCPJCNPJ(row?.cpf_cnpj)} </span>
                                        <Button onClick={()=> copyContent(row?.cpf_cnpj)} className='p-button-sm p-button-text' icon='pi pi-copy' />
                                    </>
                                :
                                    '-'
                             }
                            </div>
                        }}
                        field="cpf_cnpj" header="CPF/CNPJ"
                        />
                        <Column style={{width:'200px'}} body={(row)=> {
                            return <div style={{display:'flex', alignItems:'center'}}>
                            {
                               row?.access_inss ? 
                                   <>
                                       <span>{row?.access_inss} </span>
                                       <Button onClick={()=> copyContent(row?.access_inss)} className='p-button-sm p-button-text' icon='pi pi-copy' />
                                   </>
                               :
                                   '-'
                            }
                           </div>
                        }} field="access_inss" header="Acesso INSS" />
                        <Column body={(row)=>{
                            return row?.path ? row?.path  : '-'
                        }} header="Localização" />
                        <Column body={(row)=>{
                            return row?.type ? <Tag style={{backgroundColor:row.type_color, width:'100%'}} value={row.type} />  : '-'
                        }} header="Tipo" />
                        <Column  style={{width:'200px', height:'45px'}} body={(row)=>{
                            return row?.status ? <Tag style={{backgroundColor:row.status_color, width:'100%', cursor: 'pointer'}} value={row.status} /> : <div style={{textAlign:'center', width:'100%'}}>-</div> 
                        }} header="Status" field='status_id' 
                        editor={(options)=>{
                            return <Dropdown 
                                    style={{width:'100%'}}
                                    optionLabel='name' 
                                    optionValue='id' 
                                    options={dataStatus}
                                    filter
                                    onChange={(e) =>{ 
                                        editCell({id:options.rowData.id, status_id: e.target.value });
                                        options.editorCallback(e.target.value);
                                    }}  
                                    value={options.value} />
                        }}
                        />
                        {
                            !tabmodel[activeIndex].params ?
                            <Column  style={{width:'200px', height:'45px'}} body={(row)=>{
                                return row?.execution ? row.execution : <div style={{textAlign:'center', width:'100%'}}>-</div> 
                            }} header="Execução" field='execution_type_id' 
                            editor={(options)=>{
                                return <Dropdown 
                                        style={{width:'100%'}}
                                        optionLabel='name' 
                                        optionValue='id' 
                                        options={executionData}
                                        filter
                                        onChange={(e) =>{ 
                                            editCell({id:options.rowData.id, status_id: e.target.value });
                                            options.editorCallback(e.target.value);
                                        }}  
                                        value={options.value} />
                            }}
                            />
                            :
                            <></>
                        }
                       
                        <Column style={{minWidth:'115px'}} body={(row)=>{
                            return <>
                                <Button className='p-button-sm p-button-text' icon="pi pi-book" onClick={()=>{openResume(row)}} />
                                <Button className='p-button-sm p-button-text' icon="pi pi-eye" onClick={()=>navigate(`/user/expertisedeployment/view/${row?.id}`)} />
                            </>
                        }} header="" />
                    </DataTable>

                    <Dialog header="Detalhes" visible={showDialogData} style={{ width: '90vw', maxWidth:'1000px' }} footer={null} onHide={() => setShowDialogData(false)}>
                        <div className="grid">
                            <div className="col-12">
                                <h5>Motivação</h5>
                            </div>
                            <div className="col-12">
                                <h5>Motivação</h5>
                                <div>
                                    {
                                        dataResume?.reason
                                    }
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}