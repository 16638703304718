import React from 'react';

import moment from 'moment';
import 'moment/locale/pt-br';

export const UIFullcalendarWeek = (_data) => {

    moment.locale('pt-br');

    let dayStart = _data.date.getDate() - _data.date.getDay();
    dayStart = dayStart >= 0 ? dayStart  : 1;
    
    //let init = new Date(_data.date.getFullYear(), _data.date.getMonth(), dayStart).getDay();    

    const openDay = (_day) =>{
        _data.openDay(_day);
    }

    const mountBox = () => {
        let listbox = [];
        let dayTemp = dayStart;

        for(let i = 0; i < 7; i++ ){
            const temp = new Date(_data.date.getFullYear(), _data.date.getMonth(), dayTemp);
            
            const today = new Date();


            if(_data.date.getMonth() === temp.getMonth() && temp.getDay() === i){
                const eventsList = _data.events?.filter(_calendar => {
                    return _calendar.date === temp.toISOString().substr(0, 10);
                })
                
                listbox.push(<div key={`day-bt-${temp.getDate()}-${i}`} onClick={()=>openDay(temp)} className="day-calendar">
                        <div className={`${temp.toLocaleDateString() === today.toLocaleDateString() ? 'today' : '' } number ${eventsList?.length > 0 ? 'hasevent' : ''}`}>{temp.getDate()}</div>
                        {
                            eventsList?.length > 0 ? 
                                <div className='events'>
                                    {/* Agendados: { eventsList.length } */}
                                </div>
                            :
                                ''
                        }
                    </div>);

                dayTemp++;
            }else{
                listbox.push(<div key={`clear-${i}`}></div>);
            }
        }

        return listbox;
        
    }

    return (
        <div className="ui-fullcalendar-week" >
            <div className="header-week-calendar">
                {
                    moment.localeData().weekdays().map((item, id) =>{
                        return <div key={`week-${id}`}>{item}</div>
                    })
                }
            </div>
            <div className="days-week-calendar">
                {
                    mountBox()
                }
            </div>
            <div className="footer-calendar">
                <div className="current-day">Dia atual</div>
                <div className="pre-day">Marcações</div>
            </div>
        </div>
    )
}