import React, {useState, useEffect, useCallback, useMemo} from 'react';

import { TabMenu } from 'primereact/tabmenu';
import { PrimeIcons } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Chip } from 'primereact/chip';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { api, URL_TICKET_ALL } from '@/services/api';
import { useNavigate } from 'react-router-dom';

import '@/assets/styles/pages/ticket/index.scss';
import { formatShortName } from '@/services/utils';
import { useAPP } from '@/services/provider/app';

export const PageUserTicket = () => {

    const { user } = useAPP();

    const navigate = useNavigate();

    const tabmodel = useMemo(()=> [
        {label: 'Meus tickets', params: [`user_id = ${user.id}`]},
        {label: 'Em aberto', params: ['ticket_status_id not in (2)']},
        {label: 'Fechado', params: ['ticket_status_id in (2)']},
        {label: 'Todos', params: null}
    ], [])

    const [activeIndex, setActiveIndex] = useState(0);
    const [contentData, setContentData] = useState();

    const getContentData = useCallback(async (_data)=>{
        const data = await api.get(URL_TICKET_ALL, tabmodel[_data].params, true);
        setContentData(data?.data);
        console.log(data?.data);
    }, [setContentData, tabmodel])
    
    useEffect(()=>{
        setContentData(null);
        getContentData(activeIndex);
    },[activeIndex, getContentData])

    return(
        <div className="page-user-ticket">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Tickets'}
                    ]}
                />
            </div>
            <div className="header mt-4 mb-2">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><></>} 
                    right={()=><>
                        <Button onClick={()=>{getContentData(activeIndex)}} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>{navigate('/user/ticket/create')}} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} 
                />
            </div>
            <div className="grid">
                <div className="col-12">
                    <TabMenu 
                    activeIndex={activeIndex}
                    onTabChange={(e)=>setActiveIndex(e.index)}
                    model={tabmodel} />
                </div>
                <div className="col-12 mt-2">
                <DataTable 
                        value={contentData} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há casos cadastrados"
                        size='small'
                        stripedRows
                    >
                        <Column style={{width:'220px'}} body={(row)=> {
                            return row?.datetime_end ? `${new Date(row?.datetime_end).toLocaleDateString()} - ${new Date(row?.datetime_end).toLocaleTimeString()}` : '-'
                        }}
                        header="Termino" />
                        <Column style={{width:'90px', textAlign:'center'}} body={(row)=> {
                            return ~~row.priority === 1 ? <div className='ticket-priority'></div> : '-';
                        } } header="Prioridade" />
                        <Column body={(row)=> {
                            return row.ticket_identification;
                        } } header="Identificação" />
                        <Column body={(row)=> {
                            return row?.user ? formatShortName(row.user?.name) : '-'
                        } } header="Responsável" />
                        <Column body={(row)=> {
                            return row?.user_open ? formatShortName(row.user_open?.name) : '-'
                        } } header="Criado" />
                        <Column body={(row)=> {
                            return row?.tag?.length > 0 ? row?.tag?.map(item =>  <Chip className='m-1' key={`tag-${item.id}`} label={item.name} />) : '-'
                        } } header="Tag" />
                        <Column body={(row)=> {
                            return row?.status_name
                        } } header="Status" />
                        <Column style={{width:'50px'}} body={(row)=> {
                            return <>
                                <Button onClick={()=>navigate(`/user/tickets/${row?.id}`)} icon="pi pi-eye" className='p-button-text' />
                            </>
                        } } header="" />
                    </DataTable>
                </div>
            </div>
        </div>
    )
}