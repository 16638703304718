import React from 'react';


import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { useAPP } from '@/services/provider/app';


export const PageProfile = () => {

    const { user } = useAPP();

    //console.log(user);

    return (
        <div className="page-profile">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'profile'}
                    ]}
                />
            </div>
            <div className="grid mt-4">
                <div className="col-12">
                    <strong>Nome</strong>: {user?.name}
                </div>
                <div className="col">
                   <strong>Username</strong>:  {user?.username}
                </div>
            </div>
            <div className="grid mt-4">
                <div className="col-12">
                </div>
            </div>
        </div>
    )
}