import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Rating } from 'primereact/rating';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
 
 
import { useForm, Controller } from 'react-hook-form';

import { CSAT, NPS} from '@/services/provider/feedback';

import { URL_FEEDBACK_ISCOMPLETE, api, URL_FEEDBACK_CREATE } from '@/services/api';

import logo from '@/assets/images/logo.png';

import '@/assets/styles/pages/feedback/index.scss';

export const PageFeedback = () => {

    const params = useParams();
    const [isComplete, setIsComplete] = useState(false);
    const [pageLoad, setPageload] = useState(false);

    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;

    const onSubmit = useCallback(async (_data) => { 

        let list = [];

        let user = params?.token;
        if(user)
            user = atob(user);
            user = JSON.parse(user);

        Object.keys(_data).map(item =>{
            const data = {
                type: item.split('_')[0],
                question_id: item.split('_')[1],
                value: _data[item]
            }

            if(user?.id){
                data.calendar_id = user?.id;
            }else{
                data.user_ref = user?.user_ref;
                data.user_ref_key = user?.user_ref_key;
            }

            const pro = api.post(`${URL_FEEDBACK_CREATE}?token=${params?.token}`, data, false);
            list.push(pro);

            return item;

        })

        await Promise.all(list);

        setIsComplete(true);

    }, [params])

    const verifyComplete = useCallback(async () => {
        const data = await api.get(`${URL_FEEDBACK_ISCOMPLETE}?token=${params?.token}`, null, false);
        //console.log(data?.data);
        setIsComplete(data?.data);
        setPageload(true);
    }, [params]);

    useEffect(()=>{
        verifyComplete();
    },[verifyComplete])

    if(!params?.token)
        return (
            <div className="page-feedback">
                Link inválido 
            </div>
        )

    if(isComplete){
        return (
            <div className="page-feedback">
                <div className="main-frame">
                    <div className="grid">
                        <div className="col-12 logo">
                            <img src={logo} alt="AFF Advocacia" />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="box-form finish">
                                <p>Seu feedback foi coletado com sucesso.</p>
                                <p>Obrigado.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if(!pageLoad){
        return(<div className="page-feedback"></div>)

    }

    return(
        <div className="page-feedback">
            <div className="main-frame">
                <div className="grid">
                    <div className="col-12 logo">
                        <img src={logo} alt="AFF Advocacia" />
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="box-form">
                            <h3>
                               Sua opinião é muito importante, nos ajude passando o feedback do atendimento:
                            </h3>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {
                                    CSAT.map(item => <div key={`csat_${item.id}`} className="grid mt-2 mb-2">
                                        <div className="col-9">
                                            {item.question}
                                        </div>
                                        <div className="col-3">
                                        <Controller name={`csat_${item.id}`} roles={{required: true}} control={control}  render={({ field }) => (
                                                <>
                                                    <Rating cancel={false} {...field } id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} stars={5}  />
                                                </>
                                            )} />
                                        </div>
                                    </div>)
                                }
                                {
                                    NPS.map(item => <div key={`NSP_${item.id}`} className="grid mt-4 mb-2 grid-nps">
                                        <div key={`nps_${item.id}`} className="col-12">
                                            <strong>{item.question}</strong>
                                        </div>
                                        <div className="col-12 nps">
                                            <Controller name={`nps_${item.id}`} roles={{required: true}} control={control}  render={({ field }) => (
                                                <>
                                                    <SelectButton 
                                                    options={[
                                                        {label:0, value:0, className:'item-1'},
                                                        {label:1, value:1, className:'item-2'},
                                                        {label:2, value:2, className:'item-3'},
                                                        {label:3, value:3, className:'item-4'},
                                                        {label:4, value:4, className:'item-5'},
                                                        {label:5, value:5, className:'item-6'},
                                                        {label:6, value:6, className:'item-7'},
                                                        {label:7, value:7, className:'item-8'},
                                                        {label:8, value:8, className:'item-9'},
                                                        {label:9, value:9, className:'item-10'},
                                                        {label:10, value:10, className:'item-11'}
                                                    ]} {...field } id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} stars={5} cancel={false} />
                                                </>
                                            )} />
                                        </div>
                                    </div>)
                                }
                                <div className="grid mt-4">
                                    <div className="col-12 area-buttons">
                                        <Button label="Enviar" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}