import React, { useCallback, useEffect, useState } from "react";

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { useParams } from "react-router-dom";
import { URL_USER_MY_NOTIFICATION_READ, api } from "@/services/api";

export const PageNotificationView = () => {

    const params = useParams();

    const [content, setContent] = useState();

    
    const getData = useCallback(async () => {
        const data = await api.get(`${URL_USER_MY_NOTIFICATION_READ}/${params?.id}`, null, true);
        setContent(data.data);
    }, [params])
    
    
    useEffect(()=>{
        console.log(params.id);
        getData();
    }, [params, getData]);

    return <div key={`page-notification-id-${params?.id}`} className="page-notification-view">
        <div className="breadcrump">
            <UIBreadcrumb 
                model={[
                    {label:'Início', url:'/user'},
                    {label:'Notificações', url:'/user/notification'},
                    {label:'Leitura'}
                ]}
            />
        </div>
        <div className="grid mt-4">
            <div className="col-12">
                <h3>
                    {content?.title}
                </h3>
            </div>
            <div className="col-12">
                {content?.description}
            </div>
        </div>
    </div>
}