import React, {useState, useCallback, useEffect, useRef} from "react";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
 import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
 
import { useForm, Controller } from 'react-hook-form';

import { api, URL_FINANCE_BYCASE, URL_FINANCE_PAYMENT_MODE, URL_FINANCE_PAID } from "@/services/api";
import { formatMoney } from "@/services/utils";
import { Button } from "primereact/button";

export const PageUserProcessCasesFinance = (props) => {

    const [content, setContent] = useState();
    const [showDialogFinance, setShowDialogFinance] = useState(false);
    const [financeData, setFinanceData] = useState();
    const [paymentMode, setPaymentMode] = useState();

    const form = useForm({});
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const toast = useRef(null);

    const getData = useCallback(async ()=> {
        const data = await api.get(`${URL_FINANCE_BYCASE}/${props?.data?.id}`, null, true);
        setContent(data.data || []);
        //console.log(data.data);
    }, [props, setContent]);

    const onSubmitFinance = useCallback(async (_data) => {
        
         const senddata = {};
         senddata.finance_id = financeData?.id;
         senddata.finance_mode_paid_id = _data.type;
         senddata.timepaid = new Date();
         senddata.paid = _data.value;
         senddata.total = financeData.value;
        // senddata.ispayment = 1;

        let result;

        // start.current = start.current || new Date();
        
        // senddata.date = `${start.current.toISOString().substr(0, 10)} ${start.current.toLocaleTimeString()}`;
        result = await api.post(URL_FINANCE_PAID, senddata, true);

        if(result){
            toast.current.show({severity:'success', summary: 'Sucesso', detail:'Dados Salvos com sucesso', life: 3000});
        }else{
            toast.current.show({severity:'error', summary: 'Erro', detail:'Houve problemas ao salvar os dados. Tente novamente.', life: 3000});
        }

        reset({});
        setShowDialogFinance(false);
        getData()
    },[reset, setShowDialogFinance, getData, financeData])

    const getFinancePaymentModeData = useCallback(async () => {

        const data =  await api.get(URL_FINANCE_PAYMENT_MODE, null, true);
        setPaymentMode(data.data);

    }, [])

    useEffect(()=>{
        getData();
        getFinancePaymentModeData();
    }, [getData, getFinancePaymentModeData])


    if(!content)
        return (
            <div className="page-user-processcases-finance">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-user-processcases-finance">
            <div className="grid">
                {/* <div className="col-3">
                    <div><strong><small>Data de criação</small></strong></div>
                    <div>{(content?.date ? `${new Date(content?.date).toLocaleDateString()} - ${new Date(content?.date).toLocaleTimeString()}` : '-')}</div>
                </div>
                <div className="col-3">
                    <div><strong><small>Última atualização</small></strong></div>
                    <div>{content?.timemodified ? `${new Date(content?.timemodified).toLocaleDateString()} - ${new Date(content?.timemodified).toLocaleTimeString()}` : '-'}</div>
                </div>
                <div className="col-2">
                    <div><strong><small>Parcelas</small></strong></div>
                    <div>{content?.portion ? `${~~(content?.paids?.filter(el => ~~el.ispayment === 1))?.length} / ${~~content?.portion}` : '-'}</div>
                </div>
                <div className="col-2">
                    <div><strong><small>Valor pago</small></strong></div>
                    <div>{formatMoney(content?.paid)}</div>
                </div>
                <div className="col-2">
                    <div><strong><small>Valor total</small></strong></div>
                    <div>{formatMoney(content?.total)}</div>
                </div> */}
                <div className="col-12">
                <h3>
                    Detalhes de pagemnto
                </h3>
                <DataTable 
                value={content} 
                responsiveLayout="scroll"
                emptyMessage="Não há pagamentos cadastrados"
                size='small'
                stripedRows
            >
                <Column  body={(row)=> {
                    return row.title;
                } } header="Titulo" />
                <Column style={{width:'120px'}} body={(row)=> {
                    return row?.date ? new Date(row?.date).toLocaleDateString() : '-';
                } } header="Vencimento" />
                <Column style={{width:'120px'}} body={(row)=> {
                    const paid = row?.paids?.at(-1);
                    return paid?.timepaid ? new Date(paid?.timepaid).toLocaleDateString() : '-';
                } } header="Pagamento" />
                <Column style={{width:'150px'}}  body={(row)=> {
                    const sum = row?.paids?.reduce((a, n) => parseFloat(n.paid) + parseFloat(a) , 0);
                    return formatMoney(sum.toFixed(2));
                } } header="Valor" />
                <Column style={{width:'150px'}} body={(row)=> {
                    const now = new Date();
                    const limit = new Date(row.date);
                    const isValid =  limit > now;

                    const sum = row?.paids?.reduce((a, n) => parseFloat(n.paid) + parseFloat(a) , 0);
                    const isPaided = sum === parseFloat(row?.value);

                    return <Tag style={{width:'100%'}} severity={isPaided ? 'success' : (isValid ? 'Warning' : 'danger')} value={isPaided ? 'Pago' : (isValid ? 'Em aberto' : 'Atrasado')} />
                } } header="Status" />
                <Column style={{width:'50px', textAlign:'right'}} body={(row)=> {
                    const sum = row?.paids?.reduce((a, n) => parseFloat(n.paid) + parseFloat(a) , 0);
                    const isPaided = sum === parseFloat(row?.value);
                    return <>
                        {
                            isPaided ?
                                <>
                                    {/* <Button icon="pi pi-print" className="p-button-text p-button-secondary" /> */}
                                </>
                            :
                                <Button onClick={()=> { form.setValue('type', row?.finance_mode_paid_id); form.setValue('value', (parseFloat(financeData.value) - financeData?.paids?.reduce((a, n) => parseFloat(n.paid) + parseFloat(a) , 0)).toFixed(2)); setFinanceData(row); setShowDialogFinance(true) } } icon="pi pi-dollar" className="p-button-text p-button-help" />
                        }
                    </>;
                } } header="" />
            </DataTable>
                </div>
            </div>

            <Dialog header="Pagamento" visible={showDialogFinance} style={{ width: '90vw', maxWidth:'1000px' }} footer={null} onHide={() =>{reset({}); setShowDialogFinance(false);}}>
                <form onSubmit={handleSubmit(onSubmitFinance)}>
                    <div className="grid">
                        <div className="col-12" style={{display:'flex', alignItems:'center'}}>
                            <h3>
                                {financeData?.title }
                            </h3>
                        </div>
                        <div className="col-4" style={{display:'flex', alignItems:'center'}}>
                           Total: {formatMoney( parseFloat(financeData?.value).toFixed(2) )}
                        </div>
                        <div className="col-4" style={{display:'flex', alignItems:'center'}}>
                           Pago: {formatMoney( parseFloat(financeData?.paids?.reduce((a, n) => parseFloat(n.paid) + parseFloat(a) , 0)).toFixed(2) )}
                        </div>
                        <div className="col-4">
                            <Controller name={`type`}  defaultValue={~~financeData?.finance_mode_paid_id} control={control}  render={({ field, fieldState }) => (
                                <Dropdown 
                                
                                    required
                                    options={paymentMode}
                                    optionLabel='name'
                                    optionValue='id'
                                    inputStyle={{width:'100%'}}  placeholder='Forma pagamento' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        
                        </div>
                        <div className="col-2" style={{display:'flex', alignItems:'center'}}>
                            Valor a ser lançado
                        </div>    
                        <div className="col-4">
                            <Controller name={`value`} control={control}  render={({ field, fieldState }) => (
                                <InputText  {...field} type='number' pattern="[0-9]*,[0-9]{2}" min={0} max={parseFloat(financeData.value) - financeData?.paids?.reduce((a, n) => parseFloat(n.paid) + parseFloat(a) , 0)} step={0.01} required  placeholder='valor' className='' style={{width:'100%'}} onValueChange={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value}  />
                            )} />
                        </div>
                        <div className="col-12" style={{textAlign:'right'}}>
                            <Button className='p-button-warning' label="Lançar pagamento"/>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}