export const calculateOlder = (_date = null) => {

    if(!_date)
        return '';

    const end = new Date();
    const start = new Date(_date?.split('-')[0], ~~_date?.split('-')[1] - 1, _date?.split('-')[2]);

    let d = 0;
    let mo = end.getMonth() - start.getMonth()
    let y = end.getYear() - start.getYear();
    

    if( end.getMonth() < start.getMonth())
        y--;

    if(end.getDate() >= start.getDate()){
        d = end.getDate() - start.getDate();
    }else{
        d = 31 + end.getDate() - start.getDate();

        mo--;
        if(mo < 0){
            mo = 11;
            y--;
        }
    }

    return `${y} ${y <= 1 ? 'ano' : 'anos'}, ${mo} ${ mo <= 1 ? 'mês' : 'meses'}, ${d} ${d <= 1 ? 'dia' : 'dias' }`;
}

export const formatCPJCNPJ = (_data = null) => {
    if(!_data)
        return '';
    
    let doc = [];
    let point = 0;

    if(_data.length === 11){
        _data.split('').map(item => {
            doc.push(item);
            point++;
            if(point % 3 === 0 && point < 9)
                doc.push('.');
            else if(point === 9){
                doc.push('-')
            }
            return item;
        })
    }else{

    }

    return doc.join('');
}

export const formatRG = (_data = null) => {
    if(!_data)
        return '';
    
    let doc = [];

    if((/^[a-z]*[0-9]/gi).test(_data)){
        _data.split('').map((item, id, _arr) => {
            if((/[a-z]/gi).test(item) ){
                doc.push(item.toUpperCase());
            }
            if((/[a-z]/gi).test(item) && (/[0-9]/gi).test(_arr[id + 1 ]) ){
                doc.push(' ');
            }

            if((/[0-9]/gi).test(item) ){
                doc.push(item);
            }
            return item;
        })
    }else{
        doc = _data.split('');
    }

    return doc.join('');
}

export const formatPhoneNumber = (_data = null) => {
    if(!_data)
        return '';

    const type = _data.length === 11 ? 'mobile' : 'phone';

    let tel = [];
    let poiter = 1;
    if((/phone/gi).test(type)){
        tel.push('(');
        _data.split('').map(item => {
            tel.push(item);
            poiter++;

            if(poiter === 3){
                tel.push(')');
                poiter++;
                tel.push(' ');
                poiter++;
            }

            if(poiter === 9){
                tel.push(' ');
                poiter++;
            }

            return item;
        })
    }else{
        tel.push('(');
        _data.split('').map(item => {
            tel.push(item);
            poiter++;

            if(poiter === 3){
                tel.push(')');
                poiter++;
                tel.push(' ');
                poiter++;
            }

            if(poiter === 10){
                tel.push(' ');
                poiter++;
            }

            return item;
        })
    }

    return tel.join('');
}

export const formatMoney = (_data = null) => {
    if(!_data){
        return `R$ 0,00`;
    }

    let [real, cents] = _data?.toString()?.split('.');
    real = ~~(real || 0);
    cents = ~~(cents || 0);

    let strreal = [];
    const realDigits = real.toString().split('').length;
    let count = 0;
    real.toString().split('').reverse().map((item) => {
        
        if(realDigits > 3){
            if(count  === 3){
                strreal.unshift('.');
                count = 1;
            }else{
                count++;
            }
        }

        strreal.unshift(item);
        

        return item;
    })
    const strcents = cents < 10 ? `0${cents}` : cents;

    return `R$ ${strreal.join('')},${strcents}`;

}

export const calculateDurationProcessCases = (_start = null, _end = null) => {
    if(!_start){
        return  '-';
    }

    const start = new Date(_start);
    const end = _end ? new Date(_end) : new Date();

    const time = end - start;

    const s = Math.round((time / 1000) % 60);
    const m = Math.round((time / 60000) % 60);
    const h = Math.round((time / 3600000) % 24);
    const dt = Math.floor((time / 86400000));
    let d = 0;
    let mo = end.getMonth() >= start.getMonth() ? (end.getMonth() - start.getMonth()) : (end.getMonth() - start.getMonth()) + 12;
    let y = end.getYear() - start.getYear();

    if( end.getMonth() < start.getMonth())
        y--;

    if(end.getDate() >= start.getDate()){
        d = end.getDate() - start.getDate();
    }else{
        d = 31 + end.getDate() - start.getDate();

        mo--;
        if(mo < 0){
            mo = 11;
            y--;
        }
    }


    return {
        full: `${dt} Dias corridos - ${y} ${y <= 1 ? 'ano' : 'anos'}, ${mo} ${ mo <= 1 ? 'mês' : 'meses'}, ${d} ${d <= 1 ? 'dia' : 'dias' }, -  ${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`,
        time: `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`,
        days: `${d} dia(s)`
    };

}

export const formatShortName = (_name) => {
    if(!_name){
        return _name;
    }
    const names = _name?.split(' ');

    return names?.length > 1 ? `${names.at(0)} ${names.at(-1)}` : names.at(0);
}