import React, { useState, useCallback, useEffect, useMemo, useRef} from 'react';

import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { useNavigate } from 'react-router-dom';
import { api, URL_FINANCE_FLOW_BY_PERIOD, URL_FINANCE_PAID } from '@/services/api';
import { formatMoney } from '@/services/utils';

import { FilterMatchMode, FilterOperator } from 'primereact/api';

export const PageFinanceFlow = () => {

    const navigate = useNavigate();


    const toast = useRef(null);

    const descriptionSend =  useRef();

    const temp = new Date();

    const [calendar, setCalendar] = useState(new Date(temp.getFullYear(), temp.getMonth(), 1));
    const [calendarEnd, setCalendarEnd] = useState(new Date(temp.getFullYear(), temp.getMonth() + 1, 0));
    const [ list, setList] = useState();
    const [filter, setFilter] = useState({
        'case_name': { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const [showDetails, setShowDetails] = useState(false);
    const [detailsContent, setDetailsContent] = useState();

    const [showPayment, setShowPayment] = useState(false);
    const [selectPayment, setSelectPayment] = useState();
    const [detailsPayment, setDetailsPayment] = useState(null);
    const [currentPaymentSend, setCurrentPaymentSend] = useState();
    const [currentDescription, setCurrentDescription] = useState('');
    const [currentPaymentDate, setCurrentPaymentDate] = useState(new Date());

    moment.locale('pt-br');

    const objDataLocale = useMemo(()=>({
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }),[])

    addLocale('pt-BR', objDataLocale);

    const selectPeriod = useCallback((e) =>{

        const reference = {
            month:()=>{
                const start = new Date();
                start.setDate(1);
                const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);

                setCalendar(start);
                setCalendarEnd(end);
            },
            quarter:()=> {
                const start = new Date();
                const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);

                start.setDate(1);
                start.setMonth(start.getMonth() - 3);

                setCalendar(start);
                setCalendarEnd(end);
            },
            semester:()=>{
                const start = new Date();
                const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);

                start.setDate(1);
                start.setMonth(start.getMonth() - 6);

                setCalendar(start);
                setCalendarEnd(end);
            },
            year:()=>{
                const start = new Date();
                start.setDate(1);
                start.setFullYear(start.getFullYear() - 1);
                const end = new Date();


                setCalendar(start);
                setCalendarEnd(end);
            }
        }

        if(reference[e]){
            reference[e]();
        }else{
            const start = new Date();
            start.setDate(1);
            const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
            setCalendar(start);
            setCalendarEnd(end);
        }
    },[])

    const getData = useCallback(async () => {

        //console.log(calendar?.toISOString().substring(0, 10), calendarEnd?.toISOString().substring(0, 10));

        let result = await api.get(`${URL_FINANCE_FLOW_BY_PERIOD}/${calendar?.toISOString().substring(0, 10)}/${calendarEnd?.toISOString().substring(0, 10)}`, null , true);
        result.data = result?.data?.map(item => {
            const current = new Date(item.date);
            let monthText = objDataLocale.monthNames[current.getMonth()].split('');
            monthText[0] = monthText[0].toLocaleUpperCase();
            monthText = monthText.join('');

            item.dategroup = `${monthText} de ${current.getFullYear()}`
            
            return item;
        })
        //console.log(result.data);
        setList(result.data);

    }, [calendar, calendarEnd, objDataLocale])

    const sendPayment = useCallback(async () => {

        const sendData = {
            finance_id: selectPayment.id,
            finance_mode_paid_id: selectPayment.finance_mode_paid_id,
            total: selectPayment.value,
            paid: currentPaymentSend,
            description: currentDescription,
            timepaid: `${currentPaymentDate.toISOString().substr(0, 10)} ${currentPaymentDate.toLocaleTimeString()}`

        };

        const rs = await api.post(URL_FINANCE_PAID, sendData, true);

        toast.current.show({severity:'success', summary: 'Sucesso', detail:'Dados gravados com sucesso', life: 3000});

        setShowPayment(false);
        
        getData();

    }, [selectPayment, currentDescription, currentPaymentSend, getData]);

    const openDetail = (_data) => {
        console.log(_data);
        
        const _html = <div>
            <div className="grid">
                <div className="col-12">
                    <h4>
                        {_data.title}
                    </h4>
                </div>
                <div className="col-12">
                    {_data.description}
                </div>
            </div>
        </div>

        setDetailsContent(_html);
        setShowDetails(true);
    }


    const openPayment = useCallback((_data) => {
        console.log('#', _data);
        setSelectPayment(_data);
        setCurrentPaymentSend(_data.value);
        setCurrentDescription('');
        setShowPayment(true);
    }, [setSelectPayment, setCurrentPaymentSend, setShowPayment, setCurrentDescription])


    useEffect(()=>{
        getData();
    }, [calendar, calendarEnd, getData])

    useEffect(()=>{

        if(!showPayment){
            setDetailsPayment(null); 
        }

    }, [showPayment])

    useEffect(()=>{
        getData();
    }, [getData])

    if(!list)
        return (
            <div className="page-finance-flow">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-finance-flow">
            <div className="header">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><>
                        <strong>Período: </strong>
                        <Calendar className='ml-2' dateFormat="dd/mm/yy" showIcon locale='pt-BR' value={calendar} onChange={(e)=>setCalendar(e.value)} />
                        <Calendar className='ml-3 mr-3' dateFormat="dd/mm/yy" showIcon locale='pt-BR' minDate={calendar} value={calendarEnd} onChange={(e)=>setCalendarEnd(e.value)} />
                        <SplitButton onClick={()=>selectPeriod('month')} label="Mês" className="p-button-raised"
                            model={[
                                {label:'Trimeste', command:()=>selectPeriod('quarter')},
                                {label:'Semestre', command:()=>selectPeriod('semester')},
                                {label:'Anual', command:()=>selectPeriod('year')}
                            ]} 
                         />
 
                    </>} 
                    right={()=><>
                    </>} 
                />
            </div>
            <div className="grid mt-2">
                <div className="col-12">
                <DataTable 
                    value={list} 
                    responsiveLayout="scroll"
                    emptyMessage="Não há dados cadastrados"
                    size='small'
                    stripedRows
                    groupRowsBy="dategroup"
                    rowGroupMode="subheader"
                    // filterDisplay='row'
                    // rowGroupMode='rowspan'
                    filters={filter}
                    rowGroupHeaderTemplate={(data)=>{
                        return <div className='grid' style={{backgroundColor:'var(--blue-50)', margin: 0}}>
                            <div className="col-12">
                                <strong>
                                    {data.dategroup}
                                </strong>
                            </div>
                        </div>
                    }}
                    rowGroupFooterTemplate={(data)=>{
                        return <>
                        <td colSpan="3"  style={{ textAlign: 'left', backgroundColor:'var(--blue-600)', color:'#fff' }}>
                            {data.dategroup}
                        </td>
                            <td colSpan="4"  style={{ textAlign: 'right', backgroundColor:'var(--blue-600)', color:'#fff' }}>
                                <div>
                                    <strong className='mr-2'>
                                        A receber:
                                    </strong>
                                    {formatMoney(list?.reduce((c, n) =>{
                                        if(n.dategroup === data.dategroup){
                                            return c+= parseFloat(n.value)
                                        }else{
                                            return c;
                                        }
                                    }, 0).toFixed(2))}
                                </div>
                                <div className='mt-2'>
                                    <strong className='mr-2'>Total recebido:</strong>
                                    {formatMoney(list?.reduce((c, n) =>{
                                        if(n.dategroup === data.dategroup && n.timepaid){
                                            return c+= parseFloat(n.value)
                                        }else{
                                            return c;
                                        }
                                    }, 0).toFixed(2))}
                                </div>
                            </td>
                        </>
                    }}

                    footer={()=>{
                        return <div style={{textAlign:'right'}} className="mt-3">
                            <div>
                                <strong>Total a receber</strong>: <span>{formatMoney(list?.reduce((c, n) =>{
                                    return c+= n.value ? parseFloat(n.value) : 0;
                                }, 0).toFixed(2))}</span>
                            </div>
                            <div className='mt-2'>
                                <strong>Total a recebido</strong>: <span>{formatMoney(list?.reduce((c, n) =>{
                                    return  n.timepaid ? c+= parseFloat(n.value) : c;
                                }, 0).toFixed(2))}</span>
                            </div>
                        </div>
                    }}
                >
                    
                    <Column style={{width:'120px'}} body={(row)=> {
                        return new Date(`${row.date} 00:00:00`).toLocaleDateString();
                    } } header="Vencimento" />
                    <Column body={(row)=> {
                        return row.title;
                    } } header="Título" />
                    <Column style={{minWidth:'150px'}} body={(row)=> {
                        return row.value ? formatMoney(row.value) : '-';
                    } } header="Valor" />
                    <Column style={{minWidth:'150px'}} body={(row)=> {
                        return row.valuepaid ? formatMoney(row.valuepaid) : '-';
                    } } header="Pago" />
                    <Column style={{width:'20px', textAlign:'center'}} body={(row)=> {
                        return <i className={`pi ${row.direction === 'in' ? 'pi-arrow-up' : 'pi-arrow-down'}`} style={{color:`${row.direction === 'in' ? 'var(--green-400)' : 'var(--red-400)'}`}} />;
                    } } header="D/C" />
                    <Column style={{width:'60px', textAlign:'center'}} body={(row)=> {
                        return (row.timepaid && parseFloat(row.value) === parseFloat(row.valuepaid) ? <i className='pi pi-check-circle' style={{color:'var(--green-400)'}} /> : (row.timepaid && row.valuepaid ? <i className='pi pi-exclamation-circle' style={{color:'var(--yellow-400)'}} /> : <i className='pi pi-times-circle' style={{color:'var(--red-400)'}} /> ))
                    } } header="Status" />
                    <Column 
                        style={{width:'120px', textAlign:'right'}}
                        body={(row)=>{
                            return <>
                                {
                                    !(row.timepaid) ?
                                        <Button onClick={()=> { openPayment(row) }} icon="pi pi-dollar" className='p-button-sm p-button-text' />
                                    :
                                        ''
                                }
                                <Button onClick={()=> openDetail(row)} icon="pi pi-eye" className='p-button-sm p-button-text' />
                            </>
                        }}
                    />
                </DataTable>
                </div>
            </div>
            <Dialog header="Detalhes" visible={showDetails} style={{ maxWidth: '90%', minWidth: '300px' }} footer={()=>{}} onHide={() => setShowDetails(false)}>
                {detailsContent}
            </Dialog>

            <Dialog header="Lançamento" visible={showPayment} style={{ maxWidth: '90%', minWidth: '300px' }} footer={()=>{}} onHide={() =>{ setShowPayment(false); } } >
                <div>
                    <div className="grid">
                        <div className="col-12">
                            <h4>
                                {selectPayment?.title}
                            </h4>
                        </div>
                        <div className="col-12">
                            {selectPayment?.description}
                        </div>
                        <div className="col-12">

                        </div>
                        <div className="col-3" style={{alignItems:'center'}}>
                            <div className='mb-4'><strong>Referência</strong>:</div>
                            <div>{`${selectPayment?.ref}`}</div>
                        </div>
                        <div className="col-1" style={{alignItems:'center'}}>
                            <div className='mb-4'><strong>Vencimento</strong>:</div>
                            <div>{new Date(`${selectPayment?.date} 00:00:00`).toLocaleDateString()}</div>
                        </div>
                        <div className="col-1" style={{alignItems:'center'}}>
                            <div className='mb-4'><strong>C/D</strong>:</div>
                            <div>{`${(/in/gi).test(selectPayment?.direction) ? 'Entrada' : 'Saída' }`}</div>
                        </div>
                        <div className="col-1" style={{alignItems:'center'}}>
                            <div className='mb-4'><strong>Parcela</strong>:</div>
                            <div>{selectPayment?.number}</div>
                        </div>
                        <div className="col-3" style={{alignItems:'center'}}>
                            <div className='mb-2'><strong>Valor a ser lançado</strong>:</div>
                            <div>
                                <InputNumber allowEmpty={false} showButtons mode="decimal" locale="pt-BR" minFractionDigits={2} maxFractionDigits={2} onValueChange={(e)=> setCurrentPaymentSend(e.value)} style={{width:'100%'}} value={parseFloat(currentPaymentSend).toFixed(2)}  max={(parseFloat(selectPayment?.value) - parseFloat(selectPayment?.valuepaid || 0)).toFixed(2) } min={0} step={0.01} />
                            </div>
                        </div>
                        <div className="col-3" style={{alignItems:'center'}}>
                            <div className='mb-2'><strong>Data lançamento</strong>:</div>
                            <div>
                                <Calendar style={{width:'100%'}} dateFormat="dd/mm/yy" showTime showIcon locale='pt-BR' value={currentPaymentDate} onChange={(e)=>setCurrentPaymentDate(e.value)} />
                            </div>
                        </div>
                        <div className="col-2" style={{display:'flex', alignItems:'center'}}>
                        </div>
                        <div className="col-4">
                        </div>
                        <div className="col-12">
                            <InputTextarea autoResize style={{width:'100%'}} placeholder='Comentário' onInput={(e)=> { setCurrentDescription(e.target.value) }} value={currentDescription} />
                        </div>
                        <div className="col-12" style={{textAlign:'right'}}>
                            <Button label='Salvar' onClick={()=> sendPayment() } className='p-button-sm' />
                        </div>
                    </div>
                </div>
            </Dialog>

            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}