import React, { useCallback, useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useNavigate, useParams } from 'react-router-dom';
import { api, URL_PROCESSCASES_BYUSER } from '@/services/api';
import { formatShortName } from '@/services/utils';

export const PageProfileUserProcessCases = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState();
    const [showinfo, setShowinfo] = useState(false);
    const [infotext, setInfotext] = useState();

    const openInfo = useCallback((data)=>{
        setInfotext(data);
        setShowinfo(true);
    }, [])

    const getData = useCallback(async () => {
        const _data = await api.get(`${URL_PROCESSCASES_BYUSER}/${params?.id}`, null, true);
        setData(_data.data);
        //console.log(_data.data);

    }, [params]);

    useEffect(() => {
        getData();
    }, [getData])

    return (
        <div className="page-profile-attendance">
            <DataTable 
            style={{width:'100%'}}
            size="small"
            value={data} 
            emptyMessage="Não há atendimentos"
            rows={10}
            paginator={data?.length > 10}
            >
                <Column body={(row)=> {
                    return row?.id;
                } } header="ID" />
                <Column body={(row)=> {
                    return row?.id ? <span style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/processcases/${row?.id}`, {state:{tabActive:0}})}>{row?.name}</span> : '-';
                } } header="Titulo" />
                <Column body={(row)=> {
                    return formatShortName(row?.name_user_adm);
                } } header="Responsável" />
                <Column body={(row)=> {
                    return row?.timecreated ? `${new Date(row?.timecreated).toLocaleDateString()} - ${new Date(row?.timecreated).toLocaleTimeString()}` : '-';
                } } header="Aberto" />
                <Column style={{width:'120px'}} body={(row)=> {
                    return row?.status_name;
                } } header="Status" />
                <Column style={{width:'120px'}} body={(row)=>{
                    return <>
                        <Button onClick={()=>openInfo(row)} className='p-button-sm p-button-text mr-2' icon="pi pi-book" />
                        <Button onClick={()=>navigate(`/user/processcases/${row.id}`)} className='p-button-text p-button-info' icon='pi pi-eye' />;
                    </>
                }} header="" />
                    
            </DataTable>
            <Dialog header={`Detalhes atendimento - ${infotext?.id}`}
                visible={showinfo} 
                style={{ width: '98%', maxWidth:'600px' }} 
                onHide={() =>{setShowinfo(false);}}>
                    {infotext?.description}
            </Dialog>
        </div>
    )
}