export const SITE_MAP = {
    home_client:{
        private: true,
        menu_mark:'/user',
        path:'',
        url:'/user',
        component:'PageUserHome'
    },
    home_user:{
        private: true,
        menu_mark:'/user',
        path:'',
        url:'/user',
        component:'PageUserHome'
    },
    calendar_client:{
        private: true,
        menu_mark:'/user/calendar',
        path:'/calendar',
        url:'/user/calendar',
        component:'PageClientCalendar'
    },
    calendar_user:{
        private: true,
        menu_mark:'/user/calendar',
        path:'/calendar',
        url:'/user/calendar',
        component:'PageUserCalendar'
    },
    calendar_collaborador_ausence:{
        private: true,
        menu_mark:'/user/calendar/ausence',
        path:'/calendar/ausence',
        url:'/user/calendar/ausence',
        component:'PageCollaboratorCalendarAusence'
    },
    process_cases_client:{
        private: true,
        menu_mark:'/user/processcases',
        path:'/processcases',
        url:'/user/processcases',
        component:'PageUserProcessCases'
    },
    process_cases_create:{
        private: true,
        menu_mark:'/user/processcases/create',
        path:'/processcases/create',
        url:'/user/processcases/create',
        component:'PageProcessCasesCreate'
    },
    process_cases_user:{
        private: true,
        menu_mark:'/user/processcases',
        path:'/processcases',
        url:'/user/processcases',
        component:'PageUserProcessCases'
    },
    process_cases_view:{
        private: true,
        menu_mark:'/user/processcases/[0-9]*',
        path:'/processcases/:id',
        url:'/user/processcases/:id',
        component:'PageUserProcessCasesView'
    },
    clients:{
        private: true,
        menu_mark:'/user/clients',
        path:'/clients',
        url:'/user/clients',
        component:'PageClients'
    },
    profile_users:{
        private: true,
        menu_mark:'/user/profile/[0-9]*',
        path:'/profile/:id',
        url:'/user/profile/:id',
        component:'PageProfileUser'
    },
    profile_collaborators:{
        private: true,
        menu_mark:'/user/collaborator/[0-9]*',
        path:'/collaborator/:id',
        url:'/user/collaborator/:id',
        component:'PageProfileCollaborator'
    },
    attendance:{
        private: true,
        menu_mark:'/user/attendance',
        path:'/attendance',
        url:'/user/attendance',
        component:'PageUserAttendance'
    },
    attendance_create:{
        private: true,
        menu_mark:'/user/attendance/create',
        path:'/attendance/create',
        url:'/user/attendance/create',
        component:'PageUserAttendanceCreate'
    },
    attendance_view:{
        private: true,
        menu_mark:'/user/attendance/[0-9]*',
        path:'/attendance/:id',
        url:'/user/attendance/:id',
        component:'PageUserAttendanceView'
    },
    services:{
        private: true,
        menu_mark:'/user/services',
        path:'/services',
        url:'/user/services',
        component:'PageConfiguration'
    },
    create_client:{
        private: true,
        menu_mark:'/user/client',
        path:'/client',
        url:'/user/client',
        component:'PageClientCreate'
    },
    feedback:{
        private: true,
        menu_mark:'/user/feedback',
        path:'/feedback',
        url:'/user/feedback',
        component:'PageUserFeedback'
    },
    tickets:{
        private: true,
        menu_mark:'/user/tickets',
        path:'/tickets',
        url:'/user/tickets',
        component:'PageUserTicket'
    },
    ticket_view:{
        private: true,
        menu_mark:'/user/tickets/[0-9]*',
        path:'/tickets/:id',
        url:'/user/tickets/:id',
        component:'PageUserTicketView'
    },
    finance:{
        private: true,
        menu_mark:'/user/finance',
        path:'/finance',
        url:'/user/finance',
        component:'PageFinance'
    },
    finance_create:{
        private: true,
        menu_mark:'/user/finance/create',
        path:'/finance/create',
        url:'/user/finance/create',
        component:'PageFinanceCreate'
    },
    collaborators:{
        private: true,
        menu_mark:'/user/collaborators',
        path:'/collaborators',
        url:'/user/collaborators',
        component:'PageCollaborators'
    },
    create_collaborators:{
        private: true,
        menu_mark:'/user/collaborators',
        path:'/collaborators/create',
        url:'/user/collaborators/create',
        component:'PageCollaboratorsCreate'
    },
    wiki:{
        private: true,
        menu_mark:'/user/wiki',
        path:'/wiki',
        url:'/user/wiki',
        component:'PageUserWiki'
    },
    create_wiki:{
        private: true,
        menu_mark:'/user/wiki',
        path:'/wiki/create',
        url:'/user/wiki/create',
        component:'PageUserWikiCreate'
    },
    read_wiki:{
        private: true,
        menu_mark:'/user/wiki',
        path:'/wiki/read/:id',
        url:'/user/wiki/read/:id',
        component:'PageUserWikiRead'
    },
    create_ticket:{
        private: true,
        menu_mark:'/user/ticket',
        path:'/ticket/create',
        url:'/user/ticket/create',
        component:'PageUserTicketCreate'
    },
    
    control_deadline:{
        private: true,
        menu_mark:'/user/deadline',
        path:'/deadline',
        url:'/user/deadline',
        component:'PageUserDeadline'
    },
    control_deadline_create:{
        private: true,
        menu_mark:'/user/deadline/create',
        path:'/deadline/create',
        url:'/user/deadline/create',
        component:'PageUserDeadlineCreate'
    },
    control_deadline_view:{
        private: true,
        menu_mark:'/user/deadline/view/:id',
        path:'/deadline/view/:id',
        url:'/user/deadline/view/:id',
        component:'PageUserDeadlineView'
    },
    calendar_collaborator_create:{
        private: true,
        menu_mark:'/user/calendar/create',
        path:'/calendar/create',
        url:'/user/calendar/create',
        component:'PageUserCalendarCreate'
    },
    control_expertise_deployment:{
        private: true,
        menu_mark:'/user/expertisedeployment',
        path:'/expertisedeployment',
        url:'/user/expertisedeployment',
        component:'PageUserExpertiseDeployment'
    },
    control_expertise_deployment_create:{
        private: true,
        menu_mark:'/user/expertisedeployment/create',
        path:'/expertisedeployment/create',
        url:'/user/expertisedeployment/create',
        component:'PageUserExpertiseDeploymentCreate'
    },
    control_expertise_deployment_view:{
        private: true,
        menu_mark:'/user/expertisedeployment/view/:id',
        path:'/expertisedeployment/view/:id',
        url:'/user/expertisedeployment/view/:id',
        component:'PageUserExpertiseDeploymentView'
    },
    dre:{
        private: true,
        menu_mark:'/user/dre',
        path:'/dre',
        url:'/user/dre',
        component:'PageDRE'
    },
    calendar_notification:{
        private: true,
        menu_mark:'/user/calendarnotification',
        path:'/calendarnotification',
        url:'/user/calendarnotification',
        component:'PageCalendarNotification'
    },

    partner:{
        private: true,
        menu_mark:'/user/partner',
        path:'/partner',
        url:'/user/partner',
        component:'PagePartner'
    },

    partner_create:{
        private: true,
        menu_mark:'/user/partner/create',
        path:'/partner/create',
        url:'/user/partner/create',
        component:'PagePartnerCreate'
    },

    partner_edit:{
        private: true,
        menu_mark:'/user/partner/edit/:id',
        path:'/partner/edit/:id',
        url:'/user/partner/edit/:id',
        component:'PagePartnerEdit'
    },

    


}