import { PageFeedback } from '@/pages/feedback';
import { PageLogin } from '@/pages/login';
import { RequireAuth } from '@/services/auth';
import { PageCalendar } from '@/pages/calendar';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NavigationBase } from './base';

export const NavigateRouter = () =>{

    
    return (
            <BrowserRouter>
                <Routes>
                    <Route path="user/*" element={
                        <RequireAuth>
                            <NavigationBase />
                        </RequireAuth>
                    } />
                    <Route path="/feedback" element={<PageFeedback />} >
                        <Route path=":token" element={<PageFeedback />} />
                    </Route>
                    <Route path="/agendamento" index element={<PageCalendar />} />
                    <Route path="*" index element={<PageLogin />} />
                </Routes>
            </BrowserRouter>
    )
}