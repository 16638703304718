import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { TabMenu } from 'primereact/tabmenu';
import { Tag } from 'primereact/tag';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';

import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { api, URL_PROFILE_CLIENT, URL_USER_UPDATE, URL_USER_CLIENT_TYPE } from '@/services/api';
import { calculateOlder, formatCPJCNPJ, formatRG } from '@/services/utils';

import { useForm, Controller } from 'react-hook-form';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { formatPhoneNumber } from '@/services/utils';

import { PageProfileUserCalendar } from './calendar';
import { PageProfileUserAttendance } from './attendance';
import { PageProfileUserFinance } from './finance';
import { PageProfileUserProcessCases } from './process_cases';
import { PageProfileUserDocument } from './document';

import '@/assets/styles/pages/profile/index.scss';

export const PageProfileUser = () => {

    
    const [user, setUser] = useState(null);
     
    const [active, setActive] = useState(0);
    const [isEdit, setIsEdit] = useState(false);

    const [alterPassword, setAlterPassword] = useState(false);

    const [listWorks, setListWorks] = useState([]);
    const [work, setWork] = useState();

    const [listContacts, setListContacts] = useState([]);
    const [contact, setContact] = useState();

    const [listAddress, setListAddress] = useState([]);
    const [address, setAddress] = useState();
    const addressData = useRef();
    const contactData = useRef();
    const responsableData = useRef();
    
    
    const [listResponsable, setListResponsable] = useState([]);
    const [responsable, setResponsable] = useState();

    const [listBank, setListBank] = useState([]);
    const [bank, setBank] = useState();

    const [listClientTypeData, setListClinetTypeData] = useState();
    const [listClientType, setListClientType] = useState([]);

    const workData = useRef();
    const responsablepointer = useRef();
    const bankpointer = useRef();

    const toast = useRef(null);
    
    const params = useParams();
    

    const form = useForm({}); 
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const tabMenuList = useMemo(()=>[
        {label: 'Agenda', component: <PageProfileUserCalendar user={params.id} />},
        {label: 'Atendimentos', component: <PageProfileUserAttendance user={params.id} />},
        {label: 'Documentos', component: <PageProfileUserDocument user={params.id} />},
        {label: 'Financeiro', component: <PageProfileUserFinance user={params.id} />},
        {label: 'Processos e casos', component: <PageProfileUserProcessCases user={params.id} />}
    ], [params]);

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje', 
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);
    

    const getData = useCallback(async () => {
        const data = await api.get(`${URL_PROFILE_CLIENT}/${params.id}`, false, true);
        console.dir(data.data);
        setUser(data?.data);
        setListWorks(data?.data?.user_work);
        setListContacts(data?.data?.user_contact);
        setListAddress(data?.data?.user_address);
        setListBank(data?.data?.user_bank);
        setListClientType(data?.data?.user_bank);
        setListResponsable(data?.data?.user_responsable);

    }, [setUser, params])

    const getContent = () => {

        if(tabMenuList[active]?.component){
            return tabMenuList[active]?.component;
        }

        return 'Página não encontrada';
    }

    const copyContent = (data) => {
        navigator.clipboard.writeText(data);
        toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Conteúdo copiado com sucesso'});
    }

    

    const addBank = useCallback((e)=>{
        e.preventDefault();
        const temp = listBank ? [...listBank] : [];
        const insert = {...bank};
        insert.id = bankpointer.current;
        bankpointer.current++;
        temp.push(insert);
        setBank('');
        setListBank(temp);
    }, [bank, listBank])

    const removeBank = useCallback((_data)=>{

        const newlist = listBank?.filter(item => ~~_data !== ~~item?.id);

        setListBank(newlist);

    }, [listBank]);

    const onRowEditCompleteBank = (e) =>{
        let temp  = [...listBank];
        temp[e.index] = e.newData;

        setListBank(temp);
    }

    const addResponsable = useCallback((e)=>{
        e.preventDefault();
        const temp = listResponsable ? [...listResponsable] : [];
        const insert = {...responsable};
        insert.id = `new_${~~responsablepointer.current}`;
        responsablepointer.current = ~~responsablepointer.current + 1;
        temp.push(insert);
        setResponsable('');
        setListResponsable(temp);
    }, [responsable, listResponsable])

    const removeResponsable = useCallback((_data)=>{

        const newlist = listResponsable?.filter(item => ~~_data !== ~~item?.id);

        setListResponsable(newlist);

    }, [listResponsable]);

    const onRowEditCompleteResponsable = useCallback((e) =>{
        let temp  = [...listResponsable];
        temp[e.index] = e.newData;

        setListResponsable((_d)=> temp);
    },[listResponsable])

    const addWork = useCallback(async ()=> {
        
        const temp = listWorks ? [...listWorks] : [];

        if(!workData.current?.id || workData.current?.id === 0){
            workData.current.temp_id = new Date().getTime();
            delete workData.current.id;
        }

        let sendData = {...workData.current}
        if(sendData?.actual){
            delete sendData?.end;
            delete sendData?.actual;
        }
        
        temp.push(sendData);
        setListWorks(temp);

        let tempreset = {...workData.current};
        Object.keys(tempreset)?.map(item => {
            tempreset[item] = '';
            return item;
        })
        
        setWork(tempreset);

    }, [listWorks, setListWorks, setWork, workData]);

    const removeWork = useCallback(async (_data) => {
        const newlist = listWorks?.filter(item => ~~_data !== ( item?.temp_id ? ~~item?.temp_id : ~~item?.id));
        setListWorks(newlist);
    }, [])

    const onRowEditCompleteWork = (e) =>{
        let temp  = [...listWorks];
        temp[e.index] = e.newData;

        setListWorks(temp);
    }


    const addContact = useCallback(async ()=> {
        const temp = listContacts ? [...listContacts] : [];

        if(!contactData.current?.id || contactData.current?.id === 0){
            contactData.current.temp_id = new Date().getTime();
            delete contactData.current.id;
        }

        let sendData = {...contactData.current}
        
        temp.push(sendData);
        setListContacts(temp);

        let tempreset = {...contactData.current};
        Object.keys(tempreset)?.map(item => {
            tempreset[item] = '';
            return item;
        })
        
        setContact(tempreset);
    }, [contactData, setContact, listContacts]);

    const removeContact = useCallback(async (_data) => {
        const newlist = listContacts?.filter(item => ~~_data !== ( item?.temp_id ? ~~item?.temp_id : ~~item?.id));
        setListContacts(newlist);
    }, [listContacts, setListContacts])

    const onRowEditCompleteContact = (e) =>{
        let temp  = [...listContacts];
        temp[e.index] = e.newData;

        setListContacts(temp);
    }


    const addAddress = useCallback(async (_data)=> {
        const temp = listAddress ?  [...listAddress] : [];

        if(!addressData.current?.id || addressData.current?.id === 0){
            addressData.current.temp_id = new Date().getTime();
            delete addressData.current.id;
        }

        let sendData = {...addressData.current}
        
        temp.push(sendData);
        setListAddress(temp);

        let tempreset = {...addressData.current};
        Object.keys(tempreset)?.map(item => {
            tempreset[item] = '';
            return item;
        })
        
        setAddress(tempreset);
    }, [listAddress, setListAddress, setAddress, addressData]);

    const removeAddress = useCallback(async (_data) => {
        const newlist = listAddress?.filter(item => ~~_data !== ( item?.temp_id ? ~~item?.temp_id : ~~item?.id));
        setListAddress(newlist);
    }, [listAddress, setListAddress])

    const onRowEditCompleteAddress = (e) =>{
        let temp  = [...listAddress];
        temp[e.index] = e.newData;

        setListAddress(temp);
    }


    const getCepData = useCallback(async (_data) => {
        const cep = _data?.replace(/\D/gi, '');
        if(cep?.length < 8)
            return;
        const data = await api.get(`https://viacep.com.br/ws/${cep}/json/`, null, false);

        const temp = {...address};
        temp.zipcode = data?.cep;
        temp.address = data?.logradouro;
        temp.district = data?.bairro;
        temp.city = data?.localidade;
        temp.state = data?.uf;

        setAddress(temp);

    }, [setAddress, address])

    const getAddressData = useCallback(async (_state, _city, _address) => {
        
        const state = _state?.replace(/^\s|\s$/gi,'');
        const city = _city?.replace(/^\s|\s$/gi,'');
        const addressData = _address?.replace(/^\s|\s$/gi,'').replace(/\s/gi,'+');

        const data = await api.get(`https://viacep.com.br/ws/${state}/${city}/${addressData}/json/`, null, false);
        const temp = {...address};
        temp.zipcode = data[0]?.cep;
        temp.address = data[0]?.logradouro;
        temp.district = data[0]?.bairro;
        temp.city = data[0]?.localidade;
        temp.state = data[0]?.uf;

        setAddress(temp);



    }, [setAddress, address])

    const searchAddress = useCallback(async (event) => {
        
        let addressFind = null;
        let stateFind = null;
        let cityFind = null;

        const accepctInfo = () => {
           getAddressData(stateFind, cityFind, addressFind);
        }


        confirmDialog({
            target: event.currentTarget,
            message: <>
                <p>Busque seu CEP</p>
                <div className="grid">
                    <div className="col-4">
                        <InputText onInput={(e)=>{
                            stateFind = e.target.value;
                        }} style={{width:'100%'}} placeholder='Estado' />
                    </div>
                    <div className="col-8">
                        <InputText onInput={(e)=>{
                            cityFind = e.target.value;
                        }} style={{width:'100%'}} placeholder='Cidade' />
                    </div>
                </div>
                <div>
                    <InputText onInput={(e)=>{
                        addressFind = e.target.value;
                    }} style={{width:'100%'}} placeholder='Rua' />
                </div>
            </>,
            acceptLabel: 'Buscar',
            rejectLabel:'Cancelar',
            accept: () => accepctInfo(),
            reject: () => null
        });
    }, [getAddressData])


    const onRowEditCompleteClientType = (e) =>{
        let temp  = [...listClientType];
        temp[e.index] = e.newData;

        setListClientType(temp);
    }

    const onSubmit = useCallback(async (_data) => {
        
        //console.log(_data);

        let temp = {
            user: {},
            user_data: {}
        };

        temp['user'].password = _data['password'];

        Object.keys(user)?.map(item => {
            if(!(/user_data|user_work|user_address|user_contact|user_bank|user_responsable|access|type|timemodified|user_id/gi).test(item))
                temp['user'][item] = _data[item];
            return item;
        })

        Object.keys((user?.user_data || []))?.map(item => {
            if(!(/id/gi).test(item))
                temp.user_data[item] = _data[item];
            else
                temp.user_data[item] = _data['user_data'][item];
            return item;
        })



        temp.user_work = listWorks ? [...listWorks] : null;
        temp.user_address = listAddress ? [...listAddress] : null;
        temp.user_contact = listContacts ? [...listContacts] : null;
        temp.user_responsable = responsableData.current?.length > 0 ? responsableData.current : null;
        temp.user_bank = listBank ? [...listBank] : null;


        temp.user_responsable = temp?.user_responsable?.map(item => {
            if((/new/gi).test(item.id))
                delete item.id;

            return item;
        })

        
        let _clientType = {client_type_id: null, client_type_extra_id: null};

        if((/_/gi).test(_data.client_type)){
            _clientType.client_type_id = _data.client_type.split('_')[0];
            _clientType.client_type_extra_id = _data.client_type.split('_')[1];
        }else{
            _clientType.client_type_id = _data.client_type;
        }
        
        console.log('##',temp);
        console.log('@@',listResponsable);

        temp.user_client_type = _data?.client_type ? _clientType : null;

        const accepctData = () => {
            
            if(!alterPassword){
                delete temp['user'].password;
            }
            //console.log(temp);

            const result = api.put(URL_USER_UPDATE, temp, true);

            setIsEdit(false);
            setAlterPassword(false);
            setUser('reload');
         }
 
 
         confirmDialog({
            header:'Atenção',
            message:'Deseja salvar as alterações',
            acceptLabel: 'Sim',
            rejectLabel:'Não',
            accept: () => accepctData(),
            reject: () => null
         });


    }, [listWorks, listAddress, listContacts, user, alterPassword, getData])

    const getDataClientType = useCallback(async ()=>{
        const data = await api.get(URL_USER_CLIENT_TYPE, null, true);
        let temp = [];

        data?.data?.map(item => {
            
            if(item?.items){
                item.items = item.items.map(el => {
                    temp.push({label: el.label, value:`${item.value}_${el.value}`});
                    return el;
                })
            }else{
                temp.push({...item});
            }

            return item;

        })
      

        setListClinetTypeData(temp);

    }, [setListClinetTypeData])

    useEffect(()=>{
        getDataClientType();
    }, [getDataClientType]);

    useEffect(()=> {

        if(isEdit){
            reset(user);
        }

    }, [isEdit, reset, user])

    useEffect(()=>{
        workData.current = {...work};
    }, [work, workData])

    useEffect(()=>{
        contactData.current = {...contact};
    }, [contact, contactData])

    useEffect(()=>{
        addressData.current = {...address};
    }, [address, addressData])

    useEffect(()=>{
        responsableData.current = listResponsable;
    }, [listResponsable, responsableData])

    useEffect(()=>{
        if((/reload/gi).test(user))
            getData();
    },[user, getData])

    useEffect(()=>{
        getData();
    }, [getData])

    if(!user || (/reload/gi).test(user))
        return (
            <div className="page-clients">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-clients">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Clientes', url:'/user/clients'},
                        {label:'Perfil'}
                    ]}
                />
            </div>
            <div className="header mt-4 mb-4">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><></>} 
                    right={()=><>
                        { !isEdit ? <Button onClick={()=>{getData()}} icon="pi pi-sync" className="p-button-warning p-button-text" /> : ''}
                        {
                            isEdit ?
                                <>
                                    <Button onClick={()=>{setIsEdit(false); getData();}} icon="pi pi-times" className="p-button-danger p-button-text" />
                                    <Button onClick={handleSubmit(onSubmit)} icon="pi pi-save" className="p-button-success ml-2 p-button-text" />
                                </>
                            :
                                <>
                                    <Button onClick={()=>{setIsEdit(true)}} icon="pi pi-pencil" className="p-button-success ml-2 p-button-text" />
                                </>
                        }
                    </>} 
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} >
            <div className="grid">
                <div className="col-10">
                    <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <h3>{user?.name}</h3>
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`name`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Nome' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
    
                <div className="col-2" style={{display:'flex', alignItems:'center'}}>
                    <Inplace style={{width:'100%'}} active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay style={{width:'100%'}}>
                            <Tag style={{width:'100%',backgroundColor: (~~user.active === 1 ? 'var(--green-200)' : 'var(--red-200)'), color: (~~user.active === 1 ? 'var(--green-800)' : 'var(--red-800)')}} value={~~user.active === 1 ? 'Ativo' : 'Inátivo' } />
                        </InplaceDisplay>
                        <InplaceContent style={{width:'100%'}}>
                            <Controller name={`active`} defaultValue={~~user.active === 1} control={control}  render={({ field, fieldState }) => (
                                <Dropdown placeholder='Ativo' {...field} options={[{name:'Sim', id: true},{name: 'Não', id: false}
                                ]} optionLabel="name" optionValue="id"  style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                    <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                <strong>CPF/CNPJ</strong>: {formatCPJCNPJ(user?.user_data?.cpf_cnpj)} 
                            </div>
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`cpf_cnpj`} defaultValue={user?.user_data?.cpf_cnpj} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='CPF / CNPJ' {...field} inputStyle={{width:'100%'}} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace> 
                    {
                        !isEdit ? 
                            <div className='button-copy-area'>
                                { user?.user_data?.cpf_cnpj ? <Button onClick={()=> copyContent(user?.user_data?.cpf_cnpj)} className='p-button-sm p-button-text buton-copy' icon='pi pi-copy' /> : ''}
                            </div>
                        :
                        ''
                    }
                </div>
                <div className="col-6">
                        <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                            <InplaceDisplay>
                                <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                    <strong>Acesso INSS</strong>:  {user?.user_data?.access_inss}
                                </div>
                            </InplaceDisplay>
                            <InplaceContent>
                                <Controller name={`access_inss`} defaultValue={user?.user_data?.access_inss} control={control}  render={({ field, fieldState }) => (
                                    <InputText placeholder='INSS' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                    )} />
                            </InplaceContent>
                        </Inplace>
                        {
                        !isEdit ? 
                            <div className='button-copy-area'>
                               { user?.user_data?.access_inss ? <Button onClick={()=> copyContent(user?.user_data?.access_inss)} className='p-button-sm p-button-text buton-copy' icon='pi pi-copy' /> : ''}
                            </div>
                        :
                        ''
                    }
                </div>
            </div>
            <div className="grid mt-2 mb-4">
                <div className="col-12">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                        <div style={{display:'inline-flex', alignItems:'center'}}>
                                <strong>ID</strong>: {user?.user_ref || user.id}
                            </div>
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`user_ref`} defaultValue={user?.user_ref || user?.id} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Referência ID' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                    {
                        !isEdit ? 
                            <div className='button-copy-area'>
                                { user?.user_ref || user.id || null ? <Button onClick={()=> copyContent(user?.user_ref || user.id)} className='p-button-sm p-button-text buton-copy' icon='pi pi-copy' /> : ''}
                            </div>
                        :
                        ''
                    }
                </div>
                <div className="col-6">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Username</strong>: {user?.username}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`username`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputText {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                {
                    isEdit ?
                        <div className="col-3" style={{display:'flex', alignItems:'center'}}>
                            
                            <label htmlFor="cb2" className="p-checkbox-label mr-4">
                                <strong>Deseja alterar senha?</strong>
                            </label>
                            <Checkbox inputId="cb2" value="alterpassword" onChange={()=> setAlterPassword(!alterPassword) } checked={alterPassword} />
                        </div>
                    :
                    ''
                }
                <div className={isEdit ? "col-3" : "col-6"}>
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Senha</strong>: **********
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`password`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Senha' type='password' disabled={!alterPassword} {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                    <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Data nascimento</strong>: {user?.user_data?.birthday ? new Date(user?.user_data?.birthday?.split('-')[0], ~~user?.user_data?.birthday?.split('-')[1] - 1, user?.user_data?.birthday?.split('-')[2]).toLocaleDateString() : '' } {user?.user_data?.birthday ? ' - ' : ''} {calculateOlder(user?.user_data?.birthday)}
                        </InplaceDisplay>
                        <InplaceContent>
                        <Controller name={`birthday`} defaultValue={ new Date(user?.user_data?.birthday?.split('-')[0], ~~user?.user_data?.birthday?.split('-')[1] - 1, user?.user_data?.birthday?.split('-')[2])} control={control}  render={({ field, fieldState }) => (
                            <Calendar {...field} locale='pt-BR' dateFormat='dd/mm/yy' showIcon style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3">
                    <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <span style={{textTransform:'capitalize'}}><strong>Estado civíl</strong>: {user?.user_data?.marital_status}</span>
                        </InplaceDisplay>
                        <InplaceContent>
                        <Controller name={`marital_status`} defaultValue={user?.user_data?.marital_status } control={control}  render={({ field, fieldState }) => (
                            <Dropdown 
                            required
                            options={[
                                {label:'Solteiro(a)', value:'solteiro(a)'},
                                {label:'Casado(a)', value:'casado(a)'},
                                {label:'Divorciado(a)', value:'divorciado(a)'},
                                {label:'Viuvo(a)', value:'viuvo(a)'},
                                {label:'União Estável', value:'União Estável'}
                            ]}
                            inputStyle={{width:'100%'}}  placeholder='Estado civil' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                        )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3">
                    <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Gênero</strong>: {(/m/gi).test(user?.user_data?.genere) ? 'Masculino' : 'Feminino'}
                        </InplaceDisplay>
                        <InplaceContent>
                        <Controller name={`genere`} defaultValue={user?.user_data?.genere } control={control}  render={({ field, fieldState }) => (
                            <Dropdown 
                            required
                            options={[
                                {label:'Masculino', value:'M'},
                                {label:'Feminino', value:'F'}
                            ]}
                            inputStyle={{width:'100%'}}  placeholder='Gênero' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                        )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                
                <div className="col-6" style={{display:'flex', alignItems:'center'}}>
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>RG</strong>: {formatRG(user?.user_data?.rg)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`rg`} defaultValue={user?.user_data?.rg} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='RG' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>CTPS</strong>: {(user?.user_data?.ctps)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`ctps`} defaultValue={user?.user_data?.ctps} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='CTPS' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>PIS</strong>: {(user?.user_data?.pis)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`pis`} defaultValue={user?.user_data?.pis} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='PIS' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                   <Inplace style={{width:'100%'}} active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay style={{width:'100%'}}>
                            <strong>Falecido</strong>? {~~user?.user_data?.islive !== 1 ? 'Sim' : 'Não'}
                        </InplaceDisplay>
                        <InplaceContent style={{width:'100%'}}>
                            <Controller name={`islive`} defaultValue={~~user?.user_data?.islive} control={control}  render={({ field, fieldState }) => (
                                <Dropdown placeholder='Falecido' {...field} options={[{name:'Sim', id: false},{name: 'Não', id: true}
                                ]} optionLabel="name" optionValue="id"  onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Nome pai</strong>: {(user?.user_data?.father_name)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`father_name`} defaultValue={user?.user_data?.father_name} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Nome pai' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Nome mãe</strong>: {(user?.user_data?.mother_name)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`mother_name`} defaultValue={user?.user_data?.mother_name} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Nome mãe' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6 'mt-2 mb-2">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Localização da pasta</strong>: {(user?.user_data?.path_location)}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`path_location`} defaultValue={user?.user_data?.path_location} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Pasta' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6">
                <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                    <InplaceDisplay>
                        <strong>Tipo de cliente</strong>: { user?.user_client_type ? (`${user?.user_client_type[0]?.client_type_name}${user?.user_client_type[0]?.client_type_extra_id ? ' - ' : ''}${user?.user_client_type[0]?.client_type_extra_id ? user?.user_client_type[0]?.client_type_extra_name : ''}` ) : '-'}
                    </InplaceDisplay>
                    <InplaceContent>
                        <Controller name={`client_type`} defaultValue={ user?.user_client_type ? (`${user?.user_client_type[0]?.client_type_id}${user?.user_client_type[0]?.client_type_extra_id ? `_${user?.user_client_type[0]?.client_type_extra_id}` : ''}`) : ''} control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <Dropdown 
                                filter
                                required
                                options={listClientTypeData}
                                inputStyle={{width:'100%'}}  placeholder='Selecione o tipo de cliente' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </InplaceContent>
                </Inplace>
                
                </div>
                <div className="col-12 'mt-2 mb-2">
                   
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <>
                            <strong>Outras informações</strong>: 
                            <div className='mt-2 mb-2'>{(user?.user_data?.others)}</div>
                            </>
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`others`} defaultValue={user?.user_data?.others} control={control}  render={({ field, fieldState }) => (
                                <InputTextarea autoResize placeholder='Outros' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-12">
                        
                    <strong>
                        Contato com terceiros
                    </strong>
                </div>
                <div className="col-12">
                    <DataTable 
                        header={()=><>
                            { isEdit ?
                            <div className="grid">
                                <div className="col-6">
                                    <InputText placeholder='Nome' style={{width:'100%'}} value={responsable?.name} onChange={(e)=> {
                                        const t = {...responsable};
                                        t.name = e.target.value;
                                        setResponsable(t);
                                    }} />
                                </div>
                                <div className="col-3">
                                    <InputText placeholder='Tipo (Filho, irmão ...)' style={{width:'100%'}} value={responsable?.category} onChange={(e)=> {
                                        const t = {...responsable};
                                        t.category = e.target.value;
                                        setResponsable(t);
                                    }} />
                                </div>
                                <div className="col-3">
                                    <InputText placeholder='CPF' style={{width:'100%'}} value={responsable?.document} onChange={(e)=> {
                                        const t = {...responsable};
                                        t.document = e.target.value;
                                        setResponsable(t);
                                    }} />
                                </div>
                                <div className="col-3">
                                    <InputText placeholder='Telefone' style={{width:'100%'}} value={responsable?.phone} onChange={(e)=> {
                                        const t = {...responsable};
                                        t.phone = e.target.value;
                                        setResponsable(t);
                                    }} />
                                </div>
                                <div className="col-3">
                                    <InputText placeholder='Email' style={{width:'100%'}} value={responsable?.email} onChange={(e)=> {
                                        const t = {...responsable};
                                        t.email = e.target.value;
                                        setResponsable(t);
                                    }} />
                                </div>
                                <div className="col-5" style={{display:'flex', alignItems:'center'}}>
                                    <Checkbox inputId="cb3" value={true} onChange={(e)=> {
                                        const t = {...responsable};
                                        t.signature = e.checked;
                                        setResponsable(t);
                                    }} 
                                    checked={responsable?.signature} />
                                    <label htmlFor="cb3" className="p-checkbox-label ml-2">Documento assinado?</label>
                                </div>
                                <div className="col-1" style={{textAlign:'right'}}>
                                    <Button className='p-button-secundary' onClick={addResponsable} tooltip="Adicionar terceiro" tooltipOptions={{position:'bottom'}} icon="pi pi-plus" />
                                </div>
                            </div>
                            : '' }
                        </>}
                        value={listResponsable || []} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há informações cadastradas"
                        size='small'
                        stripedRows
                        rows={10}
                        paginator={listResponsable?.length > 10}
                        editMode="row"
                        onRowEditComplete={onRowEditCompleteResponsable}
                    >
                        <Column field="name" header="Nome" 
                        editor={
                            (options) => {
                                return <InputText placeholder='Nome' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }/>
                        <Column field="type" header="Tipo" 
                        editor={
                            (options) => {
                                return <InputText placeholder='Tipo' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }/>
                        <Column field="document" header="CPF" 
                        editor={
                            (options) => {
                                return <InputText placeholder='CPF' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }/>
                        <Column field="phone" header="Telefone" 
                        editor={
                            (options) => {
                                return <InputText placeholder='Telefone' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }/>
                        <Column field="email" header="Email" 
                        editor={
                            (options) => {
                                return <InputText placeholder='Email' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }/>
                        <Column body={(row)=> ~~row?.signature === 1 ? 'Sim' : 'Não' } field="signature" header="Assinado" editor={
                            (options) => {
                                return <div style={{display:'flex', alignItems:'center'}}>
                                            <Checkbox  value={true} onChange={(e)=> {options.editorCallback(e.checked)}} 
                                            checked={options?.checked} />
                                        </div>
                            }
                        }/>
                        <Column rowEditor={isEdit} headerStyle={{ minWidth: '50px', textAlign:'right'}} bodyStyle={{ textAlign: 'right' }}></Column>
                        { isEdit ?  <Column style={{width:'50px'}} body={(row)=> {
                            return <Button onClick={()=>removeResponsable(row.id)} icon="pi pi-times" className='p-button-danger p-button-sm p-button-text' />
                        }} header="" /> : <></>}
                    </DataTable>
                </div>
                <div className="col-12">
                        <strong>
                            Dados bancários
                        </strong>
                    </div>
                    <div className="col-12">
                        <DataTable 
                            header={()=><>
                            {
                                isEdit ?
                                <div className="grid">
                                    <div className="col-6">
                                        <InputText placeholder='Banco' style={{width:'100%'}} value={bank?.name} onChange={(e)=> {
                                            const t = {...bank};
                                            t.name = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-6">
                                        <InputText placeholder='Tipo (corrente, poupança)' style={{width:'100%'}} value={bank?.category} onChange={(e)=> {
                                            const t = {...bank};
                                            t.type = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-2">
                                        <InputText placeholder='Agência' style={{width:'100%'}} value={bank?.agency} onChange={(e)=> {
                                            const t = {...bank};
                                            t.agency = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-4">
                                        <InputText placeholder='Conta' style={{width:'100%'}} value={bank?.account} onChange={(e)=> {
                                            const t = {...bank};
                                            t.account = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                    <div className="col-5">
                                        <InputText placeholder='PIX' style={{width:'100%'}} value={bank?.pix} onChange={(e)=> {
                                            const t = {...bank};
                                            t.pix = e.target.value;
                                            setBank(t);
                                        }} />
                                    </div>
                                 
                                    <div className="col-1" style={{textAlign:'right'}}>
                                        <Button className='p-button-secundary' onClick={addBank} tooltip="Adicionar dados bancários" tooltipOptions={{position:'bottom'}} icon="pi pi-plus" />
                                    </div>
                                </div>
                                :''}
                            </>}
                            value={listBank || []} 
                            responsiveLayout="scroll"
                            emptyMessage="Não há informações cadastradas"
                            size='small'
                            stripedRows
                            rows={10}
                            paginator={listBank?.length > 10}
                            editMode="row"
                            onRowEditComplete={onRowEditCompleteBank}
                        >
                            <Column field="name" header="Nome" />
                            <Column field="type" header="Tipo" />
                            <Column field="agency" header="Agência" />
                            <Column field="account" header="Conta" />
                            <Column field="pix" header="PIX" />
                            <Column style={{width:'50px'}} body={(row)=> {
                                return <Button onClick={()=>removeBank(row.id)} icon="pi pi-times" className='p-button-danger p-button-sm p-button-text' />
                            }} header="" />
                        </DataTable>
                    </div>
                <div className="col-12">
                   <Inplace active={isEdit} onToggle={(e) => setIsEdit(e.value)}>
                        <InplaceDisplay>
                            <strong>Profissão</strong>: {user?.user_data?.work}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`work`} defaultValue={user?.user_data?.work} control={control}  render={({ field, fieldState }) => (
                                <InputText placeholder='Profissão' {...field} style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                )} />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-12">
                   <div className='mb-2'>
                        <strong>Histórico profissional</strong>
                   </div>
                   <DataTable
                         header={()=>
                            (
                                isEdit ?
                                <>
                                    <div className="grid">
                                        <div className="col-3">
                                            <Calendar dateFormat='dd/mm/yy' locale='pt-BR' placeholder='Início' showIcon value={work?.start} onChange={(e)=> {
                                                const t = {...work};
                                                t.start = e.value;
                                                setWork(t);
                                            }} />
                                        </div>
                                        <div className="col-3">
                                            <Calendar dateFormat='dd/mm/yy' locale='pt-BR' placeholder='Fim' showIcon value={work?.end} onChange={(e)=> {
                                                const t = {...work};
                                                t.end = e.value;
                                                setWork(t);
                                            }}/>
                                        </div>
                                        <div className="col-2" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                            <Checkbox inputId="cb1" value={work?.actual} onChange={(e)=> {
                                                const t = {...work};
                                                t.actual = e.checked;
                                                setWork(t);
                                            }} 
                                            checked={work?.actual} />
                                            <label htmlFor="cb1" className="p-checkbox-label ml-2">Atual</label>
                                        </div>
                                        <div className="col-3">
                                            <InputText placeholder='Empresa' style={{width:'100%'}} value={work?.name} onChange={(e)=> {
                                                const t = {...work};
                                                t.name = e.target.value;
                                                setWork(t);
                                            }} />
                                        </div>
                                        <div className="col-1" style={{textAlign:'right'}}>
                                            <Button className='p-button-secundary' onClick={(e)=> { e.preventDefault(); addWork(); }} tooltip="Adicionar atividade profissional" tooltipOptions={{position:'bottom'}} icon="pi pi-plus" />
                                        </div>
                                    </div>
                                </>
                            :
                            ''
                            )
                        }
                        value={listWorks} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há dados cadastrados"
                        size='small'
                        stripedRows
                        rows={3}
                        paginator={listWorks?.length > 3}
                        editMode="row"
                        onRowEditComplete={onRowEditCompleteWork}
                    >
                        <Column field="name" header="Nome" 
                        editor={
                            (options) => {
                                return <InputText placeholder='Nome' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }
                        />
                        <Column body={(row)=> {
                            return row?.start ? new Date(row.start).toLocaleDateString() : '-'
                        }} header="Início" 
                        editor={
                            (options) => {
                                return <Calendar locale='pt-BR' dateFormat='dd/mm/yy' placeholder='Início' showIcon inputStyle={{width:'100%'}} style={{width:'100%'}} value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }
                        />
                        <Column body={(row)=> {
                            return row?.end ? new Date(row.end).toLocaleDateString() : '-'
                        }} header="Término" 
                        editor={
                            (options) => {
                                return <Calendar locale='pt-BR' dateFormat='dd/mm/yy' placeholder='Término' showIcon inputStyle={{width:'100%'}} style={{width:'100%'}} value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }
                        />
                        <Column rowEditor={isEdit} headerStyle={{ minWidth: '50px', textAlign:'right'}} bodyStyle={{ textAlign: 'right' }}></Column>
                        <Column style={{width:'50px'}} body={(row)=> {
                            return (isEdit ? <Button onClick={()=>removeWork(row.temp_id || row.id)} icon="pi pi-trash" className='p-button-danger p-button-sm p-button-text' /> : '')
                        }} header="" />
                    </DataTable>
                </div>
                
                <div className="col-12">
                   <div className='mb-2'>
                        <strong>Contatos</strong>
                   </div>
                   <DataTable 
                        header={()=>
                            (
                                isEdit ?
                                <>
                                    <div className="grid">
                                        <div className="col-3">
                                            <Dropdown value={contact?.type} options={[{label:'Email', value: 'email'},{label: 'Celular', value: 'mobile'}, {label: 'Telefone', value: 'phone'}]} placeholder='Tipo' onChange={(e)=> {
                                                const t = {...contact};
                                                t.type = e.target.value;
                                                setContact(t);
                                            }} />
                                        </div>
                                        <div className="col-4">
                                            <InputText value={contact?.value} type={(/email/gi).test(contact?.type) ? 'email' : 'tel' } placeholder='Valor' style={{width:'100%'}} onChange={(e)=> {
                                                const t = {...contact};
                                                t.value = e.target.value;
                                                setContact(t);
                                            }} />
                                        </div>
                                        <div className="col-4">
                                            <InputText value={contact?.note} placeholder='Observação' style={{width:'100%'}} onChange={(e)=> {
                                                const t = {...contact};
                                                t.note = e.target.value;
                                                setContact(t);
                                            }} />
                                        </div>
                                        <div className="col-1" style={{textAlign:'right'}}>
                                            <Button className='p-button-secundary' onClick={(e)=>{e.preventDefault(); addContact();}} tooltip="Adicionar contato" tooltipOptions={{position:'bottom'}} icon="pi pi-plus" />
                                        </div>
                                    </div>
                                </>
                            :
                            ''
                            )
                        }
                        value={listContacts} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há dados cadastradas"
                        size='small'
                        stripedRows
                        rows={10}
                        paginator={listContacts?.length > 10}
                        editMode="row"
                        onRowEditComplete={onRowEditCompleteContact}
                    >
                        <Column style={{width:'200px'}} field="type" header="Tipo" 
                        editor={
                            (options) => {
                                return <Dropdown options={[{label:'Email', value: 'email'},{label: 'Celular', value: 'mobile'}, {label: 'Telefone', value: 'phone'}]} placeholder='Tipo' inputStyle={{width:'100%'}} style={{width:'100%'}}  value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }
                        
                        />
                        
                        <Column body={(row)=>{
                            return !(/email/gi).test(row?.type) ? <>{formatPhoneNumber(row?.value)}</> : <>{row?.value}</>
                        }}  
                        field="value" header="Contato" 
                        editor={
                            (options) => {
                                return <InputText placeholder='Valor' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                    }
                    />
                        <Column  field="note" header="Observação" 
                        editor={
                            (options) => {
                                return <InputText placeholder='Observação' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }
                        />
                        <Column rowEditor={isEdit} headerStyle={{ minWidth: '50px', textAlign:'right'}} bodyStyle={{ textAlign: 'right' }}></Column>
                        <Column style={{width:'50px'}} body={(row)=> {
                            return (isEdit ? <Button onClick={(e)=>{e.preventDefault(); removeContact(row.temp_id || row.id);}} icon="pi pi-trash" className='p-button-danger p-button-sm p-button-text' /> : '')
                        }} header="" />
                    </DataTable>
                </div>
                <div className="col-12">
                   <div className='mb-2'>
                        <strong>Endereços</strong>
                   </div>
                   <DataTable
                        header={()=>
                            (
                                isEdit ?
                                <>
                                    <div className="grid">
                                        <div className="col-6">
                                            <InputText placeholder='Nome (Casa, empresa, loja , outros)' value={address?.name} style={{width:'100%'}} onChange={(e)=> {
                                                const t = {...address};
                                                t.name = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-6">
                                              <div className='grid'>
                                                <div className="col-10">
                                                    <span style={{width:'100%'}} className="p-input-icon-left">
                                                        <i className="pi pi-map-marker" />
                                                        <InputText required inputStyle={{width:'100%'}} value={address?.zipcode} placeholder='CEP' autoComplete='off' className='' style={{width:'100%'}} nChange={(e)=> {
                                                            const t = {...address};
                                                            t.zipcode = e.target.value;
                                                            setAddress(t); 
                                                            getCepData(e.target.value);
                                                            
                                                        }}/>
                                                    </span>
                                                </div>
                                                <div className="col-2" style={{textAlign:'right'}}>
                                                    <Button style={{width:'100%'}} onClick={searchAddress} icon="pi pi-search" className="p-button-secondary"/>
                                                    <ConfirmDialog />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <InputText placeholder='Endereço' style={{width:'100%'}} value={address?.address} onChange={(e)=> {
                                                const t = {...address};
                                                t.address = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-3">
                                            <InputText placeholder='Número' style={{width:'100%'}} value={address?.number} onChange={(e)=> {
                                                const t = {...address};
                                                t.number = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-3">
                                            <InputText placeholder='Complemento' style={{width:'100%'}} value={address?.extra} onChange={(e)=> {
                                                const t = {...address};
                                                t.extra = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-6">
                                            <InputText placeholder='Bairro' style={{width:'100%'}} value={address?.district} onChange={(e)=> {
                                                const t = {...address};
                                                t.district = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-3">
                                            <InputText placeholder='Cidade' style={{width:'100%'}} value={address?.city} onChange={(e)=> {
                                                const t = {...address};
                                                t.city = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-3">
                                            <InputText placeholder='Estado' style={{width:'100%'}} value={address?.state} onChange={(e)=> {
                                                const t = {...address};
                                                t.state = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-11">
                                            <InputTextarea autoResize placeholder='Referência' value={address?.reference} style={{width:'100%'}} onChange={(e)=> {
                                                const t = {...address};
                                                t.reference = e.target.value;
                                                setAddress(t);
                                            }} />
                                        </div>
                                        <div className="col-1" style={{textAlign:'right'}}>
                                            <Button style={{width:'100%'}} className='p-button-secundary' onClick={(e)=>{e.preventDefault(); addAddress();}} tooltip="Adicionar endereço" tooltipOptions={{position:'bottom'}} icon="pi pi-plus" />
                                        </div>
                                    </div>
                                </>
                            :
                            ''
                            )
                        } 
                        value={listAddress} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há dados cadastrados"
                        size='small'
                        stripedRows
                        rows={10}
                        paginator={listAddress?.length > 10}
                        editMode="row"
                        onRowEditComplete={onRowEditCompleteAddress}
                    >
                        <Column field="name" header="Nome" 
                        editor={
                            (options) => {
                                return <InputText placeholder='Nome' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                            }
                        }
                        />
                        <Column field="zipcode" header="CEP" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='CEP' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="address" header="Endereço" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Endereço' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="number" header="Número" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Número' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="extra" header="Complemento" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Complemento' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="district" header="Bairro" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Bairro' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="city" header="Cidade" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Cidade' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="state" header="Estado" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Estado' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column field="reference" header="Referência" 
                            editor={
                                (options) => {
                                    return <InputText placeholder='Referência' inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} 
                                    onChange={(e) => { 
                                        options.editorCallback(e.target.value)
                                    }} />;
                                }
                        } />
                        <Column rowEditor={isEdit} headerStyle={{ minWidth: '50px', textAlign:'right'}} bodyStyle={{ textAlign: 'right' }}></Column>
                        <Column style={{width:'50px'}} body={(row)=> {
                            return (isEdit ? <Button onClick={()=>removeAddress(row.temp_id || row.id)} icon="pi pi-trash" className='p-button-danger p-button-sm p-button-text' /> : '')
                        }} header="" />
                    </DataTable>
                </div>
            </div>
            </form>
            <div className="grid">
                <div className="col-12">
                <TabMenu 
                    activeIndex={active}
                    onTabChange={(e)=>setActive(e.index)}
                    model={tabMenuList} />
                </div>
            </div>
            <div key={`active-${active}`} className="grid mt-2 mb-2">
                <div class="col-12">
                    {getContent()}
                </div>
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}