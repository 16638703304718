import React, { useState, useCallback, useEffect } from 'react';

import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
 
 

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import '@/assets/styles/components/ui/clientcalendar/index.scss';
import { URL_CALENDAR_AVAILABLE_TIMES, api, URL_CALENDAR_BYCLIENT, URL_CALENDAR_DELETE } from '@/services/api';
import { useAPP } from '@/services/provider/app';

export const UIClientCalendar = (props) =>{

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const { user } = useAPP();

    const [currentDate, setCurrentDate] = useState(props?.selectedData || new Date());
    const [listtimes, setListtimes] = useState([]);
    const [selectedDay, setSelectedDay] = useState(props?.selectedData?.getDate() || null);
    const [registersData, setRegistersData] = useState([]);
    const [selectedTime, setSelectedTime] = useState( listtimes?.length > 0 ? props?.selectedData?.toLocaleTimeString() : null);

    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(),  currentDate.getDay());
    let init = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`).getDay();
    let lastday = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    
    let countweek = 1;
    for(let i=0; i < lastday; i++){
        const temp = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${i}`);
        if(temp.getDay() === 6)
            countweek++;
    }
    
    const boxTotal = countweek * 7;

    const openDay = useCallback(async (_day) =>{
        setSelectedDay(_day.getDate());
        const date = `${currentDate.getFullYear()}-${currentDate.getMonth()+1}-${_day.getDate()}`;
        let params = '';
        if(props?.juridics){
            params = `?juridic=${props?.juridics.join(',')}`;
        }
        const data = await api.get(`${URL_CALENDAR_AVAILABLE_TIMES}/${date}${params}`, null, false);
        setRegistersData(data?.data);

        let _list = [];
        
        data?.data?.map(item => {
            if(item[Object.keys(item)[0]]?.length > 0){
                _list.push({label: Object.keys(item)[0], enable: true})
            }else{
                _list.push({label: Object.keys(item)[0], enable: false})
            }
            return item;
        })

        const registers = await api.get(`${URL_CALENDAR_BYCLIENT}/${user?.id}/${date}/${date}`, null, false);
        
        const now = new Date();
        
        _list = _list.map(item => {
            
            const filter = registers?.data?.filter(el => {
                return  el.time === item.label
            });
            
            if(filter?.length > 0){
                item.register = filter[0];
            }
            
            const select_temp = new Date(`${date} ${item.label}`);
            
            item.active = now.getTime() < select_temp.getTime();
            
            return item;
        })

        setListtimes(_list);
        
    }, [setSelectedDay, setListtimes, currentDate, user, setRegistersData, props])

    const openTime = useCallback((_time) => {
        const date = new Date(`${currentDate.getFullYear()}-${currentDate.getMonth()+1}-${selectedDay} ${_time}`);
        const selected = registersData?.filter(item => item[_time]);
        const juridics = [];
        selected[0][_time]?.map(item => {
            juridics.push(item?.id);
            return item;
        })
        props.updateSelectJuridics(juridics);
        props.updateSelectedData(date);
    },[props, currentDate, registersData, selectedDay]);

    const cancelRegister = useCallback(async (_id) => {
        const data = await api.delete(`${URL_CALENDAR_DELETE}/${_id}`, false, false);
        if(data?.data){
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), selectedDay);
            openDay(date);
        }else{

        }
    },[currentDate, openDay, selectedDay]);

    const mountBox = () => {
        
        let listbox = [];
        let dayTemp = 1;

        for(let i =0; i < boxTotal; i++ ){
            const temp = new Date(date.getFullYear(), date.getMonth(), dayTemp);
            
            const today = new Date();

            if(date.getMonth() === temp.getMonth() && i > init){     
                listbox.push(<div key={`day-bt-${temp.getDate()}-${i}`} onClick={()=>openDay(temp)} className={`day-calendar ${selectedDay === temp.getDate() ? 'selected' : ''}`}>
                        <div className={`${temp.toLocaleDateString() === today.toLocaleDateString() ? 'today' : '' } number ${temp.toLocaleDateString() === props?.selectedData?.toLocaleDateString() ? 'pre-selected' : '' }`}>{temp.getDate()}</div></div>);
                dayTemp++;
            }else{
                listbox.push(<div key={`clear-${i}`}></div>);
            }
        }

        return listbox;
    }

    const showExistentTime = () => {
        ////console.log(listtimes);
        let content = listtimes.map(item => <>
            {
                item?.register ? 
                    <div className='card-register'>
                        <div className='icon'>
                            <i className='pi pi-clock'/>
                        </div>
                        <div className='title'>
                            {item?.register?.identification}
                        </div>
                        <div className='time'>
                            {item?.register?.time}
                        </div>
                        <div className="buttons">
                            {
                                new Date().getTime() < new Date(`${item?.register?.date} ${item?.register?.time}`).getTime() ?
                                    <div className='cancel-button' onClick={()=>cancelRegister(item?.register?.id)}><i className='pi pi-times mr-2' />Cancelar</div>
                                :
                                    ''
                            }
                        </div>
                    </div>
                :
                ''
            }
        </>);
        return content;
    }

    const alterMonth = useCallback((isNext)=>{
        const _date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

        _date.setMonth(_date.getMonth() + (isNext ? 1 : -1));

        setCurrentDate(_date);
    }, [currentDate]);

    useEffect(()=>{
        setSelectedTime(null);
    }, [selectedDay, setSelectedTime])

    useEffect(()=>{
        if(selectedTime){
            openTime(selectedTime)
        }
    }, [selectedTime, openTime])

    return (
        <div className="ui-client-calendar">
            <div className="grid mt-4">
                <div className="col-6">
                    <div className='area-calendar'>
                        <div>
                            <Button onClick={()=>alterMonth(false)} style={{color:'#666'}} icon="pi pi-chevron-left" className='p-button-text' />
                        </div>
                        <div>
                            <Calendar
                            locale='pt-BR'
                            value={currentDate} 
                            onChange={(e) => {
                                setSelectedDay();
                                setCurrentDate(e.value);
                            }} 
                            view="month" 
                            dateFormat="MM - yy"
                            />
                        </div>
                        <div>
                            <Button onClick={()=>alterMonth(true)} style={{color:'#666'}} icon="pi pi-chevron-right" className='p-button-text' />
                        </div>
                    </div>
                    <div className="component-calendar mt-4">
                        <div className="header-month-calendar">
                            {
                                moment.localeData().weekdaysShort().map((item, id) =>{
                                    return <div key={`week-${id}`}>{item}</div>
                                })
                            }
                        </div>
                        <div className="days-month-calendar">
                            {mountBox()}
                        </div>
                        <div className="footer-calendar">
                            <div className="current-day">Dia atual</div>
                            <div className="selected-day">Dia selecionado</div>
                            <div className="pre-day">Marcações</div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div >
                        {
                            listtimes.length > 0 ?
                                <>
                                <div className='title-resume'>
                                    Datas e Horários
                                </div>
                                <div className="resume-selection">
                                    <span>{moment.localeData().weekdays()[(new Date(currentDate.getFullYear(), currentDate.getMonth(), selectedDay)).getDay()]}</span>, {selectedDay < 10 ? `0${selectedDay}` : selectedDay} de <span>{moment.localeData().months()[currentDate.getMonth()]}</span>
                                </div>
                                <Dropdown style={{backgroundColor:'#CFEAC0', width:'80%', color:'#666'}} optionLabel='label' optionValue='label' key={`list-${selectedDay}`} value={selectedTime} options={listtimes?.filter(item => item?.active && item?.enable)} onChange={(e) => setSelectedTime(e.value)} placeholder="Selecione o horário"/>
                                </>
                            :
                                ''
                        }
                        {
                            listtimes?.filter(item => item?.register)?.length > 0 ? <div className='title-register'>Marcações:</div> : ''
                        }
                        {
                            showExistentTime()
                        }
                        {
                            props?.selectedData ? <div className='title-register'>Selecionado para marcação:</div> : ''
                        }
                        {
                            props?.selectedData ? 
                                <div className='card-register-pre'>
                                    <div className='icon'>
                                        <i className='pi pi-calendar-plus'/>
                                    </div>
                                    <div className='title'>
                                        {props?.selectedData?.toLocaleDateString()} {props?.selectedData?.toLocaleTimeString()}
                                    </div>
                                </div> : ''
                        }

                        {/* <DataTable 
                            key={selectedDay}
                            value={listtimes} 
                            emptyMessage="Não há horários disponíveis"
                            rows={7}
                            paginator
                            responsiveLayout="scroll"
                            size='small'
                            >
                            <Column style={{width:'100px'}} field="label" header="Horário" />
                            <Column body={(row)=><div>{(row?.register) ? <Chip style={{width:'100%', fontSize:'0.8em', backgroundColor:'var(--green-100)', color:'var(--green-800)'}} label={`ID: ${row?.register?.identification || row?.register?.id}`} icon="pi pi-user" /> : ((row.label === props?.selectedData?.toLocaleTimeString() && (new Date(currentDate.getFullYear(), currentDate.getMonth(),  selectedDay))?.toLocaleDateString() === props?.selectedData?.toLocaleDateString()) ? <Chip style={{width:'auto', background:'var(--yellow-300)', color:'var(--yellow-800)'}} onRemove={()=>props.updateSelectedData(null)} label={`Selecionado`} removable icon="pi pi-user" />  :'')}</div>} header="Agendado" />
                            <Column style={{width:'130px'}} body={(row)=> row?.enable || (row?.register) ? <div>
                                {
                                    row?.register ?
                                        <Button onClick={()=>cancelRegister(row?.register?.id)} icon="pi pi-times" className='p-button-sm p-button-text p-button-danger' label="Cancelar" />
                                    :
                                        <Button onClick={()=>openTime(row.label)} icon="pi pi-calendar-plus" className='p-button-sm p-button-text p-button-success' label="Agendar" />
                                }
                            </div> : ''} header="Ação" />
                        </DataTable> */}
                    </div>
                </div>
            </div>
        </div>
    )
}