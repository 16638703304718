import React from 'react';


export const PageUserHome = () => {

    return (
        <div className="page-user-home">
            <div className="grid">
                <div className="col-12 md:col-4">
                    <div className="card">
                        <div className="title">Total de processos</div>
                        <div className="content">0</div>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <div className="card">
                        <div className="title">Processos concluídos</div>
                        <div className="content">0</div>
                    </div>
                </div>
                <div className="col-12 md:col-4">
                    <div className="card">
                        <div className="title">Processos em aberto</div>
                        <div className="content">0</div>
                    </div>
                </div>
            </div>
        </div>
    )
}