import React, { useState, useCallback, useEffect, useMemo, useRef} from "react";

import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
 import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import { ATTENDANCE_LOCATION, ATTENDANCE_TYPE } from '@/services/provider/attendance';

import { useForm, Controller } from 'react-hook-form';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { api, URL_PROCESSCASES_BYID, URL_PROFILE_CLIENT, URL_ATTENDANCE_CREATE, URL_FINANCE_BYCASE, URL_FINANCE_PAID, URL_FINANCE_PAID_UPDATE } from "@/services/api";
import { calculateDurationProcessCases, formatCPJCNPJ, formatRG, calculateOlder, formatPhoneNumber, formatShortName } from "@/services/utils";
import { PageUserProcessCasesResume } from "../resume";
import { PageUserProcessCasesProcess } from "../process";
import { PageUserProcessCasesAttendance } from "../attendance";
import { PageUserProcessCasesFinance } from "../finance";
import { PageUserProcessCasesTickets } from "../tickets";
import { PageUserProcessCasesHistory } from "../history";
import { PageProcessCasesDocument } from "../document";
import { useAPP } from "@/services/provider/app";
import { PageUserProcessCasesProcessADM } from "../processadm";


export const PageUserProcessCasesView = (props) => {

    const location = useLocation();

    const { user } = useAPP();
    const params = useParams();
    const navigate = useNavigate();

    const [dataCase, setDataCase] = useState();
    const [activeIndex, setActiveIndex] = useState(location?.state?.tabActive|| 0);
    const [contentpage, setContentPage] = useState();
    const [userData, setUserData] = useState();
    const [financeData, setFinanceData] = useState();
    const [isNow, setIsNow] = useState(true);

    const [showDialogUser, setShowDialogUser] = useState(false);
    const [showDialogAttendance, setShowDialogAttendance] = useState(false);
    const [showDialogFinance, setShowDialogFinance] = useState(false);

    const tabmodel = useMemo(()=> [
        {label: 'Resumo'},
        {label: 'Requisição ADM'},
        {label: 'Processo'},
        {label: 'Histórico'},
        {label: 'Atendimentos'},
        {label: 'Financeiro'},
        {label: 'Arquivos'},
        {label: 'Tickets'}
    ], [])

    const form = useForm({});
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const start = useRef();
    const toast = useRef(null);

    const getData = useCallback(async () => {
        const data = await api.get(`${URL_PROCESSCASES_BYID}/${params?.id}`, false, true);
        setDataCase(data.data);
        //console.log(data.data);

    }, [setDataCase, params]);

    const onSubmitAttendance = useCallback(async (_data) => {
        
        const erros = [];

        if(!_data.type){
            erros.push('Tipo de atendimento não selecionado');
        }

        if(!_data.location){
            erros.push('Local do atendimento não selecionado');
        }

        if(!_data.description){
            erros.push('Descreva o atendimento');
        }

        if(erros.length > 0){
           
            const _html = <ul>
                {
                    erros?.map((item, id) => {
                        return  <li key={`erros_${id}`}>{item}</li>
                    })
                }
            </ul>

            toast.current.show({severity:'error', summary: 'Erro', detail:_html, life: 3000});
            
            return;
        }

        const end = new Date();

        const senddata = {..._data};
        senddata.user_id = dataCase?.client_id;
        senddata.name_user = dataCase?.name_user;
        senddata.process_cases_id = dataCase?.id;
        senddata.attendance_user_id = user?.id;
        senddata.start = `${start.current.toISOString().substr(0, 10)} ${start.current.toLocaleTimeString()}`;
        senddata.end = `${end.toISOString().substr(0, 10)} ${end.toLocaleTimeString()}`;
        senddata.generate_case = 0;

        //console.log(senddata);

        const result = await api.post(URL_ATTENDANCE_CREATE, {attendance: senddata}, true);
        
        if(result){
            toast.current.show({severity:'success', summary: 'Sucesso', detail:'Dados Salvos com sucesso', life: 3000});
        }else{
            toast.current.show({severity:'error', summary: 'Erro', detail:'Houve problemas ao salvar os dados. Tente novamente.', life: 3000});
        }

        reset({});
        setShowDialogAttendance(false);
        getData()

    }, [dataCase, user, setShowDialogAttendance, getData, reset])

    const onSubmitFinance = useCallback(async (_data) => {
        
        const senddata = {..._data};
        senddata.finance_id = financeData?.id;
        senddata.ispayment = 1;
        senddata.method = 'boleto';

        let result;

        start.current = start.current || new Date();

        if(isNow){
            delete senddata.id;
            senddata.date = `${start.current.toISOString().substr(0, 10)} ${start.current.toLocaleTimeString()}`;
            result = await api.post(URL_FINANCE_PAID, senddata, true);
        }else{
            result = await api.put(URL_FINANCE_PAID_UPDATE, senddata, true);
        }

        if(result){
            toast.current.show({severity:'success', summary: 'Sucesso', detail:'Dados Salvos com sucesso', life: 3000});
        }else{
            toast.current.show({severity:'error', summary: 'Erro', detail:'Houve problemas ao salvar os dados. Tente novamente.', life: 3000});
        }

        reset({});
        setShowDialogFinance(false);
        getData()
    },[reset, setShowDialogFinance, getData, financeData, isNow])

    const getDataUser = useCallback(async (_data) => {
        const data = await api.get(`${URL_PROFILE_CLIENT}/${_data}`, false, true);
        setUserData(data.data);
        //console.log(data.data);

    }, [setUserData]);

    const goToPage = (_index) => {
        setActiveIndex(_index);
    }

    const getContentPage = useCallback((_index)=>{

        const pages = [
            <PageUserProcessCasesResume key={new Date().getTime()} goToPage={goToPage} data={dataCase} />,
            <PageUserProcessCasesProcessADM key={new Date().getTime()} data={dataCase} type={'adm'} />,
            <PageUserProcessCasesProcess key={new Date().getTime()} data={dataCase} type={'jud'}/>,
            <PageUserProcessCasesHistory key={new Date().getTime()} data={dataCase} />,
            <PageUserProcessCasesAttendance key={new Date().getTime()} data={dataCase} />,
            <PageUserProcessCasesFinance key={new Date().getTime()} data={dataCase} />,
            <PageProcessCasesDocument key={new Date().getTime()} data={dataCase} />,,
            <PageUserProcessCasesTickets key={new Date().getTime()} data={dataCase} />
        ];

        let page = '';

        if(pages[_index]){
            page = pages[_index];
        }else{
            page = <div>Não há informações cadastradas</div>
        }

        setContentPage(page);

    }, [setContentPage, dataCase])

    const openUserData = useCallback(async () => {
        setShowDialogUser(true);
    }, [])

    const openAttendanceData = useCallback(async () => {
        start.current = start.current || new Date();
        setShowDialogAttendance(true);
    }, [setShowDialogAttendance])

    const openFinanceData = useCallback(async () => {
        setIsNow(true);
        const data = await api.get(`${URL_FINANCE_BYCASE}/${dataCase?.id}`, null, true);
        let finance = data.data;
        if(finance){
            let pending = finance?.paids?.filter(item => ~~item.ispayment === 0);
            
            pending = pending?.map(item => {
                 item.label = (new Date(item?.date)).toLocaleDateString();
                 item.value = item.id;
                return item;
            })
            finance.pending = pending;
        }
        
        setFinanceData(finance);
        setShowDialogFinance(true);
    }, [setShowDialogFinance, setFinanceData, dataCase])

    useEffect(()=>{
        getContentPage(activeIndex);
    }, [activeIndex, getContentPage])

    useEffect(()=>{
        getDataUser(dataCase?.client_id);
    }, [dataCase, getDataUser]);

    useEffect(()=>{
        getData();
    }, [getData]);


    if(!dataCase){
        return (
            <div className="page-user-processcases-view">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )
    }

    return (
        <div className="page-user-processcases-view">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Processos e casos', url:'/user/processcases'},
                        {label:'Detalhes de processos e casos'}
                    ]}
                />
                <h3>
                    Processos e casos
                </h3>
            </div>
            <div className="header mb-3">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><>
                    </>} 
                    right={()=><>
                        <Button onClick={()=>{getData()}} icon="pi pi-sync" className="p-button-warning p-button-text" />
                        <Button onClick={()=>openUserData()} icon="pi pi-user" className="p-button-secundary p-button-text ml-2" />
                        {/* <Button onClick={()=>openAttendanceData()} icon="pi pi-at" className="p-button-secundary p-button-text ml-2" />
                        <Button onClick={()=>openFinanceData()} icon="pi pi-dollar" className="p-button-secundary p-button-text mr-2 ml-2" /> */}
                        {/* <Button icon="pi pi-pencil" className="p-button-success p-button-text mr-2" /> */}
                        <Button icon="pi pi-times-circle" className="p-button-danger p-button-text" />
                    </>} 
                />
            </div>
            <div className="grid">
                <div className="col-8">
                    <div><strong><small>Título</small></strong></div>
                    <div>{dataCase?.name}</div>
                </div>
                <div className="col-4">
                    <div><strong><small>Status</small></strong></div>
                    <div>{dataCase?.status_name}</div>
                </div>
                <div className="col-6">
                    <div><strong><small>Cliente</small></strong></div>
                    <div onClick={()=>navigate(`/user/profile/${dataCase.client_id}`)} style={{color:'var(--blue-500)', cursor:'pointer'}}>{dataCase?.name_user}</div>
                </div>
                <div className="col-6">
                    <div><strong><small>Responsável</small></strong></div>
                    <div>{dataCase?.name_user_adm ?  formatShortName(dataCase?.name_user_adm) : '-'}</div>
                </div>
                <div className="col-4">
                    <div><strong><small>Iníciado</small></strong></div>
                    <div>{dataCase?.timecreated ? `${new Date(dataCase?.timecreated).toLocaleDateString()} - ${new Date(dataCase?.timecreated).toLocaleTimeString()}` : '-'}</div>
                </div>
                <div className="col-4">
                    <div><strong><small>Finalizado</small></strong></div>
                    <div>{dataCase?.timeend ? `${new Date(dataCase?.timeend).toLocaleDateString()} - ${new Date(dataCase?.timeend).toLocaleTimeString()}` : '-'}</div>
                </div>
                <div className="col-4">
                    <div><strong><small>Tempo</small></strong></div>
                    <div>{calculateDurationProcessCases(dataCase?.timecreated, dataCase?.timeend).full}</div>
                </div>
                <div className="col-12">
                    <strong><small>Notificar cliente</small></strong> { ~~dataCase?.isnotify === 1 ? 'Sim' : 'Não'}   
                </div>
                <div className="col-12">
                    <div className="mt-2 mb-4"><strong><small>Partes</small></strong></div>
                    <div >
                        <DataTable 
                            style={{width:'100%'}}
                            size="small"
                            value={dataCase?.others_peoples} 
                            emptyMessage="Não há outros envolvidos"
                            editMode="row" 
                            dataKey="id" 
                            >
                            <Column style={{width:'250px'}} field='type' header="Tipo" editor={
                                    (options) => {
                                        return <Dropdown options={[
                                            {label:'Autor', value: 'Autor'},
                                            {label:'Réu', value: 'Réu'}]} 
                                            inputStyle={{width:'100%'}} style={{width:'100%'}}  value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
                                    }
                            } />
                            <Column style={{width:'250px'}} field='name' header="Nome" editor={
                                (options) => {
                                    return <InputText inputStyle={{width:'100%'}} style={{width:'100%'}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                                }
                            } />
                            <Column style={{width:'auto'}} field='extra' header="Extra" editor={
                                (options) => {
                                    return <InputTextarea autoResize inputStyle={{width:'100%'}} style={{width:'100%'}} value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  />;
                                }
                            } />
                            {/* <Column rowEditor headerStyle={{ width: '100px'}} bodyStyle={{ textAlign: 'center' }}></Column> */}
                        </DataTable>
                    </div>
                </div>
                <div className="col-12">
                    <div><strong><small>Descrição</small></strong></div>
                    <div style={{whiteSpace: 'pre-wrap'}}>{dataCase?.description}</div>
                </div>
            </div>
            <div className="grid mt-4 mb-2">
                <div className="col-12">
                    <TabMenu 
                    activeIndex={activeIndex}
                    onTabChange={(e)=>setActiveIndex(e.index)}
                    model={tabmodel} />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    {contentpage}
                </div>
            </div>

            <Dialog header="Dados do cliente" visible={showDialogUser} style={{ width: '90vw', maxWidth:'1000px' }} footer={null} onHide={() => setShowDialogUser(false)}>
                {
                    userData ?
                    <>
                        <div className="grid">
                            <div className="col-12">
                                <strong>
                                   {userData?.user_ref} {userData?.name}
                                </strong>
                            </div>
                            <div className="col-4">
                                <div><strong><small>Data de nascimento</small></strong></div>
                                <div>
                                    {userData?.user_data?.birthday ? new Date(userData?.user_data?.birthday.split('-')[0], ~~userData?.user_data?.birthday.split('-')[1] - 1, userData?.user_data?.birthday.split('-')[2]).toLocaleDateString() : '' } {userData?.user_data?.birthday ? ' - ' : ''} {calculateOlder(userData?.user_data?.birthday)} {userData?.user_data?.birthday ? 'anos' : ''}
                                </div>
                            </div>
                            <div className="col-4">
                                <div><strong><small>CPF/CNPJ</small></strong></div>
                                <div>
                                    {formatCPJCNPJ(userData?.user_data?.cpf_cnpj)}
                                </div>
                            </div>
                            <div className="col-4">
                                <div><strong><small>RG</small></strong></div>
                                <div>
                                    {formatRG(userData?.user_data?.rg)}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mt-2 mb-4"><strong><small>Contato(s)</small></strong></div>
                                <DataTable     
                                    value={userData?.user_contact} 
                                    responsiveLayout="scroll"
                                    emptyMessage="Não há contatos cadastrados"
                                    size='small'
                                    stripedRows
                                    rows={10}
                                    paginator={userData?.user_contact?.length > 10}
                                >
                                    <Column style={{width:'200px'}} field="type" header="Tipo" />
                                    <Column body={(row)=>{
                                        return (/[0-9]/gi).test(row?.value) ? <>{formatPhoneNumber(row?.value)}</> : <>{row?.value}</>
                                    }}  field="value" header="Contato" />
                                    <Column  field="note" header="Observação" />
                                </DataTable>
                            </div>
                            <div className="col-12">
                                <div className="mt-2 mb-4"><strong><small>Endereço(s)</small></strong></div>
                                <DataTable     
                                    value={userData?.user_address} 
                                    responsiveLayout="scroll"
                                    emptyMessage="Não há endereços cadastrados"
                                    size='small'
                                    stripedRows
                                    rows={10}
                                    paginator={userData?.user_address?.length > 10}
                                >
                                    <Column style={{width:'200px'}} field="name" header="Nome" />
                                    <Column body={(row)=>{
                                        return <>
                                            <div>{row?.address}, {row?.number} {row?.extra ? `- ${row?.extra} ` : ''} - {row?.district} - {row?.city} - {row?.state} - {row?.zipcode}</div>
                                            <div>{row?.reference}</div>
                                        </>
                                    }}  field="address" header="Endereço" />
                                </DataTable>
                            </div>
                        </div>
                    </>
                    :
                    <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
                }
            </Dialog>

            <Dialog header="Novo atendimento" visible={showDialogAttendance} style={{ width: '90vw', maxWidth:'1000px' }} footer={null} onHide={() => { reset({}); setShowDialogAttendance(false);}}>
                <form onSubmit={handleSubmit(onSubmitAttendance)}>
                    <div className="grid">
                    <div className="col-12">
                            <strong>Inicio do atendimento</strong>: {start.current?.toLocaleDateString()} - {start.current?.toLocaleTimeString()}
                        </div>
                        <div className="col-6">
                            <Controller name={`type`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Dropdown {...field} optionLabel='name' options={ATTENDANCE_TYPE} placeholder='Tipo de atendimento' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-6">
                            {
                                <Controller name={`location`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                    <span style={{width:'100%'}} className="p-input-icon-left">
                                        <Dropdown {...field} optionLabel='name' options={ATTENDANCE_LOCATION}  placeholder='Local do atendimento' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} value={field.value}  />
                                    </span>
                                )} />
                            }
                        </div>    
                        <div className="col-12">
                            <Controller name={`description`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <InputTextarea {...field} autoResize required inputStyle={{width:'100%', minHeight:'300px'}} placeholder='Descreva o atendimento' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value}  />
                            )} />
                        </div>
                        <div className="col-12" style={{textAlign:'right'}}>
                            <Button className='p-button-warning' label="Finalizar atendimento"/>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog header="Pagamento" visible={showDialogFinance} style={{ width: '90vw', maxWidth:'1000px' }} footer={null} onHide={() =>{reset({}); setShowDialogFinance(false);}}>
                <form onSubmit={handleSubmit(onSubmitFinance)}>
                    <div className="grid">
                        <div className="col-3">
                            <SelectButton
                                unselectable={false} 
                                value={isNow} 
                                options={[
                                    {label:'Hoje', value: true},
                                    {label:'Em aberto', value: false}
                                ]} 
                                onChange={(e) => setIsNow(e.value)} />
                        </div>
                        <div className="col-5" style={{display:'flex', alignItems:'center'}}>
                            {
                                isNow ?
                                    <>{start.current?.toLocaleDateString()} - {start.current?.toLocaleTimeString()}</>
                                :
                                <Controller name={`id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                    <Dropdown {...field} options={financeData?.pending} placeholder='Selecione a data' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} value={field.value}  />
                                )} />
                            }
                        </div>    
                        <div className="col-4">
                            <Controller name={`value`} control={control}  render={({ field, fieldState }) => (
                                <InputText  {...field} type='number' pattern="[0-9]*,[0-9]{2}" min={0} step={0.01} required  placeholder='valor' className='' style={{width:'100%'}} onValueChange={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value}  />
                            )} />
                        </div>
                        <div className="col-12" style={{textAlign:'right'}}>
                            <Button className='p-button-warning' label="Lançar pagamento"/>
                        </div>
                    </div>
                </form>
            </Dialog>

            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}