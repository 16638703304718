import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';


import { Timeline } from 'primereact/timeline';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';

import { Chip } from 'primereact/chip';
 

import { useForm, Controller } from 'react-hook-form';
 
import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { api, URL_TICKET_ADDHISTORY, URL_TICKET_BYID, URL_TICKET_UPDATE, URL_TICKET_STATUS, URL_COLLABORATORS } from '@/services/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useAPP } from '@/services/provider/app';
import { formatShortName } from '@/services/utils';

import '@/assets/styles/pages/ticket/index.scss';

export const PageUserTicketView = () => {

    const navigate = useNavigate();

    const {user, setIsLoading} = useAPP();

    const params = useParams();
    const [dataTicket, setDataTicket] = useState();
    const [statusList, setStatusList] = useState();
    const [collaboratorsList, setCollaboratorsList] = useState();

    const toast = useRef(null);

    
    const form = useForm( );
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const getData = useCallback(async () =>{
        const data = await api.get(`${URL_TICKET_BYID}/${params.id}`, null, true);
        let tk = data.data;
        if(tk?.history){
            tk.history = tk?.history?.map((item, id) => {
                if(tk?.history.length - 1 === id){
                    item.icon = 'pi pi-arrow-circle-up';
                }else if(~~tk?.ticket_status_id === 1 && id === 0){
                    item.icon = 'pi pi-stop-circle';
                }else if(id > 0){
                    item.icon = 'pi pi-arrow-up';
                }else{
                    item.icon = 'pi pi-circle-fill';
                }
                return item;
            });
        }
        //console.log(tk);
        reset(data.data);
        setDataTicket(tk);
    }, [params, reset]);

    const getDataStatus = useCallback(async () =>{
        const data = await api.get(URL_TICKET_STATUS, null, true);  
        //console.log(data.data) ;
        setStatusList(data.data);
    }, []);

    const getDataCollaborador = useCallback(async () =>{
        const data = await api.get(URL_COLLABORATORS, null, true);  
        //console.log(data.data) ;
        setCollaboratorsList(data.data);
    }, []);


    const onSubmit = useCallback(async (_data) => {
        
        //console.log('#', _data);

        

        const acceptFunc = async () =>{
            const sendTicket = {
                priority: _data.priority,
                ticket_status_id: _data.ticket_status_id,
                user_id: _data.user_id,
                id: _data.id
    
            };
            
            await api.put(URL_TICKET_UPDATE, sendTicket, true);
    
    
            const sendTicketHistory = {
                ticket_id: _data.id,
                user_id: user.id,
                description: _data.comments
            };
    
            if(_data.comments){
                await api.post(URL_TICKET_ADDHISTORY, sendTicketHistory, true);
            }
    
            reset({});
            getData();
            toast.current.show({severity:'success', summary:`Sucesso`, detail:'Dados atualizado com sucesso', life: 3000});
        }


        confirmDialog({
            message: 'Deseja salvar as alterações?',
            header: 'Confirmar',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Sim",
            rejectLabel:"Não",
            accept: () => acceptFunc(),
            reject: () =>{}
        });

    },[reset, getData, user, params]);

    const onSubmitErros = useCallback((_data)=>{

        const erros = Object.keys(_data)?.map(item => {
            return ({severity:'error', summary:`Campo obrigatório`, detail:_data[item].message, life: 3000})
        })

        toast.current.show(erros);
    }, [])

    useEffect(()=>{
        getData();
        getDataStatus();
        getDataCollaborador();
    }, [getData, getDataStatus, getDataCollaborador])

    return (
        <div className="page-user-ticket-view">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Tickets', url:'/user/tickets'},
                        {label:'Detalhes tickets'}
                    ]}
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit, onSubmitErros)}>
            <div className="header mt-4">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px 0'}}
                    left={()=><>
                       <strong>Ticket ID</strong>: {dataTicket?.ticket_identification}
                    </>} 
                    right={()=><>
                        <Button onClick={(e)=>{ e.preventDefault(); getData() }} icon="pi pi-sync" className="p-button-warning p-button-text p-button-sm ml-2 mr-2" />
                        <Button onClick={()=>{}} icon="pi pi-save" tooltip='Salvar alterações' tooltipOptions={{position:'bottom'}} className="p-button-primary p-button-text p-button-sm ml-2 mr-2" />
                    </>} 
                />
            </div>
            <div className="grid mt-2">
                <div className="col-4">
                    <div><strong><small>Abertura</small></strong></div>
                    <div>{dataTicket?.datetime_start ? `${new Date(dataTicket?.datetime_start).toLocaleDateString()} - ${new Date(dataTicket?.datetime_start).toLocaleTimeString()}` : '-'}</div>
                </div>
                <div className="col-4">
                    <div><strong><small>Limite</small></strong></div>
                    <div>{dataTicket?.datetime_end ? `${new Date(dataTicket?.datetime_end).toLocaleDateString()} - ${new Date(dataTicket?.datetime_end).toLocaleTimeString()}` : '-'}</div>
                </div>
                <div className="col-4">
                    <div><strong><small>Finalizado</small></strong></div>
                    <div>{dataTicket?.datetime_close ? `${new Date(dataTicket?.datetime_close).toLocaleDateString()} - ${new Date(dataTicket?.datetime_close).toLocaleTimeString()}` : '-'}</div>
                </div>
                <div className="col-12">
                    <div><strong><small>Título caso</small></strong></div>
                    <div style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/processcases/${dataTicket?.process_cases_id}`)}>{dataTicket?.case_name}</div>
                </div>
                <div className="col-6">
                    <div><strong><small>Área de atuação</small></strong></div>
                    <div>{dataTicket?.ticket_acting?.name}</div>
                </div>
                <div className="col-6">
                    <div><strong><small>Área do escritório</small></strong></div>
                    <div>{dataTicket?.ticket_office?.name}</div>
                </div>
                <div className="col-4">
                    <div><strong><small>Tratar como prioridade</small></strong></div>
                    <Controller name={`priority`} defaultValue={~~dataTicket?.priority} rules={{ required: 'Prioridade' }} control={control}  render={({ field, fieldState }) => (
                        <Dropdown 
                            options={[
                                {label:'Não', value:'0'},
                                {label:'Sim', value:'1'}
                            ]}
                            inputStyle={{width:'100%'}}  placeholder='Tem prioridade?' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                    )} />
                </div>
                <div className="col-4">
                    <div><strong><small>Responsável</small></strong></div>
                    <Controller name={`user_id`} defaultValue=''  control={control}  render={({ field, fieldState }) => (
                        <Dropdown 
                            {...field}
                            filter
                            options={collaboratorsList}
                            optionLabel="name"
                            optionValue='id'
                            inputStyle={{width:'100%'}}  placeholder='Responsável' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name}  value={field.value}  />
                    )} />
                </div>
                <div className="col-4">
                    <div><strong><small>Status</small></strong></div>
                    <Controller name={`ticket_status_id`} defaultValue={~~dataTicket?.ticket_status_id} rules={{ required: 'Status' }} control={control}  render={({ field, fieldState }) => (
                        <Dropdown 
                            {...field}
                            filter
                            options={statusList}
                            optionLabel="name"
                            optionValue='id'
                            inputStyle={{width:'100%'}}  placeholder='Status' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name}  value={field.value}  />
                    )} />
                </div>
                <div className="col-4">
                    <div><strong><small>Aberto por</small></strong></div>
                    <div>{dataTicket?.user_open?.prefixe ? `${dataTicket?.user_open?.prefixe} ` : '' }{formatShortName(dataTicket?.user_open?.name)}</div>
                </div>
                <div className="col-12">
                    <div><strong><small>Tag</small></strong></div>
                    <div className='mt-2 mb-2'>
                        {dataTicket?.tag?.map(item => {
                            return <Chip className='mr-2' key={`tag-${item.id}`} label={item.name} />
                        })}
                    </div>
                </div>
                <div className="col-12">
                    <div><strong><small>Descrição</small></strong></div>
                    <div>{dataTicket?.description}</div>
                </div>                
            </div>
            {
            ~~dataTicket?.ticket_status_id !== 2 ?
                <div className="grid mt-3">
                    <div className="col-12">
                        <strong className='mb-2'>
                            Adicionar comentário
                        </strong>
                    </div>
                    <div className="col-12">
                       
                            <div>
                                <Controller name={`comments`} defaultValue="" rules={{ required: 'Comentário' }} control={control}  render={({ field, fieldState }) => (
                                    <InputTextarea {...field} autoResize  inputStyle={{width:'100%', minHeight:'300px'}} placeholder='Escreva seu comentário' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value}  />
                                )} />
                            </div>
                            {/* <div className='mt-2' style={{textAlign:'right'}}>
                                <Button label='Adicionar comentário' className='p-button-sm' />
                            </div> */}
                    </div>
                </div>
                :
                ""
            }
            </form>
            <div className="grid mt-3">
                <div className="col-12">
                    <div><strong><small>Histórico ticket</small></strong></div>
                </div>
                <div className="col-12">
                    <Timeline value={dataTicket?.history} 
                    marker={(item) => <i className={item.icon}></i>}
                    opposite={(item) => <>
                        <small>{new Date(item?.timemodified).toLocaleDateString()} {new Date(item?.timemodified).toLocaleTimeString()}</small>
                    </>} 
                    content={(item) => <div>
                        <div><strong><small className="p-text-secondary">{formatShortName(item?.user_name)}</small></strong></div>
                        <div>{item?.description}</div>
                    </div>
                    } />
                </div>
            </div>
            <Toast ref={toast} position="bottom-right" />
            <ConfirmDialog />
        </div>
    )
}