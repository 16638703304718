import React, { useState, useCallback, useEffect } from "react";

import { ProgressBar } from 'primereact/progressbar';

import * as PDFJS from "pdfjs-dist/build/pdf";
import PDFJSWORKER from "pdfjs-dist/build/pdf.worker.entry";

import { URL_FILES_FILE } from "@/services/api";

export const LoadFile = (props) => {


    PDFJS.GlobalWorkerOptions.workerSrc = PDFJSWORKER;

    const [contentData, setContentData] = useState(null);

    const createFiles = useCallback(async(_data) => {
        const _urlbase = `${URL_FILES_FILE}/?file=${props.data.url}`;
        const file = PDFJS.getDocument(_urlbase);
        const pdf = await file.promise;
        const pages = [];

        for(let i = 1; i <= pdf.numPages; i++){
            const p = await pdf.getPage(i);

            const viewport = p.getViewport({scale:1});
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width =  viewport.width;
            canvas.height = viewport.height;
            
            
            const render = { canvasContext: context, viewport: viewport};
            const task = p.render(render);
            await task.promise;

            pages.push(canvas);

        }

    
        return <div>
            {
                pages?.map((pg, id) => <div key={`page-${id}`}>
                    <img alt="" src={pg.toDataURL()} />
                </div>)
            }
        </div>


    },[])

    const getData = useCallback(async () => {

        const view = (/jpg|jpeg|png|gif|svg/gi).test(props.data.ext) ? <img style={{maxWidth:'100%'}} src={`${URL_FILES_FILE}/?file=${props.data.url}`} alt='' /> : ((/pdf/gi).test(props.data.ext) ? await createFiles() : 'Não é possível exibir este formato');

        setContentData(view);

    }, [])

    useEffect(()=>{
        getData();
    }, [getData]);

    if(!contentData){
        return (
            <div className="load-file">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>
        )
    }

    return (
        <div className="load-file" style={{textAlign:'center'}}>
            {contentData}
        </div>
    )
}