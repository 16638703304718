import React from 'react';
import ReactDOM from 'react-dom/client';

import { NavigateRouter } from './components/navigation';
import { AppProvider } from './services/provider/app';

import './assets/styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <NavigateRouter />
    </AppProvider>
  </React.StrictMode>
);
