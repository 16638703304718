import React, { useCallback, useState, useEffect, useRef } from "react";

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';


import { useNavigate } from "react-router-dom";
import { api, URL_USER_MY_NOTIFICATION } from "@/services/api";

export const PageNotification = () => {


    const navigate = useNavigate();

    const [notificationList, setNotificationList] = useState([]);

    const [updatePage, setUpdatePage] = useState(0);

    const timerUpdate =  useRef();

    const getNotification = useCallback(async () => {
        const dataNotification = await api.get(URL_USER_MY_NOTIFICATION, null, true);
        setNotificationList(dataNotification.data);

        timerUpdate.current = setTimeout(()=>{
            clearTimeout(timerUpdate.current);
            setUpdatePage(new Date().getTime());
        }, 60000);

    }, [])

    useEffect(()=>{
        getNotification();

        return(()=>{
            clearTimeout(timerUpdate.current);
        })
    }, [updatePage, getNotification])

    return <div className="page-notification">
        <div className="breadcrump">
            <UIBreadcrumb 
                model={[
                    {label:'Início', url:'/user'},
                    {label:'Notificações'}
                ]}
            />
        </div>
        <div className="header mt-2 mb-2">
            <Toolbar 
                style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                left={()=><></>} 
                right={()=><>
                    <Button onClick={()=>{
                        setUpdatePage(new Date().getTime())
                    }} icon="pi pi-sync" className="p-button-warning p-button-text" />
                </>} 
            />
        </div>
        <div className="grid">
            <div className="col-12">
                <DataTable 
                    emptyMessage="Não há informações."
                    value={notificationList}
                    row={10}
                    paginator={~~notificationList?.length > 10}
                >
                    <Column body={(row)=> {
                        return <div>
                        <div style={{maxWidth:'90%', textOverflow:'ellipsis', whiteSpace:'nowrap', wordBreak:'keep-all', overflow:'hidden'}}>
                            {row.title}
                        </div>
                    </div>
                    }} field="title" header="Titulo" />
                    <Column body={(row)=>{
                        return <div>
                            <div style={{maxWidth:'90%', textOverflow:'ellipsis', whiteSpace:'nowrap', wordBreak:'keep-all', overflow:'hidden'}}>
                                {row.description}
                            </div>
                        </div>
                    }} field="description" header="Descrição" />
                    <Column body={(row)=>{
                        return <div>
                                {~~row.viewed === 1 ? 'Lida' : 'Não Lida'}
                        </div>
                    }} field="viewed" header="Status" />
                    <Column body={(row)=>{
                        return <>
                            <Button onClick={()=>navigate(`/user/notification/view/${row.id}`)} icon="pi pi-eye" className="p-button-sm p-button-text" />
                        </>
                    }} />
                </DataTable>
            </div>
        </div>
    </div>

}