import React, {useState, useEffect, useCallback, useRef} from 'react';

import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AutoComplete } from 'primereact/autocomplete';
import { Timeline } from 'primereact/timeline';
import { Toast } from 'primereact/toast';

import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';

import { useForm, Controller } from 'react-hook-form';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { formatCPJCNPJ } from '@/services/utils';

import { useAPP } from '@/services/provider/app';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { api, URL_EXPERTISEDEPLOYMENT_BYID, URL_EXPERTISEDEPLOYMENT_STATUS, URL_EXPERTISEDEPLOYMENT_TYPE, URL_EXPERTISEDEPLOYMENT_UPDATE, URL_COLLABORATORS, URL_EXPERTISEDEPLOYMENT_EXECUTION, URL_EXPERTISEDEPLOYMENT_HISTORY_CREATE } from '@/services/api';

export const PageUserExpertiseDeploymentView = () => {

    const { user } = useAPP();

    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState();

    const [active, setActive] = useState(false);
    const [ type, setType] = useState();
    const [ execution, setExecution] = useState();
    const [ status, setStatus] = useState();
    const [ collaborators, setCollaborators] = useState();


    const [historyText, setHistoryText] = useState();

    const toast = useRef(null);

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const form = useForm({});
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const tagForm = useRef();

    const sendData = useRef({});

    const getData = useCallback(async ()=>{
        let result = await api.get(`${URL_EXPERTISEDEPLOYMENT_BYID}/${params?.id}`, null, true);

        Object.keys(result?.data).map(el => {
            if((/[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}/gi).test(result?.data[el])){
                result.data[el] = new Date(result?.data[el])
            }

            return el
        })

        
        if(result?.data?.priority)
            result.data.priority= ~~result.data.priority + 1;

        //console.log(result.data);
        sendData.current = result?.data;
        setData(result?.data);
    }, [params]);

    const getStatus  = useCallback(async()=>{
        const result = await api.get(`${URL_EXPERTISEDEPLOYMENT_STATUS}`, null, false);
        setStatus(result?.data);
    }, [])

    const getExecution  = useCallback(async()=>{
         const result = await api.get(`${URL_EXPERTISEDEPLOYMENT_EXECUTION}`, null, false);
         setExecution(result?.data);
    }, [])

    const getType  = useCallback(async()=>{
        const result = await api.get(`${URL_EXPERTISEDEPLOYMENT_TYPE}`, null, false);
        
        setType(result?.data);
    }, [])

    const getCollaborators  = useCallback(async()=>{
        const result = await api.get(`${URL_COLLABORATORS}`, null, true);
        setCollaborators(result?.data);
    }, [])

    const saveData = useCallback(async(_data)=>{
        
        let data = {...sendData.current};

        const remove = ["status", "status_color" , "path", "type", "type_color", "process_number", "process_cases_id", "user_id", "name_user", "access_inss", "user_adm_name",'user_attorney_name', 'history', 'execution', 'cpf_cnpj', 'client'];

        remove.map(item => {
            delete data[item];
            return item;
        })

        data.id = params.id;
        
        Object.keys(data).map(item => {
            if(_data[item] instanceof Date){
                data[item] = _data[item];
            }else{
                data[item] = _data[item];
            }
            return item;
        }) 

        data.priority = ~~data.priority === 1 ? '0' : '1';

        await api.put(URL_EXPERTISEDEPLOYMENT_UPDATE, data, true);
        ////console.log(result);
        
        setActive(false);
        getData();
    }, [params, getData])


    const addHistory = useCallback(async () => {
        const data = {
            expertise_deployment_id: params.id,
            user_id: user.id,
            description: historyText
        }

        await api.post(URL_EXPERTISEDEPLOYMENT_HISTORY_CREATE, data, true);
        setHistoryText('');
        getData();

    }, [params, user, historyText, getData])

    const copyContent = (data) => {
        navigator.clipboard.writeText(data);
        toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Conteúdo copiado com sucesso'});
    }

    useEffect(()=>{
        if(active){
            reset(sendData.current)
        }
    }, [active, sendData, reset])

    useEffect(()=>{
        const init = async () => {
            await getStatus();
            await getExecution();
            await getType();
            await getCollaborators();
            getData();
        }

        init();

    },[getData, getStatus, getExecution, getType, getCollaborators]);


    if(!data)
        return (
            <div className="page-user-expertisedeployment-view">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-user-expertisedeployment-view">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Perícia e implantação', url:'/user/expertisedeployment' },
                        {label:'Detalhes perícia e implantação' }
                    ]}
                />
            </div>
            <div className="header mt-4 mb-2">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><></>} 
                    right={()=><>
                        {
                            active ?
                            <>
                                <Button onClick={()=>{setActive(false)}} icon="pi pi-times" className="p-button-danger mr-2 p-button-text" />
                                <Button onClick={handleSubmit(saveData)} icon="pi pi-save" className="p-button-success mr-2 p-button-text" />
                            </>
                            :
                            <Button onClick={()=>{setActive(true)}} icon="pi pi-pencil" className="p-button-success mr-2 p-button-text" />
                        }
                        <Button onClick={()=>{getData()}} icon="pi pi-sync" className="p-button-warning p-button-text" />
                    </>} 
                />
            </div>
            <form ref={tagForm} onSubmit={(e)=> e.preventDefault()} >
            <div className="grid mt-4">
                <div className="col-8">
                    <strong>Processo</strong>: { data?.process_id ? <span style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/processcases/${data?.process_cases_id}`,{state:{tabActive:1}})}>{data?.process_number}</span> : '-'}
                </div>
                <div className="col-4">
                    <strong>Prioridade</strong>: {data?.priority ? 
                             <span style={{display:'inline-block',  width:'calc(100% - 75px)'}}>
                                <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                                    <InplaceDisplay>
                                        <Tag style={{backgroundColor:~~data?.priority === 2 ? 'var(--red-500)' : 'var(--green-500)', width:'100%'}} value={~~data?.priority === 2 ? 'Sim' : 'Não'} />
                                    </InplaceDisplay>
                                    <InplaceContent>
                                        <Controller name={`priority`} defaultValue={data?.priority} control={control}  render={({ field, fieldState }) => (
                                            <Dropdown {...field} options={[{id: 2 , name:'Sim'}, {id: 1 , name:'Não'}]} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                                        )} />
                                    </InplaceContent>
                                </Inplace>
                             </span>
                             : '-'}
                </div>
                <div className="col-12">
                    <strong>Cliente</strong>: { data?.process_id ? <span style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/profile/${data?.user_id}`)}>{data?.client}</span> : '-'}
                </div>
                <div className="col-4" style={{display:'flex', alignItems:'center'}}>
                    <strong>CPF/ CNPJ</strong>: { data?.cpf_cnpj ? <><span className='ml-2'>{formatCPJCNPJ(data?.cpf_cnpj)} </span><Button onClick={()=> copyContent(data?.cpf_cnpj)} className='p-button-sm p-button-text' icon='pi pi-copy' /></> : '-'}
                </div>
                <div className="col-8" style={{display:'flex', alignItems:'center'}}>
                    <strong>Senha INSS</strong>: { data?.access_inss ? <><span className='ml-2'>{data?.access_inss} </span><Button onClick={()=> copyContent(data?.access_inss)} className='p-button-sm p-button-text' icon='pi pi-copy' /></> : '-'}
                </div>
                <div className="col-4">
                    <strong>Responsável</strong>:
                    <div className='mt-2'>
                        {data?.user_adm_name || '-'}
                    </div>
                </div>
                <div className="col-4">
                    <strong>Advogado(a)</strong>:
                    <div className='mt-2'>
                        {data?.user_attorney_name || '-'}
                    </div>
                </div>
                <div className="col-4">
                    <strong>Atualizado em</strong>: 
                    <div className='mt-2'>
                        {data?.timemodified ? `${new Date(data.timemodified).toLocaleDateString()} - ${new Date(data.timemodified).toLocaleTimeString()}` : '-'}
                    </div>
                </div>
                <div className="col-4">
                    <div><strong>Espécie</strong></div>
                    <div className='mt-2'>
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            <Tag style={{backgroundColor:data?.type_color, width:'100%'}} value={data?.type} />
                        </InplaceDisplay>
                        <InplaceContent>
                        <Controller name={`type_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Dropdown filter {...field} options={type} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                        </InplaceContent>
                    </Inplace>
                    </div>
                </div>
                <div className="col-4">
                    <div><strong>Status</strong></div>
                    <div className='mt-2'>
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            <Tag style={{backgroundColor:data?.status_color, width:'100%'}} value={data?.status} />
                        </InplaceDisplay>
                        <InplaceContent>
                            <Controller name={`status_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Dropdown filter {...field} options={status} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                        </InplaceContent>
                    </Inplace>
                    </div>
                </div>
                <div className="col-4">
                    <div><strong>Execução</strong></div>
                    <div className='mt-2'>
                        <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                            <InplaceDisplay>
                                {data?.execution}
                            </InplaceDisplay>
                            <InplaceContent>
                            <Controller name={`execution_type_id`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Dropdown filter {...field} options={execution} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                            )} />
                            </InplaceContent>
                        </Inplace>
                    </div>
                </div>
                <div className="col-4">
                    <div className='mb-2'><strong>Data estipulado/ agendado</strong></div>
                    <Inplace active={active} onToggle={(e) => setActive(e.value)}>
                        <InplaceDisplay>
                            {data?.timefuture ? new Date(data.timefuture).toLocaleDateString() : '-'}
                        </InplaceDisplay>
                        <InplaceContent>
                        <Controller name={`timefuture`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Calendar {...field} locale='pt-BR' showIcon style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                        </InplaceContent>
                    </Inplace>
                </div>        
                <div className="col-8">
                    <div className='mb-2'><strong>Criado em</strong></div>
                    {data?.timecreated ? `${new Date(data.timecreated).toLocaleDateString()} - ${new Date(data.timecreated).toLocaleTimeString()}` : '-'}
                </div>
                <div className="col-12">

                </div>
            </div>
            <div className="col-12 mt-3">
                    <div className='mb-2'><strong>Observações</strong></div>
                    <div className="grid mt-2 mb-2">
                        <div className="col-12">
                            <InputTextarea value={historyText} onInput={(e)=>{ setHistoryText(e.target.value);}} autoResize style={{width:'100%'}} placeholder='Adicione uma observação' />
                        </div>
                        <div className="col-12" style={{textAlign:'right'}}>
                            <Button onClick={addHistory} label='Enviar do observação' />
                        </div>
                    </div>
                    <Timeline value={data?.history} 
                    opposite={(item)=> item.timecreated ? `${new Date(item.timecreated).toLocaleDateString()} - ${new Date(item.timecreated).toLocaleTimeString()}` : '-'} 
                    content={(item) => {
                        return <div>
                            <div><strong>{item?.user_name}</strong></div>
                            <small>{item?.description}</small>
                        </div>
                    }} />
                </div>
            </form>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}