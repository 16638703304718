import React, { useCallback, useState, useRef } from 'react';

import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';

import { useForm, Controller } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';
import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { api, URL_WIKI_CREATE } from '@/services/api';

import "@/assets/styles/pages/wiki/index.scss";

export const PageUserWikiCreate = () => {

    const navigate = useNavigate();

    const [text, setText] = useState();

    const filesUpload = useRef();
    const filesUploadList = useRef();
    const coverUpload = useRef();
    const coverFileUpload = useRef();
    const wikiID = useRef(null);
    wikiID.current = wikiID.current ? wikiID.current : new Date().getTime();

    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;
    const reset =  form.reset;
    

    const uploadFiles = useCallback(async (_data) => {
        filesUploadList.current = _data.files;
    }, 
    [])

    const uploadFileCover = useCallback(async (_data) => {
        coverFileUpload.current = _data.files;
    }, 
    [])

    const onSubmit = useCallback(async (_data) => {
        coverUpload.current.upload();
        filesUpload.current.upload();

        const sendData = new FormData();
        const wiki = {..._data};
        wiki.content = text;
        wiki.path = wikiID.current;

        Object.keys(wiki).map(item => {
            sendData.append(item, wiki[item]);
            return item;
        })

        filesUploadList.current.map((item, id) => {
            const ext = item.name.match(/.[a-z0-9]{3,4}$/gi)[0];
            sendData.append(`file_material_${id}`, item, `material_${id}${ext}`);
            return item;
        })

        coverFileUpload.current.map(item => {
            const ext = item.name.match(/.[a-z0-9]{3,4}$/gi)[0];
            sendData.append('file_cover', item, `cover${ext}`);
            return item;
        })

        //console.log(filesUploadList);

        await api.upload(URL_WIKI_CREATE, sendData, true);

        reset({});

    }, 
    [text, reset])

    return (
        <div className="page-user-wiki-create">
            <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Wiki', url: '/user/wiki'},
                        {label:'Criar wiki'}
                    ]}
                />
            <div>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="grid mt-4">
                        <div className="col-12">
                            <strong>Wiki ID</strong>: { wikiID.current }
                        </div>
                        <div className="col-12 area-cover">
                            
                            <FileUpload 
                            emptyTemplate={
                                <div className="cover">
                                    <div className="photo">
                                        <i className='pi pi-image' style={{fontSize:'6em'}} />
                                    </div>
                                    <div className="button">
                                        Arraste a image aqui
                                    </div>
                                </div>
                            }
                            itemTemplate={(file, props)=>{
                                return <div className="cover is-image">
                                            <div className="photo">
                                                <img src={`${file.objectURL}`} alt="" />
                                            </div>
                                        </div>
                            }}
                            uploadOptions={
                                {label: 'Uplaod', icon: 'pi pi-upload', className: 'p-button-success', style:{display:'none'}}
                            }
                            chooseLabel="Adicionar"
                            cancelLabel='Cancelar'
                            accept="image/*" customUpload uploadHandler={uploadFileCover}  ref={coverUpload} name="cover" url=""  />
                        </div>
                        <div className="col-12">
                            <Controller name={`name`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}} className="p-input-icon-left">
                                    <i className="pi pi-file" />
                                    <InputText required inputStyle={{width:'100%'}} placeholder='Título' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`description`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <InputTextarea autoResize inputStyle={{width:'100%'}} placeholder='Descrição' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-12">
                            <h3>Conteúdo</h3>
                            <Editor style={{height:'320px'}} value={text} onTextChange={(e) => setText(e.htmlValue)} />
                        </div>
                        <div className="col-12">
                            <h3>Material complementar</h3>
                            <FileUpload 
                            chooseLabel="Adicionar"
                            cancelLabel='Cancelar'
                            uploadOptions={
                                {label: 'Uplaod', icon: 'pi pi-upload', className: 'p-button-success', style:{display:'none'}}
                            }
                            customUpload uploadHandler={uploadFiles}  ref={filesUpload} name="material[]" url="" multiple />
                        </div>
                    </div>
                    <div className="grid">
                    <div className="col-12 mt-4 mb-4" style={{textAlign:'right'}}>
                        <Button onClick={()=> navigate('/user/wiki')} label="Cancelar" className='p-button-danger mr-2 p-button-sm' />
                        <Button label="Cadastrar" className='p-button-sm' />
                    </div>
                </div>
                </form>
            </div>
            
        </div>
    )
}