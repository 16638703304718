import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';

import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { TabMenu } from 'primereact/tabmenu';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Timeline } from 'primereact/timeline';
import { Toast } from 'primereact/toast';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { useNavigate } from 'react-router-dom';
import { api, URL_DEADLINE_ALL, URL_DEADLINE_STATUS, URL_DEADLINE_UPDATE, URL_COLLABORATORS, URL_FILES_FILE } from '@/services/api';
import { formatShortName } from '@/services/utils';



export const PageUserDeadline = () => {

    const tabmodel = useMemo(()=> [
        {label: 'Em andamento', params: {filter: false}},
        {label: 'Cumprido',  params: {filter: true, propfilter:'status', value: 'CUMPRIDO'}},
        {label: 'Decorrido',  params: {filter: true, propfilter:'status', value: 'DECORRIDO'}},
        {label: 'Todos', params: null}
    ], [])

    const navigate = useNavigate();

    const toast = useRef(null);

    const [data, setData] = useState();
    const [dataStatus, setDataStatus] = useState();
    const [activeIndex, setActiveIndex] = useState();
    const [contentData, setContentData] = useState();
    const [showDialogData, setShowDialogData] = useState(false);
    const [dataResume, setDataResume] = useState();
    const [collaboratorsList, setCollaboratorsList] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const clearFilter = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'user_ref': { value: null, matchMode: FilterMatchMode.STARTS_WITH }
        })
        setGlobalFilterValue('');
    }
    
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters };
        _filters1['global'].value = value;
    
        setFilters(_filters1);
        setGlobalFilterValue(value);
    }

    const getContentData = useCallback(async (_data)=>{

        let list = [...data];
        if(tabmodel[_data].params){
            if(tabmodel[_data].params.filter){
                list = list.filter(item => {
                    if(tabmodel[_data].params?.value)
                        return item[tabmodel[_data].params.propfilter] === tabmodel[_data].params?.value
                    else
                        return item[tabmodel[_data].params.propfilter]
                });
            }else{
                list = list.filter(item => !/cumprido|decorrido/gi.test(item.status));
            }
        }

       // console.log(list);

        setContentData(list);
    }, [setContentData, tabmodel, data])

    const openResume = (_data) => {
        setDataResume(_data);
        setShowDialogData(true);
    }

    const donwloadContent = useCallback( async (_data) => {
        const url_base = `${URL_FILES_FILE}/?file=${_data.url}`;

        const contentData = await fetch(url_base);
        const content = await contentData.blob();

        const href = URL.createObjectURL(content);

        const forcedownload = document.createElement('a');
        forcedownload.setAttribute('download', `${_data.name}.${_data.ext}`);
        forcedownload.setAttribute('href', href);
        forcedownload.click();
        URL.revokeObjectURL(href);

    }, []);
    
    useEffect(()=>{
        if(data){
            setContentData(null);
            getContentData(activeIndex);
        }
    },[activeIndex, getContentData, data])

    const getData = useCallback(async ()=> {
        setIsLoading(true);
        const result = await api.get(URL_DEADLINE_ALL, null ,true);
        result.data = result.data?.map(item=>{
            item.id_name = `Prazo ${item.id}`;
            return item;
        })

        //console.log(result.data);
        
        setData(result.data);
        setActiveIndex(0);
        setIsLoading(false);
    }, [])

    const getDataStatus = useCallback(async ()=> {
        const result = await api.get(URL_DEADLINE_STATUS, null ,false);
        setDataStatus(result.data);
    }, [])

    const editCell = useCallback(async (_data = null) => {  

         await api.put(URL_DEADLINE_UPDATE, _data, true);
        getData();
    }, [getData]);

    const getAllCollaborators = useCallback(async()=>{
        const cl = await api.get(URL_COLLABORATORS, null, true);
        setCollaboratorsList(cl.data);
    }, []);

    const copyContent = (data) => {
        navigator.clipboard.writeText(data);
        toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Conteúdo copiado com sucesso'});
    }

    useEffect(()=>{
        getDataStatus();
        getData();
        getAllCollaborators();
    },[getData, getDataStatus, getAllCollaborators])

    if(!contentData)
        return (
            <div className="page-user-deadline">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-user-deadline">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Controle de prazos'}
                    ]}
                />
            </div>
            <div className="header mt-4 mb-4">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: 0}}
                    left={()=><>
                    </>} 
                    right={()=><>
                        <Button onClick={()=>{getData()}} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>{navigate('/user/deadline/create')}} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} />
            </div>
            <div className="menu">
                <TabMenu 
                    activeIndex={activeIndex}
                    onTabChange={(e)=>setActiveIndex(e.index)}
                    model={tabmodel} />
            </div>
            <div className="grid">
                <div className="col-12">
                <DataTable 
                        value={contentData} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há prazos cadastrados"
                        size='small'
                        stripedRows
                        rows={10}
                        paginator={contentData?.length > 10}
                        filters={filters}
                        globalFilterFields={['specie', 'status', 'id','id_name', 'process_number']}
                        editMode="cell"
                        loading={isLoading}
                        header={()=>{
                            return <div className="flex justify-content-end">
                                        <span className="p-input-icon-left">
                                            <i className="pi pi-search" />
                                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Busca" />
                                        </span>
                                        <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-sm ml-2" onClick={clearFilter} />
                                    </div>
                        }}
                    >
                        <Column style={{width:'100px'}} body={(row)=>{
                            return row.id_name
                        }} header="ID" />
                        <Column style={{width:'150px'}} body={(row)=>{
                            return  <div style={{display:'flex', alignItems:'center'}}>
                                {
                                    row?.name_user ?
                                        <>
                                        {formatShortName(row?.name_user)}
                                        <Button onClick={()=> copyContent(row?.name_user)} className='p-button-sm p-button-text' icon='pi pi-copy' />
                                        </>
                                    :
                                    '-'
                                }
                            </div>
                        }} header="Cliente" />
                        <Column style={{width:'150px'}} body={(row)=>{
                            return  <div style={{display:'flex', alignItems:'center'}}>
                            {
                                row?.process_number ?
                                    <>
                                    <span style={{ textOverflow:'ellipsis', overflow:'hidden', width:'100px'}} >{row?.process_number}</span>
                                    <Button onClick={()=> copyContent(row?.process_number)} tooltip={row?.process_number} className='p-button-sm p-button-text' icon='pi pi-copy' />
                                    </>
                                :
                                '-'
                            }
                        </div>
                        }} header="Processo" />
                        <Column body={(row)=>{
                            return row.time_recommended ? `${new Date(row.time_recommended).toLocaleDateString()}` : '-'
                        }} header="Recomendada" />
                        <Column sortable body={(row)=>{
                            return row.time_fatal ? `${new Date(row.time_fatal).toLocaleDateString()}` : '-'
                        }} field="time_fatal" header="Fatal" />
                        <Column  style={{width:'200px', height:'45px'}} body={(row)=>{
                            return row?.status ? <Tag style={{backgroundColor:row.status_color, width:'100%', cursor: 'pointer'}} value={row.status} /> : <div style={{textAlign:'center', width:'100%'}}>-</div> 
                        }} header="Status" field='deadline_control_status_id' 
                        editor={(options)=>{
                            return <Dropdown 
                                    style={{width:'100%'}}
                                    optionLabel='name' 
                                    optionValue='id' 
                                    options={dataStatus}
                                    filter
                                    onChange={(e) =>{ 
                                        editCell({id:options.rowData.id, deadline_control_status_id: e.target.value });
                                        options.editorCallback(e.target.value);
                                    }}  
                                    value={options.value} />
                        }}
                        />
                        <Column  body={(row)=>{
                            return  row?.user_adm_name ? formatShortName(row?.user_adm_name) : '-'
                        }} header="Responsável"  field='user_adm_id'
                        editor={(options)=>{
                            return <Dropdown 
                                    style={{width:'100%'}}
                                    optionLabel='name' 
                                    optionValue='id' 
                                    options={collaboratorsList}
                                    filter
                                    onChange={(e) =>{ 
                                        editCell({id:options.rowData.id, user_adm_id: e.target.value });
                                        options.editorCallback(e.target.value);
                                    }}  
                                    value={options.value} />
                        }}/>
                        <Column  body={(row)=>{
                            return  row?.user_attorney_name ? formatShortName(row?.user_attorney_name) : '-'
                        }} header="Advogado(a)" field='attorney_id'
                        editor={(options)=>{
                            return <Dropdown 
                                    style={{width:'100%'}}
                                    optionLabel='name' 
                                    optionValue='id' 
                                    options={collaboratorsList}
                                    filter
                                    onChange={(e) =>{ 
                                        editCell({id:options.rowData.id, attorney_id: e.target.value });
                                        options.editorCallback(e.target.value);
                                    }}  
                                    value={options.value} />
                        }}/>
                        <Column style={{width:'160px', minWidth:'160px'}} body={(row)=>{
                            return <>
                                <Button className='p-button-sm p-button-text' icon="pi pi-info-circle" tooltip={row.reason} tooltipOptions={{position:'left'}} />
                                <Button className='p-button-sm p-button-text' icon="pi pi-book" onClick={()=>{openResume(row)}} />
                                <Button className='p-button-sm p-button-text' icon="pi pi-eye" onClick={()=>navigate(`/user/deadline/view/${row?.id}`)} />
                            </>
                        }} header="" />
                    </DataTable>

                    <Dialog maximizable blockScroll header={`Detalhes - ${dataResume?.id_name}`} visible={showDialogData} style={{ width: '90vw', maxWidth:'1000px' }} footer={null} onHide={() => setShowDialogData(false)}>
                        <div className="grid">
                            <div className="col-6">
                                <strong>Número do processo</strong>: {dataResume?.process_number ? <span style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/processcases/${dataResume?.process_cases_id}`,{state:{tabActive:1}})}>{dataResume?.process_number}</span>  : '-'}
                            </div>
                            <div className="col-6">
                                <strong>Status</strong>: {dataResume?.status}
                            </div>
                            <div className="col-4">
                                <strong>Cliente</strong>: { dataResume?.name_user}
                            </div>
                            <div className="col-4">
                                <strong>Responsável</strong>: { formatShortName(dataResume?.user_adm_name)}
                            </div>
                            <div className="col-4">
                                <strong>Procurador(a)</strong>: { formatShortName(dataResume?.user_attorney_name)}
                            </div>
                            <div className="col-4">
                                <strong>Tribunal</strong>: { dataResume?.court}
                            </div>
                            <div className="col-8">
                                <strong>Espécie</strong>: { dataResume?.specie}
                            </div>
                            <div className="col-4">
                                <strong>Criado em</strong>: {dataResume?.timecreated ? `${new Date(dataResume.timecreated).toLocaleDateString()} - ${new Date(dataResume.timecreated).toLocaleTimeString()}` : '-'}
                            </div>
                            <div className="col-4">
                                <strong>Data recomendada</strong>: {dataResume?.time_recommended ? `${new Date(dataResume.time_recommended).toLocaleDateString()} - ${new Date(dataResume.time_recommended).toLocaleTimeString()}` : '-'}
                            </div>
                            <div className="col-4">
                                <strong>Data fatal</strong>: {dataResume?.time_fatal ? `${new Date(dataResume.time_fatal).toLocaleDateString()} - ${new Date(dataResume.time_fatal).toLocaleTimeString()}` : '-'}
                            </div>
                            <div className="col-4">
                                <strong>Delegado em</strong>: {dataResume?.time_set_user_adm ? `${new Date(dataResume.time_set_user_adm).toLocaleDateString()} - ${new Date(dataResume.time_set_user_adm).toLocaleTimeString()}` : '-'}
                            </div>
                            <div className="col-4">
                                <strong>Expedição</strong>: {dataResume?.expedition ? `${new Date(dataResume.expedition).toLocaleDateString()} - ${new Date(dataResume.expedition).toLocaleTimeString()}` : '-'}
                            </div>
                            <div className="col-4">
                                <strong>Ciência</strong>: {dataResume?.science ? `${new Date(dataResume.science).toLocaleDateString()} - ${new Date(dataResume.science).toLocaleTimeString()}` : '-'}
                            </div>
                            <div className="col-4">
                                <strong>Duração do prazo</strong>: {dataResume?.duration_time ? `${dataResume?.duration_time} dia(s)` : '-'}
                            </div>
                            <div className="col-4">
                                <strong>Cumprido em</strong>: {dataResume?.time_completed ? `${new Date(dataResume.time_completed).toLocaleDateString()} - ${new Date(dataResume.time_completed).toLocaleTimeString()}` : '-'}
                            </div>
                            <div className="col-12">
                                <h5>Motivação</h5>
                                <div>
                                    {
                                        dataResume?.reason
                                    }
                                </div>
                            </div>
                            <div className="col-12">
                                <h5>Arquivos</h5>
                                <DataTable 
                                    style={{width:'100%'}}
                                    size="small"
                                    value={dataResume?.files} 
                                    emptyMessage="Não há arquivos"
                                    dataKey="id" 
                                    >
                                    
                                    <Column  field='name' header="Nome" />
                                    <Column body={(row)=>{
                                        return row?.datetime ? `${new Date(row.datetime).toLocaleDateString()} - ${new Date(row.datetime).toLocaleTimeString()}` : '-'
                                    }} style={{width:'150px'}} field='datetime' header="Criação" />
                                    <Column body={(row)=>{
                                        return row?.timemodified ? `${new Date(row.timemodified).toLocaleDateString()} - ${new Date(row.timemodified).toLocaleTimeString()}` : '-'
                                    }} style={{width:'150px'}} field='timemodified' header="Atualização" />
                                    <Column body={(row)=>{
                                        return <Button disabled={!row?.url} onClick={()=>{donwloadContent(row)}} icon="pi pi-download" className='p-button-text' />
                                    }} style={{width:'50px'}} header="" />
                                </DataTable>
                            </div>
                            <div className="col-12">
                                <h5>Histórico</h5>
                                <Timeline value={dataResume?.history} 
                                opposite={(item)=> item.timecreated ? `${new Date(item.timecreated).toLocaleDateString()} - ${new Date(item.timecreated).toLocaleTimeString()}` : '-'} 
                                content={(item) => {
                                    return <div>
                                        <div><strong>{item?.user_name}</strong></div>
                                        <small>{item?.description}</small>
                                    </div>
                                }} />
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}