import React from "react";


export const PageCalendarNotificationResume = () =>{
    
    return (
        <div className="calender-notification-resume">
            <div className="grid">
                <div className="col-12">

                </div>
            </div>
            <div className="grid mt-3 mb-3">
                <div className="col-4">

                </div>
                <div className="col-4">

                </div>
                <div className="col-4">

                </div>
            </div>
        </div>
    )
}