import React, {useState, useEffect, useCallback, useRef} from 'react';
 
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { useNavigate } from 'react-router-dom';

import "@/assets/styles/pages/wiki/index.scss";
import { api, URL_WIKI_ALL } from '@/services/api';

export const PageUserWiki = () => {

    const [content, setContent] = useState([]);

    const navigate = useNavigate();

    const getData = useCallback(async () => {

        const result = await api.get(URL_WIKI_ALL, null, true);
        //console.log(result.data);
        setContent(result?.data);

    }, []);

    useEffect(()=>{
        getData();
    }, [getData])
    
    return (
        <div className="page-user-wiki">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Wiki'}
                    ]}
                />
            </div>
            <div className="header mt-4 mb-4">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: 0}}
                    left={()=><>
                    </>} 
                    right={()=><>
                        <Button onClick={()=>getData()} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>navigate('/user/wiki/create')} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} />
            </div>
            <div className="grid mt-4">
                <div className="col-12">
                    <div className="list-main">
                        {
                            content?.map(item => {
                                return <div className="card-wiki">
                                    <div className="cover">
                                        <i className="pi pi-file"></i>
                                    </div>
                                    <div className="title">
                                        {item.name}
                                    </div>
                                    <div className="footer">
                                        <div className="status">
                                            Não visualizado
                                        </div>
                                        <div className="buttons">
                                            <div onClick={()=> navigate(`/user/wiki/read/${item.id}`)} className="button-access">
                                                <i className="pi pi-eye mr-2"></i>Acessar
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}