import React, { useCallback, useState, useRef, useEffect } from 'react';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';


import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { useNavigate } from 'react-router-dom';
import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { api, URL_COLLABORATORS, URL_USER_AREAS, URL_PROCESSCASES_ALL, URL_FINANCE_TYPE, URL_FINANCE_TYPE_VALUE_DATA, URL_FINANCE_PAYMENT_MODE, URL_FINANCE_CREATE } from '@/services/api';
import { formatMoney } from '@/services/utils';
import { Checkbox } from 'primereact/checkbox';

export const PageFinanceCreate = () => {

    const navigate = useNavigate();

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);

    const tikectID = useRef(null);
    tikectID.current = tikectID.current ? tikectID.current : new Date().getTime();

    const created = useRef(null);
    created.current = created.current ? created.current : new Date();

    const toast = useRef(null);

    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;

    const [financeTypes, setFinanceTypes] = useState();
    const [currentFinanceType, setCurrentFinanceType] = useState();
    const [currentFinaceTypeDataValue, setCurrentFinaceTypeDataValue] = useState();
    const [collaboratorsList, setCollaboratorsList] = useState();
    const [areasList, setAreasList] = useState();
    const [casesList, setCasesList] = useState();
    const [paymentMode, setPaymentMode] = useState();
    const [parcelament, setParcelament] = useState(1);
    const [parcelamentData, setParcelamentData] = useState();
    const [hasTotal, setHasTotal] = useState(false);
    const [updateTitle, setUpdateTitle] = useState(0);
    const [updateList, setUpdateList] = useState(0);

    const [isLoad, setIsLoad] = useState(false);


    const getCollaborators = useCallback(async () => {
        const data = await api.get(URL_COLLABORATORS, null, true);
        setCollaboratorsList(data.data);
        
    }, [])
    
    const getAreas = useCallback(async () => {
        const data = await api.get(URL_USER_AREAS, null, true);
        setAreasList(data.data);
        
    }, [])
    
    const getCases = useCallback(async () => {
        let data = await api.get(URL_PROCESSCASES_ALL, null, true);
        data.data = data.data.map(item => {
            item.label = `${item.name} ${item.process_number ? `- ${item.process_number}` : ''}`;
            return item;
        })
        //console.log(data.data);
        setCasesList(data.data);

    }, [])

    const getFinanceTypes = useCallback(async () => {

        const data =  await api.get(URL_FINANCE_TYPE, null, true);
        setFinanceTypes(data.data);

    }, [])

    const getFinanceTypesDataValues = useCallback(async () => {

        const data =  await api.get(`${URL_FINANCE_TYPE_VALUE_DATA}/${currentFinanceType}`, null, true);
        setCurrentFinaceTypeDataValue(data.data);

    }, [currentFinanceType])

    const getFinancePaymentModeData = useCallback(async () => {

        const data =  await api.get(URL_FINANCE_PAYMENT_MODE, null, true);
        setPaymentMode(data.data);

    }, [])

    const alterParcelamentData = ((id, _data) => {

        const temp  = parcelamentData.map(item => {
            if(~~id === ~~item.number){
                const temp = {...item, ..._data};
                return temp;
            }
            return item;
        })

        setParcelamentData(temp);

    })

    const onSubmit = useCallback(async (_data) => {

        const _finaceType = financeTypes?.filter(item => ~~item.id === ~~currentFinanceType)[0]?.id;
        const _titleId = form.getValues('finance_type_data_value');
        const _id = currentFinaceTypeDataValue?.filter(item => ~~item.id  === ~~_titleId)[0]?.id;

        const sendData = parcelamentData?.map(item => {
            return (
                {
                    finance_type_id: _finaceType,
                    finance_type_value: _id,
                    number: item.number,
                    title: _data.title,
                    date: item.date,
                    value: item.value,
                    valuepaid: item.valuepaid,
                    paid: item.paid ? 1 : 0,
                    finance_mode_paid_id: _data.type,
                    destinaton: _data.destination,
                    description: _data.description,
                    created: created.current
                }
            )
        });

        const total = parseFloat(_data.valor);
        const temp_total = sendData?.reduce((c, n) => c + parseFloat(n.value), 0);
        const temp_total_paid = sendData?.reduce((c, n) => c + parseFloat(n.valuepaid), 0);

        if(total === temp_total && temp_total_paid <= total){
            console.log(sendData);

            const list = sendData.map(item => {
                return api.post(URL_FINANCE_CREATE, item, true);
            })
            
            await Promise.all(list);

            toast.current.show({severity:'sucess', summary: 'Sucesso', detail:'Dados cadastrados.', life: 3000});

            navigate('/user/finance');

        }else{
            toast.current.show({severity:'error', summary: 'Erro', detail:'Existe problemas nos valores.', life: 3000});
        }


    }, 
    [parcelamentData])


    useEffect(()=>{

        const _finaceType = financeTypes?.filter(item => ~~item.id === ~~currentFinanceType)[0]?.name;
        const _titleId = form.getValues('finance_type_data_value');
        const _text = currentFinaceTypeDataValue?.filter(item => ~~item.id  === ~~_titleId)[0]?.datavalue;
        let _title = `${_finaceType} - ${_text}`;

        if(!_text){
            _title = '';
        }

        form.setValue('title', _title);

    }, [updateTitle, form])

    useEffect(()=>{
        const _value = form.getValues('valor');
        if(_value){
            const list = [];
            const now = new Date();
            
            for(let i=0; i < parcelament; i++){
                const _parcelamentItem = {
                    number: i + 1,
                    value: parseFloat(parseFloat(_value) / parcelament).toFixed(2),
                    valuepaid: parseFloat(parseFloat(_value) / parcelament).toFixed(2),
                    date: now.toISOString().substr(0, 10),
                    paid: i == 0 
                }
                
                list.push(_parcelamentItem);
                
                now.setMonth(now.getMonth() + 1);
            }
            
            const totalTemp = list?.reduce((c, n) => c + parseFloat(n.value), 0);
            const diff = parseFloat(_value) - totalTemp;

            if(diff !== 0){
                list[0].value = (parseFloat(list[0].value) + diff).toFixed(2);
                list[0].valuepaid = (parseFloat(list[0].valuepaid)).toFixed(2);
            }

            console.log(list);

            setParcelamentData(list);
        }
    }, [parcelament, form, updateList])

    useEffect(()=> {

        if(currentFinanceType){
            form.setValue('title', '');
            getFinanceTypesDataValues();
        }

    }, [currentFinanceType])

    useEffect(()=>{
        getFinanceTypes();
        getFinancePaymentModeData();
    }, [getFinanceTypes, getFinancePaymentModeData])

    return (
        <div className="page-user-wiki-create">
            <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Financeiro', url: '/user/finance'},
                        {label:'Lançar no financeiro'}
                    ]}
                />
            <div>
                <form onSubmit={handleSubmit(onSubmit)}  style={{pointerEvents: isLoad ? 'none' : 'auto'}} >
                    <div className="grid mt-4">
                        
                        <div className="col-3" style={{display:'flex', alignItems:'center'}}>
                          <strong>Criado em</strong>:  { created.current.toLocaleDateString() } - {created.current.toLocaleTimeString()}
                        </div>
                        <div className="col-9">
                        <Dropdown 
                            required
                            options={financeTypes}
                            optionLabel='name'
                            optionValue='id'
                            filter
                            inputStyle={{width:'100%'}}  placeholder='Tipo de lançamento' className='' style={{width:'100%'}} onChange={(e) => {setCurrentFinanceType(e.target.value);}} value={currentFinanceType}  />
                        </div>
                        <div className="col-12">
                            <Controller name={`finance_type_data_value`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Dropdown 
                                    required
                                    filter
                                    options={currentFinaceTypeDataValue}
                                    optionLabel='datavalue'
                                    optionValue='id'
                                    inputStyle={{width:'100%'}}  placeholder='Selecione a referência' className='' style={{width:'100%'}} 
                                    onSelect={(e)=>{
                                        setUpdateTitle(e);
                                    }}
                                    onChange={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        
                        </div>
                        <div className="col-3">
                            <Controller name={`destination`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Dropdown 
                                    required
                                    options={[
                                        {label:'Entrada', value:'in'},
                                        {label:'Saída', value:'out'}
                                    ]}
                                    inputStyle={{width:'100%'}}  placeholder='Selecione o lançamento' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        </div>
                        <div className="col-3">
                            <Controller name={`type`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <Dropdown 
                                    required
                                    options={paymentMode}
                                    optionLabel='name'
                                    optionValue='id'
                                    inputStyle={{width:'100%'}}  placeholder='Forma pagamento' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.value);}}  id={field.name} {...field} value={field.value}  />
                            )} />
                        
                        </div>
                        
                        <div className="col-3">
                            <Controller name={`valor`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <InputText inputStyle={{width:'100%'}} type="number" defaultValue={0} step={0.01} min={0}  placeholder='valor' className='' style={{width:'100%'}} 
                                    onInput={(e) =>{setHasTotal(true); setUpdateList(new Date().getTime())}} 
                                    onChange={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-3">
                            <Controller name={`parcelament`} defaultValue={1} control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <InputText disabled={!hasTotal} inputStyle={{width:'100%'}} type="number" defaultValue={1} step={1} min={1}  placeholder='Parcelas' className='' style={{width:'100%'}} onInput={(e) => setParcelament(e.target.value)} onChange={(e) => { field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-12">
                            <DataTable
                                value={parcelamentData}
                                emptyMessage="Não há informações"
                                footerColumnGroup={
                                    <ColumnGroup>
                                        <Row>
                                        <Column footer="Total:" colSpan={2} footerStyle={{textAlign: 'right'}}/>
                                        <Column footer={ formatMoney(parcelamentData?.reduce((c, n) => c + parseFloat(n.value), 0).toFixed(2))}  />
                                        <Column footer={ formatMoney(parcelamentData?.reduce((c, n) => c + parseFloat(n.valuepaid), 0).toFixed(2))}  />
                                        <Column />
                                        </Row>
                                    </ColumnGroup>
                                }
                            >
                                <Column style={{width: '100px'}} field="number" header="Número" />
                                <Column body={(row)=> <Calendar value={new Date(`${row.date} 00:00:00`)} onChange={(e)=> alterParcelamentData(row.number, {date: e.target.value})} showIcon dateFormat='dd/mm/yy' locale='pt-BR' /> } field="date" header="Data" />
                                <Column body={(row)=> <InputText inputStyle={{width:'100%'}} type="number" onChange={(e)=> alterParcelamentData(row.number, {value: e.target.value})} defaultValue={0} step={0.01} min={0}  placeholder='Valor' className='' style={{width:'100%'}} value={row.value}  /> } field="value" header="Valor" />
                                <Column body={(row)=> <InputText inputStyle={{width:'100%'}} type="number" onChange={(e)=> alterParcelamentData(row.number, {valuepaid: e.target.value})} defaultValue={0} step={0.01} min={0}  placeholder='Valor pago' className='' style={{width:'100%'}} value={row.valuepaid}  /> } field="paidvalue" header="Valor Pago" />
                                <Column style={{width: '60px', textAlign:'center'}} body={(row)=>{
                                    return <Checkbox onChange={(e)=> alterParcelamentData(row.number, {paid: !row.paid})} checked={row.paid} />
                                }} field="paid" header="Pago" />
                            </DataTable>
                        </div>
                        <div className="col-12">
                            <Controller name={`title`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <InputText inputStyle={{width:'100%'}} placeholder='Título' className='' style={{width:'100%'}} onChange={(e) => { field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`description`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span style={{width:'100%'}}>
                                    <InputTextarea autoResize inputStyle={{width:'100%'}} placeholder='Descrição' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 mt-4 mb-4" style={{textAlign:'right'}}>
                            <Button onClick={()=> navigate('/user/finance')} label="Cancelar" className='p-button-danger mr-2 p-button-sm' />
                            <Button loading={isLoad} label="Cadastrar" className='p-button-sm' />
                        </div>
                    </div>
                </form>
            </div>
            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}