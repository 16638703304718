import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';

import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';

import { addLocale } from 'primereact/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import { UIBreadcrumb } from '@/components/ui/breadcrumb';

import { useForm, Controller } from 'react-hook-form';

import { useNavigate} from 'react-router-dom';
import { api, URL_DEADLINE_COURT,URL_DEADLINE_STATUS, URL_DEADLINE_SPECIES, URL_COLLABORATORS, URL_PROCESSCASES_PROCESS_LIST, URL_DEADLINE_CREATE } from '@/services/api';
import { InputText } from 'primereact/inputtext';
import { useAPP } from '@/services/provider/app';

export const PageUserDeadlineCreate = () => {


    const { user } = useAPP();

    const navigate = useNavigate();

    const [ client, setClient] = useState();
    const [ court, setCourt] = useState();
    const [ species, setSpecies] = useState();
    const [ status, setStatus] = useState();
    const [ collaborators, setCollaborators] = useState();
    const [process, setProcess] = useState();
    const [currentProcess, setCurrentProcess] = useState();
    const [files, setFiles] = useState([]);

    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [fileDate, setFileDate] = useState();

    let createDate = useRef(new Date());
    createDate.current = createDate.current ? createDate.current : new Date();

    const toast = useRef(null);
    const upfile = useRef(null);

    const defaultValues = useMemo(()=>({
        process_id: null,
        user_adm_id: null,
        deadline_control_court_id: null,
        deadline_control_species_id: null,
        deadline_control_status_id: null,
        description: null,
        time_recommended: null,
        time_fatal: null,
        expedition: null,
        science: null,
        duration_time: null
    }),[])

    const form = useForm(defaultValues);
    const control = form.control;
    const handleSubmit = form.handleSubmit;

    moment.locale('pt-br');

    const objDataLocale = {
        firstDayOfWeek: 1,
        dayNames: moment.localeData().weekdays(),
        dayNamesShort: moment.localeData().weekdaysShort(),
        dayNamesMin: moment.localeData().weekdaysMin(),
        monthNames: moment.localeData().months(),
        monthNamesShort: moment.localeData().monthsShort(),
        today: 'Hoje',
        clear: 'Limpar'
    }

    addLocale('pt-BR', objDataLocale);


    const getDataClient = useCallback(async (_data)=>{

        let data = process?.filter(item => ~~item?.id === ~~_data);

        if(data?.length > 0){
            data =  data[0].client;
        }
        //const result = await api.get(`${URL_USER_CLIENT}/${data?.id}`, null, true);
        ////console.log(result.data);
        setClient(data);
    }, [process]);

    const getStatus  = useCallback(async()=>{
        const result = await api.get(`${URL_DEADLINE_STATUS}`, null, false);
        setStatus(result?.data);
    }, [])

    const getSpecies  = useCallback(async()=>{
        const result = await api.get(`${URL_DEADLINE_SPECIES}`, null, false);
        setSpecies(result?.data);
    }, [])

    const getCourt  = useCallback(async()=>{
        const result = await api.get(`${URL_DEADLINE_COURT}`, null, false);
        
        setCourt(result?.data);
    }, [])

    const getCollaborators  = useCallback(async()=>{
        const result = await api.get(`${URL_COLLABORATORS}`, null, true);
        setCollaborators(result?.data);
    }, [])

    const getProcess  = useCallback(async()=>{
        const result = await api.get(`${URL_PROCESSCASES_PROCESS_LIST}`, null, true);
        //console.log(result.data);
        setProcess(result?.data);
    }, [])


    const onSubmit = useCallback(async (_data) => {
        
        let data = {..._data};

        if(data?.user_adm_id){
            data.time_set_user_adm = new Date();
        }


        const sendData =  {
            deadline: data,
            deadline_files: files?.length > 0 ? files : null,
        }

        sendData.deadline.duration_time = sendData?.deadline?.duration_time?.toString()?.replace(/\D/gi, '');
        
        const result = await api.post(URL_DEADLINE_CREATE, sendData, true);

        navigate(`/user/deadline/view/${result?.data}`);

    }, [navigate, files]);

    const onSubmitErros = useCallback((_data)=>{

        const erros = Object.keys(_data)?.map(item => {
            return ({severity:'error', summary:`Campo obrigatório`, detail:_data[item].message, life: 3000})
        })

        toast.current.show(erros);
    }, [])

    const removeFiles = useCallback((_elm)=>{
        const temp = files.filter(item => item === _elm);
        setFiles(temp);
    }, [files])

    const addFile = useCallback((_data) => {
        let temp = [...files];
        temp.push({name: fileName, datetime: fileDate, file:file});
        //console.log(temp);

        upfile.current.clear();
        setFile(null);
        setFileName('');
        setFileDate(null)
        setFiles(temp);
    }, [fileName, fileDate, files, setFiles])

    useEffect(()=>{
        getDataClient(currentProcess);
    }, [currentProcess, getDataClient])

    useEffect(()=>{
        const init = async () => {
            await getStatus();
            await getSpecies();
            await getCourt();
            await getCollaborators();
            await getProcess();
        }

        init();

    },[getStatus, getSpecies, getCourt, getCollaborators, getProcess]);



    return (
        <div className="page-user-deadline-create">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Controle de prazos', url:'/user/deadline' },
                        {label:'Criar controle de prazos' }
                    ]}
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit, onSubmitErros)}>
                <div className="grid mt-4">
                    <div className="col-12">
                        <Controller name={`process_id`} defaultValue="" rules={{ required: 'Processo' }} control={control}  render={({ field, fieldState }) => (
                            <Dropdown placeholder='Processo' filter {...field} options={process} optionLabel="number" optionValue="id" style={{width:'100%'}} onChange={(e) => {setCurrentProcess(e.target.value); field.onChange(e.target.value); }}  id={field.name}  value={field.value} />
                        )} /> 
                    </div>
                    <div className="col-12">
                        {client?.name || '-'}
                    </div>
                    <div className="col-12">
                        <Controller name={`user_adm_id`} defaultValue="" rules={{ required: 'Colaborador' }} control={control}  render={({ field, fieldState }) => (
                            <Dropdown filter {...field} placeholder="Colaborador" options={collaborators} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                    </div>
                    <div className="col-12">
                        <Controller name={`deadline_control_court_id`} rules={{ required: 'Tribunal' }} defaultValue="" control={control}  render={({ field, fieldState }) => (
                            <Dropdown placeholder='Tribunal' filter {...field} options={court} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                    </div>
                    <div className="col-12">
                        <Controller name={`deadline_control_species_id`} defaultValue="" rules={{ required: 'Espécie' }} control={control}  render={({ field, fieldState }) => (
                            <Dropdown placeholder='Especie' filter {...field} options={species} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                    </div>
                    <div className="col-12">
                        <Controller name={`deadline_control_status_id`} defaultValue="" rules={{ required: 'Status' }} control={control}  render={({ field, fieldState }) => (
                            <Dropdown placeholder='Status' filter {...field} options={status} optionLabel="name" optionValue="id" style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value} />
                        )} />
                    </div>
                    <div className="col-12">
                        <Controller name={`reason`} defaultValue="" rules={{ required: 'Motivo da intimação' }} control={control}  render={({ field, fieldState }) => (
                            <InputTextarea {...field} autoResize inputStyle={{width:'100%', minHeight:'300px'}} placeholder='Motivo da intimação' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value)}}  id={field.name}  value={field.value}  />
                        )} />
                    </div>
                    
                    <div className="col-4">
                        <Controller name={`time_recommended`} defaultValue="" rules={{ required: 'Data recomendada' }} control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <Calendar locale='pt-BR' dateFormat='dd/mm/yy' showIcon inputStyle={{width:'100%'}} placeholder='Data recomendada' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-4">
                        <Controller name={`time_fatal`} defaultValue="" rules={{ required: 'Data fatal' }} control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <Calendar  locale='pt-BR' dateFormat='dd/mm/yy' showIcon inputStyle={{width:'100%'}} placeholder='Data fatal' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                        
                    </div>
                    <div className="col-4">
                        <Controller name={`expedition`} defaultValue="" rules={{ required: 'Data expedição' }} control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <Calendar locale='pt-BR' dateFormat='dd/mm/yy' showIcon inputStyle={{width:'100%'}} placeholder='Expedição' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                    </div>
                    <div className="col-4">
                        <Controller name={`science`} defaultValue="" rules={{ required: 'Data da ciência' }} control={control}  render={({ field, fieldState }) => (
                            <span style={{width:'100%'}} className="">
                                <Calendar locale='pt-BR' dateFormat='dd/mm/yy' showIcon inputStyle={{width:'100%'}} placeholder='Ciência' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                            </span>
                        )} />
                        
                    </div>
                    <div className="col-4">
                        <Controller name={`duration_time`} defaultValue="" rules={{ required: 'Duração do prazo' }} control={control}  render={({ field, fieldState }) => (
                            <AutoComplete 
                                {...field}
                                field='name' 
                                suggestions={[
                                    {name:'1 dia', value:1},
                                    {name:'5 dias', value:5},
                                    {name:'10 dias', value:10},
                                    {name:'15 dias', value:15},
                                    {name:'30 dias', value:30}
                                ]}  
                                inputStyle={{width:'100%'}} 
                                placeholder="Duração do prazo"
                                className='' 
                                style={{width:'100%'}} 
                                onInput={(e) => {field.onChange(e.target.value); }} 
                                onSelect={(e)=>{ field.onChange(e.value.name)  }} 
                                value={field.value}
                                min={1}
                            />
                        )} />
                        
                    </div>
                    <div className="col-4" style={{alignItems:'center', display:'flex'}}>
                        <strong>Criado em</strong>: <span className='ml-2'> {`${createDate.current.toLocaleDateString()}`}</span>
                    </div>
                    <div className="col-12">
                        <div className='mb-2'><strong>Arquivos</strong></div>
                            <DataTable 
                                style={{width:'100%'}}
                                size="small"
                                value={files} 
                                emptyMessage="Não há dados"
                                dataKey="id" 
                                header={()=>{
                                    return <div className='grid'>
                                        <div className="col-5">
                                            <InputText style={{width:'100%'}} placeholder='Nome do arquivo' value={fileName} onInput={(e) => setFileName(e.target.value)} />
                                        </div>
                                        <div className="col-5">
                                            <Calendar locale='pt-BR' dateFormat='dd/mm/yy' showIcon  style={{width:'100%'}} inputStyle={{width:'100%'}} value={fileDate} onChange={(e)=> setFileDate(e.target.value)} />
                                        </div>
                                        <div className="col-2">
                                            <Button  style={{width:'100%'}} onClick={(e)=>{e.preventDefault(); addFile();}} label='Adicionar arquivo' />
                                        </div>
                                    </div>
                                }}
                                >
                                
                                <Column  field='name' header="Nome" />
                                <Column body={(row)=>{
                                    return row?.datetime ? new Date(row.datetime).toLocaleDateString() : '-'
                                }} style={{width:'250px'}} field='datetime' header="Data" />
                                <Column style={{width:'40px', textAlign:'center'}} body={(row)=>{
                                    return <Button onClick={(e)=>{e.preventDefault(); removeFiles(row.id)}} icon="pi pi-trash" className="p-button-danger p-button-text" />
                                }} header=""/>
                            </DataTable>
                        </div>
                </div>
                <div className="grid mt-4">
                    <div className="col-12" style={{textAlign:'right'}}>
                        <Button onClick={()=>navigate('/user/deadline')} className='p-button-danger p-button-text mr-2' label="Cancelar atendimento"/>
                        <Button className='p-button-warning' label="Criar controle de prazo"/>
                    </div>
                </div>
            </form>
            <ConfirmDialog />
            <Toast ref={toast} position="bottom-right" />
        </div>
    )
}