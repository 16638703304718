import React, { useState, useCallback, useEffect } from "react";

import { ProgressBar } from 'primereact/progressbar'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { useNavigate, useParams } from "react-router-dom";
import { api, URL_PROCESSCASES_RESUME, URL_PROCESSCASES_PROCESS_CREATE_COMMENTS } from "@/services/api";

import '@/assets/styles/pages/user/process_cases/index.scss';
import { InputTextarea } from "primereact/inputtextarea";
import { useAPP } from "@/services/provider/app";

export const PageUserProcessCasesResume = (props) => {


    const {user} = useAPP();


    const navigate = useNavigate();

    const params = useParams();

    const [data, setData] = useState();

    const [comments, setComment] = useState();

    const getData = useCallback(async () => {
        let result = await api.get(`${URL_PROCESSCASES_RESUME}/${params?.id}`, false, true);
        //console.log('#', result.data);
        const observableList = [];

        const dataObservables = {
            finance_open: {name: 'Financeiro', url:'/user/finance', fielddate:'date'},
            tickets_open: {name: 'Ticket', url:'/user/tickets', fielddate:'datetime_end'}
        }

        Object.keys(dataObservables).map(item => {

            result.data[item]?.map(el => {
                const obs = {
                    type: dataObservables[item].name,
                    url: `${dataObservables[item].url}/${el.id}`,
                    date: el[dataObservables[item].fielddate]
                };
                observableList.push(obs);
                return el;
            })

            return item;
        })


        if(result.data){
            result.data.observable = observableList;
        }


        setData(result.data); 
    }, [params])

    const sendComment = useCallback(async ()=>{
        const sendData = {
            user_id:user.id,
            process_cases_id: params?.id,
            description: comments
        }
        await api.post(`${URL_PROCESSCASES_PROCESS_CREATE_COMMENTS}`, sendData, true);
        setComment('');
        getData();
    },[getData, params, user, comments, setComment])

    useEffect(()=> {
        getData();
    }, [getData]);

    if(!data)
        return (
            <div className="page-user-processcases-resume">
                <ProgressBar style={{ height: '6px' }} mode="indeterminate" />
            </div>    
        )

    return (
        <div className="page-user-processcases-resume">
            <div className="grid">
                <div className="col-6">
                    <h4>Comentários sobre o caso</h4>
                    <div className="grid">
                        <div className="col-12">
                            <InputTextarea onChange={(e)=> setComment(e.target.value)} value={comments} style={{width:'100%'}} autoResize placeholder="Adicionar comentário" />
                        </div>
                        <div className="col-12 mb-2" style={{display:'flex', justifyContent:'flex-end', textAlign:'right'}}>
                            <Button onClick={(e)=>{e.preventDefault(); sendComment()}} label="Adicionar comentário" className="p-button-sm" />
                        </div>
                        <div className="col-12">
                            {
                                data?.process_cases_comments?.map(item => {
                                    return <div key={`comment-id-${item.id}`} className="comments">
                                        <div className="user">{item.name}</div>
                                        <div className="date"><small>{new Date(item.timecreated).toLocaleDateString()} - {new Date(item.timecreated).toLocaleTimeString()}</small></div>
                                        <div className="description">{item.description}</div>
                                    </div>
                                })
                            }
                            {
                                ~~data?.process_cases_comments?.length === 0 ? <div className="no-comments">Não há comentários para esse caso.</div> : ''
                            }
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <h4>Pontos a serem observados</h4>
                    <DataTable     
                            value={data?.observable} 
                            responsiveLayout="scroll"
                            emptyMessage="Não há informações"
                            size='small'
                            stripedRows
                            rows={10}
                            paginator={data?.observable?.length > 10}
                        >
                            <Column body={(row)=> {
                                return row.type;
                            } } header="Demanda" />
                            <Column body={(row)=> {
                                return <>{new Date(row.date).toLocaleDateString()} - {new Date(row.date).toLocaleTimeString()}</>;
                            } } header="Data" />
                            <Column style={{width:'40px'}} body={(row)=> {
                                return <Button onClick={()=>navigate(row?.url)} className='p-button-text p-button-info' icon='pi pi-eye' />;
                            } } header="" />
                        </DataTable>
                </div>
            </div>
            {/* <div className="grid">
                <div className="col-12">
                    <h4>Status atual do processo</h4>
                    {
                        data?.process_status?.description
                    }
                </div>
                <div className="col-6"> 
                    <h4>Tickets em aberto</h4>
                    <div className="data-list-tickets">
                        <DataTable     
                            value={data?.tickets_open} 
                            responsiveLayout="scroll"
                            emptyMessage="Não há informações"
                            size='small'
                            stripedRows
                            rows={10}
                            paginator={data?.tickets_open?.length > 10}
                        >
                            <Column body={(row)=> {
                                return row.ticket_identification;
                            } } header="Identificação" />
                            <Column body={(row)=> {
                                return row?.datetime_end ? `${new Date(row?.datetime_end).toLocaleDateString()} - ${new Date(row?.datetime_end).toLocaleTimeString()}` : '-';
                            } } header="Prazo final" />
                            <Column style={{textAlign:'center'}} body={(row)=> {
                                return ~~row.priority === 1 ? 'Sim' : 'Não';
                            } } header="Prioridade" />
                            <Column style={{width:'40px'}} body={(row)=> {
                                return <Button onClick={()=>navigate(`/user/tickets/${row.id}`)} className='p-button-text p-button-info' icon='pi pi-eye' />;
                            } } header="" />
                        </DataTable>
                    </div>
                    <div className="mt-2" style={{textAlign:'right', width:'100%', margin:'5px auto', cursor:'pointer', color:'var(--blue-500)',textDecoration:'underline'}}><small onClick={()=>props.goToPage(6)}>Acessar todos</small></div>
                </div>
                <div className="col-6"> 
                    <h4>Financeiro em atraso/ próximo ao vencimento</h4>
                    <DataTable     
                            value={data?.finance_open} 
                            responsiveLayout="scroll"
                            emptyMessage="Não há informações"
                            size='small'
                            stripedRows
                            rows={10}
                            paginator={data?.finance_open?.length > 10}
                        >
                            <Column body={(row)=> {
                                return row?.date ? `${new Date(row?.date).toLocaleDateString()}` : '-';
                            } } header="Vencimento" />
                            
                            <Column style={{width:'40px'}} body={(row)=> {
                                return <Button onClick={()=>navigate(`/user/finance/${row.id}`)} className='p-button-text p-button-info' icon='pi pi-eye' />;
                            } } header="" />
                        </DataTable>
                    <div className="mt-2" style={{textAlign:'right', width:'90%', margin:'5px auto', cursor:'pointer', color:'var(--blue-500)',textDecoration:'underline'}}><small onClick={()=>props.goToPage(4)}>Acessar todos</small></div>
                </div>
            </div> */}
        </div>
    )
}