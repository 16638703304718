import React, { useCallback  } from 'react';
import { useNavigate } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useForm, Controller } from 'react-hook-form';

import { api, URL_LOGIN, URL_PROFILE } from '@/services/api';
import { setLogin } from '@/services/auth';
import { useAPP } from '@/services/provider/app';

import logo from "@/assets/images/logo.png";

import '@/assets/styles/pages/login/index.scss';

export const PageLogin = () => {

    const navigate = useNavigate();

    const form = useForm(null);
    const control = form.control;
    const handleSubmit = form.handleSubmit;

    const { setUser } = useAPP();

    const onSubmit = useCallback(async (_data) => {

        const result = await api.post(URL_LOGIN, {..._data });
        if(result.data){
            setLogin(result.data);
            const userdata = await api.get(URL_PROFILE, null, true);
            setUser(userdata?.data);
            navigate('/user', {replace: true});
        }else{

        }

    },[setUser, navigate]);

    return(
        <div className="page-login">
            
            <div className="background-login"></div>
            <div className="box-login">
                <div className="grid mb-3">
                    <div className="col-12 name-client">
                        <img src={logo} alt="AFF Advocacia" /> 
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid">
                        <div className="col-12">
                            <Controller name={`username`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span className="p-input-icon-left">
                                    <i className="pi pi-envelope" />
                                    <InputText placeholder='Usuário' autoComplete='off' className='' style={{width:'100%'}} onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-12">
                            <Controller name={`password`} defaultValue="" control={control}  render={({ field, fieldState }) => (
                                <span className="p-input-icon-left">
                                    <i className="pi pi-lock" />
                                    <InputText type="password" placeholder='Senha' className='' style={{width:'100%'}}  onInput={(e) => {field.onChange(e.target.value);}}  id={field.name} {...field} value={field.value}  />
                                </span>
                            )} />
                        </div>
                        <div className="col-12 mt-3 aling-center">
                            <Button style={{minWidth:'200px'}} className='p-button-sm' label='Entrar' />
                        </div>
                        <div className="col-12">
                            <span className='recovery'>Esqueceu o sua senha?</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}