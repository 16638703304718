import React, {useState, useEffect, useCallback, useMemo} from 'react';

import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';


import { UIBreadcrumb } from '@/components/ui/breadcrumb'; 
import { api, URL_PROCESSCASES_ALL } from '@/services/api';
import { useNavigate } from 'react-router-dom';
import { formatShortName } from '@/services/utils';

export const PageUserProcessCases = () => {

    const navigate = useNavigate();

    const tabmodel = useMemo(()=> [
            {label: 'Em aberto', params: {status: 0}},
            {label: 'Fechado',  params: {status: 1}},
            {label: 'Todos', params: null}
        ], [])

    const [activeIndex, setActiveIndex] = useState(0);
    const [contentData, setContentData] = useState();
    const [showDialogData, setShowDialogData] = useState(false);
    const [dataResume, setDataResume] = useState();

    const getContentData = useCallback(async (_data)=>{
        const data = await api.get(URL_PROCESSCASES_ALL, tabmodel[_data].params, true);
        setContentData(data.data);
        //console.log(data.data);
    }, [setContentData, tabmodel])

    const openResume = (_data) => {
        //console.log(_data);
        setDataResume(_data);
        setShowDialogData(true);
    }
    
    useEffect(()=>{
        setContentData(null);
        getContentData(activeIndex);
    },[activeIndex, getContentData])

    return (
        <div className="page-user-processcases">
            <div className="breadcrump">
                <UIBreadcrumb 
                    model={[
                        {label:'Início', url:'/user'},
                        {label:'Processos e casos'}
                    ]}
                />
                <h3>
                    Processos e casos
                </h3>
            </div>
            <div className="header mt-2 mb-2">
                <Toolbar 
                    style={{background:'rgba(0, 0, 0, 0.02)', boxShadow:'unset', border:0, margin:0, padding: '10px'}}
                    left={()=><></>} 
                    right={()=><>
                        <Button onClick={()=>{getContentData(activeIndex)}} icon="pi pi-sync" className="p-button-warning p-button-text mr-2" />
                        <Button onClick={()=>{navigate('/user/processcases/create')}} icon="pi pi-plus" className="p-button-success p-button-text" />
                    </>} 
                />
            </div>
            <div className="menu">
                <TabMenu 
                    activeIndex={activeIndex}
                    onTabChange={(e)=>setActiveIndex(e.index)}
                    model={tabmodel} />
            </div>
            <div className="grid mt-4">
                <div className="col-12">
                    <DataTable 
                        value={contentData} 
                        responsiveLayout="scroll"
                        emptyMessage="Não há casos cadastrados"
                        size='small'
                        stripedRows
                        rows={10}
                        paginator={contentData?.length > 10}
                        filterDisplay="row"
                    >
                        <Column body={(row)=> {
                            return row.id;
                        } } header="ID" />
                        <Column body={(row)=> {
                            return row.name;
                        } } header="Titulo" />
                        <Column body={(row)=> {
                            return row?.process_adm_number ? <span style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/processcases/${row?.id}`,{state:{tabActive:1}})}>{row?.process_adm_number}</span>  : '-'
                        } } header="Requisição ADM" />
                        <Column body={(row)=> {
                            return row?.process_number ? <span style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/processcases/${row?.id}`,{state:{tabActive:2}})}>{row?.process_number}</span>  : '-'
                        } } header="Processo" />
                        <Column body={(row)=> {
                                return <div style={{color:'var(--blue-500)', cursor:'pointer'}} onClick={()=>navigate(`/user/profile/${row?.client_id}`)}>{ row?.name_client}</div>;
                            } } 
                            header="Cliente"
                            showFilterMenu={false} 
                            field='name_client'
                            filter 
                            filterElement={(options)=>{
                                let clients_names = contentData?.map(item => item.name_client);
                                clients_names = [...new Set(clients_names)];
                                clients_names = clients_names?.map(item => ({label:item, value:item}));
                                return <Dropdown filter placeholder="Filtro" className="p-column-filter" showClear value={options?.value} options={clients_names} onChange={(e) =>{ options.filterApplyCallback(e.value, options.index)} } />
                                } 
                            }
                        />
                        <Column body={(row)=> {
                            return row?.cpf_cnpj
                        } } header="CPF/CNPJ" />
                        <Column body={(row)=> {
                            return formatShortName(row.name_user);
                        } } header="Responsável" 
                            showFilterMenu={false} 
                            field='name_user'
                            filter 
                            filterElement={(options)=>{
                                let clients_names = contentData?.map(item => item.name_user);
                                clients_names = [...new Set(clients_names)];
                                clients_names = clients_names?.map(item => ({label:item, value:item}));
                                return <Dropdown filter placeholder="Filtro" className="p-column-filter" showClear value={options?.value} options={clients_names} onChange={(e) =>{ options.filterApplyCallback(e.value, options.index)} } />
                                } 
                            }
                        />
                        <Column body={(row)=> {
                            return row?.process_cases_tag_name || '-';
                        } } header="Tag" />
                        <Column style={{width:'120px'}} body={(row)=> {
                            return row?.status_name;
                        } } header="Status" />
                        <Column style={{width:'120px'}} body={(row)=> {
                            return <>
                                    <Button onClick={()=>openResume(row)} className='p-button-text p-button-info mr-1' icon='pi pi-book' />
                                    <Button onClick={()=>navigate(`/user/processcases/${row.id}`)} className='p-button-text p-button-info' icon='pi pi-eye' />
                                   </>
                        } } header="" />
                    </DataTable>
                </div>
            </div>
            <Dialog header={`Caso id: ${dataResume?.id}`}
                visible={showDialogData} 
                style={{ width: '98%', maxWidth:'600px' }} 
                onHide={() =>{setShowDialogData(false);}}>
                    
            </Dialog>
        </div>
    )
}